import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NavifyauthService } from './navifyauth.service';


@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar, private router: Router, private auth: NavifyauthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: { 'X-Navify-Tenant': environment.tenantAlias },
      withCredentials: true
    });
    const error_status_401 = 401;
    const error_status_403 = 403;
    const error_status_500 = 500;

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {

        /* istanbul ignore else */
        if (error.status === error_status_401) {
          /* this.snackBar.open(`You don't have access to this application!`, '',{
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['expired-snackbar']
          }); */
          this.auth.logOut();
          // this.router.navigate(['login']);
        }
        /* istanbul ignore else */
        if (error.status === error_status_500) {
          this.snackBar.open('Server Error, Please contact administrator ', '', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['warning-snackbar']
          });
        }
        /* istanbul ignore else */
        if (error.status === error_status_403) {
          this.snackBar.open('Session Expired', '', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['expired-snackbar']
          });
          this.router.navigate(['login']);
        }
        return throwError(() => error);
      }));


  }
}
