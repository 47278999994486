<div class="loadingcontainer">
    <div *ngIf="isSubmitLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class mx-auto">
        </mat-progress-spinner>
    </div>
</div>
<div class="cont-fluid cmn_tbl invite-colab">
    <mat-card>
        <mat-card-title>
            <h2>{{i18n.projectMnt.collaborators.IC.TITLE}}</h2>
        </mat-card-title>
        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-modal-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class mx-auto">
                </mat-progress-spinner>
            </div>
        </div>
        <mat-card-content *ngIf="isLoading == false" id="invite-collaborator">
            <form [formGroup]="createForm">
                <mat-grid-list cols="3" rowHeight="100px">
                    <mat-grid-tile align="left">
                        <mat-form-field appearance="fill" *ngIf="noMappingUsers == false">
                            <mat-label>{{i18n.projectMnt.collaborators.IC.LBL_SU}} <mat-icon
                                    class="search-icon">{{i18n.ICONS.SEARCH}}</mat-icon>
                            </mat-label>
                            <mat-select [formControl]="userEmailList" class="filter_user"
                                (openedChange)="onEndFocus($event)" multiple>
                                <mat-select-trigger *ngIf="selectedEmail?.length > 0">
                                    {{selectedEmail?.[0].userEmail || ''}}
                                    <span *ngIf="(selectedEmail?.length || 0) > 1" class="example-additional-selection">
                                        (+{{(selectedEmail?.length || 0) - 1}} {{selectedEmail?.length === 2
                                        ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>

                                <mat-form-field appearance="fill" class="srhFld">
                                    <mat-label>{{i18n.projectMnt.collaborators.IC.LBL_SU}}
                                        <mat-icon class="search-icon"> {{i18n.ICONS.SEARCH}} </mat-icon>
                                    </mat-label>
                                    <input matInput placeholder="" name="search" (keyup)="searchValue($event)" />
                                </mat-form-field>
                                <mat-option *ngFor="let topping of filterdOptions" #matOption
                                    (click)="toggle(matOption.selected, matOption.value)" [value]="topping">
                                    {{topping.userEmail}} </mat-option>
                                <mat-option class="d-none"></mat-option>
                                <div [ngClass]="{'d-none': noRecord === false}" id="collaborators-noUser-div">
                                    <h2> {{i18n.projectMnt.collaborators.IC.LBL_UNF}}</h2>
                                    <p>{{i18n.projectMnt.collaborators.IC.LBL_DESC}}</p>
                                    <button mat-raised-button (click)="onInviteUser()" [disabled]="false">
                                        {{i18n.projectMnt.collaborators.IC.BTN_IU}}</button>
                                </div>
                            </mat-select>

                        </mat-form-field>

                        <div class="no-map-users" *ngIf="noMappingUsers == true">
                            <p class="mb-1 fw-bold"> {{i18n.projectMnt.collaborators.IC.LBL_NMU}}</p>
                            <p>{{i18n.projectMnt.collaborators.IC.LBL_CBNU}}</p>
                            <button mat-raised-button (click)="onInviteUser()"
                                [disabled]="false">{{i18n.projectMnt.collaborators.IC.BTN_IU}}</button>
                        </div>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <div>
                            <mat-label>
                                <h5>{{i18n.projectMnt.collaborators.IC.LBL_ON}} </h5>
                                <h3>{{this.orgName}}</h3>
                            </mat-label>
                        </div>

                    </mat-grid-tile>
                    <mat-grid-tile>
                        <section>
                            <mat-label>
                                <h5>{{i18n.projectMnt.collaborators.IC.LBL_PN}} </h5>
                                <h3>{{projectName}}</h3>
                            </mat-label>

                        </section>
                    </mat-grid-tile>
                </mat-grid-list>

                <mat-table
                    [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData"
                    matSort matSortDirection="asc" matSortDisableClear class="scrollbar invite-collaborator-table"
                    id="style-1">

                    <!-- User Email ID Column-->
                    <ng-container matColumnDef="userEmail">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="name-header">
                            {{i18n.projectMnt.collaborators.IC.LBL_UE}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="name-cell">{{element.userEmail}}
                        </mat-cell>
                    </ng-container>

                    <!-- User Full Name Column-->
                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectMnt.collaborators.IC.LBL_FN}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.firstName}}
                            {{element.lastName}}
                        </mat-cell>
                    </ng-container>

                    <!-- Project Role Column -->
                    <ng-container matColumnDef="projectRole">
                        <mat-header-cell *matHeaderCellDef class="projectRole-col-header"> Project Role
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <mat-form-field appearance="fill" class="role_fld">
                                <mat-label>{{i18n.projectMnt.collaborators.IC.LBL_SR}}</mat-label>
                                <mat-select (selectionChange)="onChange(element, $event.value)" required>
                                    <mat-option *ngFor="let item of roles" [value]="item.roleName"
                                        [disabled]="(disableDataControllerRole == true && item.roleName.toLowerCase() === role.data_controller) || item.roleName.toLowerCase() === role.imcore_member_data_ingress_only">
                                        {{item.roleName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef class="action-header">
                            <mat-icon *ngIf="dataSource.data.length > 0">{{i18n.ICONS.DELETE}}</mat-icon>
                        </mat-header-cell>
                        <mat-cell class="action-cell" *matCellDef="let element ;  let j = index;">
                            <mat-icon (click)="onDelete(element)">{{i18n.ICONS.DELETE}}</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="empty-row">
                        <mat-cell class="d-flex justify-content-center"
                            *matCellDef="let element">{{i18n.projectMnt.collaborators.IC.LBL_DESC2}} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];">
                    </mat-row>
                </mat-table>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-card-footer class="me-3">
        <div mat-dialog-actions>
            <button mat-stroked-button class="basic-btn" (click)="onDecline()">{{i18n.BTN_CANCEL}}</button>
            <button mat-raised-button [disabled]="showInvite" (click)="submitInvite()">{{i18n.BTN_INVITE}}</button>
        </div>
    </mat-card-footer>
</div>