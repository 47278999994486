import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { InviteuserComponent } from 'src/app/pages/user-management/inviteuser/inviteuser.component';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/pages/user-management/user-management.service';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { CommonService } from 'src/app/services/common.service';
import { environment as env} from 'src/environments/environment';
import { i18n } from 'src/app/i18n.en';

export interface userObj {
  firstName:string;
  lastName: string;
  status: string;
  tcAccepted: boolean;
  userEmail:string;
  userType:string;
  userUuid:string;
  username: Array<[]>;
}

@Component({
  selector: 'app-manage-data-controller',
  templateUrl: './manage-data-controller.component.html',
  styleUrls: ['./manage-data-controller.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageDataControllerComponent implements OnInit {

  i18n = i18n;
  assignForm: FormGroup
  displayedColumns: string[] = ['userEmail', 'firstName', 'projectRole'];
  hasNoUserData = false;
  dataSource: any;
  isLoading = false;
  showSearchCancelBtn: boolean;
  profile: any;
  emptyData = new MatTableDataSource([{ empty: "row" }]);

  role:any = env.config.roles;
  firstname: string;
  userEmailList = new FormControl();
  selectedUser: any;
  filterdOptions: any;
  noRecord = false;
  userNames: any;
  showInvite = false;
  disableDataControllerRole = false;
  projectName: string;
  orgName: string;
  rolesData: any = [];
  result: any;
  toppingList: any;
  apiData: any;
  roles: any = [];
  userResult: any;
  userListArray: any = [];
  username: any;
  inviteUserData: any;
  userRole: string;
  collaboratorsObject: any;
  projectKey: string;
  noMappingUsers = false;
  isDataControllerExist = false;
  isSubmitLoading = false;
  selectedEmail: any =[];
  currentDataController: string;
  selected: string;
  onFocusLabelStyle: boolean = false;
  existingUserId: string;
  newUserUuid: string;
  existingRoleHashKey: string;

  @ViewChild(MatSort) sort: MatSort;

  public inputSearch: FormControl = new FormControl('');

  constructor(public toastrService: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any, private readonly dialogRef: MatDialogRef<ManageDataControllerComponent>, private readonly matDialog: MatDialog, private readonly navifyServ: NavifyauthService, 
    private readonly userService: UserManagementService, private readonly projectService: ProjectManagementService, private readonly commonService: CommonService) {
    this.currentDataController = data.currentDataController;
    this.projectName = data.projectName;
    this.projectKey = data.projectKey;
    this.orgName = data.orgName;
    this.existingUserId = data.existingUserId;
    this.existingRoleHashKey = data.existingRoleHashKey;
  }

  ngOnInit(): void {
    //this.projectKey = 'testp1691058256279';
    //this.projectKey = 'ravit1690984167308';
    this.orgName = 'imCORE';  
    this.getRoles();
    this.getProjectUserList();
    this.assignForm = new FormGroup({
      //roleName: new FormControl("", Validators.required),
      userEmailList: new FormControl("", Validators.required)
    });
    this.showInvite = true;
    //this.onInviteUser();
  }

  getRoles() {
    this.commonService.getPermissionRoles(this.orgName).subscribe((data: any) => {
      this.roles = data.result.orgProjectRoles;
    });
  }

  getProjectUserList() {
    this.isLoading = true;    
    this.projectService.getProjectSearchUserList(this.projectKey, this.orgName).subscribe((data: any) => {
      this.toppingList = data.result;
      this.isLoading = false;
      this.filterUsers();
    });
  }

  onSearchCancelBtn() {
    this.showSearchCancelBtn = false;
    this.inputSearch.reset();
  }
  async logout() {
    await this.navifyServ.logOut();
  }

  onDecline() {
    this.dialogRef.close();
  }

  filterUsers() {
    this.filterdOptions = this.toppingList;
  }

  searchValue(event: any) {
    this.selectedUser = event.target.value;
    this.filterdOptions = this.toppingList.filter(
      (item: any) => (item.userEmail.toLowerCase()).includes(this.selectedUser.toLowerCase()));

    if (this.filterdOptions.length === 0) {
      this.noRecord = true;
    }
    else {
      this.noRecord = false;
    }
  }

  onEndFocus(event: any) {  
    this.userEmailList.setValue(this.selectedEmail);    
    this.filterdOptions = this.toppingList;
    if (this.filterdOptions.length === 0) {
      this.noRecord = true;
    }
    else {
      this.noRecord = false;
    }  
    
  }

  onDelete(elm: any) {
    this.selectedEmail.splice(this.selectedEmail.indexOf(elm,0),1)
    elm.role = '';
    this.dataSource.data = this.dataSource.data
      .filter((i: any) => i !== elm)
      .map((i: any, idx: any) => (i.position = (idx + 1), i));
    this.userNames = this.dataSource.data;
    this.userEmailList.setValue(this.userNames);
    this.enableInvite();
  }

  onInviteUser() {
    const dialogRef = this.matDialog.open(InviteuserComponent, {
      width: '660px',
      data: {
        isEdit: false,
        fromComponent: "project-audit",
        projectName: this.projectName,
        orgName: this.orgName,
        projectKey: this.projectKey,
        existingUserId: this.existingUserId,
      }
    });
    this.dialogRef.close();
    dialogRef.afterClosed().subscribe((result) => {
      if(result !==''){
        this.dialogRef.close('yes');
        window.location.reload();
      }
    });
  }

  compareById(o1?: any, o2?: any, from?:string) {
    return o1.userEmail === o2;
  }

  onChange(elem: any, roleName: any) {
    let roleId;
    this.roles.forEach((role: any) => {
      if (role.roleName == roleName) {
        roleId = role.roleId;
      }
    });

    const roleObj = {
      roleName: roleName,
      roleId: roleId
    }
    elem['role'] = roleObj;
    this.dataSource.data.filter((element: any) => { element.role !== '' ? this.showInvite = false : this.showInvite = true });
    this.enableInvite();
  }

  enableInvite() {
    this.disableDataControllerRole = false;
    this.showInvite = false;
    for (let k = 0; k < this.dataSource.data.length; k++) {
      if (!this.dataSource.data[k].role) {
        this.showInvite = true;
      } 
    }
   if(this.dataSource.data.length === 0) {
     this.showInvite = true;
   }

    if (this.isDataControllerExist) {
      this.disableDataControllerRole = true;
    }
    else {
      this.dataSource.data.forEach((element: any) => {
        if (element.role?.roleName === "Data Controller") {
          this.disableDataControllerRole = true;
        }
      });
    }
  }

  onAssign() {
    this.isSubmitLoading = true; 
    const obj = {
      existingUserId: this.existingUserId,
      existingRoleHashKey: this.existingRoleHashKey,
      newUserUuid: this.newUserUuid 
    }
    this.userService.manageDataController(obj).subscribe({
        next: (res:any)=>{ 
          if(res.responseCode==='200'||res.responseCode==='201'){         
          this.dialogRef.close('yes');         
          this.toastrService.success(res.messages);
          this.ngOnInit();
          }else{
            this.dialogRef.close();
            this.toastrService.error(res.message);
          } 
          window.location.reload();     
        },
        error: (err)=>{         
        this.dialogRef.close();
        },
        complete: ()=>{  
          this.getProjectUserList();
          this.isSubmitLoading = false;   
        }
      });
  }
  selectUser(selectValue: any){
    console.log("value select: ", selectValue);
    this.newUserUuid = selectValue.userUuid;
    this.onFocusLabelStyle = true;
  }
  optionSelected(event: any): void {
    const selectedValue = event.option.value.userEmail;
    this.userEmailList.setValue(selectedValue);
  }

}
