<!-- Page Loader -->
<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<mat-card>
    <mat-card-header>
        <mat-card-title class="title-matcard">
            Audit History - {{projectName}}
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="no-data empty-dta" *ngIf="datasource?.data?.length === 0">{{i18n.projectMnt.LBL_NDF}}</div>

        <mat-table [dataSource]="datasource" *ngIf="datasource?.data?.length > 0" matSort 
            matSortActive="latestAuditDate" matSortDirection="desc"
            matSortDisableClear>

            <!-- Last Audit Date Column-->
            <ng-container matColumnDef="latestAuditDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectAudit.TAB_AH_CONTENT.TBL_HDR_LAD}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element.latestAuditDate)?(element.latestAuditDate|date : 'y-MMM-dd'):'-'}}
                </mat-cell>
                
            </ng-container>

            <!-- Audited By Column -->
            <ng-container matColumnDef="auditedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectAudit.TAB_AH_CONTENT.TBL_HDR_ABY}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.auditedBy}} </mat-cell>
            </ng-container>

            <!-- Audit Result Column -->
            <ng-container matColumnDef="auditResult">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectAudit.TAB_AH_CONTENT.TBL_HDR_AR}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon class="partially-done"
                        *ngIf="((element?.auditResult?.auditResult | lowercase) === 'passed with warning')">done</mat-icon>
                    <mat-icon class="done"
                        *ngIf="((element?.auditResult?.auditResult | lowercase) === 'audit passed')">check_circle_outline</mat-icon>
                    {{(element?.auditResult?.auditResult)?(element?.auditResult?.auditResult):'-'}} </mat-cell>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="action-header">
                    {{i18n.projectAudit.TAB_AH_CONTENT.TBL_HDR_ATN}} </mat-header-cell>
                <mat-cell class="action-cell" *matCellDef="let element">
                    <button mat-stroked-button color="primary" (click)="auditResultOnView(element)">View</button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="userviewColumns;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: userviewColumns;"></mat-row>

        </mat-table>

    </mat-card-content>
    <mat-card-footer class="p-10">
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
            [hidden]="datasource?.data?.length === 0">
        </mat-paginator>
    </mat-card-footer>

</mat-card>