<li [matMenuTriggerFor]="profileMenu">
    <mat-icon>{{i18n.ICONS.PROFILE}}</mat-icon>
    <mat-menu class="profileMenu" #profileMenu="matMenu">
        <ul>
            <li>
                <span class="border rounded-circle p-2 profile-name-bg text-white">{{getShortName()}}</span>
            </li>
            <li>
                {{profile.displayName}}
            </li>
            <li>
                {{profile.username}}
            </li>
        </ul>
        <mat-divider></mat-divider>
        <mat-list role="list" *ngIf="showResetPwd">
            <mat-list-item role="listitem"><a (click)="reset()">{{i18n.LINKS.PR}}</a></mat-list-item>
        </mat-list>
        <mat-divider *ngIf="showResetPwd"></mat-divider>
        <div class="bt">
            <button class="mt-2 text-end border-2 logoff-button" (click)="logout()">{{i18n.BTN_LOGOFF}}</button>
        </div>


    </mat-menu>
</li>