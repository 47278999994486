import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { i18n } from 'src/app/i18n.en';
import { AuditResultsComponent } from '../audit-results/audit-results.component';
import { ActivatedRoute } from '@angular/router';
import { AuditService } from '../../audit.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

export interface auditSettings {
  firstAuditDate: string,
  currentCycleBeginDate: string,
  cadence: string,
  numberOfDays: number,
  auditStatus: string,
  latestAuditDate: null,
  startAudit: boolean,
  auditResult: boolean
}

@Component({
  selector: 'app-audit-start-results',
  templateUrl: './audit-start-results.component.html',
  styleUrls: ['./audit-start-results.component.scss']
})
export class AuditStartResultsComponent implements OnInit {
  i18n = i18n;
  auditSettings;
  projectName: string;
  isLoading: boolean = false;
  queryParamData: {};
  profile;
  auditId;
  @Input('projectKey') projectKey;
  constructor(private readonly matDialog: MatDialog, public toaster: ToastrService, public activatedRoute: ActivatedRoute,
     private readonly storageService: StorageService, private readonly auditService: AuditService) { }

  ngOnInit(): void {
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.getProjectAuditDetails();
  }


  getProjectAuditDetails() {
    this.isLoading = true;
    this.auditService.getAuditDetails(this.projectKey).subscribe(res => { 
      console.log("res['result'].projectDetails.auditSettings: ", res['result'].projectDetails.auditSettings);    
      const auditSet = res['result'].projectDetails.auditSettings;
     this.auditSettings = auditSet;     
      this.projectName = res['result'].projectName;
      this.isLoading = false;
    })

  }

  onAuditResults() {
    const dialogRef = this.matDialog.open(AuditResultsComponent, {
      width: '620px',
      data: {
        auditId: this.auditSettings['auditId'],
        auditSettings: this.auditSettings,
        projectKey: this.projectKey

      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toaster.success('Audit result saved successfully');
        this.getProjectAuditDetails();
      }
    })
  }
  onStartAudit() {
    const obj1 = {
      "userUuid": this.storageService.secureStorage.getItem('userUuid'),
      "orgKey": environment.config.orgKey,
      "auditedBy": this.profile.username,
      "projectKey": this.projectKey,
      "projectName": this.projectName,
      "auditSettings": this.auditSettings
    }
    this.isLoading = true;
    this.auditService.getStartAudit(obj1).subscribe({
      next: (res) => {
        this.toaster.success('Audit started successfully');
        this.getProjectAuditDetails();
      },
      error: (error) => {
        this.isLoading = false;
      }
    })
  }


}
