<div class="cont-fluid projDetails" *ngIf="projDetailsData !== {}">
    <div class="loadingcontainer">
        <div *ngIf="isLoading" class="loading-modal-spinner">
            <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
            </mat-progress-spinner>
        </div>
    </div>
    <h3 class="title-prjDetails">
        {{i18n.projectMnt.PD.TITLE}}
        <!-- <button mat-raised-button (click)="editDetails(projDetailsData)" *ngIf="currentUserPermissions?.projectMgntAccess?.editProject">{{i18n.projectMnt.PD.BTN_ED}}</button> -->
        <button mat-raised-button (click)="editDetails(projDetailsData)" *ngIf="showEditPrj">{{i18n.projectMnt.PD.BTN_ED}}</button>
    </h3>

    <h3> {{i18n.projectMnt.PD.SUB_TITLE_PI}}</h3>
    <mat-grid-list cols="4" rowHeight="100px" >
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.PN}}</p>
                <h4> {{projDetailsData.projectName}}</h4>               
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.ON}}</p>
                <h4> {{projDetailsData.orgKey}}</h4>
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.R}}</p>
                <h4> {{projDetailsData?.region?.regionName}}</h4>
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.ISD}}</p>
                <h4> {{projDetailsData.productOwner}}</h4>
            </mat-label>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="4" rowHeight="100px">
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.SN}}</p>
                <h4> {{projDetailsData.siteName? projDetailsData.siteName : '-'}}</h4>
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3">
            <mat-label>
                <p>{{i18n.projectMnt.PD.PD}}</p>
                <h4> {{projDetailsData.projectDescription}}</h4>                
            </mat-label>
        </mat-grid-tile>       
    </mat-grid-list>

    <mat-divider></mat-divider> <br />
    
    <h3>{{i18n.projectMnt.PD.SUB_TITLE_OI}}</h3>

    <mat-grid-list cols="5" rowHeight="100px">
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.DC}}</p>
                <h4> {{projDetailsData?.dataClassification?.name}}</h4>
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.AB}}</p>
                <h4> {{projDetailsData.publishToOrgData}}</h4>
            </mat-label>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="5" rowHeight="100px">
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.SAR}}</p>
                <h4> {{projDetailsData.auditRule? projDetailsData.auditRule : '-'}}</h4>
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>{{i18n.projectMnt.PD.SFAD}}</p>
                <h4> {{projDetailsData.firstAuditDate? projDetailsData.firstAuditDate : '-'}}</h4>
            </mat-label>
        </mat-grid-tile>
    </mat-grid-list>
</div>