<mat-card class="project-overview">
    <mat-card-header>
        <mat-card-title class="title-matcard">
            {{i18n.projectMnt.PO.TITLE}}
        </mat-card-title>
        <div class="search-btn-div">
            <mat-form-field *ngIf="overviewList?.data?.length > 0" appearance="fill" floatLabel="always">
                <mat-icon matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon>
                <mat-icon matSuffix [hidden]="!showSearchCancelBtn"
                    (click)="onSearchCancelBtn()">{{i18n.ICONS.CANCEL}}</mat-icon>
                <input matInput placeholder="Search here" (keyup)="applyFilter($event)" [formControl]="inputSearch"
                    autocomplete="off" />
            </mat-form-field>
            <button mat-raised-button (click)="createProject()"
                *ngIf="currentUserPermissions?.projectMgntAccess?.createProject">{{i18n.projectMnt.PO.BTN_ADD}}</button>
        </div>

    </mat-card-header>
    <mat-card-content>
        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-modal-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
                </mat-progress-spinner>
            </div>
        </div>
        <div class="no-data empty-dta" *ngIf="overviewList?.data?.length === 0 || overviewTblLength?.length === 0">
            {{i18n.projectMnt.LBL_NDF}}</div>
        <mat-table [dataSource]="overviewList" *ngIf="overviewList?.data?.length > 0" matSort
            matSortActive="createdDate" matSortDirection="desc" matSortDisableClear>

            <!-- Radio button column -->
            <!-- <ng-container matColumnDef="radio">
                <th mat-header-cell *matHeaderCellDef class="radio-btn-headercell">
                </th>
                <td mat-cell *matCellDef="let element" class="radio-btn-cell">

                    <mat-radio-button (click)="onSelectProject(element)"></mat-radio-button>
                </td>
            </ng-container> -->

            <!-- Project Name Column-->
            <ng-container matColumnDef="projectName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Project Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.projectName}}
                </mat-cell>
            </ng-container>

            <!-- Project ID Column -->
            <ng-container matColumnDef="projectKey">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Project ID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="copyLink">
                    <mat-icon [cdkCopyToClipboard]="element.projectKey"
                        (click)="onCopyProjectKey()">content_copy</mat-icon>
                    {{element?.projectKey}} </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.status}} </mat-cell>
            </ng-container>

            <!-- Organization Role Column -->
            <ng-container matColumnDef="region">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Region </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.region.regionName}} </mat-cell>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.status === 'Creation in progress'? '-' : element?.createdDate | date : 'y-MMM-dd HH:mm:ss' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="action-header"> Actions </mat-header-cell>
                <mat-cell class="action-cell" *matCellDef="let element">
                    <button
                        *ngIf="element.status==='Created' && service.permission(element.roleId===null?orgRoleId:element.roleId, 'accessProject')"
                        mat-stroked-button (click)="redirectToProjectdetails(element)"
                        color="primary">{{i18n.BTN_SELECT}}</button>
                        <button
                        *ngIf="element.status==='Rejected' && service.permission(element.roleId===null?orgRoleId:element.roleId, 'accessProject')"
                        mat-stroked-button (click)="redirectToProjectdetails(element)"
                        color="primary">{{i18n.BTN_SELECT}}</button>
                        <button
                        *ngIf="element.status==='Transfer pending' && service.permission(element.roleId===null?orgRoleId:element.roleId, 'accessProject')"
                        mat-stroked-button (click)="redirectToTransferPage(element)"
                        color="primary" class="transfer-button">Transfer Project</button>
                        <button
                        *ngIf="element.status==='Draft' && service.permission(element.roleId===null?orgRoleId:element.roleId, 'accessProject')"
                        mat-stroked-button (click)="redirectToContinuePage(element)"
                        color="primary">Continue</button>
                        <button
                        *ngIf="element.status==='Creation in progress' && service.permission(element.roleId===null?orgRoleId:element.roleId, 'accessProject')"
                        mat-stroked-button [disabled]="true" (click)="redirectToContinuePage(element)"
                        color="primary">Transfer Project</button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="projectviewColumns;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: projectviewColumns;"></mat-row>

        </mat-table>
    </mat-card-content>

    <mat-card-footer class="alend">
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
            [hidden]="overviewList?.data?.length === 0">
        </mat-paginator>
    </mat-card-footer>
</mat-card>