<div class="user-details cmn_tbl">

    <!-- Page Loader -->
<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

    <mat-card>
        <mat-card-header>
            <mat-card-title>
                {{fullName}}
            </mat-card-title>
            <div class="username-div">
                <p class="user-title">User Name</p> 
                <p class="user-name">{{userName}} </p>  
            </div>
        </mat-card-header>                
         
        <mat-card-content>
            <div class="no-data empty-dta" *ngIf="projectviewDataSource?.data?.length === 0">{{i18n.projectMnt.LBL_NDF}}</div>
            <mat-table [dataSource]="projectviewDataSource" *ngIf="projectviewDataSource?.data?.length > 0" matSort
                matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="projectName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="prjName-header"> Project Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="prjName-cell"> {{element.projectName}}
                    </mat-cell>
                </ng-container>

        
                <ng-container matColumnDef="roleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="role-header"> Role Assigned </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="role-cell"> {{element?.roleName}} </mat-cell>
                </ng-container>

            
                <ng-container matColumnDef="trianingStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{(element?.trianingStatus.toLowerCase()==='completed')?'Active':'Invited'}} </mat-cell>
                </ng-container>



                <mat-header-row *matHeaderRowDef="projectviewColumns; sticky:true;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: projectviewColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
        <mat-card-footer class="user-details-footer">
            <button mat-stroked-button (click)="closeDialog()">{{i18n.projectMnt.UO.UD.BTN_CLOSE}}</button>
        </mat-card-footer>
    </mat-card>
</div>


