
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectDetailsComponent {
  i18n = i18n;
  currentUserPermissions:any;
  // inputText: string = 'Project Details';
  // collabinputText: string = 'Collaborator';
  isLoading = false;
  projectDetailsList: any = {};
  projDetailsData: any = {};
  roles:any;
  orgRole: any;
  showEditPrj: boolean;

  constructor(public service: ProjectManagementService,public commonSer:CommonService, public storageService: StorageService, public activatedRoute: ActivatedRoute, public router: Router, public toastr: ToastrService) {
   
  }

  ngOnInit(): void {   
    this.activatedRoute.queryParams.subscribe(queryParams => {        
    this.projectDetailsList = queryParams      
    });
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.getProjectDetails();
    this.showEditPrj = this.currentUserPermissions?.projectMgntAccess?.editProject;   
  }

  getProjectDetails(){
    this.isLoading = true;
    this.service.getProjectDetails(this.projectDetailsList.projectKey).subscribe((data:any)=>{
      this.projDetailsData  = data.result;
      this.isLoading = false;
    })
  }

  editDetails(projDetailsData:any){ 
    const tempV = projDetailsData    
    tempV.classificationcode = projDetailsData.dataClassification.classificationcode;
    tempV.type = projDetailsData.dataClassification.type;
    tempV.name = projDetailsData.dataClassification.name;
    tempV.regionValue = projDetailsData.region.regionValue;
    tempV.regionName = projDetailsData.region.regionName;   
    this.router.navigate(['projectmanagement/createproject'], {
      queryParams:{...tempV, 'roleId':this.projectDetailsList.roleId}
   }); 
  }

}
