import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectManagementService } from '../project-management.service';
import { StepperObj5, projectCreation } from '../add-project/model/model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { i18n } from 'src/app/i18n.en';
import { environment } from 'src/environments/environment';
import { InviteuserComponent } from '../../user-management/inviteuser/inviteuser.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-project-files-transfer-details',
  templateUrl: './project-files-transfer-details.component.html',
  styleUrls: ['./project-files-transfer-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectFilesTransferDetailsComponent implements OnInit {
  i18n = i18n;
  projectCreation = projectCreation;
  formDataObj: StepperObj5;
  projTabData: StepperObj5;
  projectID: string;
  isAccept: FormControl;
  userEmailList: FormControl;
  filterdOptions;
  toppingList;
  noRecord: boolean = false;
  isLoading: boolean;
  orgName: string;
  selectedUser: string;
  role = environment.config.roles;
  dataControllerExist: string;
  noMappingUsers: boolean = false;
  disableDataControllerRole: boolean = false;
  projectName: string;
  dataControllerName: string;
  inviteUser: string = "No";
  profile: { displayName: string, username: string };
  selectedObj;
  constructor(public readonly service: ProjectManagementService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly storageService: StorageService,
    private readonly matDialog: MatDialog,
    private readonly toastrService: ToastrService) {
    this.isAccept = new FormControl(false);
    this.userEmailList = new FormControl(null, [Validators.email]);
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
    this.projectName = this.activatedRoute.snapshot.queryParams["projectName"];
    this.orgName = environment.config.orgKey;
  }



  ngOnInit(): void {
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'))
    this.getProjectDetails();
    this.getProjectUserList();
    this.updateTheValuesExist();
  }

  getProjectDetails() {
    this.dataControllerName = this.profile.username
    this.service.getProjectDetails(this.projectID).subscribe((data: any) => {
      this.projTabData = data.result.projectDetails;
      this.formDataObj = this.projTabData;
      this.filterUsers();
    });
  }
  getProjectUserList() {
    this.isLoading = true;
    const PAYLOAD = {
      "orgkey": this.orgName,
      "email": this.dataControllerName
    }
    this.service.getTransferUserList(PAYLOAD).subscribe((data: any) => {
      this.toppingList = data.result;
      this.isLoading = false;
    });
  }
  filterUsers() {
    this.filterdOptions = this.toppingList;
  }
  searchValue(event: any) {
    this.selectedUser = event.target.value;
    this.filterdOptions = this.toppingList?.filter(
      (item: any) => (item.userEmail.toLowerCase()).includes(this.selectedUser.toLowerCase()));

    if (this.filterdOptions?.length == 0) {
      this.noRecord = true;
    }
    else {
      this.noRecord = false;
    }
  }


  onInviteUser() {
    const dialogRef = this.matDialog.open(InviteuserComponent, {
      width: '660px',
      data: {
        isEdit: false,
        fromComponent: "project-audit",
        projectName: this.projectName,
        orgName: this.orgName,
        projectKey: this.projectID,
        fromApi: 'project-transfer',
        existingUserId: this.storageService.secureStorage.getItem('userUuid'),
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result !== '' && result !== 'cancel') {
        this.getProjectUserList();
        this.userEmailList.setValue(result);
        this.inviteUser = 'Yes';
      }
    });
  }

  transferFormData(clickedEvent: string) {
    this.toppingList.find((x) => {
      if (x.userEmail === this.userEmailList.value) {
        this.selectedObj = x
      }
    })
    let acceptedText;
    if (this.isAccept.value) {
      acceptedText = 'Yes'
    }
    else {
      acceptedText = 'No'
    }
    return {
      "projectKey": this.projectID,
      "projectName": this.projectName,
      "roleName": "imCORE Scientific Director",
      "orgKey": this.orgName,
      "userId": this.storageService.secureStorage.getItem('userUuid'),
      "roleId": this.storageService.secureStorage.getItem('orgRoleId'),
      "projectDetails": {
        "transfer": {
          "transferStatus": clickedEvent,
          "consent": "abc",
          "acceptText": "accepttext",
          "isAccept": acceptedText,
          "inviteUser": this.inviteUser,
          "transferUUID": this.selectedObj?.userUuid ? this.selectedObj?.userUuid : this.storageService.secureStorage.getItem('userUuid'),
          "transferTo": this.userEmailList.value ? this.userEmailList.value : this.profile.username,
          "transferOn": new Date()
        }
      }
    }
  }

  optionSelected(event: any): void {
    const selectedValue = event.option.value.userEmail;
    this.userEmailList.setValue(selectedValue);
  }
  onClickSaveandExit(event: string) {
    this.service.sendTranferData(this.transferFormData(event)).subscribe({
      next: () => {
        this.router.navigate(['projectmanagement'])
      }
    });
  }

  sendTransferData(event: string) {
    this.service.sendTranferData(this.transferFormData(event)).subscribe({
      next: () => {
        this.router.navigate(['projectmanagement'])
        const message = 'Project Transferred successfully'
        this.toastrService.success(message);
      }
    });
  }
  updateTheValuesExist() {
    this.service.getProjectDetails(this.projectID).subscribe((data: any) => {
      const transferData = data?.result?.projectDetails.transfer;
      if (transferData && transferData !== null && transferData.transferTo !== this.dataControllerName) {
        this.userEmailList.setValue(transferData.transferTo);
        this.userEmailList.markAsPristine();
      }
    });
  }
}

