<div class="cont project-file">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto backbutton">
                        <mat-icon class="backbutton" [routerLink]="['/projectaudit']">{{i18n.ICONS.BACK}}</mat-icon>
                    </li>
                    <li class="px-2 w-auto my-auto"><span
                            class="me-2 project-sub-title">{{i18n.projectAudit.TITLE}}</span>
                        <span class="me-2 fw-normal pipe-separator">|</span> <span
                            class="fs-6 project-sub-title">{{queryParamData.projectName}}</span>
                    </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>



    <div class="cont-fluid cmn_tbl">

        <!-- breadcrumb -->
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
        <li>
            <mat-icon>navigate_next</mat-icon>
        </li>
        <li class="breadcrumb-item"><a routerLink="/projectaudit">{{i18n.BREADCRUMB.PA}} - {{queryParamData.projectName}}</a></li>
        <li>
            <mat-icon>navigate_next</mat-icon>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{breadcrumbTitle}}</li>
    </ol>
</nav>

        <mat-card class="p0">
            <mat-tab-group (selectedTabChange)="onTabClick($event)" [selectedIndex]="selectedTabIndex">
                <mat-tab [label]="i18n.projectAudit.TABS.TAB_SAR">
                    <ng-template matTabContent>
                        <app-audit-start-results [projectKey]="queryParamData.projectKey"></app-audit-start-results>
                    </ng-template>

                </mat-tab>

                <mat-tab [label]="i18n.projectAudit.TABS.TAB_PI">
                    <ng-template matTabContent>
                        <app-project-information></app-project-information>
                    </ng-template>
                </mat-tab>

                <mat-tab [label]="i18n.projectAudit.TABS.TAB_MU">
                    <ng-template matTabContent>
                        <app-manage-users></app-manage-users>                    
                    </ng-template>
                </mat-tab>

                <mat-tab [label]="i18n.projectAudit.TABS.TAB_AH">
                    <ng-template matTabContent>
                        <app-audit-history [projectKey]="queryParamData.projectKey" [projectName]="queryParamData.projectName"></app-audit-history>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>
        </mat-card>
    </div>

</div>

<!--  -->