import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InviteuserComponent } from '../inviteuser/inviteuser.component';
import { NavifyauthService } from '../../../services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApproveuserComponent } from '../approveuser/approveuser.component';
import { UserManagementService } from '../user-management.service';
import { i18n } from '../../../i18n.en';
 

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class UsermanagementComponent implements OnInit {

  i18n = i18n;

  displayedColumns: string[] = ['radio', 'userEmail', 'firstName', 'lastName', 'roleDetails.roleName', 'roleDetails.trianingStatus', 'actions'];
  hasNoUserData = false;
  dataSource: any;
  isLoading = false;
  showSearchCancelBtn: boolean;
  canEditUser: boolean;
  profile: any;
  selectedRowElement: any;
  orgRole:string | null;
  currentUserPermissions:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public inputSearch: FormControl = new FormControl('');

  constructor(private readonly userService: UserManagementService, private matDialog: MatDialog, private navifyServ: NavifyauthService, private storageService: StorageService) {
    this.dataSource = [];
  }

  ngOnInit(): void {
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.getTableData();
    this.orgRole = this.storageService.secureStorage.getItem("role");
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
  }
  getTableData() {
    this.isLoading = true;
    this.userService.getUserDetails().subscribe((data: any) => {
      this.isLoading = false;
      this.canEditUser = false;     
      this.dataSource = new MatTableDataSource(data.result);
      this.dataSource.sortingDataAccessor = (item:any, property:any) => {
        switch(property) {
          case 'roleDetails.roleName': return item.roleDetails.roleName;
          case 'roleDetails.trianingStatus': return item.roleDetails.trianingStatus.toLowerCase();
          default: return item[property];
        }
      };
      setTimeout(() => this.dataSource.paginator = this.paginator);
      setTimeout(() => this.dataSource.sort = this.sort);     
      this.hasNoUserData = (this.dataSource.data.length === 0) ? true : false;
      this.dataSource.filterPredicate = function(datas:any, filter: string): boolean {           
        return datas.userEmail.toLowerCase().includes(filter)||datas.firstName.toLowerCase().includes(filter)
        ||datas.lastName.toLowerCase().includes(filter)||datas.roleDetails?.roleName.toLowerCase().includes(filter)
        ||datas.roleDetails?.trianingStatus.toLowerCase().includes(filter)||!filter;
      };  
    })
  }

  applyFilter(event: Event) {
    this.showSearchCancelBtn = true;
    const filterValue = (event.target as HTMLInputElement).value;    
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(!filterValue){
      this.showSearchCancelBtn = false;
    }
  }

  onSearchCancelBtn() {
    this.showSearchCancelBtn = false;
    this.inputSearch.reset();
    this.getTableData();
  }
  async logout() {
    await this.navifyServ.logOut();
  }

  onInviteUser() {
    const dialogRef = this.matDialog.open(InviteuserComponent, {
      width: '660px',
      data: {
        isEdit: false,
        fromComponent: 'userManagement'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {      
      if (result === 'invited') {
        this.getTableData();
      }
    })
  }

  onSelectUser(element: any) {
    this.canEditUser = true;
    this.selectedRowElement = element;
  }

  onEditUser() {
    const dialogRef = this.matDialog.open(InviteuserComponent, {
      width: '620px',
      data: {
        isEdit: true,
        selectedRowElement: this.selectedRowElement,
        fromComponent: 'userManagement'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {      
      if (result === 'updated') {
        this.getTableData();
      }
    })
  }

  onApprove(element: any) {
    const dialogRef = this.matDialog.open(ApproveuserComponent, {
      // disableClose: true,
      width: '450px',
      height: '300px',
      data: {
        isEdit: true,
        selectedRowElement: element
      }
    });
    dialogRef.afterClosed().subscribe((result) => {    
      // console.log(result)
      if (result) {
        this.getTableData();
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
    this.dataSource.filterPredicate = function(data:any, filter: string): boolean {           
      return data.userEmail.toLowerCase().includes(filter)||data.firstName.toLowerCase().includes(filter)
      ||data.lastName.toLowerCase().includes(filter)||data.roleDetails?.roleName.toLowerCase().includes(filter)||!filter;
  };  
  }
}
function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.userManagement.PAGINATION;

  return customPaginatorIntl;
}