import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavifyauthService } from './services/navifyauth.service';
import { StorageService } from './services/storage.service';
import { CommonService } from './services/common.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  profile: any;
  titleName: string;
  title = 'Welcome to starCAP';
  constructor(public router: Router, readonly navifyServ: NavifyauthService, readonly titleService: Title, readonly storageService: StorageService, readonly service:CommonService){
   this.service.headertitleMessgae$.subscribe(res => this.titleName= res.title);
   router.canceledNavigationResolution = 'computed';
  }
  
ngOnInit() {
  this.titleService.setTitle(this.title);
  this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
  // console.log(this.profile);
  this.navifyServ.getLoginResults();
  this.navifyServ.getProfile().subscribe((data)=>{
    // console.log(data);
  })
  //this.service.rumConfig();
}
  async logout(){
    await this.navifyServ.logOut();
  }

  

}
