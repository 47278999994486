<div class="create-project-transfer">
  <mat-grid-list cols="1" rowHeight="50px" class="popup-close">
    <mat-grid-tile class="header-close">
      <a>
        <mat-icon (click)="service.onCancelEvent()">{{projectCreation.icon.close}}</mat-icon>
      </a>
      <h3>Project Transfer</h3>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="list-card">
    <mat-card class="container inner-section">
      <mat-card-title>1.Review Summary</mat-card-title>
      <app-review-tab-summary [formDataObj]="formDataObj" [projectID]="projectID"></app-review-tab-summary>
    </mat-card>
    <mat-card class="container inner-section terms">
      <mat-card-title>2.Review Details</mat-card-title>
      <p>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt
        Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation
        Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.</p>
      <div class="accept-container">
        <mat-checkbox [formControl]="isAccept" class="example-disabled-checkbox">
          <span class="checkbox-text">I Accept</span>
        </mat-checkbox>
        <p class="terms-and-ccondition">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor
          Incididunt
          Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation
          Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.</p>
      </div>
    </mat-card>
    <mat-card class="container inner-section data-controller">
      <mat-card-title>3.Select Data Controller</mat-card-title>
      <div class="mb-3">
        <div class="label-title">Current Data Controller</div>
        <div class="static-value">{{ dataControllerName }}</div>
      </div>
      <mat-label>
        <div class="label-title">Search for new Data Controller (email ID)</div>
        <mat-form-field appearance="fill">
          <mat-label>Search Username (Email ID)</mat-label>
          <mat-icon matPrefix class="search-icon"> {{i18n.ICONS.SEARCH}} </mat-icon>
          <input type="text" matInput [formControl]="userEmailList" [matAutocomplete]="auto"
            (keyup)="searchValue($event)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
            <mat-option *ngFor="let option of filterdOptions" [value]="option">
              {{ option?.userEmail }}
            </mat-option>
            <mat-option class="d-none"></mat-option>
            <div [ngClass]="{'d-none': noRecord === false}" class="collaborators-noUser-div">
              <h2> {{i18n.projectMnt.collaborators.IC.LBL_UNF}}</h2>
              <p>{{i18n.projectMnt.collaborators.IC.LBL_DESC}}</p>
              <button mat-raised-button (click)="onInviteUser()" [disabled]="false">
                {{i18n.projectMnt.collaborators.IC.BTN_IU}}</button>
            </div>
          </mat-autocomplete>
        </mat-form-field>
      </mat-label>
    </mat-card>
  </div>
  <div class="footer px-3">
    <mat-card class="footer-container">
      <button mat-raised-button class="data-file-cancel-button" (click)="service.onCancelEvent()">Cancel</button>
      <button mat-raised-button type="submit" class="data-file-cancel-button" (click)="onClickSaveandExit('No')"
        [disabled]="!isAccept.value">Save and Exit</button>
      <button mat-raised-button type="submit" class="float-left" [disabled]="!(isAccept.value  && userEmailList.valid)"
        (click)="sendTransferData('Yes')">Transfer</button>
    </mat-card>
  </div>
</div>