<div class="approve-popup-card">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{i18n.projectMnt.collaborators.RC.TITLE}}</mat-card-title>
        </mat-card-header>

        <mat-card-content
        *ngIf="!showDCPopup">             
            <p>{{i18n.projectMnt.collaborators.RC.DESC1}} <strong>{{data.selectedRowElement.projectName}}</strong> {{i18n.projectMnt.collaborators.RC.DESC2}} </p>
        </mat-card-content>
        <mat-card-content *ngIf="showDCPopup">             
            <p>The selected user is the Data Controller for this project. Transfer this role to another user using "Manage Data Controller" option in Collaborators page before removing the user</p>
        </mat-card-content>
    
        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button (click)="onCancel()">{{i18n.BTN_CANCEL}}</button>
            <button mat-raised-button (click)="onRemove()" [disabled]="showDCPopup">{{i18n.BTN_REMOVE}}</button>
        </div>
        </mat-card-footer>
    </mat-card>
</div>