<div class="loadingcontainer">
    <div *ngIf="isSubmitLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class mx-auto">
        </mat-progress-spinner>
    </div>
</div>
<div class="cont-fluid cmn_tbl manage-data-controller ms-4 me-0">
    <mat-card>
        <mat-card-title>
            <h2>{{i18n.projectAudit.LBL_MDC}}</h2>
        </mat-card-title>
        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-modal-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class mx-auto">
                </mat-progress-spinner>
            </div>
        </div>
        <mat-card-content *ngIf="isLoading == false" id="invite-collaborator">
            <form [formGroup]="assignForm">
                <div>Current Data Controller</div>
                <div class="mt-2 mb-4">{{currentDataController}}</div>
                
                <mat-grid-list cols="1" rowHeight="100px">
                    <div>Search for new Data Controller</div>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label class="label-top">Search Username (Email ID)
                                <mat-icon class="search-icon">{{i18n.ICONS.SEARCH}}</mat-icon>
                            </mat-label>
                            <input type="text" matInput formControlName="userEmailList" [matAutocomplete]="auto"
                                (keyup)="searchValue($event)">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
                                <mat-option *ngFor="let topping of filterdOptions" #matOption
                                    (onSelectionChange)="selectUser(topping)" [value]="topping.userEmail">
                                    {{topping.userEmail}} </mat-option>
                                <mat-option class="d-none"></mat-option>
                                <div class="text-center p-4" [ngClass]="{'d-none': noRecord === false}"
                                    id="collaborators-noUser-div">
                                    <h2> {{i18n.projectMnt.collaborators.IC.LBL_UNF}}</h2>
                                    <p>{{i18n.projectMnt.collaborators.IC.LBL_DESC}}</p>
                                    <button mat-raised-button (click)="onInviteUser()" [disabled]="false">
                                        {{i18n.projectMnt.collaborators.IC.BTN_IU}}</button>
                                </div>
                            </mat-autocomplete>
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-card-footer class="me-3">
        <div mat-dialog-actions>
            <button mat-stroked-button class="basic-btn" (click)="onDecline()">{{i18n.BTN_CANCEL}}</button>
            <button mat-raised-button [disabled]="!assignForm.valid" (click)="onAssign()">{{i18n.projectAudit.BTN_ASSIGN}}</button>
        </div>
    </mat-card-footer>
</div>