import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { ProjectManagementService } from '../project-management.service';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-dataset',
  templateUrl: './create-dataset.component.html',
  styleUrls: ['./create-dataset.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateDatasetComponent implements OnInit {
  i18n = i18n;
  isLoading = false;
  createDataset: FormGroup;
  datasetNameErrorPopupDisplay = false;
  datasetNamePattern = /^[a-z\d\-_\s]+$/i;
  datasetNameMinLength = 1;
  datasetNameMaxLength = 75;
  datasetDescriptionMaxLength = 2000;
  projectdetailsValues: any;
  projectName:string;


  constructor(private readonly activatedRoute:
    ActivatedRoute, private readonly router: Router,
    private storageService: StorageService, private service: ProjectManagementService, private readonly toastrService: ToastrService) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.projectdetailsValues = queryParams;
      this.projectName = queryParams['projectName'];
    });
  }

  ngOnInit(): void {
    this.createDataset = new FormGroup({
      datasetName: new FormControl('', [Validators.required, Validators.minLength(this.datasetNameMinLength)
        , Validators.maxLength(this.datasetNameMaxLength), Validators.pattern(this.datasetNamePattern)]),
      datasetDescription: new FormControl('', [Validators.required, Validators.maxLength(this.datasetDescriptionMaxLength)])
    })
  }

  get datasetName() { return this.createDataset.get('datasetName'); }

  get datasetDescription() { return this.createDataset.get('datasetDescription'); }

  backToDataFile() {
    this.router.navigate(['projectmanagement/datafileslist'], {
      queryParams: this.projectdetailsValues
    });
    this.service.newEvent('backToFiles');

  }

  backToDataIngress(){
    this.router.navigate(['projectmanagement/projectdetails'], {
      queryParams: {...this.projectdetailsValues, selectProjectTabIndex:'2'}
    });
  }
  errorPopup(event: any, field: any) {
    const textLength = event.target.value;
    if ((textLength.length) > 0) {
      if (field === 'dataset-name')
        this.datasetNameErrorPopupDisplay = true;
    } else {
      this.datasetNameErrorPopupDisplay = false;
    }

  }
  onFocus() {
    this.datasetNameErrorPopupDisplay = true;
  }
  onFocusOutEvent() {
    this.datasetNameErrorPopupDisplay = false;
  }

  onCreateDataset(obj:any) {
    if (this.createDataset.invalid) {
      return;
    }
    else {
      const profile = JSON.parse(this.storageService.secureStorage.getItem('profile'))
      const USERID = this.storageService.secureStorage.getItem('userUuid');
      const selectedData = this.storageService.secureStorage.getItem('selectedFiles' + USERID);
      const newArr = selectedData.map((objs: any) => {
        return { ...objs, approval_status: 'Pending for Approval' };
      });
      const postObj = {
        "datasetName": obj.datasetName,
        "datasetDescription": obj.datasetDescription,
        "projectName": this.projectdetailsValues['projectName'],
        "projectId": this.projectdetailsValues['projectKey'],
        "region": this.projectdetailsValues['region'],
        "siteName": this.projectdetailsValues['siteName'],
        "createdBy": profile.username,
        "orgName": environment.config.orgKey,
        "orgId": environment.config.orgKey,
        "userId": USERID,
        "dataFilesCount": newArr.length,
        "filesList": newArr
      }
      this.isLoading = true;
      this.service.createdDataset(postObj).subscribe({
        next: (data: any) => {         
          this.toastrService.success(data.messages[0]);
          setTimeout(() => {
            this.isLoading = false;
            this.router.navigate(['projectmanagement/projectdetails'], {
            queryParams: {...this.projectdetailsValues, selectProjectTabIndex:'2'}
            });
            this.service.newEvent(2);
          }, 2000);
        },
        error: (err) => {
          console.log(err)
          this.isLoading = false;
          this.toastrService.error(err.error.error[0].errorDetails);
        }
      });
    }
  }
}
