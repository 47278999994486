import Dexie, { Table } from 'dexie';


export interface FilesDetailList {
    id?: number;
    status?: string;
    filename?: string;
    chunkCount?: number;
    fileId?: string;
    userUuid?: string;
    projectKey?: string;
    timeStamp?: Date;
    fileDetails?: {
        name: string;
        size: number;
        lastModified: number;
    }
  }


export class AppDB extends Dexie {
  filesDetailList: Table<FilesDetailList, number>;

  constructor(dbName) {
    super(dbName);
  }

  async createStore(storeName: string) {
    if (this.isOpen() === false) {
      this.version(1).stores({
        // filesList: '++id, filesList',
        [storeName]: '++id, *status, *userUuid, *timeStamp',
      })
    }
    else {
    }
  }

  async populate() {
  }

  async resetDatabase() {
    await this.transaction('rw', 'filesDetailList', () => {
      this.filesDetailList.clear();
    });
  }
}
