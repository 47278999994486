import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { i18n } from 'src/app/i18n.en';
import { ProjectManagementService } from '../../project-management/project-management.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadReportComponent implements OnInit {
  downloadForm: FormGroup;
  i18n = i18n;
  maxDate = new Date();
  isLoading: boolean;
  projectName: string;
  projectKey: string;
  orgKey: string;
  transferDate: string;
  projectInfoDownloadUrl: string;
  datasetDownloadUrl: string;

  @ViewChild('subcontent') subContent: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<DownloadReportComponent>, 
  private readonly activatedRoute: ActivatedRoute, public readonly projectService: ProjectManagementService,
  public router: Router) { }

  ngOnInit(): void {
    this.downloadForm = new FormGroup({
      'startDate': new FormControl('', Validators.required),
      'endDate': new FormControl('', Validators.required) 
    });
    this.projectKey = this.activatedRoute.snapshot.queryParams["projectKey"];
    this.projectName = this.activatedRoute.snapshot.queryParams["projectName"];
    this.orgKey = this.activatedRoute.snapshot.queryParams["orgName"];
    this.transferDate = this.data.transferDate;
  }

  /* public clickMainContent() {
    console.log("clickMainContent");
    const domain = environment.config.domain;
    this.downloadUrl = domain+environment.downloadProjectInfo+"?projectName="+this.projectName+"&orgKey="+this.orgKey+"&projectKey="+this.projectKey;
    this.subContent.nativeElement.click();
  } */
  onCancel(){
    this.dialogRef.close();
  }

  downloadProjectInfo_old(){
    const datepipe: DatePipe = new DatePipe('en-US');
    const startDate = datepipe.transform( this.downloadForm.get('startDate')?.value, 'y-MM-dd');
    const endDate = datepipe.transform( this.downloadForm.get('endDate')?.value, 'y-MM-dd');
    const domain = environment.config.domain;
    this.projectInfoDownloadUrl = domain+environment.downloadProjectInfo+"?projectName="+this.projectName+"&orgKey="+this.orgKey+"&projectKey="+this.projectKey;
    this.datasetDownloadUrl = domain+environment.downloadProjectInfo+"?projectName="+this.projectName+"&orgKey="+this.orgKey+"&projectKey="+this.projectKey+"&startDate="+startDate+"&endDate="+endDate;
    //this.subContent.nativeElement.click();

    //e.downloadProjectInfo();
    window.open(this.projectInfoDownloadUrl);
    window.open(this.datasetDownloadUrl);


    /* const blob = new Blob([this.projectInfoDownloadUrl], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a); */


    /* this.router.navigate([this.downloadUrl], {
      queryParams: {
        projectName: this.projectName,
        orgKey: this.orgKey,
        projectKey: this.projectKey
      }
  }); */
    
    //this.subContent.nativeElement.click();
    console.log("downloadProjectInfo method");
    
    //this.isLoading = true;
    //const domain = environment.config.domain;
    //this.downloadUrl = domain+environment.downloadProjectInfo+"?projectName="+this.projectName+"&orgKey="+this.orgKey+"&projectKey="+this.projectKey;
    //console.log("URL: ", this.downloadUrl);
    //let dl = document.getElementById("dl") as HTMLInputElement;
    /* const el = document.getElementById('multiple-dl') as HTMLInputElement;
    if (el != null){
      el.addEventListener('click', (event) =>{
        console.log("inside addeventlistener");
      });
    } */
    
    //this.fileDownload(downloadUrl, "testing");
     /* this.projectService.getdownloadProjectInfo( this.projectName, this.orgKey, this.projectKey ).subscribe({
      next: (data:any)=>{
        
        console.log("download project Info: ", data);
        const blob = new Blob([data], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.isLoading = false;
      },
      error: (err)=>{
        this.isLoading = false;
        console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      },
      complete: ()=>{
        this.isLoading = false;
      }
    }); */
  }

  downloadDataSets_old(){
    console.log("downloadDataSets method");
    this.isLoading = true;
    const datepipe: DatePipe = new DatePipe('en-US');
    const startDate = datepipe.transform( this.downloadForm.get('startDate')?.value, 'y-MM-dd');
    const endDate = datepipe.transform( this.downloadForm.get('endDate')?.value, 'y-MM-dd');
    //const startDate = this.downloadForm.get('startDate')?.value;
    //const endDate = this.downloadForm.get('endDate')?.value;
    this.projectService.getdownloadDataSets( this.projectName, this.orgKey, this.projectKey, startDate, endDate).subscribe({
      next: (data:any)=>{
        console.log("download project Info: ", data);   
        this.isLoading = false;
      },
      error: (err)=>{
        this.isLoading = false;
        console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      },
      complete: ()=>{
        this.isLoading = false;
      }
    });
  }

  downloadProjectInfo(){
    console.log("downloadProjectInfo");
    this.isLoading = true;
    const domain = environment.config.domain;
    this.projectInfoDownloadUrl = domain+environment.downloadProjectInfo+"?projectName="+this.projectName+"&orgKey="+this.orgKey+"&projectKey="+this.projectKey;
    console.log("Download Project Info: ", this.projectInfoDownloadUrl);
    this.fileDownload(this.projectInfoDownloadUrl);
    setTimeout(() => this.downloadDataSets(), 3000);
    setTimeout(() => this.dialogRef.close(), 6000);    
  }

  downloadDataSets(){ 
    console.log("downloadDataSets");
    const datepipe: DatePipe = new DatePipe('en-US');
    const startDate = datepipe.transform( this.downloadForm.get('startDate')?.value, 'y-MM-dd');
    const endDate = datepipe.transform( this.downloadForm.get('endDate')?.value, 'y-MM-dd');
    const domain = environment.config.domain;
    this.datasetDownloadUrl = domain+environment.downloadDataSets+"?projectName="+this.projectName+"&orgKey="+this.orgKey+"&projectKey="+this.projectKey+"&startDate="+startDate+"&endDate="+endDate;
    console.log("Download Project Info: ", this.datasetDownloadUrl);
    this.fileDownload(this.datasetDownloadUrl);
    //this.isLoading = false;
  }

  fileDownload(url:string) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
  }
}
