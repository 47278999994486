<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner> 
    </div>
</div>

<div class="cont project-file">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto backbutton">
                        <mat-icon class="backbutton" [routerLink]="['/projectmanagement']">{{i18n.ICONS.BACK}}</mat-icon>
                    </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-sub-title">{{i18n.projectMnt.TITLE}}</span>
                        <span class="me-2 fw-normal pipe-separator">|</span> <span class="fs-6 project-sub-title">{{projectName}}</span>
                    </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>



    <div class="cont-fluid cmn_tbl">

        <!-- breadcrumb -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}}{{projectName}}</a>
                </li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li *ngIf="selectedTabIndex===0" class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.PD}}</li>
                <li *ngIf="selectedTabIndex===1 && service.permission(roleId, 'viewCollaborators') && !(status === 'Rejected')" class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.COLL}}</li>
                <li *ngIf="selectedTabIndex==2 && service.permission(roleId, 'datasetList') && !(status === 'Rejected')" class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.DI}}</li>
                <li *ngIf="selectedTabIndex==3 && service.permission(roleId, 'auditSettings') && !(status === 'Rejected')" class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.AS}}</li>
                <li *ngIf="selectedTabIndex==4 && service.permission(roleId, 'approveDataset') && !(status === 'Rejected')" class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.MA}}</li>
            </ol>
        </nav>
        <mat-card class="p0">
            <mat-tab-group (selectedTabChange)="onTabClick($event)" [selectedIndex]="selectedTabIndex">
                <mat-tab [label]="i18n.projectMnt.PF.TABS.PD">
                    <ng-template matTabContent>
                        <app-edit-project [isFromAuditProjectInfo]=false></app-edit-project>
                    </ng-template>
        
                </mat-tab>
        
                <mat-tab [label]="i18n.projectMnt.PF.TABS.COLL" *ngIf="service.permission(roleId, 'viewCollaborators') && !(status === 'Rejected')">
                    <ng-template matTabContent>
                        <app-collaborators [projectID]="projectID" [projectName]="projectName"
                            ></app-collaborators>
                    </ng-template>
                </mat-tab>
        
                <mat-tab [label]="i18n.projectMnt.PF.TABS.DI" *ngIf="service.permission(roleId, 'datasetList') && !(status === 'Rejected')">
                    <ng-template matTabContent>
                        <app-dataingress-overview [projectID]="projectID" [projectName]="projectName"
                            [roleId]="roleId"></app-dataingress-overview>
                    </ng-template>
                </mat-tab>

                <!-- Audit Settings -->
                <mat-tab label="Audit Settings" *ngIf="service.permission(roleId, 'auditSettings') && !(status === 'Rejected')">
                    <ng-template matTabContent>
                        <app-audit-settings></app-audit-settings>
                    </ng-template>
                </mat-tab>
        
                <!-- My Approvals -->
                <mat-tab [label]="i18n.projectMnt.PF.TABS.MA" *ngIf="service.permission(roleId, 'approveDataset') && !(status === 'Rejected')">
                    <ng-template matTabContent>
                        <app-my-approvals></app-my-approvals>
                    </ng-template>
                </mat-tab>
        
            </mat-tab-group>
        </mat-card>
    </div>

</div>

<!--  -->