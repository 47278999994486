
export const projectCreation = {
    pc: {
        s: 'Yes',
        n: 'No',
        attach: "Attachment",
        cm: 'Comments'
    },
    addFiles: {
        dow: "Download",
    },
    review: {
        maxlimit: 21,
    },
    footerLabel: {
        cancel: "Cancel",
        saveAndExist: "Save and Exit",
        approve: "Approve",
        previous: "Previous"
    },
    formValidation: {
        minLength1: 1,
        maxlength50: 50,
        maxlength100: 100,
        maxlength200: 200,
        maxlength500: 500,
        maxLength5000: 5000,
        maxLength250:250,
        projectNamePattern: '[a-zA-Z0-9]+',
        collaboratingSitePattern: '^(?!\d+$)(?:[a-zA-Z0-9-;_.,/\\\\][a-zA-Z0-9-;_.,/\\\\ \n\r\s]*)?$',
        alphanumericPattern: '^(?!\d+$)(?:[a-zA-Z0-9.,][a-zA-Z0-9., \n\r\s]*)?$',
        alphabetPattern: '^(?!\d+$)(?:[a-zA-Z-][a-zA-Z- \n\r\s]*)?',
        patternWithHypenAndUnderscore: '^(?!\d+$)(?:[a-zA-Z0-9-_.,][a-zA-Z0-9-_., \n\r\s]*)?',
        patternWithHypenAndUnderscoreonly:'^(?!\d+$)(?:[a-zA-Z0-9-_][a-zA-Z0-9-_ \n\r\s]*)?',
        studyIdentifierPattern: '^(?!\d+$)(?:[a-zA-Z0-9-_.][a-zA-Z0-9-_. \n\r\s]*)?',
        emailValidator:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    icon: {
        close: 'close',
        info: 'info',
    }
}

export class AdditionaFiles {
    budgetFiles:any []
    otherFiles: any[]
}

export class RegionModel { regionName: string; regionValue: string }
export interface ProjectIdea {
    projectIdeaName?: string;
    piRegion?: RegionModel;
    projectIdeaDesc?:string;
    email?: string;
    principalFirstName?: string;
    principalLastName?: string;
    leadSite?: string;
    coauthorFirstName?: string;
    coauthorLastName?: string;
    collaboratingSite?: string;
    diseaseArea?: string;
    studyType?: string[];
    studyDesignDesc?: string;
    drugDetails?: string;
    scientistDetails?: string;
    immuneFitness?: string;
    rfpDetails?: string;
    specialExpertise?: string;
    imCoreIdentifier?: string;
    supportingIdeaFiles?: any;
    studyIdentifier?: string;
    projectStatus?: string;
    remarks?: string;
    statusDetails?: string;
    sarm?: string;
    communication?: any;
    submissionYear?: string;
}


export class FileObj {
    fileName?:string;
    fileId?:string;
    fileSize?:string;
    uploadId?:string;
    fileType:string
}
export class ProjectConcept {
    isIsctIdeaEndorsed: string;
    pcEndorsementDate: string;
    pcComments: string;
    pcFiles: any;
    pcOverrideReason: string
}
export class ComputeConfig {
    computeDetails: string;
}
export class AdditionalFiles {
    budgetFiles: any;
    otherFiles: any;
}
export class CitcDetails {
    isCitcIdeaEndorsed: string;
    citcEndorsementDate: string;
    citcComments: string;
    citcFiles: any;
    citcOverrideReason: string;
}
export class Review {
    additionalEmails: string;
    reviewStatus:string;
}
export interface StepperObj5 {
    projectIdea?: ProjectIdea;
    projectConcept?: ProjectConcept;
    computeConfig?: ComputeConfig;
    additionalFiles?: AdditionalFiles;
    citcDetails?: CitcDetails;
    review?: Review;
}