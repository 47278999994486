import { Component, Input, OnInit } from '@angular/core';
import { ComputeConfig, projectCreation } from '../../model/model';

@Component({
  selector: 'app-compute-configuration-details',
  templateUrl: './compute-configuration-details.component.html',
  styleUrls: ['./compute-configuration-details.component.scss']
})
export class ComputeConfigurationDetailsComponent  {
  projectCreation = projectCreation;
  @Input() computeConfig: ComputeConfig | undefined;

}
