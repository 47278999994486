<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto"> <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2"
                            src="./assets/images/Icon_user_management.svg" alt="" /> </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-title">{{i18n.HEADER.SP}}</span><span
                            class="me-2 fw-normal">|</span> <span class="fs-6">{{i18n.BREADCRUMB.PUM}}</span> </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>                    
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div> 

</div>
<div class="cont-fluid cmn_tbl user-tble">
    <!-- breadcrumb -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
            <li>
                <mat-icon>navigate_next</mat-icon>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.PUM}}</li>
        </ol>
    </nav>

    <mat-card>
        <!-- Page Loader -->
        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-modal-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
                </mat-progress-spinner>
            </div>
        </div>

        <mat-card-header>
            <mat-card-title>
                {{i18n.userManagement.PUM}}
            </mat-card-title>
            <div class="search-btn-div">
                <mat-form-field #input *ngIf="dataSource?.data?.length > 0" appearance="fill" floatLabel="always">
                    <mat-icon class="mat-icon-search" matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon><mat-icon matSuffix
                        [hidden]="!showSearchCancelBtn" (click)="onSearchCancelBtn()">{{i18n.ICONS.CANCEL}}</mat-icon>
                    <input matInput placeholder="Search here" (keyup)="applyFilter($event)" [formControl]="inputSearch"
                        autocomplete="off" />
                </mat-form-field>
                <button mat-raised-button class="userBtn" (click)="onInviteUser()">{{i18n.userManagement.BTN.IU}}</button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div class="no-data empty-dta" *ngIf="dataSource?.data?.length === 0">{{i18n.userManagement.NDF}}</div>
            <mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length > 0" matSort matSortDirection="asc" matSortDisableClear>

                <!-- Radio button column -->
                <ng-container matColumnDef="radio">
                    <th mat-header-cell *matHeaderCellDef class="radio-btn-headercell">
                    </th>
                    <td mat-cell *matCellDef="let element" class="radio-btn-cell">

                        <!-- <mat-radio-button (click)="onSelectUser(element)"></mat-radio-button> -->
                        <mat-radio-group [(ngModel)]="selectedRowElement" (change)="onSelectUser(element)">
                            <mat-radio-button [value]="element"></mat-radio-button>
                        </mat-radio-group>
                    </td>
                </ng-container>

                <!-- User Name Column-->
                <ng-container matColumnDef="userEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="name-header"> Username (Email ID)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="name-cell"> {{element.userEmail}}
                    </mat-cell>
                </ng-container>

                <!-- First Name Column -->
                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.firstName}} </mat-cell>
                </ng-container>

                <!-- Last Name Column -->
                <ng-container matColumnDef="lastName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.lastName}} </mat-cell>
                </ng-container>

                <!-- Organization Role Column -->
                <ng-container matColumnDef="roleDetails.roleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="orgRole-header"> Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="orgRole-cell"> {{element?.roleDetails.roleName}} </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="roleDetails.trianingStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.roleDetails.trianingStatus}} </mat-cell>
                </ng-container>

                <!-- Action Button Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="action-header"> Actions </mat-header-cell>
                    <mat-cell class="action-cell" *matCellDef="let element">
                        <button *ngIf="element?.roleDetails.trianingStatus.toLowerCase()==='invited'" mat-stroked-button
                            color="primary" (click)="onApprove(element)">{{i18n.BTN_APPROVE}}</button>
                    </mat-cell>
                </ng-container>

                <!-- Table header -->
                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>
        </mat-card-content>

        <mat-card-footer>
            <button mat-stroked-button [disabled]="!canEditUser" (click)="onEditUser()">{{i18n.userManagement.BTN.EDIT}}</button>
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true" [hidden]="dataSource?.data?.length === 0">
            </mat-paginator>
        </mat-card-footer>


    </mat-card>
</div>