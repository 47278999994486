<!-- Page Loader -->
<div class="cmn_tbl dataingress_tbl">  

    <mat-card>
        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-modal-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
                </mat-progress-spinner>
            </div>
        </div>
        <mat-card-header>
            <mat-card-title>
                {{i18n.projectMnt.DI.TITLE}}
            </mat-card-title>
            <div class="search-btn-div">
                <button mat-raised-button class="userBtn" (click)="onCreateDataset()">{{i18n.projectMnt.DI.BTN_CDS}}</button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div class="no-data empty-dta" *ngIf="dataSource?.data?.length === 0">{{i18n.LBL_NDF}}</div>
            <mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length > 0" matSort matSortDirection="desc" matSortActive="createdDate"
                matSortDisableClear>

                <ng-container matColumnDef="datasetName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Dataset Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.datasetName}}
                    </mat-cell>
                </ng-container>

                <!-- Number of Files Column -->
                <ng-container matColumnDef="dataFilesCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>No. of Files </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.dataFilesCount}} </mat-cell>
                </ng-container>

                <!-- File Size Column -->
                <ng-container matColumnDef="datasetSize">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Dataset Size </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.datasetSize | bytesToMB}} </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="datasetStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.datasetStatus}} </mat-cell>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.createdDate | date : 'y-MMM-dd HH:mm:ss'}}
                    </mat-cell>
                </ng-container>

                <!-- Action Button Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="action-header"> Actions </mat-header-cell>
                    <mat-cell class="action-cell" *matCellDef="let element">
                        <button mat-stroked-button (click)="onSelectDataset(element)">{{i18n.BTN_SELECT}}</button>
                    </mat-cell> 
                </ng-container>

                <!-- Table header -->
                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>
        </mat-card-content>

        <mat-card-footer>
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                [hidden]="dataSource?.data?.length === 0">
            </mat-paginator>
        </mat-card-footer>
    </mat-card>
</div>