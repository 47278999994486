import { Component, OnInit } from '@angular/core';
import { i18n } from 'src/app/i18n.en';
import { Permissions } from 'src/app/interfaces/common.interface';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {
  i18n = i18n;
  cUPs: Permissions; // currentUserPermissions
  
  constructor( readonly storageService: StorageService) { }
  
  ngOnInit(): void {
    this.cUPs = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);    
    //console.log(this.cUPs)
  }
  redirectToDataCatalog(){
    window.location.replace('/datacatalog');
  }
}
