<div class="create-project-multi-form">
  <mat-grid-list cols="1" rowHeight="50px" class="popup-close">
    <mat-grid-tile class="header-close">
      <a>
        <mat-icon (click)="onCancelEvent()">{{projectCreation.icon.close}}</mat-icon>
      </a>
      <h3>Add Project</h3>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom" class="stepper-class-container"
    (selectionChange)="onStepChange($event)">
    <mat-step [stepControl]="form1">
      <ng-template matStepLabel>Project Idea</ng-template>
      <mat-card class="container projectIdea-inner-section">
        <mat-card-title>1.Project Idea</mat-card-title>
        <app-project-idea [projectID]="projectID"
          [projectName]="projectName" (emitProjectKey)="emitProjectKey($event)" (emitFileOnUplaod)="emitFileOnUplaod($event)" [fileListDeleteOnSave]="fileListDeleteOnSave">
        </app-project-idea>
        <div class="footer px-3">
          <mat-card class="footer-container">
            <button mat-raised-button class="data-file-cancel-button" (click)="onCancelEvent()">Cancel</button>
            <button mat-raised-button type="submit" class="data-file-cancel-button" [disabled]="!(step1Component.projectIdea.valid) || validateInprogress()" (click)="onFormDataChanged('')">Save
              and Exit</button>
            <button mat-raised-button type="submit" [disabled]="!(step1Component.projectIdea.valid)" class="float-left"
              (click)="onNext()">Next</button>
            <button mat-raised-button class="data-file-cancel-button float-left" disabled>Previous</button>
          </mat-card>
        </div>
      </mat-card>
    </mat-step>
    <mat-step [stepControl]="form2">
      <ng-template matStepLabel>Project Concept</ng-template>
      <mat-card class="container inner-section">
        <mat-card-title>2.Project Concept</mat-card-title>
        <app-project-concept [projectID]="projectID"
          [projectName]="projectName" 
          (emitFileOnUplaod)="emitFileOnUplaod($event)"
          [fileListDeleteOnSave]="fileListDeleteOnSave">
        </app-project-concept>
        <div class="footer px-3">
          <mat-card class="footer-container">
            <button mat-raised-button class="data-file-cancel-button" (click)="onCancelEvent()">Cancel</button>
            <button mat-raised-button type="submit" class="data-file-cancel-button" (click)="onFormDataChanged('')"
              [disabled]="!(step2Component.projectConcept.valid) || validateInprogress()">Save and Exit</button>
            <button *ngIf="!stopMovingToNextStep" mat-raised-button type="submit" (click)="onNext()"
              [disabled]="!(step2Component.projectConcept.valid)" class="float-left">Next</button>
            <button *ngIf="stopMovingToNextStep" mat-raised-button type="submit" disabled
              class="float-left">Next</button>
            <button mat-raised-button class="data-file-cancel-button float-left"
              (click)="onPrevious()">Previous</button>
          </mat-card>
        </div>
      </mat-card>
    </mat-step>
    <mat-step [stepControl]="form3" [aria-labelledby]="stopMovingToNextStep ? 'disable-stepper-header' : ''">
      <ng-template matStepLabel>Compute Configuration</ng-template>
      <mat-card class="container inner-section">
        <mat-card-title>3.Compute Configuration</mat-card-title>
        <app-compute-configuration [projectID]="projectID" [projectName]="projectName"></app-compute-configuration>
        <div class="footer px-3">
          <mat-card class="footer-container">
            <button mat-raised-button class="data-file-cancel-button" (click)="onCancelEvent()">Cancel</button>
            <button mat-raised-button type="submit" class="data-file-cancel-button" (click)="onFormDataChanged('')"
              [disabled]="!(step3Component.computeConfig.valid) || validateInprogress()">Save and Exit</button>
            <button mat-raised-button type="submit" (click)="onNext()"
              [disabled]="!(step3Component.computeConfig.valid)" class="float-left">Next</button>
            <button mat-raised-button class="data-file-cancel-button float-left"
              (click)="onPrevious()">Previous</button>
          </mat-card>
        </div>
      </mat-card>
    </mat-step>
    <mat-step [stepControl]="form4">
      <ng-template matStepLabel>Additional Files</ng-template>
      <mat-card class="container inner-section">
        <mat-card-title>4.Additional Files</mat-card-title>
        <app-additional-files [projectID]="projectID"
          [projectName]="projectName" (emitFileOnUplaod)="emitFileOnUplaod($event)"
          [fileListDeleteOnSave]="fileListDeleteOnSave">
        </app-additional-files>
        <div class="footer px-3">
          <mat-card class="footer-container">
            <button mat-raised-button class="data-file-cancel-button" (click)="onCancelEvent()">Cancel</button>
            <button mat-raised-button type="submit" class="data-file-cancel-button" (click)="onFormDataChanged('')"
              [disabled]="!(step4Component.additionalFiles.valid) || validateInprogress()">Save and Exit</button>
            <button mat-raised-button type="submit" (click)="onNext()"
              [disabled]="!(step4Component.additionalFiles.valid)" class="float-left">Next</button>
            <button mat-raised-button class="data-file-cancel-button float-left"
              (click)="onPrevious()">Previous</button>
          </mat-card>
        </div>
      </mat-card>
    </mat-step>
    <mat-step [stepControl]="form5">
      <ng-template matStepLabel>CITC Details</ng-template>
      <mat-card class="container inner-section">
        <mat-card-title>5.CITC Details</mat-card-title>
        <app-citcdetails [projectID]="projectID"
          [projectName]="projectName" (emitFileOnUplaod)="emitFileOnUplaod($event)"
          [fileListDeleteOnSave]="fileListDeleteOnSave">
        </app-citcdetails>
        <div class="footer px-3">
          <mat-card class="footer-container">
            <button mat-raised-button class="data-file-cancel-button" (click)="onCancelEvent()">Cancel</button>
            <button mat-raised-button type="submit" class="data-file-cancel-button" (click)="onFormDataChanged('')"
              [disabled]="!(step5Component.citcDetails.valid) || validateInprogress()">Save and Exit</button>
            <button *ngIf="!stopMovingReview" mat-raised-button type="submit" (click)="onNext()"
              [disabled]="!(step5Component.citcDetails.valid)" class="float-left">Next</button>
            <button *ngIf="stopMovingReview" mat-raised-button type="submit" disabled class="float-left">Next</button>
            <button mat-raised-button class="data-file-cancel-button float-left"
              (click)="onPrevious()">Previous</button>
          </mat-card>
        </div>
      </mat-card>
    </mat-step>
    <mat-step [stepControl]="form6" [aria-labelledby]="stopMovingReview ? 'disable-stepper-header' : ''">
      <ng-template matStepLabel>Review Project Proposal</ng-template>
      <app-review-project-proposal [formDataObj]="formDataObj" (previousStep)="onPrevious()"
        (formDataChanged)="onFormDataChanged($event)" [validateInprogress]="validateInprogress()"[projectID]="projectID" [projectName]="projectName">
      </app-review-project-proposal>
    </mat-step>
  </mat-horizontal-stepper>
</div>