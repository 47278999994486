<!-- Page Loader -->
<div class="loadingcontainer">
  <div *ngIf="isLoading" class="loading-modal-spinner">
      <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
      </mat-progress-spinner>
  </div>
</div>

<mat-card class="audit-results">
  <mat-card-header>
    <mat-card-title>
      {{i18n.projectAudit.DAR_POPUP.TITLE}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="audit-result-form">
      <form [formGroup]="auditResultForm">

        <!-- Audit passed toggle-Required field -->
        <h4 class="field-title"> {{i18n.projectAudit.DAR_POPUP.FF_TITLE_DAR}} *</h4>
        <mat-button-toggle-group class="auditresult-toggle-gp" formControlName="auditResult" aria-label="Audit Result"
          required>
          <mat-button-toggle
            value={{i18n.projectAudit.DAR_POPUP.FF_TBTN_AP}}>{{i18n.projectAudit.DAR_POPUP.FF_TBTN_AP}}</mat-button-toggle>
          <mat-button-toggle
            value={{i18n.projectAudit.DAR_POPUP.FF_TBTN_PWW}}>{{i18n.projectAudit.DAR_POPUP.FF_TBTN_PWW}}</mat-button-toggle>
        </mat-button-toggle-group>


        <!-- comments-Required field -->
        <mat-form-field floatLabel="always" class="comments-formfield">
          <mat-label class="field-title">{{i18n.projectAudit.DAR_POPUP.FF_TITLE_CMT}}</mat-label>
          <textarea matInput #postalCode formControlName="auditComments" maxlength="5000"></textarea>
          <mat-hint align="end">{{postalCode.value.length}} / 5000</mat-hint>
        </mat-form-field>

        <!-- Additional Email To Notify-Optional field -->
        <h4 class="field-title">{{i18n.projectAudit.DAR_POPUP.FF_TITLE_ARN}}</h4>
        <div class="chipfield-div">
          <mat-form-field class="email-chip-formfield" appearance="fill">
            <mat-label
              [ngClass]="(maxEmailCountError||auditResultForm.get('additionalEmail')?.invalid)?'error-red':'default-color'">Enter
              email address one by one and press enter</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let mail of additionalEmailsToNotify" [selectable]="selectable" [removable]="removable"
                (removed)="remove(mail)">
                {{mail}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input matInput type="email" (keyup)="onkeyUp($event)" [matChipInputFor]="chipList"
                formControlName="additionalEmail" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
          </mat-form-field>
          <mat-error *ngIf="formgetter?.['additionalEmail']?.errors?.['email']">
            {{i18n.projectAudit.DAR_POPUP.FF_ARN_ERR_M_VALID}}
          </mat-error>
          <mat-error *ngIf="maxEmailCountError">
            {{i18n.projectAudit.DAR_POPUP.FF_ARN_ERR_M_COUNT}}
          </mat-error>
        </div>
        <!-- <div>
          <mat-form-field class="scrollbar" id="style-1" appearance="fill">
            <mat-label
              [ngClass]="(maxEmailCountError||auditResultForm.get('additionalEmail')?.invalid)?'error-red':'default-color'">Enter
              email address one by one and press enter</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let mail of additionalEmailsToNotify" [selectable]="selectable" [removable]="removable"
                (removed)="remove(mail)">
                {{mail}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input matInput type="email" (keyup)="onkeyUp($event)" [matChipInputFor]="chipList"
                formControlName="additionalEmail" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
          </mat-form-field>
          <mat-error *ngIf="formgetter?.['additionalEmail']?.errors?.['email']">
            {{i18n.projectAudit.DAR_POPUP.FF_ARN_ERR_M_VALID}}
          </mat-error>
          <mat-error *ngIf="maxEmailCountError">
            {{i18n.projectAudit.DAR_POPUP.FF_ARN_ERR_M_COUNT}}
          </mat-error>
        </div> -->

      <!-- Attachments -->
        <div>
          <h4 class="field-title attachments-title">{{i18n.projectAudit.DAR_POPUP.FF_TITLE_ATC}}</h4>
          <div class="attachments-div">
            <mat-icon>info_outline</mat-icon>
            <div>
              <h4 class="guidlines-txt">{{i18n.projectAudit.DAR_POPUP.TXT_GDL_UP}}</h4>
              <ol>
                <li> {{i18n.projectAudit.DAR_POPUP.TXT_GDL_1}}</li>
                <li>{{i18n.projectAudit.DAR_POPUP.TXT_GDL_2}}</li>
                <li>{{i18n.projectAudit.DAR_POPUP.TXT_GDL_3}}</li>
                <li>{{i18n.projectAudit.DAR_POPUP.TXT_GDL_4}}</li>
              </ol>
            </div>
          </div>
          <div class="project-audit-upload">
            <app-file-upload #projectAuditFileUpload [maxFilesAllowed]="20" [maxParallelFileUploads]="4"
            [resetOnLoad]="true"
              [userUuid]="userUuid"
              [projectKey]="projectID"
              [errForValidFileSize]="'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection'"
              [errForCumulativeFileSize]="'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.'"
              [maxParallelChunksUpload]="10" [maxChunkSizeInBytes]="209715200" [maxFileSizeAllowedInGB]="0.5"
              [maxCumulativeSizeAllowedInGB]="1"
              [allowedExtensions]="['.doc', '.docx', '.txt', '.pdf', '.csv', '.png', '.jpeg', '.xls', '.xlsx', '.ics', '.msg']"
              [restrictedExtensions]="[]" [fileUploadApis]='projectService.pcFileUploadApis'
              [dataForFileUpload]="dataForFileUpload" [dataForEachFileInUpload]="supportdataForEachFile"
              [Identifier]="'projectAuditFileList'"
              (completedFileEventReceiver)="supportFileUploadCompletionEvent($event)"></app-file-upload>
          </div>
          <div class="projectaudit-file-upload-list">
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let item of toList(supportfileUploadStatusData)"
              class="files-list-container">
              <mat-grid-tile [colspan]=1>
                <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span class="text-break">{{item.name}}</span>

                </div>
              </mat-grid-tile>
              <mat-grid-tile class="item2">
                <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                  value="{{item.fileProgress}}"></mat-progress-bar>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let items of f['supportingIdeaFiles'].value"
              class="files-list-container">
              <mat-grid-tile class="item1">
                <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span class="text-break">{{
                    items.fileName}}</span></div>
              </mat-grid-tile>
              <mat-grid-tile class="item2">
                <mat-icon (click)="deleteFile(items, f['supportingIdeaFiles'])" class="delete-icon">clear</mat-icon>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
        <div class="footer">
          <button mat-stroked-button (click)="onClose(f['supportingIdeaFiles'])">Cancel</button>
          <button mat-raised-button [disabled]="!auditResultForm.valid" 
            (click)="onSubmit(auditResultForm.value)">Submit</button>
        </div>
      </form>      
    </div>    
  </mat-card-content> 
</mat-card>