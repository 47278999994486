import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-audit-settings-popup',
  templateUrl: './audit-settings-popup.component.html',
  styleUrls: ['./audit-settings-popup.component.scss']
})
export class AuditSettingsPopupComponent implements OnInit {

  queryData: object;

  constructor(public dialogRef: MatDialogRef<AuditSettingsPopupComponent>, public router: Router, public activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
              ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => { 
      this.queryData = queryParams;  
      });
  }

  onNo() {
    this.dialogRef.close('cancel');
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {...this.queryData, from: 'noPopup'}});
  }

  onYes(){
    this.dialogRef.close('yes');
    this.router.navigate(['/projectaudit/configureprojectaudit'], { relativeTo: this.activatedRoute, queryParams: {...this.queryData}});
  }

}
