import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplobbyService {

  // baseUrl: string = environment.config.domain;

  constructor(private http: HttpClient) { }


  /* roleUrl: string = this.baseUrl + environment.role; 
  getRole() {
    return this.http.get(this.roleUrl);
  } */
}
