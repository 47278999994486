import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  makeIt = false;
  constructor(private router: Router, private storageService: StorageService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const allowedRoles:any = route.data as string[];
      const roles = this.storageService.secureStorage.getItem('currentUserRoles');      
      roles.forEach((role:string) => {
        // console.log('allowedRoles:', allowedRoles.allowedRoles, role)
        if(allowedRoles.allowedRoles.includes(role.toLowerCase())){
          // console.log('RG:', role.toLowerCase()) 
          this.makeIt = true;        
        } else{          
          // this.router.navigate(['/404']);
        }
      });
      if(!this.makeIt){
        this.router.navigate(['/404']);
      }
      return this.makeIt;     
  }
  
}
