<div class="component-sidenav-inner-content">
    <div class="component-sidenav-body-content">
        <div class="component-viewer-nav">
            <div class="component-viewer-nav-content">
                <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
                    <mat-tab label="Project Idea"></mat-tab>
                    <mat-tab label="Project Concept"></mat-tab>
                    <mat-tab label="Compute Configuration"></mat-tab>
                    <mat-tab label="Additional Files"></mat-tab>
                    <mat-tab label="CITC Details"></mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="component-viewer">
            <div [hidden]="!(selectedTabIndex === 0)">
                <div class="row">
                    <div class="col-10">
                        <app-project-idea-details *ngIf="!displayEditDetails" [projDetails] = "projectDetails"  [projectIdea]="formDataObj?.projectIdea"
                            [projectID]="projectID" [isFromAuditProjectInfo]="isFromAuditProjectInfo">
                        </app-project-idea-details>
                        <div class="container projectIdea-inner-section" *ngIf="displayEditDetails">
                            <mat-card-title>Project Idea</mat-card-title>
                            <app-project-idea [setExitingData]="formDataObj?.projectIdea" [projectID]="projectID" (emitFileOnUplaod)="emitFileOnUplaod($event)" [fileListDeleteOnSave]="fileListDeleteOnSave">
                            </app-project-idea>
                            <div class="footer px-3">
                                <mat-card class="footer-container">
                                    <button mat-raised-button class="data-file-cancel-button"
                                        (click)="oncancel()">Cancel</button>
                                    <button mat-raised-button class="float-left" [disabled]="!(isFormValid())" (click)="onUpdate('')">Update</button>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button mat-raised-button class="float-left edit-details" *ngIf="(!displayEditDetails) && (showEditForDC) && !isFromAuditProjectInfo"
                            (click)="editProjectDetails()">Edit Details</button>
                            <button mat-raised-button class="float-left edit-details" *ngIf="isFromAuditProjectInfo" [disabled]="!startAuditStatus"
                            (click)="downloadReport()">Download</button>
                    </div>
                </div>
            </div>
            <div [hidden]="!(selectedTabIndex === 1)">
                <div class="row">
                    <div class="col-10">
                        <app-project-concept-details *ngIf="!displayEditDetails"
                            [projectConcept]="formDataObj?.projectConcept" [projectID]="projectID">
                        </app-project-concept-details>
                        <div class="container inner-section" *ngIf="displayEditDetails">
                            <mat-card-title>Project Concept</mat-card-title>
                            <app-project-concept [setExitingData]="formDataObj?.projectConcept" [projectID]="projectID"
                            (emitFileOnUplaod)="emitFileOnUplaod($event)"
                            [fileListDeleteOnSave]="fileListDeleteOnSave"></app-project-concept>
                            <div class="footer px-3">
                                <mat-card class="footer-container">
                                    <button mat-raised-button class="data-file-cancel-button"
                                        (click)="oncancel()">Cancel</button>
                                    <button mat-raised-button class="float-left" [disabled]="!(isFormValid())" (click)="onUpdate('')">Update</button>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button mat-raised-button class="float-left edit-details" *ngIf="(!displayEditDetails) && (showEditForDC) && !isFromAuditProjectInfo"
                            (click)="editProjectDetails()">Edit Details</button>
                            <!-- <button mat-raised-button class="float-left edit-details" *ngIf="isFromAuditProjectInfo" [disabled]="!startAuditStatus"
                            (click)="downloadReport()">Download</button> -->
                    </div>
                </div>


            </div>
            <div [hidden]="!(selectedTabIndex === 2)">
                <div class="row">
                    <div class="col-10">
                        <app-compute-configuration-details *ngIf="!displayEditDetails"
                            [computeConfig]="formDataObj?.computeConfig">
                        </app-compute-configuration-details>
                        <mat-card class="container inner-section" *ngIf="displayEditDetails">
                            <mat-card-title>Compute Configuration</mat-card-title>
                            <app-compute-configuration [setExitingData]="formDataObj?.computeConfig"
                                [projectID]="projectID"></app-compute-configuration>
                            <div class="footer px-3">
                                <mat-card class="footer-container">
                                    <button mat-raised-button class="data-file-cancel-button"
                                        (click)="oncancel()">Cancel</button>
                                    <button mat-raised-button class="float-left" (click)="onUpdate('')" [disabled]="!(isFormValid())">Update</button>
                                </mat-card>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-2">
                        <button mat-raised-button class="float-left edit-details" *ngIf="(!displayEditDetails) && (showEditForDC) && !isFromAuditProjectInfo"
                            (click)="editProjectDetails()">Edit Details</button>
                            <!-- <button mat-raised-button class="float-left edit-details" *ngIf="isFromAuditProjectInfo" [disabled]="!startAuditStatus"
                            (click)="downloadReport()">Download</button> -->
                    </div>
                </div>
            </div>
            <div [hidden]="!(selectedTabIndex === 3)">
                <div class="row">
                    <div class="col-10">
                        <app-additional-files-details *ngIf="!displayEditDetails"
                            [additionalFiles]="formDataObj?.additionalFiles" [projectID]="projectID">
                        </app-additional-files-details>
                        <mat-card class="container inner-section" *ngIf="displayEditDetails">
                            <mat-card-title>Additional Files</mat-card-title>
                            <app-additional-files [setExitingData]="formDataObj?.additionalFiles"
                                [projectID]="projectID"  (emitFileOnUplaod)="emitFileOnUplaod($event)"
                                [fileListDeleteOnSave]="fileListDeleteOnSave"></app-additional-files>
                            <div class="footer px-3">
                                <mat-card class="footer-container">
                                    <button mat-raised-button class="data-file-cancel-button"
                                        (click)="oncancel()">Cancel</button>
                                    <button mat-raised-button class="float-left" (click)="onUpdate('')" [disabled]="!(isFormValid())">Update</button>
                                </mat-card>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-2">
                        <button mat-raised-button class="float-left edit-details" *ngIf="(!displayEditDetails) && (showEditForDC) && !isFromAuditProjectInfo"
                            (click)="editProjectDetails()">Edit Details</button>
                            <!-- <button mat-raised-button class="float-left edit-details" *ngIf="isFromAuditProjectInfo" [disabled]="!startAuditStatus"
                            (click)="downloadReport()">Download</button> -->
                    </div>
                </div>
            </div>
            <div [hidden]="!(selectedTabIndex === 4)">
                <div class="row">
                    <div class="col-10">
                        <app-citc-details *ngIf="!displayEditDetails" [citcDetails]="formDataObj?.citcDetails"
                            [projectID]="projectID">
                        </app-citc-details>
                        <mat-card class="container inner-section" *ngIf="displayEditDetails">
                            <mat-card-title>CITC Details</mat-card-title>
                            <app-citcdetails [setExitingData]="formDataObj?.citcDetails" [projectID]="projectID"
                            (emitFileOnUplaod)="emitFileOnUplaod($event)" 
                            [fileListDeleteOnSave]="fileListDeleteOnSave"
                            ></app-citcdetails>
                            <div class="footer px-3">
                                <mat-card class="footer-container">
                                    <button mat-raised-button class="data-file-cancel-button"
                                        (click)="oncancel()">Cancel</button>
                                    <button mat-raised-button class="float-left" (click)="onUpdate('')" [disabled]="!(isFormValid())">Update</button>
                                </mat-card>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-2">
                        <button mat-raised-button class="float-left edit-details"*ngIf="(!displayEditDetails) && (showEditForDC) && !isFromAuditProjectInfo"
                            (click)="editProjectDetails()">Edit Details</button>
                            <!-- <button mat-raised-button class="float-left edit-details" *ngIf="isFromAuditProjectInfo" [disabled]="!startAuditStatus"
                            (click)="downloadReport()">Download</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>