import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { i18n } from 'src/app/i18n.en';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { NavifyauthService } from '../../../services/navifyauth.service';
import { AuditSettingsPopupComponent } from '../audit-settings-popup/audit-settings-popup.component';

@Component({
  selector: 'app-project-file',
  templateUrl: './project-file.component.html',
  styleUrls: ['./project-file.component.scss']
})
export class ProjectFileComponent implements OnInit {
  i18n = i18n;
  projectName: string;
  selectedTabIndex = 0;
  profile: any;
  orgRole: string | null;
  projectID: string;
  roleId:string;
  currentUserPermissions: any;
  queryParamsData: any;
  auditSettingsStatus;
  isLoading: boolean;
  status:string;
  userRole: boolean = false;

  constructor(private route: ActivatedRoute,public commonSer: CommonService, private readonly router: Router,
   public storageService: StorageService, private activatedRoute: ActivatedRoute, 
    public service: ProjectManagementService, private readonly dialogRef: MatDialogRef<ProjectFileComponent>, private readonly matDialog: MatDialog) { }

  ngOnInit(): void {
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);     
   // const roles = this.storageService.secureStorage.getItem('currentUserRoles');
    /* for(const role of roles){
      if(role.toLowerCase() === env.config.roles.data_controller){
        this.userRole = true;
        break;
      }
    } */
    this.profile = { displayName: localStorage.getItem('dn'), email: localStorage.getItem('email') };
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParamsData = queryParams;
      this.status = queryParams['status']
      console.log( this.status)
      this.projectName = queryParams['projectName'];
      this.projectID = queryParams['projectKey'];
      this.selectedTabIndex = queryParams['selectProjectTabIndex']?Number(queryParams['selectProjectTabIndex']):0;
      this.roleId = queryParams['roleId'];
    });

    this.orgRole = this.storageService.secureStorage.getItem("role");
    /* if(this.queryParamsData.from !== 'noPopup' && this.userRole) {
      this.getProjectDetails();
    }  */
    if(this.queryParamsData.from !== 'noPopup' && (this.service.permission(this.roleId, 'auditSettings'))) {
      this.getProjectDetails();
    } 
      this.commonSer.rumConfig();
  }

  getProjectDetails(){
    this.isLoading = true;
    this.service.getProjectDetails(this.projectID).subscribe((data:any)=>{
      //if(data.result.projectDetails.auditSettings === null && this.queryParamsData.from === 'noPopup'){
        if(data.result.projectDetails.auditSettings === null){
          this.isLoading = false;
          this.auditSettingsPopup()
        }
      this.isLoading = false;
      this.auditSettingsStatus  = this.auditSettingsStatus.projectDetails.auditSettings;
    })
  }

  auditSettingsPopup() {
    const dialogRef = this.matDialog.open(AuditSettingsPopupComponent, {
      width: '460px',
      data: {
        isEdit: false,
        projectName: this.projectName,
        /* orgName: this.orgName,
        projectKey: this.projectKey,
        existingUserId: this.existingUserId, */
      }
    });
    this.dialogRef.close();
    /* dialogRef.afterClosed().subscribe((result) => {
      if(result !==''){
        this.dialogRef.close('yes');
      }
    }); */
  }
 
  onTabClick(tab: any) {
    this.selectedTabIndex = tab.index;
    this.router.navigate([], { relativeTo: this.route, queryParams: {...this.queryParamsData, selectProjectTabIndex:this.selectedTabIndex}});

  }
}
