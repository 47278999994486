import { ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { i18n } from 'src/app/i18n.en';
import { FileObj } from 'src/app/pages/project-management/add-project/model/model';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { environment } from 'src/environments/environment';
import { AuditService } from '../../audit.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

export class UploadResponse {
  progress: number;
  files: [];
}
export interface Fruit {
  name: string;
}
@Component({
  selector: 'app-audit-results',
  templateUrl: './audit-results.component.html',
  styleUrls: ['./audit-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditResultsComponent implements OnInit {
  i18n = i18n;
  auditResultForm: FormGroup;

  selectedFiles?: FileList;
  // progressInfos: any[] = [];
  //message: string[] = [];

  // fileInfos?: Observable<any>;
  fileName: string[] = [];
  // uploadedMedia: Array<any> = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  maxEmailCountError = false;
  readonly separatorKeysCodes: number[] = [ENTER];
  additionalEmailsToNotify: string[] = [];

  fileList: File[] = [];
  communicationFileList: FileObj[] = [];
  allowedFileSize: number;
  allowedFilesCount: number;
  disableFileDrop: boolean = false;
  hasChange: boolean = false;
  dataForFileUpload: { projectKey: string; region: string; };
  dataForEachFile: { projectFileType: string; };
  supportdataForEachFile: { projectFileType: string; };
  fileUploadApis: { fileupload: string; completeMultiPart: string; };
  supportfileUploadStatusData = {}
  fileType: string;
  isLoading: boolean;
  attachments: string[] = [];
  public files: NgxFileDropEntry[] = [];
  queryParamsData: object;
  userUuid: string;
  projectID: string;
  fileListDeleteOnCancel: any = [];
  fileListDeleteOnSave: any = [];
  fileNames: any = [];
  fileNameArray: string = '';

  @Output() formDataChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('comFileUpload') fileUploadComponent;
  @ViewChild('projectAuditFileUpload') projectAuditUploadComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private readonly auditService: AuditService,
    public readonly projectService: ProjectManagementService, private readonly _changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AuditResultsComponent>, private readonly toaster: ToastrService,
    readonly activatedRoute: ActivatedRoute, private storageService: StorageService) {

    const allowedFiles = 20;
    const allowedSize = 10737418240;
    this.allowedFileSize = allowedSize;
    this.allowedFilesCount = allowedFiles;
    dialogRef.disableClose = true;
  }

  // convenience getter for easy access to form fields
  get formgetter() { return this.auditResultForm.controls; }

  ngOnInit(): void {
    this.auditResultForm = new FormGroup({
      'auditResult': new FormControl('', Validators.required),
      'auditComments': new FormControl('', [Validators.required, this.SpecialCharValidator] as Object),
      'additionalEmail': new FormControl('', Validators.email),
      'uploadFile': new FormControl(''),
      'supportingIdeaFiles': new FormControl([])
    });
    this.fileType = `audit/${this.data.auditId}`;
    this.dataForFileUpload = {
      projectKey: this.data.projectKey,
      region: 'eu-central-1'
    }
    this.dataForEachFile = {
      projectFileType: this.fileType
    }
    this.supportdataForEachFile = {
      projectFileType: this.fileType
    }
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParamsData = queryParams;
    });
    this.userUuid = this.storageService.secureStorage.getItem('userUuid');
    this.projectID = this.queryParamsData['projectKey'];
  }

  SpecialCharValidator(control: FormControl) {
    const commentTxt: string = control.value;
    if (!(/^[a-zA-Z0-9._,/\n\- \\]*$/).test(commentTxt)) {
      return {
        specialchar: "not valid"
      }
    }
    return null;
  }
  add(event: MatChipInputEvent): void {
    console.log(event.chipInput?.inputElement.value);
    console.log(event.value)
    // const input = event.input;
    const input = event.chipInput?.inputElement;
    const value = event.value;

    // Add our email
    if ((value || '').trim() && !this.auditResultForm.get('additionalEmail')?.errors?.['email']) {
      if (this.additionalEmailsToNotify.length < 20) {
        this.additionalEmailsToNotify.push(value.trim());
      } else {
        this.maxEmailCountError = true;
      }
    }

    // Reset the input value
    if (input && !this.auditResultForm.get('additionalEmail')?.errors?.['email'] && !this.maxEmailCountError) {
      input.value = '';
    }
  }

  remove(emails: string): void {
    const index = this.additionalEmailsToNotify.indexOf(emails);

    if (index >= 0) {
      this.additionalEmailsToNotify.splice(index, 1);
      this.maxEmailCountError = false;
    }
  }

  onkeyUp(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.maxEmailCountError = false;
    }
  }

  onClose(controller) {
    //this.fileListDeleteOnCancel = (this.fileListDeleteOnCancel).filter(x => x.fileId !== (this.fileListDeleteOnSave).fileId );
    //array1.filter((object1) => !array2.some((object2) => object1.id === object2.id))
    this.fileListDeleteOnCancel = this.fileListDeleteOnCancel.filter((object1) => !this.fileListDeleteOnSave.some((object2) => object1.fileId === object2.fileId));
    const payload = {
      "projectKey": this.data.projectKey,
      "region": "eu-central-1",
      "fileDetail": this.fileListDeleteOnCancel
    }
    
    this.projectService.deletedSelectedFile(payload).subscribe({
      next: (res) => {
        if (res['result'].fileDetail[0].fileId) {
          const newArray = controller.value.filter(item => item.fileId !== res['result'].fileDetail[0].fileId);
          controller.setValue(newArray);
          this.formDataChanged.emit("fileUpload");
        }
      }
    });
    //this.deleteFileList(this.fileListDeleteOnCancel);
    this.dialogRef.close();
  }

  onSubmit(formObj) {
    //console.log("formObj: ", formObj);
    //const {fileId, fileSize, fileType, uploadId, ...filterFiles} = formObj.supportingIdeaFiles;
    for (const i in formObj.supportingIdeaFiles){
      //console.log("file: ", formObj.supportingIdeaFiles[i], formObj.supportingIdeaFiles[i].fileName);
      const {fileId, fileSize, fileType, uploadId, ...filterFiles} = formObj.supportingIdeaFiles[i];
      this.fileNames[i] = filterFiles;
    }
    //console.log("File name: ", this.fileNames, (this.fileNames).toString());
    //let text = fruits.toString();
    
    let names = this.fileNames.map((item) => item.fileName);
    console.log("Filename: ", names);
    const obj1 = {
      "projectKey": this.queryParamsData['projectKey'],
      "orgKey": this.queryParamsData['orgName'],
      "projectName": this.queryParamsData['projectName'],
      "region": this.queryParamsData['region'],
      "auditResult": formObj.auditResult,
      "additionalEmailAddress": this.additionalEmailsToNotify,
      "comments": formObj.auditComments,
      "auditSettings": this.data.auditSettings,
      //"attachments": formObj.supportingIdeaFiles,
      "attachments": names
    }
    if (this.auditResultForm.controls['supportingIdeaFiles'].untouched && Object.keys(this.supportfileUploadStatusData).length !== 0) {
      this.toaster.error('File upload is in progress. Please wait');
    } else {
      this.isLoading = true;
      this.auditService.getauditResult(obj1, this.data.auditId).subscribe({
        next: (res) => {
          this.deleteFileList(this.fileListDeleteOnSave);
          this.dialogRef.close('yes');
          this.isLoading = true;
        },
        error: (err) => {
          this.isLoading = false;
          this.toaster.error('Unable to record the audit result. Please try again.');
        }
      }) 
    }
  }

  toList(data): any[] {
    return Object.values(data)
  }
  toParse(data: string) {
    return JSON.parse(data)
  }

  supportFileUploadCompletionEvent(fileDetails) {
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "fileSize": String(fileDetails.fileDetails.size),
      "uploadId": fileDetails.uploadId,
      "fileType": fileDetails.fileName.split('.').pop()
    }
    const deleteObj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "projectFileType": this.fileType
    }
    this.attachments.push(fileDetails.fileName);

    if (this.auditResultForm.controls["supportingIdeaFiles"].value === null) {
      this.auditResultForm.controls["supportingIdeaFiles"].setValue([])
    }
    this.auditResultForm.controls["supportingIdeaFiles"].value.push(obj);
    this.fileListDeleteOnCancel.push(deleteObj);
    this.auditResultForm.controls["supportingIdeaFiles"].setValue(this.auditResultForm.controls["supportingIdeaFiles"].value);
    this.formDataChanged.emit("fileUpload");
  }
  get f() {
    return this.auditResultForm.controls;
  }

  deleteFile(file, controller) {
    console.log(file, controller);
    const payload = {
      "projectKey": this.data.projectKey,
      "region": "eu-central-1",
      "fileDetail": [
        {
          "fileName": file.fileName,
          "fileId": file.fileId,
          "projectFileType": this.fileType
        }
      ]
    }
    const removeFileObject = {
      "fileName": file.fileName,
      "fileId": file.fileId,
      "projectFileType": this.fileType
    }
    this.attachments.splice(this.attachments.indexOf(file.fileName),1)
    this.projectService.deletedSelectedFile(payload).subscribe({
      next: (res) => {
        if (res['result'].fileDetail[0].fileId) {
          const newArray = controller.value.filter(item => item.fileId !== res['result'].fileDetail[0].fileId);
          controller.setValue(newArray);
          this.fileListDeleteOnSave.push(removeFileObject);
          this.formDataChanged.emit("fileUpload");
        }
      }
    });
  }

  deleteFileList(array) {
    const payload = {  
      "projectKey": this.projectID,  
      "region": "eu-central-1",
      "fileDetail": array  
    }
  console.log("Payload: ", payload);

  if(array.length > 0){  
    this.projectService.deletedSelectedFile(payload).subscribe({  
      next: (res: any) => {  
         
      }  
    });
  
  }
  
  }
  /* deleteFile(file, controller) {
    const payload = {
      "fileName": file.fileName,
      "fileId": file.fileId,
      "projectFileType": this.fileType
    }
    if (file.fileId) {
      const newArray = controller.value.filter(item => item.fileId !== file.fileId);
      console.log(newArray);
      controller.setValue(newArray);
      this.fileListDeleteOnSave.push(payload);
    }
  } */
  ngAfterViewInit() {
    this.supportfileUploadStatusData = this.projectAuditUploadComponent.fileUploadService.filesListObject;
    this._changeDetectorRef.detectChanges();
  }
}
