import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  transform(item:any):string {
    return item.substring(0, item.indexOf('.'))
  }
}
