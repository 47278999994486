import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectManagementService } from '../../project-management.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-dataset-details',
  templateUrl: './dataset-details.component.html',
  styleUrls: ['./dataset-details.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DatasetDetailsComponent implements OnInit {
  i18n = i18n;
  projectName: string;
  projectKey: string;
  
  dataSource: any;
  displayedColumns: any = ['file_id', 'file_extension', 'approval_status'];
  isLoading = false;
  detailsData: any = [];
  datasetId: string;
  roleId: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, public router: Router, public projSer: ProjectManagementService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((queryParams: any) => {
      this.projectName = queryParams['projectName'];
      this.projectKey = queryParams['projectKey'];
      this.datasetId = queryParams['datasetId'];
      this.roleId = queryParams['roleId'];
    });
    this.getDatasetDetails();
  }

  onClose() {
    this.router.navigate(['projectmanagement/projectdetails'], {
      queryParams: {
        projectName: this.projectName,
        projectKey: this.projectKey,
        selectProjectTabIndex: 2,
        roleId: this.roleId
      }
    });
  }

  getDatasetDetails() {
    this.isLoading = true;
    this.projSer.getDatasetDetails(this.datasetId).subscribe({
      next: (res) => {
        this.detailsData = res.result;
        this.dataSource = new MatTableDataSource(res.result.filesList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        setTimeout(() => this.dataSource.sort = this.sort);
        this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
          return data.file_id.toLowerCase().includes(filter) || data.file_extension.toLowerCase().includes(filter)
            || data.approval_status.toLowerCase().includes(filter) || !filter;
        };
      },
      error: (err) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

