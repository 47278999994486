<div [formGroup]="additionalFiles" class="container">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="label-title">Budget Document *</div>
                <div class="warning-message-dialog">
                    <mat-icon class="material-icons-outlined info-icon-warn">info</mat-icon>
                    <mat-list role="list">
                        <mat-list-item role="listitem">Guidelines to upload,</mat-list-item>
                        <mat-list-item role="listitem">1. Maximum number of files allowed for upload 20 files</mat-list-item>
                        <mat-list-item role="listitem">2. Size limit per file - 0.5 GB per file</mat-list-item>
                        <mat-list-item role="listitem">3. Total attachments size - 1 GB</mat-list-item>
                        <mat-list-item role="listitem">4. File types allowed for upload - </mat-list-item>
                        <mat-list-item role="listitem" class="extention">.doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls, .xlsx, .ics (apple calendar), .msg (Outlook email message) </mat-list-item>
                    </mat-list>
                </div>
                <app-file-upload #budgetFileUpload
                [resetOnLoad]="true"
                [userUuid]="userUuid"
                [projectKey]="projectID"
                [errForValidFileSize]="'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection'"
                [errForCumulativeFileSize]="'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.'"
                [maxFilesAllowed]="20" 
                [maxParallelFileUploads]="4"
                [maxParallelChunksUpload]="10"
                [maxChunkSizeInBytes]="209715200"
                [maxFileSizeAllowedInGB]="0.5"
                [maxCumulativeSizeAllowedInGB]="1"
                [allowedExtensions] = "['.doc', '.docx', '.txt', '.pdf', '.csv', '.png', '.jpeg', '.xls', '.xlsx', '.ics', '.msg']"
                [restrictedExtensions]="[]" 
                [fileUploadApis]='service.pcFileUploadApis'
                [dataForFileUpload]="dataForFileUpload" 
                [dataForEachFileInUpload]="dataForEachFile"
                [Identifier]="'budgetFileList'"
                (completedFileEventReceiver)="fileUploadCompletionEvent($event)"></app-file-upload>  
                <mat-grid-list cols="2" rowHeight="50px" *ngFor="let item of toList(fileUploadStatusData)"
                class="files-list-container">
                <mat-grid-tile [colspan]=1>
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{item.name}}</span>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                        value="{{item.fileProgress}}"></mat-progress-bar>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let items of f['budgetFiles'].value"
                class="files-list-container">
                <mat-grid-tile class="item1">
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{
                            items.fileName}}</span></div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-icon (click)="deleteFile(items,'budgetFiles',f['budgetFiles'])"  class="delete-icon">{{projectCreation.icon.close}}</mat-icon>
                </mat-grid-tile>
            </mat-grid-list>  
            <div class="col-12 mb-4 mt-5">
                <div class="label-title">Other Attachments</div>
                <div class="warning-message-dialog">
                    <mat-icon class="material-icons-outlined info-icon-warn">info</mat-icon>
                    <mat-list role="list">
                        <mat-list-item role="listitem">Guidelines to upload,</mat-list-item>
                        <mat-list-item role="listitem">1. Maximum number of files allowed for upload 20 files</mat-list-item>
                        <mat-list-item role="listitem">2. Size limit per file - 0.5 GB per file</mat-list-item>
                        <mat-list-item role="listitem">3. Total attachments size - 1 GB</mat-list-item>
                        <mat-list-item role="listitem">4. File types allowed for upload - </mat-list-item>
                        <mat-list-item role="listitem" class="extention">.doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls, .xlsx, .ics (apple calendar), .msg (Outlook email message) </mat-list-item>
                    </mat-list>
                </div>
                <app-file-upload #otherFileUpload 
                [resetOnLoad]="true"
                [userUuid]="userUuid"
                [projectKey]="projectID"
                [errForValidFileSize]="'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection'"
                [errForCumulativeFileSize]="'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.'"
                [maxFilesAllowed]="20" 
                [maxParallelFileUploads]="4"
                [maxParallelChunksUpload]="10"
                [maxChunkSizeInBytes]="209715200"
                 [maxFileSizeAllowedInGB]="0.5"
                [maxCumulativeSizeAllowedInGB]="1" 
                [allowedExtensions] = "['.doc', '.docx', '.txt', '.pdf', '.csv', '.png', '.jpeg', '.xls', '.xlsx', '.ics', '.msg']"
                [restrictedExtensions]="[]" 
                [fileUploadApis]='service.pcFileUploadApis'
                [dataForFileUpload]="dataForFileUpload" 
                [dataForEachFileInUpload]="othersDataForEachFile"
                [Identifier]="'othersFileList'"
                (completedFileEventReceiver)="othersFileUploadCompletionEvent($event)"></app-file-upload>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let item of toList(othersFileUploadStatusData)"
                class="files-list-container">
                <mat-grid-tile [colspan]=1>
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{item.name}}</span>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                        value="{{item.fileProgress}}"></mat-progress-bar>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let items of f['otherFiles'].value"
                class="files-list-container">
                <mat-grid-tile class="item1">
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{
                            items.fileName}}</span></div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-icon (click)="deleteFile(items,'othersFiles',f['otherFiles'])" class="delete-icon">{{projectCreation.icon.close}}</mat-icon>
                </mat-grid-tile>
            </mat-grid-list>
            </div>
        </div>
    </div>