import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-reject-files',
  templateUrl: './reject-files.component.html',
  styleUrls: ['./reject-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RejectFilesComponent implements OnInit {
  i18n = i18n;
  [x: string]: any;
  rejectFilesForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<RejectFilesComponent>) { }

  ngOnInit(): void {
    this.rejectFilesForm = new FormGroup({
      reason: new FormControl('', [Validators.required]),
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit(){
    this.dialogRef.close(this.rejectFilesForm.controls['reason']?.value);
  }

}
