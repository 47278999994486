import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-remove-collaborator',
  templateUrl: './remove-collaborator.component.html',
  styleUrls: ['./remove-collaborator.component.scss']
})
export class RemoveCollaboratorComponent implements OnInit {
  i18n = i18n;
  showDCPopup: boolean = false;
  role = environment.config.roles;
  constructor(public dialogRef: MatDialogRef<RemoveCollaboratorComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private toastrService: ToastrService) { 
  }

  ngOnInit(): void {
    this.checkTheSelectedUserRole();
  }
  
  onCancel() {
    this.dialogRef.close();
  }

  onRemove(){
    // this.toastrService.success('Collaborator removed from the project successfully');
    this.dialogRef.close('yes');
  }
  checkTheSelectedUserRole(){
    if(this.data.selectedRowElement.roleName.toLowerCase() === this.role.data_controller){
      this.showDCPopup = true;
    }
    else{
      this.showDCPopup = false;
    }
  }
}
