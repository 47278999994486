import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService {
  
  userOverviewUrl = env.config.domain + env.userOverview;
  userProjectDetailsUrl = env.config.domain + env.userProjectdetails;
  organizationUrl = env.config.domain + env.organizationOverview;
  userListUrl = env.config.domain + env.userList;
  searchUserListUrl = env.config.domain + env.searchUserList;
  myApprovalsListUrl = env.config.domain + env.myApprovalsList;
  approveDatasetUrl = env.config.domain + env.approveDataset;
  dataClassificationUrl = env.config.domain + env.dataClassification;
  myapprovalDatasetDetailsUrl = env.config.domain + env.myapprovalDatasetDetails;
  cadenceDataUrl = env.config.domain + env.cadenceDataUrl;
  updateAuditSettingsUrl =  env.config.domain + env.updateAuditSettings;
  auditEditUrl = env.config.domain + env.auditEdit;
  downloadProjectInfoUrl = env.config.domain + env.downloadProjectInfo;
  downloadDataSetsUrl = env.config.domain + env.downloadDataSets;
  
  dataSetUrl = env.config.domain + env.dataSetList;

  prjDetails:any;
  private list:any =[];
  pcFileUploadApis = {
    fileupload: env.config.domain + env.projectCreationFileUpload,
    completeMultiPart: env.config.domain + env.projectCreationCompleteMultiPart
  }
 //titleMessageData =  new Subject<any>();
 //titlemessgae$ = this.titleMessageData.asObservable();



 /* sendMessage(msg:any){
 this.titleMessageData.next(msg)
 } */
  private _subject = new BehaviorSubject<any>({});
  constructor(private readonly http: HttpClient,
    private readonly router: Router,
    private readonly matDialog: MatDialog) { }


  setProjectdetails(details:any){   
    /* this.list.push(details)
    return this.list(); */
    this.prjDetails = details;    
  }
  getUserDetails(userUUID:any){
    return this.http.get(this.userProjectDetailsUrl+'/'+userUUID);
  }

  getUserOverview(orgKey:any){
    return this.http.get(this.userOverviewUrl+'/'+orgKey);
  }
  getOrgDetails(){
    return this.http.get(this.organizationUrl);
  }

  getProjectDetails(projectkey:string):Observable<any>{
    const url = env.config.domain + env.projectDetails + projectkey + '/' + env.config.orgKey;
    return this.http.get(url);
  }

  getAllRoles() {
    return this.http.get(env.config.domain + env.allRoles + env.config.orgKey);
  }

  getCollaborator(projectID: string, orgKey: any){
    return this.http.get(env.config.domain + env.collaboratorslist + '/' + projectID + '/' + orgKey);
  }
  

  getRegion(){    
    return this.http.get(env.config.domain + env.projRegion);
  }
  getClassification(){    
    return this.http.get(env.config.domain + env.projClassification);
  }
  createProject(payload:any){    
    return this.http.post(env.config.domain + env.projCreate, payload);
  }
  
  editProject(payload:any): Observable<any>{
    return this.http.put<any>(env.config.domain + env.projEdit, payload);
  }

  getProjLists(obj:any){    
    return this.http.post(env.config.domain + env.projList, obj);
  }
  getProjDetails(projKey:string){    
    return this.http.get(env.config.domain + env.projectDetails + projKey);
  }
  getProjUsers(){    
    return this.http.get(env.config.domain + env.projUsers);
  }

  deleteProjUsers(OrgKey:string, UserID:string): Observable<any>{ 
    return this.http.delete<any>(env.config.domain + env.deleteOrgUsers+ '?orgKey=' + OrgKey +'&userId='+ UserID);
  }

  deleteCollabUsers(projectKey:string, UserID:string): Observable<any>{    
    return this.http.delete<any>(env.config.domain + env.deleteProjectUsers +'/' + projectKey +'/'+ UserID);
  }

  deleteAuditUsers(projectKey:string, UserID:string): Observable<any>{    
    return this.http.delete<any>(env.config.domain + env.deleteAuditUsers +'/' + projectKey +'/'+ UserID);
  }

  getProjectUserList(projectName:any, orgName:any){
    const url = this.userListUrl + '/' + projectName + '/' + orgName;
    return this.http.get(url);
  }

  getProjectSearchUserList(projectName:any, orgName:any){
    const url = this.searchUserListUrl + '/' + projectName + '/' + orgName;
    return this.http.get(url);
  }

  getSiteNames(): Observable<any>{
    const url = env.config.domain + env.sitenames;
    return this.http.get<any>(url);
  }

  getmyApprovalsList(projectKey: any, orgKey: any){
    const url = this.myApprovalsListUrl+"?projectKey="+projectKey+"&orgKey="+orgKey;
    return this.http.get(url);
  }

  approveDataset(payload:any){
    return this.http.post(this.approveDatasetUrl, payload);
  }
  getDatasetDetails(id:string): Observable<any> {
    const url = env.config.domain + env.getDatasetDetail + id;
    return this.http.get<any>(url);
  }

  getPwd(email:string): Observable<any> {
    const url = env.config.domain + env.getPwd + '/' + email;
    return this.http.get<any>(url);
  }

 /*  dataFilesUrl = '../../../assets/json/data-files.json';
  getDataFilelist() {
    return this.http.get(this.dataFilesUrl);
  } */

  getDataFilelist(projectKey: string, userId: any) {
    const url = env.config.domain + env.getDataFilelist + projectKey + '&userId=' + userId;
    return this.http.get(url);
  }


  getsFTPUrl(projectKey: string, resourceKey: any) {
    const url = env.config.domain + env.sFTPDetails + projectKey + '&resourceKey=' + resourceKey;
    return this.http.get(url);
  }

  createdDataset(payload: any) {
    const url = env.config.domain + env.createdDataset;
    return this.http.post(url, payload);
  }

  newEvent(event: any) {
    this._subject.next(event);
  }

  get events$() {
    return this._subject.asObservable();
  }
 
  getDataSetList(prjKey:any, userEmail:any){
    return this.http.get(this.dataSetUrl+`?projectKey=${prjKey}&userEmail=${userEmail}`);
  }

  getDataClassification(){
    return this.http.get(this.dataClassificationUrl);
  }

  getMyapprovalDatasetDetails(datasetDetailsId:any){
      const url = this.myapprovalDatasetDetailsUrl+"?datasetId="+datasetDetailsId;
      //console.log("URL: ", url);
      return this.http.get(url);
  }

  getProjectRole(userId:string, orgKey: string, projectKey: string):Observable<any>{
    const url = env.config.domain + env.getProjectRole + userId+ '&orgKey=' + orgKey + '&projectKey=' + projectKey;
    return this.http.get<any>(url);
  }

  getCurrentUserRole(userId:string) {
    const url = env.config.domain + env.currentRole + userId +'&orgKey=' + env.config.orgKey;
    return this.http.get(url);
  }

  permission(roleId:any, permissionName:string){
    const projectPermissions = JSON.parse(sessionStorage.getItem('projectPermissions')!); 
    let val = false;
    if(roleId?.includes('org')){
    projectPermissions.forEach((element:any) => {
      if(element.roleId === roleId){
        val = element.permissions.ProjectManagement[permissionName];
      }
    });      
    } else{      
      for(const role of projectPermissions){
        if(role.roleId === roleId && role.permissions.ProjectManagement[permissionName]){       
          val = role.permissions.ProjectManagement[permissionName];
        } 
      } 
    }
       return val;
     
  } 

  // start OnProject Creation
  onCancelEvent() {
    const dialogRef = this.matDialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: {
        title: 'Warning',
        confiramtion_text: 'Are you sure you want to exit project creation?',
        accept_btn: 'Yes',
        decline_btn: 'No'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'accept') {
        this.router.navigate(['projectmanagement']);
      }
    })
  }


  getSites(): Observable<any> {
    const url = env.config.domain + env.sites;
    return this.http.get<any>(url);
  }
  getDieasesArea() {
    const url = env.config.domain + env.dieasesArea;
    return this.http.get<any>(url);
  }
  getStudyType() {
    const url = env.config.domain + env.studyType;
    return this.http.get<any>(url);
  }

  addProject(payload: any) {
    return this.http.post(env.config.domain + env.addProject, payload);
  }

  getDuplicateProjectName(projectName:string):Observable<any> {
    const url = env.config.domain + env.duplicateProject + "/" + projectName;
    return this.http.get<any>(url);
  }
  getTransferUserList(payload){
    return this.http.post(env.config.domain + env.transferuserList, payload);
  }
  sendTranferData(payload){
    return this.http.put(env.config.domain + env.transferData, payload);
  }
  sendEditProjectDetailsData(payload) {
    return this.http.put(env.config.domain + env.updateProject, payload);
  }
  getFilePresignedURL(payload) {
    return this.http.post(env.config.domain + env.fileDownload, payload);
  }
  deletedSelectedFile(payload) {
    const httpOptions = {
      body: payload
    }
    return this.http.delete(env.config.domain + env.fileDelete, httpOptions);
  }
  getDownloadPresignedURL(element, fileType, projectId) {
    const payload = {
      "projectKey": projectId,
      "region": "eu-central-1",
      "fileDetail": [
        {
          "fileName": element.fileName,
          "fileId": element.fileId,
          "projectFileType": fileType
        }
      ]

    }
    this.getFilePresignedURL(payload).subscribe({
      next: (response: any) => {
        this.fileDownload(response.result?.fileDetail[0]?.presignedURL, response.result.fileDetail[0].fileName);
      },
      error: (err) => {

      }
    });
  }

  fileDownload(fileDownloadURL, fileName) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileDownloadURL);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  checkUserHasDCRole(userId: string) {
    const url = env.config.domain + env.checkDCuser + "/" + userId;
    return this.http.get(url);
  }
  checkProjectCount() {
    const url = env.config.domain + env.checkProjectCount
    return this.http.get(url);
  }

  // end project creation
  
  getCadence() {
    return this.http.get(this.cadenceDataUrl);
  }

  getAuditData(obj: any, orgName: string, projectKey: string) {
    return this.http.put(this.updateAuditSettingsUrl+"/"+orgName+"/"+projectKey, obj);
  }

  /*   getauditEditUrl(orgName: string, projectKey: string){
    return this.http.get(this.auditEditUrl+"/"+orgName+"/"+projectKey);
  } */

  getdownloadProjectInfo(projectName: string, orgKey: string, projectKey: string){
    const url = this.downloadProjectInfoUrl+"?projectName="+projectName+"&orgKey="+orgKey+"&projectKey="+projectKey;
    return this.http.get(url);
  }

  getdownloadDataSets(projectName: string, orgKey: string, projectKey: string, startDate, endDate){
    const url = this.downloadDataSetsUrl+"?projectName="+projectName+"&orgKey="+orgKey+"&projectKey="+projectKey+"&startDate="+startDate+"&endDate="+endDate;
    return this.http.get(url);
  }

}

