import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from '../user-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { i18n } from '../../../i18n.en';

interface approveUserList {

  userUuid: number,
  remark: string
}

@Component({
  selector: 'app-approveuser',
  templateUrl: './approveuser.component.html',
  styleUrls: ['./approveuser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApproveuserComponent implements OnInit {
  i18n = i18n;
  approveData: any = {};
  userId: any = {};
  approveForm: FormGroup;
  isLoading: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public storageService: StorageService, private userService: UserManagementService, public dialogRef: MatDialogRef<ApproveuserComponent>, private toastrService: ToastrService) {

    this.userId = data.selectedRowElement;
    // console.log("approve data", this.userId)
  }

  ngOnInit(): void {
    this.approveForm = new FormGroup({
      remarks: new FormControl('', [Validators.required]),
    });
  }
  onCancel() {
    this.dialogRef.close('Ravi');
  }

  onApprove() {
    // console.log(this.userId)
    this.isLoading = true;
    this.approveData.userId = this.userId.userUuid;
    this.approveData.roleId = this.userId.roleDetails.roleId;
    this.approveData.roleType = this.userId.roleDetails.roleType;
    this.approveData.roleName = this.userId.roleDetails.roleName;
    this.approveData.orgKey = this.userId.roleDetails.orgKey;
    this.approveData.orgName = this.userId.roleDetails.orgName;
    this.approveData.remark = this.approveForm.controls['remarks']?.value;
    // this.approveData.roleId = this.userId.roleDetails.roleId;
    this.approveData.roleHashKey = this.userId.roleDetails.roleHashKey;
    // console.log(this.approveData)
    this.userService.postUserApproval(this.approveData).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.dialogRef.close(data);
        this.toastrService.success('User access to imCORE has been approved successfully!');
      },
      error: (err) => {
        this.toastrService.error('Server error');
      }
    })
    
  }
}
