import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { MatDialogRef } from '@angular/material/dialog';
import { CoreMaModule } from 'src/app/shared/core-ma/core-ma.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserDetailsComponent } from './user-overview/user-details/user-details.component';
import { InviteCollaboratorComponent } from './collaborators/invite-collaborator/invite-collaborator.component';
import { ProjectManagementComponent } from './project-management.component';
import { ProjectFileComponent } from './project-file/project-file.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RemoveProjectComponent } from './project-overview/remove-project/remove-project.component';
import { RemoveUserComponent } from './user-overview/remove-user/remove-user.component';
import { RemoveCollaboratorComponent } from './collaborators/remove-collaborator/remove-collaborator.component';
import { EditCollaboratorComponent } from './collaborators/edit-collaborator/edit-collaborator.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenuModule } from '@angular/material/menu';
import { MyApprovalsComponent } from './my-approvals/my-approvals.component';
import { RejectFilesComponent } from './my-approvals/reject-files/reject-files.component';
import { DataingressOverviewComponent } from './dataingress-overview/dataingress-overview.component';
import { DatasetDetailsComponent } from './dataingress-overview/dataset-details/dataset-details.component';
import { MyapprovalsDatasetDetailsComponent } from './my-approvals/myapprovals-dataset-details/myapprovals-dataset-details.component';
import { DataFilesListComponent } from './data-files-list/data-files-list.component';
import { CreateDatasetComponent } from './create-dataset/create-dataset.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { BytesToMBPipe } from 'src/app/pipes/bytes-to-mb.pipe';
import { AddProjectComponent } from './add-project/add-project.component';
import { ProjectConceptComponent } from './add-project/project-concept/project-concept.component';
import { ComputeConfigurationComponent } from './add-project/compute-configuration/compute-configuration.component';
import { ProjectIdeaComponent } from './add-project/project-idea/project-idea.component';
import { AdditionalFilesComponent } from './add-project/additional-files/additional-files.component';
import { CITCDetailsComponent } from './add-project/citcdetails/citcdetails.component';
import { ReviewProjectProposalComponent } from './add-project/review-project-proposal/review-project-proposal.component';
import { ProjectIdeaDetailsComponent } from './add-project/review-tab-summary/project-idea-details/project-idea-details.component';
import { ProjectConceptDetailsComponent } from './add-project/review-tab-summary/project-concept-details/project-concept-details.component';
import { ComputeConfigurationDetailsComponent } from './add-project/review-tab-summary/compute-configuration-details/compute-configuration-details.component';
import { CitcDetailsComponent } from './add-project/review-tab-summary/citc-details/citc-details.component';
import { AdditionalFilesDetailsComponent } from './add-project/review-tab-summary/additional-files-details/additional-files-details.component';
import { ProjectFilesTransferDetailsComponent } from './project-files-transfer-details/project-files-transfer-details.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { ReviewTabSummaryComponent } from './add-project/review-tab-summary/review-tab-summary.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { ConfigureAuditSettingsComponent } from './configure-audit-settings/configure-audit-settings.component';
import { AuditSettingsComponent } from './audit-settings/audit-settings.component';
import { AuditSettingsPopupComponent } from './audit-settings-popup/audit-settings-popup.component'; 



@NgModule({
  declarations: [
    ProjectManagementComponent,
    ProjectOverviewComponent,
    UserOverviewComponent,
    ProjectDetailsComponent,
    CollaboratorsComponent,
    UserDetailsComponent,
    InviteCollaboratorComponent,
    ProjectFileComponent,
    CreateProjectComponent,
    RemoveProjectComponent,
    RemoveUserComponent,
    RemoveCollaboratorComponent,
    EditCollaboratorComponent,
    MyApprovalsComponent,
    DatasetDetailsComponent,
    RejectFilesComponent,
    DataingressOverviewComponent,
    MyapprovalsDatasetDetailsComponent,
    DataFilesListComponent,
    CreateDatasetComponent,
    ConfirmationPopupComponent,
    CreateDatasetComponent,
    ConfirmationPopupComponent,
    AddProjectComponent,
    ProjectConceptComponent,
    ComputeConfigurationComponent,
    ProjectIdeaComponent,
    AdditionalFilesComponent,
    CITCDetailsComponent,
    ReviewProjectProposalComponent,
    ReviewTabSummaryComponent,
    ProjectIdeaDetailsComponent,
    ProjectConceptDetailsComponent,
    ComputeConfigurationDetailsComponent,
    CitcDetailsComponent,
    AdditionalFilesDetailsComponent,
    ProjectFilesTransferDetailsComponent,
    EditProjectComponent,
    ConfigureAuditSettingsComponent,
    AuditSettingsComponent,
    AuditSettingsPopupComponent
  ],
  imports: [
    CommonModule,
    CoreMaModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    ProjectRoutingModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    MatMenuModule,
    CdkStepperModule,
    NgTemplateOutlet,
    MatStepperModule,
    MatTooltipModule,
    MatProgressBarModule,
    NgxFileDropModule,
    FileUploadModule
  ],
  exports: [EditProjectComponent],
  providers: [
    BytesToMBPipe,
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ]
})
export class ProjectModule { }
