<div class="approve-popup-card">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{i18n.projectMnt.PO.RP.TITLE}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p>{{i18n.projectMnt.PO.RP.DESC}}</p>
       
        </mat-card-content>
        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button (click)="onCancel()">{{i18n.projectMnt.PO.RP.BTN_CANCEL}}</button>
            <button mat-raised-button (click)="onRemove()">{{i18n.projectMnt.PO.RP.BTN_REMOVE}}</button>
        </div>
        </mat-card-footer>
    </mat-card>
</div>