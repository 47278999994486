<div class="approve-popup-card">

    <mat-card *ngIf="from === 'project_audit'">
        <mat-card-header>
            <mat-card-title>Remove User</mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
            <p *ngIf="dataControllerCount === 0">The selected user will be removed from the<br> {{projectName}}. Are you sure, you want to continue?</p>
            <p *ngIf="dataControllerCount === 1">The selected user is the Data Controller for this project.
                Transfer this role to another user using "Manage Data Controller" option in this Manage Users page before removing the user.</p>
       
        </mat-card-content>
        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button [ngClass]="{'mb-4' : (dataControllerCount === 1)}" (click)="onCancel()">{{i18n.projectMnt.UO.RU.BTN_CANCEL}}</button>
            <button mat-raised-button [ngClass]="{'mb-4' : (dataControllerCount === 1)}" (click)="onRemove()" [disabled]="dataControllerCount === 1">{{i18n.projectMnt.UO.RU.BTN_REMOVE}}</button>
        </div>
        </mat-card-footer>
    </mat-card>
    <mat-card *ngIf="from === 'userManagement'" > 
        <mat-card-header>
            <mat-card-title mat-dialog-title>{{i18n.projectMnt.UO.RU.TITLE}}</mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="!hasDataControllerRole">
            <p>{{i18n.projectMnt.UO.RU.DESC}}</p>       
        </mat-card-content>
 
        <mat-card-content *ngIf="hasDataControllerRole">
            <p>The selected user is assigned as Data Controller in one or more projects. 
                Transfer this role to another user using "Manage Data Controller" option under the respective project's Collaborators tab before removing the user.</p>
        </mat-card-content>

        <mat-dialog-actions align="end">
            <button mat-stroked-button [ngClass]="{'mb-4' : hasDataControllerRole}" (click)="onCancel()">{{i18n.projectMnt.UO.RU.BTN_CANCEL}}</button>
            <button mat-raised-button [ngClass]="{'mb-4' : hasDataControllerRole}" (click)="onRemove()" [disabled]="hasDataControllerRole">{{i18n.projectMnt.UO.RU.BTN_REMOVE}}</button>
          </mat-dialog-actions>
    </mat-card>

    <mat-card *ngIf="from !== 'project_audit' && from !== 'userManagement'"> 
        <mat-card-header>
            <mat-card-title>{{i18n.projectMnt.UO.RU.TITLE}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p>{{i18n.projectMnt.UO.RU.DESC}}</p>       
        </mat-card-content>

        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button (click)="onCancel()">{{i18n.projectMnt.UO.RU.BTN_CANCEL}}</button>
            <button mat-raised-button (click)="onRemove()">{{i18n.projectMnt.UO.RU.BTN_REMOVE}}</button>
        </div>
        </mat-card-footer>
    </mat-card>
</div>