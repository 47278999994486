import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { InviteCollaboratorComponent } from './invite-collaborator/invite-collaborator.component';
import { RemoveCollaboratorComponent } from './remove-collaborator/remove-collaborator.component';
import { EditCollaboratorComponent } from './edit-collaborator/edit-collaborator.component';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { i18n } from 'src/app/i18n.en';
import { ManageDataControllerComponent } from '../../project-audit/project-audit-files/manage-data-controller/manage-data-controller.component';

@Component({
  selector: 'app-collaborators',
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class CollaboratorsComponent implements OnInit {
  i18n = i18n;
  overviewList: any = [];
  collaboratorDataSource: any = [];
  collaboratorviewColumns = ['radio', 'userEmail', 'firstName', 'roleName', 'trianingStatus', 'dateCreated', 'actions'];
  isLoading = false;
  hasNoUserData:boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showRemove = false;
  selectedCollaboratorRow: any;
  projectKey: string;
  @Input() projectName: string;
  @Input() projectID: string;
  role = environment.config.roles;
  dataControllerExist = false;
  roleId: string;
  currentDataController: string;
  existingUserId: string;
  existingRoleHashKey: string;

  constructor(public commonSer: CommonService, public toastr: ToastrService, 
    private readonly matDialog: MatDialog, private readonly route: ActivatedRoute, 
    public service: ProjectManagementService) { }
  
  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.projectName = queryParams['projectName'];
      this.projectKey = queryParams['projectKey'];
      this.roleId = queryParams['roleId'];
    });
    this.showRemove = true;
    
    if(!this.service.permission(this.roleId, 'inviteRemoveUser')){
      this.collaboratorviewColumns = ['userEmail', 'firstName', 'roleName', 'trianingStatus', 'dateCreated', 'actions'];
    }

    this.getCollaboratorTableData();
  }


  getCollaboratorTableData() {
    this.isLoading = true;
    this.service.getCollaborator(this.projectID, environment.config.orgKey).subscribe({
      next: (res: any) => {
        this.overviewList = res.result;
        (this.overviewList).filter((val: any) => { 
          if(val.roleName === 'Data Controller'){
            this.currentDataController = val.userEmail;
            this.existingUserId = val.userUuid;
            this.existingRoleHashKey = val.roleHashKey;
          }
        });
        this.collaboratorDataSource = new MatTableDataSource(this.overviewList);
        this.collaboratorDataSource.paginator = this.paginator;
        setTimeout(() => this.collaboratorDataSource.sort = this.sort);
        setTimeout(() => this.collaboratorDataSource.paginator = this.paginator);
        this.hasNoUserData = (this.collaboratorDataSource.data.length === 0) ? true : false;
        this.dataControllerExist = this.collaboratorDataSource.data.some((result: any) => result.roleName.toLowerCase() === this.role.data_controller);
      },
      error: (err) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.collaboratorDataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.collaboratorDataSource.filter = filterValue.trim().toLowerCase();
  }

 onInviteUser() {
    const dialogRef = this.matDialog.open(InviteCollaboratorComponent, {
      width: '1000px',
      panelClass: 'invite_colab_model',
      data: {
        projName: this.projectName,
        projectKey: this.projectKey,
        orgKey: environment.config.orgKey,
        isDataControllerExist: this.dataControllerExist
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result === 'yes') {
        this.getCollaboratorTableData();         
      }
    });
  }

  onSelectCollaborator(element: any) {
    this.showRemove = false;
    this.selectedCollaboratorRow = element;
  }

  onRemoveCollaborator() {
    const dialogRef = this.matDialog.open(RemoveCollaboratorComponent, {
      width: '400px',
      data: {
        isEdit: true,
        selectedRowElement: this.selectedCollaboratorRow
      }
    });
    dialogRef.afterClosed().subscribe((result) => {      
      if (result === 'yes') {
        this.isLoading = true;
        this.service.deleteCollabUsers(this.projectKey, this.selectedCollaboratorRow.userUuid).subscribe({
          next: (res) => {
            if(res.responseCode==='200'||res.responseCode==='201'){
              this.getCollaboratorTableData();
              this.toastr.success(this.i18n.projectMnt.collaborators.DelCollabUsers.TOASTR_S)
              }else{
                this.toastr.error(res.message);
              }             
          },
          error: (err) => { 
            this.isLoading = false;
            this.toastr.error(this.i18n.projectMnt.collaborators.DelCollabUsers.TOASTR_E);
           },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  onEditCollaborator(element: any) {
    this.isLoading = true;
    element.projName = this.projectName;
    element.orgName = environment.config.orgKey;
    const dialogRef = this.matDialog.open(EditCollaboratorComponent, {
      width: '1000px',
      data: {
        isEdit: true,
        selectedRowElement: element,
        isDataControllerExist: this.dataControllerExist
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.getCollaboratorTableData();
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }
  manageDataController() {
    const dialogRef = this.matDialog.open(ManageDataControllerComponent, {
      width: '600px',
      data: {
        currentDataController: this.currentDataController,
        projectName: this.projectName,
        existingUserId: this.existingUserId,
        projectKey: this.projectKey,
        existingRoleHashKey: this.existingRoleHashKey
      }
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      console.log("after close: ", result);
        if (result === 'yes') {
          console.log("inside IF: ");
          window.location.reload();
      }
    });
    }
  }

function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;

  return customPaginatorIntl;
}

