<div class="tab-details container">
  <div class="row">
    <div class="col-12 mb-3">
      <mat-label>
        <p>Budget Document *</p>
        <div class="create-project-table-container">
          <div class="table-container">
            <table mat-table [dataSource]="budgetdataSource?.data.length > 0  ? budgetdataSource : emptyData"
              class="mat-elevation-z0" matSort #budgetSort="matSort">
              <!-- Filename Column -->
              <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
              </ng-container>

              <!-- download Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <a (click)="service.getDownloadPresignedURL(element,'budgetFiles',projectID);"
                    class="browse-button">Download</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="empty-row">
                <td mat-cell class="mt-3 d-flex justify-content-center" *matCellDef="let element">no record found</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row
                *matRowDef="let row; columns: budgetdataSource?.data?.length > 0 ? displayedColumns: ['empty-row'];">
              </tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true" class="mat-paginator-sticky"
            #budgetPaginator>
          </mat-paginator>
        </div>

      </mat-label>
    </div>
    <div class="col-12 mb-3">
      <mat-label>
        <p>Other Attachments</p>
        <div class="create-project-table-container">
          <div class="table-container">
            <table mat-table [dataSource]="othersDataSource?.data.length > 0  ? othersDataSource : emptyData" class="mat-elevation-z0" matSort #othersSort="matSort">
              <!-- Filename Column -->
              <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
              </ng-container>

              <!-- download Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <a (click)="service.getDownloadPresignedURL(element,'othersFiles',projectID);"
                    class="browse-button">Download</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="empty-row">
                <td mat-cell class="mt-3 d-flex justify-content-center" *matCellDef="let element">no record found</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: othersDataSource?.data?.length > 0 ? displayedColumns: ['empty-row'];"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true" class="mat-paginator-sticky"
            #othersPaginator>
          </mat-paginator>
        </div>
      </mat-label>

    </div>
  </div>
</div>