import { Component, EventEmitter, Input, OnInit, Output, ViewChild,OnChanges, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectManagementService } from '../../project-management.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { AdditionalFiles, projectCreation } from '../model/model';
import { i18n } from 'src/app/i18n.en';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-additional-files',
  templateUrl: './additional-files.component.html',
  styleUrls: ['./additional-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalFilesComponent implements OnInit, OnChanges{
  projectCreation = projectCreation;
  i18n = i18n;
  additionalFiles: FormGroup;
  fileToUpload: File | null = null;
  public files: NgxFileDropEntry[] = [];
  public otherFiles: NgxFileDropEntry[] = [];
  dataForFileUpload: { projectKey: string; region: string; };
  dataForEachFile: { projectFileType: string; };
  othersDataForEachFile: { projectFileType: string; };
  fileUploadStatusData = {};
  othersFileUploadStatusData = {};
  isDropDisabled: boolean = false;
  userUuid:string;
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() setExitingData:AdditionalFiles;
  @Output() emitFileOnUplaod: EventEmitter<{ fileName: string, fileId: string, projectFileType: string }> = new EventEmitter<{ fileName: string, fileId: string, projectFileType: string }>();
  @Input() fileListDeleteOnSave;
  @ViewChild('budgetFileUpload') fileUploadComponent;
  @ViewChild('otherFileUpload') otherfileUploadComponent;
  otherFileUpload
  constructor(public readonly service: ProjectManagementService,
    private storageService: StorageService) {
    this.additionalFiles = new FormGroup({
      budgetFiles: new FormControl([], [Validators.required]),
      otherFiles: new FormControl([]),
    });
    this.userUuid = this.storageService.secureStorage.getItem('userUuid');
  }

  ngOnInit(): void {
    this.dataForFileUpload = {
      projectKey: this.projectID,
      region: 'eu-central-1'
    }
    this.dataForEachFile = {
      projectFileType: 'budgetFiles'
    }
    this.othersDataForEachFile = {
      projectFileType: 'othersFiles'
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.projectID && changes['setExitingData']){
      this.updateTheValuesExist();
    }
    if (changes['projectID']) {
      this.dataForFileUpload = {
        projectKey: this.projectID,
        region: 'eu-central-1'
      }
      this.dataForEachFile = {
        projectFileType: 'budgetFiles'
      }
      this.othersDataForEachFile = {
        projectFileType: 'othersFiles'
      }
    }
  }
  ngAfterViewInit() {
    this.fileUploadStatusData = this.fileUploadComponent.fileUploadService.filesListObject;
    this.othersFileUploadStatusData = this.otherfileUploadComponent.fileUploadService.filesListObject;
  }
  fileUploadCompletionEvent(fileDetails) {
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "fileSize": String(fileDetails.fileDetails.size),
      "uploadId": fileDetails.uploadId,
      "fileType": fileDetails.fileName.split('.').pop()
    }
    const deleteObj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "projectFileType": 'budgetFiles'
    }
    if (this.additionalFiles.controls["budgetFiles"].value === null) {
      this.additionalFiles.controls["budgetFiles"].setValue([])
    }
    this.additionalFiles.controls["budgetFiles"].value.push(obj)
    this.emitFileOnUplaod.emit(deleteObj);
    this.additionalFiles.controls["budgetFiles"].setValue(this.additionalFiles.controls["budgetFiles"].value);

  }
  othersFileUploadCompletionEvent(fileDetails) {
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "fileSize": String(fileDetails.fileDetails.size),
      "uploadId": fileDetails.uploadId,
      "fileType": fileDetails.fileName.split('.').pop()
    }
    const deleteObj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "projectFileType": 'othersFiles'
    }
    if (this.additionalFiles.controls["otherFiles"].value === null) {
      this.additionalFiles.controls["otherFiles"].setValue([])
    }
    this.additionalFiles.controls["otherFiles"].value.push(obj)
    this.emitFileOnUplaod.emit(deleteObj);
    this.additionalFiles.controls["otherFiles"].setValue(this.additionalFiles.controls["otherFiles"].value);

  }

  toList(data): any[] {
    return Object.values(data)
  }
  deleteFile(file, fileType, controller) {
    const payload = {
        "fileName": file.fileName,
        "fileId": file.fileId,
        "projectFileType": fileType
      }
        if (file.fileId) {
          const newArray = controller.value.filter(item => item.fileId !== file.fileId);
          controller.setValue(newArray);
          this.fileListDeleteOnSave.push(payload);
        }
  }
  get f() {
    return this.additionalFiles.controls;
  }
  updateTheValuesExist() {
    if (this.setExitingData && this.setExitingData !== null) {
      this.additionalFiles.patchValue(this.setExitingData);
      this.additionalFiles.markAsPristine();
    }
  }
}
