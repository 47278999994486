<div *ngIf="templateVersion == 'v1'">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)" class="innerBox">
        <ng-template ngx-file-drop-content-tmp directory="true" let-openFileSelector="openFileSelector">
            <div class="file-drag-drop-div">
                <div class="file-upload-action">
                    <mat-icon class="file-upload-icon">backup</mat-icon>
                    <h1 class="drag-drop-text">Drag and Drop Files Here</h1>
                    <h1 class="drag-drop-text">or</h1>
                </div>
                <div class="browse-button-container">
                    <a (click)="openFileSelector()" class="browse-button">Browse files</a>
                </div>
            </div>
        </ng-template>
    </ngx-file-drop>
</div>


<div *ngIf="templateVersion == 'v2'">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)" class="innerBox">
        <ng-template ngx-file-drop-content-tmp directory="true" let-openFileSelector="openFileSelector">
            <div class="file-drag-drop-div">
                <div class="file-upload-action">
                    <mat-icon class="file-upload-icon">backup</mat-icon>
                    <h1 class="drag-drop-text">Drag and Drop Folders or Files Here</h1>
                    <h1 class="drag-drop-text">or</h1>
                </div>
                <div class="browse-button-v2-container">
                    <a (click)="openFileSelector()" class="browse-button-files">Browse Files</a>
                    <a (click)="openFolderSelector()" class="browse-button-folder">Browse Folders</a>
                    <input class="folder-upload-input" id="folderUpload"  type="file" #folderInput (change)="uploadFiles(folderInput.files)" webkitdirectory mozdirectory msdirectory odirectory directory multiple />
                </div>
            </div>
        </ng-template>
    </ngx-file-drop>
</div>


