<mat-card class="container inner-section">
    <mat-card-title>Review Project Proposal</mat-card-title>
<app-review-tab-summary [formDataObj]="formDataObj" [projectID]="projectID"></app-review-tab-summary>
</mat-card>
<mat-card class="container inner-section">
    <mat-card-title>Add Additional Email Address</mat-card-title>
    <div [formGroup]="review" class="container">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="label-title">Email Address (Maximum of 20 Email Address)</div> 
                <mat-form-field class="comment-form-field" appearance="fill">
                    <textarea class="comment-text-area" matInput #additionalEmails formControlName="additionalEmails">
              </textarea>
                </mat-form-field>
            <div class="pwd-error-message-textarea"
                *ngIf="f['additionalEmails'].touched && 
                f['additionalEmails'].invalid && 
                f['additionalEmails'].errors?.['emailValidation']">
                Provide valid email address.
            </div>
            <div class="pwd-error-message-textarea"
                *ngIf="f['additionalEmails'].touched && 
                f['additionalEmails'].invalid && 
                f['additionalEmails'].errors?.['emailCountValidation']">
                Email should not be more than 20.
            </div>
            </div>
        </div>
    </div>

</mat-card>
<div class="footer px-3">
    <mat-card class="footer-container">
        <button mat-raised-button class="data-file-cancel-button" 
        (click)="service.onCancelEvent()">
        {{projectCreation.footerLabel.cancel}}</button>
        <button mat-raised-button type="submit" 
           class="data-file-cancel-button"
            (click)="onClickSaveandExit()" [disabled]="(!review.valid) || validateInprogress">
            {{projectCreation.footerLabel.saveAndExist}}</button>
        <button mat-raised-button type="submit" (click)="onApprove()"
            [disabled]="(!review.valid) || validateInprogress" class="float-left">
            {{projectCreation.footerLabel.approve}}</button>
            <button mat-raised-button class="data-file-cancel-button float-left"
            (click)="onclickPrevious()">
            {{projectCreation.footerLabel.previous}}</button>
    </mat-card>
</div>