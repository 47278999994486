// import the new component
// import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [
    NgxFileDropModule,
    MatCardModule,
    MatIconModule,
    CommonModule
  ],
  declarations: [
    FileUploadComponent
  ],
  exports: [
    FileUploadComponent,
  ]
})

export class FileUploadModule {
}