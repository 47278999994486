<mat-tab-group>
  <mat-tab label="Project Idea">
    <app-project-idea-details [projectIdea]="formDataObj?.projectIdea" [projectID]="projectID" [isFromAuditProjectInfo]=false>
    </app-project-idea-details>
  </mat-tab>
  <mat-tab label="Project Concept">
    <app-project-concept-details [projectConcept]="formDataObj?.projectConcept" [projectID]="projectID">
    </app-project-concept-details>
  </mat-tab>
  <mat-tab label="Compute Configuration">
    <app-compute-configuration-details [computeConfig]="formDataObj?.computeConfig">
    </app-compute-configuration-details>
  </mat-tab>
  <mat-tab label="Additional Files">
    <app-additional-files-details
      [additionalFiles]="formDataObj?.additionalFiles" [projectID]="projectID">
      </app-additional-files-details>
    </mat-tab>
  <mat-tab label="CITC Details">
    <app-citc-details [citcDetails]="formDataObj?.citcDetails" [projectID]="projectID">
    </app-citc-details>
  </mat-tab>
</mat-tab-group>