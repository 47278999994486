import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationManagementService {


  constructor(private http: HttpClient) { }

  organizationOverviewUrl: string = env.config.domain + env.organizationOverview; 

  getOrganizationDetails() {
    return this.http.get(this.organizationOverviewUrl);
  }
}
