import { Component, EventEmitter, Input, OnInit, Output, ViewChild,OnChanges, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectManagementService } from '../../project-management.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { projectCreation, CitcDetails } from '../model/model';
import { i18n } from 'src/app/i18n.en';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-citcdetails',
  templateUrl: './citcdetails.component.html',
  styleUrls: ['./citcdetails.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CITCDetailsComponent implements OnInit, OnChanges {
  citcDetails: FormGroup;
  i18n = i18n;
  projectCreation = projectCreation;
  isDisabled: boolean = false;
  userUuid:string;
  public files: NgxFileDropEntry[] = [];
  isDropDisabled: boolean = false;
  fileToUpload: File | null = null;
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() setExitingData:CitcDetails;
  @Output() emitFileOnUplaod: EventEmitter<{ fileName: string, fileId: string, projectFileType: string }> = new EventEmitter<{ fileName: string, fileId: string, projectFileType: string }>();
  @Input() fileListDeleteOnSave;
  fileUploadStatusData={};
  dataForFileUpload: { projectKey: string; region: string; };
  citcdataForEachFile: { projectFileType: string; };
  @ViewChild('citcFileUpload') citcfileUploadComponent;
  constructor(public readonly service: ProjectManagementService,
    private storageService: StorageService) {
    this.citcDetails = new FormGroup({
      isCitcIdeaEndorsed: new FormControl(null, [Validators.required]),
      citcEndorsementDate: new FormControl(null, [Validators.required]),
      citcComments: new FormControl(null, [Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength500), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)]),
      citcFiles: new FormControl([]),
      citcOverrideReason: new FormControl({ value: null, disabled: true },
        [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
          , Validators.maxLength(projectCreation.formValidation.maxlength500), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)])
    });
    this.userUuid = this.storageService.secureStorage.getItem('userUuid');
  }

  ngOnInit(): void {
    console.log(this.citcDetails.controls["citcFiles"].value)
    this.citcDetails.controls["citcFiles"].setValue([])
    this.showRemainingFieldasDisable();
    this.dataForFileUpload = {
      projectKey: this.projectID,
      region: 'eu-central-1'
    }
    this.citcdataForEachFile = {
      projectFileType: 'citcFiles'
    }
   
  }
  citcFileUploadCompletionEvent(fileDetails) {
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId" : fileDetails.fileId,
      "fileSize":String(fileDetails.fileDetails.size),
      "uploadId" : fileDetails.uploadId,
      "fileType" : fileDetails.fileName.split('.').pop()
 }
 const deleteObj = {
  "fileName": fileDetails.fileName,
  "fileId": fileDetails.fileId,
  "projectFileType": 'citcFiles'
}
if( this.citcDetails.controls["citcFiles"].value === null){
  this.citcDetails.controls["citcFiles"].setValue([])
 } 
  this.citcDetails.controls["citcFiles"].value.push(obj);
    this.emitFileOnUplaod.emit(deleteObj);
  this.citcDetails.controls["citcFiles"].setValue(this.citcDetails.controls["citcFiles"].value);

  }

  ngAfterViewInit() {
    this.fileUploadStatusData = this.citcfileUploadComponent.fileUploadService.filesListObject;
  }
  toList(data):any[]{
    return Object.values(data)
  }
  get f() {
    return this.citcDetails.controls;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.projectID && changes['setExitingData']){
      this.updateTheValuesExist();
    }
    if (changes['projectID']) {
      this.dataForFileUpload = {
        projectKey: this.projectID,
        region: 'eu-central-1'
      }
      this.citcdataForEachFile = {
        projectFileType: 'citcFiles'
      }
    }
  }
  showRemainingFieldasDisable() {
    this.citcDetails.controls['isCitcIdeaEndorsed'].valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue === 'No') {
          this.isDisabled = true;
          this.citcDetails.controls['citcEndorsementDate'].disable();
          this.citcDetails.controls['citcComments'].disable();
          this.citcDetails.controls['citcFiles'].disable();
          this.isDropDisabled = true;
          this.citcDetails.controls['citcOverrideReason'].enable();
        }
        else {
          this.isDisabled = false;
          this.citcDetails.controls['citcEndorsementDate'].enable();
          this.citcDetails.controls['citcComments'].enable();
          this.citcDetails.controls['citcFiles'].enable();
          this.isDropDisabled = false;
          this.citcDetails.controls['citcOverrideReason'].setValue('');
          this.citcDetails.controls['citcOverrideReason'].disable();
        }

      })
  }

  deleteFile(file, fileType, controller) {
    const payload = {
        "fileName": file.fileName,
        "fileId": file.fileId,
        "projectFileType": fileType
      }
        if (file.fileId) {
          const newArray = controller.value.filter(item => item.fileId !== file.fileId);
          console.log(newArray);
          controller.setValue(newArray);
          this.fileListDeleteOnSave.push(payload);
        }
  }
  updateTheValuesExist() {
    if (this.setExitingData && this.setExitingData !== null) {
      this.citcDetails.patchValue(this.setExitingData);
      this.citcDetails.controls['isCitcIdeaEndorsed'].disable();
      this.citcDetails.markAsPristine();
    }
  }
}

