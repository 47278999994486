
<mat-card class="auditstart-results" >
    <mat-card-header>
        <mat-card-title>
            {{i18n.projectAudit.TAB_SAR_CONTENT.TITLE}}
        </mat-card-title>
    </mat-card-header>
    <div class="loadingcontainer">
        <div *ngIf="isLoading||!auditSettings" class="loading-modal-spinner">
            <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
            </mat-progress-spinner>
        </div>
    </div>
    <mat-card-content *ngIf="auditSettings">
        
        <mat-grid-list cols="4" rowHeight="100px">
            <mat-grid-tile>
                <mat-label>
                    <p>{{i18n.projectAudit.TAB_SAR_CONTENT.H_PN}}</p>
                    <h4> {{projectName}}</h4>
                </mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-label>
                    <p>{{i18n.projectAudit.TAB_SAR_CONTENT.H_AID}}</p>                   
                    <h4 *ngIf="auditSettings['auditInitatedDateAndTime']">{{auditSettings['auditInitatedDateAndTime']|date: 'y-MMM-dd'}}</h4>
                    <h4 *ngIf="!auditSettings['auditInitatedDateAndTime']"> {{'-'}}</h4> 
                </mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-label>
                    <p>{{i18n.projectAudit.TAB_SAR_CONTENT.H_ACBD}}</p>
                    <h4 *ngIf="auditSettings['currentCycleBeginDate']"> {{auditSettings.currentCycleBeginDate|date: 'y-MMM-dd'}}</h4>
                    <h4 *ngIf="!auditSettings['currentCycleBeginDate']"> {{'-'}}</h4>
                </mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-label>
                    <p>{{i18n.projectAudit.TAB_SAR_CONTENT.H_LAD}}</p>
                    <h4 *ngIf="auditSettings['latestAuditDate']"> {{auditSettings.latestAuditDate|date: 'y-MMM-dd'}}</h4>
                    <h4 *ngIf="!auditSettings['latestAuditDate']"> {{'-'}}</h4>
                </mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-label>
                    <p>{{i18n.projectAudit.TAB_SAR_CONTENT.H_CFA}}</p>
                    <h4> {{auditSettings.cadence}} </h4>
                </mat-label>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="auditSettings.cadence.toLowerCase()==='custom'">
                <mat-label>
                    <p>{{i18n.projectAudit.TAB_SAR_CONTENT.H_SND}}</p>
                    <h4> {{auditSettings.numberOfDays}}</h4>
                </mat-label>
            </mat-grid-tile>
        </mat-grid-list>
        <div class="action-btn-div">
            <button mat-stroked-button [disabled]="!auditSettings.startAudit"
                (click)="onStartAudit()">{{i18n.projectAudit.TAB_SAR_CONTENT.BTN_SA}}</button>
            <button mat-raised-button [disabled]="!auditSettings.auditResult" (click)="onAuditResults()">{{i18n.projectAudit.TAB_SAR_CONTENT.BTN_AR}}</button>
        </div>
    </mat-card-content>
</mat-card>