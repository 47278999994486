<div class="tab-details container">
    <div class="row">
        <div class="col-12 mb-3">
            <mat-label>
                <p>Is the Idea endorsed by iScT? *</p>
                <h4>{{projectConcept?.isIsctIdeaEndorsed}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Date of Endorsement *</p>
                <h4>{{projectConcept?.pcEndorsementDate ? (projectConcept?.pcEndorsementDate| date: "yyyy-MMM-dd") : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Comments</p>
                <h4>{{projectConcept?.pcComments? projectConcept?.pcComments : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>{{projectCreation.pc.attach}}</p>
                <div class="create-project-table-container">
                    <div class="table-container">
                        <table mat-table [dataSource]="pcFilesdataSource?.data?.length > 0 ? pcFilesdataSource : emptyData" class="mat-elevation-z0" matSort>
                            <!-- Filename Column -->
                            <ng-container matColumnDef="fileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                            </ng-container>

                            <!-- download Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element">
                                    <a  (click)="service.getDownloadPresignedURL(element,'pcFiles',projectID);" class="browse-button">Download</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="empty-row">
                                <td mat-cell class="mt-3 d-flex justify-content-center"
                                    *matCellDef="let element">no record found</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: pcFilesdataSource?.data?.length > 0 ? displayedColumns: ['empty-row'];"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                        class="mat-paginator-sticky" #tablePaginator>
                    </mat-paginator>
                </div>

            </mat-label>
        </div>
        <div class="col-12 mb-3" *ngIf="projectConcept?.pcOverrideReason">
            <mat-label>
                <p>Override with Reason *</p>
                <h4>{{projectConcept?.pcOverrideReason}}</h4>
            </mat-label>
        </div>
    </div>
</div>