import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  title: string;
  confirmation_text: string;
  accept_btn: string;
  decline_btn: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmationPopupComponent>) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.confirmation_text = this.data.confiramtion_text;
    this.accept_btn = this.data.accept_btn;
    this.decline_btn = this.data.decline_btn;
  }
  onAccept() {
    this.dialogRef.close('accept');
  }
  onDecline() {
    this.dialogRef.close('declined');
  }
}
