import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { AuditService } from '../../audit.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-audit-result',
  templateUrl: './audit-result.component.html',
  styleUrls: ['./audit-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditResultComponent implements OnInit {
  i18n = i18n;
  dataSource;
  projectAuditHistory;
  isLoading = false;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['attachments', 'actions']
  constructor(public dialogRef: MatDialogRef<AuditResultComponent>, public auditService: AuditService,
    public toaster: ToastrService, public projectService: ProjectManagementService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.getProjectAuditResult();
  }

  getProjectAuditResult() {
    if (this.data.selectedRowElement.auditResult) {
      this.projectAuditHistory = this.data.selectedRowElement;
      this.dataSource = new MatTableDataSource(this.data.selectedRowElement.auditResult.attachments);
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        switch (property) {
            case 'attachments': return item;            
            default: return item[property];
        }
    };
      setTimeout(() => this.dataSource.sort = this.sort);
    } else {
      this.toaster.error('Unable to retrieve the audit information. Please try again.');
      this.dialogRef.close('cancel');
    }
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onDownload(fileName) {
    const obj = {
      "projectKey": this.data.selectedRowElement.projectKey,
      "region": "eu-central-1",
      "fileDetail": [
        {
          "fileName": fileName,
           "projectFileType": `audit/${this.data.selectedRowElement.auditId}`
        }
      ]
    }
    this.projectService.getFilePresignedURL(obj).subscribe({
      next: (response => {
        if (response['result']) {
          this.fileDownload(response['result'].fileDetail[0].presignedURL, response['result'].fileDetail[0].fileName);
        }
        if (response['error']) {
          this.toaster.error('Download Failed. Please try again');
        }
      }),
      error: (err => {
        this.toaster.error('Download Failed. Please try again');
      })
    })
  }
  fileDownload(fileDownloadURL, fileName) {
    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', fileDownloadURL);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  ngAfterViewInit() {
    setTimeout(() => this.dataSource.sort = this.sort);       
}
}
