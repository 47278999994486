import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { InviteuserComponent } from 'src/app/pages/user-management/inviteuser/inviteuser.component';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/pages/user-management/user-management.service';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { CommonService } from 'src/app/services/common.service';
import { environment as env} from 'src/environments/environment';
import { i18n } from 'src/app/i18n.en';

export interface userObj {
  firstName:string;
lastName: string;
status: string;
tcAccepted: boolean;
userEmail:string;
userType:string;
userUuid:string;
username: Array<[]>;
}

@Component({
  selector: 'app-invite-collaborator',
  templateUrl: './invite-collaborator.component.html',
  styleUrls: ['./invite-collaborator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteCollaboratorComponent implements OnInit {
  i18n = i18n;
  createForm: FormGroup
  displayedColumns: string[] = ['userEmail', 'firstName', 'projectRole', 'actions'];
  hasNoUserData = false;
  dataSource: any;
  isLoading = false;
  showSearchCancelBtn: boolean;
  profile: any;
  emptyData = new MatTableDataSource([{ empty: "row" }]);

  role:any = env.config.roles;
  firstname: string;
  userEmailList = new FormControl();
  selectedUser: any;
  filterdOptions: any;
  noRecord = false;
  userNames: any;
  showInvite = false;
  disableDataControllerRole = false;
  projectName: string;
  orgName: string;
  rolesData: any = [];
  result: any;
  activeUsers: any;
  toppingList: any;
  apiData: any;
  test: any;
  roles: any = [];
  userResult: any;
  userListArray: any = [];
  username: any;
  inviteUserData: any;
  userRole: string;
  collaboratorsObject: any;
  projectKey: string;
  noMappingUsers = false;
  isDataControllerExist = false;
  isSubmitLoading = false;
  selectedEmail: any =[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public inputSearch: FormControl = new FormControl('');

  constructor(public toastrService: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any, private readonly dialogRef: MatDialogRef<InviteCollaboratorComponent>, private readonly matDialog: MatDialog, private readonly navifyServ: NavifyauthService, 
    private readonly userService: UserManagementService, private readonly projectService: ProjectManagementService, private readonly commonService: CommonService) {
    this.projectName = data.projName;
    this.projectKey = data.projectKey;
    this.orgName = data.orgKey;
    this.isDataControllerExist = data.isDataControllerExist;
    data.result = [];
    this.dataSource = new MatTableDataSource(data.result);
    this.emptyData = new MatTableDataSource([{ empty: "row" }]);
    this.hasNoUserData = (this.dataSource.data.length === 0) ? true : false;
  }

  ngOnInit(): void {    
    this.getRoles();
    this.getProjectUserList();
    this.createForm = new FormGroup({
      roleName: new FormControl("", Validators.required),
    });
    this.showInvite = true;
  }

  getRoles() {
    this.commonService.getPermissionRoles(this.orgName).subscribe((data: any) => {
      this.roles = data.result.orgProjectRoles;
    });
  }

  getProjectUserList() {
    this.isLoading = true;
    this.projectService.getProjectSearchUserList(this.projectKey, this.orgName).subscribe((data: any) => {
      this.toppingList = data.result;
      this.isLoading = false;
      this.filterUsers();
    });
  }

  onSearchCancelBtn() {
    this.showSearchCancelBtn = false;
    this.inputSearch.reset();
  }
  async logout() {
    await this.navifyServ.logOut();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onDecline() {
    this.dialogRef.close();
  }

  filterUsers() {
    this.filterdOptions = this.toppingList;
  }

  searchValue(event: any) {
    this.selectedUser = event.target.value;
    this.filterdOptions = this.toppingList.filter(
      (item: any) => (item.userEmail.toLowerCase()).includes(this.selectedUser.toLowerCase()));

    if (this.filterdOptions.length === 0) {
      this.noRecord = true;
    }
    else {
      this.noRecord = false;
    }
  }

  onEndFocus(event: any) {  
    this.userEmailList.setValue(this.selectedEmail);    
    this.filterdOptions = this.toppingList;
    if (this.filterdOptions.length === 0) {
      this.noRecord = true;
    }
    else {
      this.noRecord = false;
    }  
    
  }

  toggle(checked:any, value:any) {    
    if(!checked){
      this.selectedEmail.splice(this.selectedEmail.indexOf(value,0),1);
    }
    this.userEmailList.value.forEach((element:any) => {
      if(!this.selectedEmail.includes(element)){
        this.selectedEmail.push(element);
      }
    });    
    this.dataSource.data = this.selectedEmail;

    this.dataSource.sort = this.sort;
    this.userNames = [{ 'username': this.selectedEmail }];
    this.toppingList.filter((item: userObj) => {
      return !this.userNames.some((values: any) => { return item.username === values.username; });
    });
    this.dataSource.data.length === 0 ? this.showInvite = true : this.showInvite = false;
    this.enableInvite();
  }

  onDelete(elm: any) {
    this.selectedEmail.splice(this.selectedEmail.indexOf(elm,0),1)
    elm.role = '';
    this.dataSource.data = this.dataSource.data
      .filter((i: any) => i !== elm)
      .map((i: any, idx: any) => (i.position = (idx + 1), i));
    this.userNames = this.dataSource.data;
    this.userEmailList.setValue(this.userNames);
    this.enableInvite();
  }

  onInviteUser() {
    if (this.isDataControllerExist) {
      this.disableDataControllerRole = true;
    }
    else {
      this.dataSource.data.forEach((element: any) => {
        if (element.role?.roleName === "Data Controller") {
          this.disableDataControllerRole = true;
        }
      });
    }

    const dialogRef = this.matDialog.open(InviteuserComponent, {
      width: '660px',
      data: {
        isEdit: false,
        fromComponent: "collaborator",
        projectName: this.projectName,
        projectKey: this.projectKey,
        orgName: this.orgName,
        isDataControllerExist: this.disableDataControllerRole
      }
    });
    this.dialogRef.close('yes');
    dialogRef.afterClosed().subscribe((result) => { 
      
      if(result==='invited'){      
      location.reload();
      }
    });
  }

  onChange(elem: any, roleName: any) {
    let roleId;
    this.roles.forEach((role: any) => {
      if (role.roleName == roleName) {
        roleId = role.roleId;
      }
    });

    const roleObj = {
      roleName: roleName,
      roleId: roleId
    }
    elem['role'] = roleObj;
    this.dataSource.data.filter((element: any) => { element.role !== '' ? this.showInvite = false : this.showInvite = true });
    this.enableInvite();
  }

  enableInvite() {
    this.disableDataControllerRole = false;
    this.showInvite = false;
    for (let k = 0; k < this.dataSource.data.length; k++) {
      if (!this.dataSource.data[k].role) {
        this.showInvite = true;
      } 
    }
   if(this.dataSource.data.length === 0) {
     this.showInvite = true;
   }

    if (this.isDataControllerExist) {
      this.disableDataControllerRole = true;
    }
    else {
      this.dataSource.data.forEach((element: any) => {
        if (element.role?.roleName === "Data Controller") {
          this.disableDataControllerRole = true;
        }
      });
    }
  }

  submitInvite() {      
    for (const i in this.dataSource.data) {
      delete this.dataSource.data[i]['firstName'];
      delete this.dataSource.data[i]['lastName'];
      delete this.dataSource.data[i]['status'];
      delete this.dataSource.data[i]['tcAccepted'];
      delete this.dataSource.data[i]['userType'];
      this.dataSource.data[i].userId = this.dataSource.data[i].userUuid;
      delete this.dataSource.data[i]['userUuid'];
      this.dataSource.data[i].projectName = this.projectName;
      this.dataSource.data[i].projectKey = this.projectKey;
      this.dataSource.data[i].orgName = this.orgName;
      this.dataSource.data[i].orgKey = this.orgName;
      this.dataSource.data[i].roleId = this.dataSource.data[i]['role'].roleId;
      this.dataSource.data[i].roleType = "PROJECT";
      this.dataSource.data[i].roleName = this.dataSource.data[i]['role'].roleName;
      delete this.dataSource.data[i]['role'];
    }
    this.isSubmitLoading = true; 
    this.userService.inviteCollaborators(this.dataSource.data).subscribe({
        next: (res:any)=>{ 
          if(res.responseCode==='200'||res.responseCode==='201'){         
          this.dialogRef.close('yes');         
          this.toastrService.success(i18n.projectMnt.collaborators.IC.TOASTR_S);
          }else{
            this.dialogRef.close();
            this.toastrService.error(res.message);
          }       
        },
        error: (err)=>{         
        this.dialogRef.close();
        },
        complete: ()=>{  
          this.getProjectUserList();
          this.isSubmitLoading = false;   
        }
      });
  }
  
}