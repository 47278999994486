import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StepperObj5 } from '../model/model';

@Component({
  selector: 'app-review-tab-summary',
  templateUrl: './review-tab-summary.component.html',
  styleUrls: ['./review-tab-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewTabSummaryComponent  {
  @Input() formDataObj: StepperObj5 | undefined;
  @Input() projectID: string;
}
