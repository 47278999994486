<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto"> <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2"
                            src="./assets/images/Icon_user_management.svg" alt="" /> </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-title">{{i18n.HEADER.SP}}</span><span
                            class="me-2 fw-normal">|</span> <span class="fs-6">{{i18n.BREADCRUMB.PA}}</span> </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

</div>
<div class="cont-fluid cmn_tbl audit-tble">
    <!-- breadcrumb -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
            <li>
                <mat-icon>navigate_next</mat-icon>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.PA}}</li>
        </ol>
    </nav>

    <mat-card>
        <!-- Page Loader -->
        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-modal-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
                </mat-progress-spinner>
            </div>
        </div>

        <mat-card-header>
            <mat-card-title>
                {{i18n.projectAudit.TBL_TITLE}}
            </mat-card-title>
            <div class="search-btn-div">
                <mat-form-field #input *ngIf="dataSource?.data?.length > 0" appearance="fill" floatLabel="always">
                    <mat-icon class="mat-icon-search" matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon><mat-icon matSuffix
                        [hidden]="!showSearchCancelBtn" (click)="onSearchCancelBtn()">{{i18n.ICONS.CANCEL}}</mat-icon>
                    <input matInput placeholder="Search here" (keyup)="applyFilter($event)" [formControl]="inputSearch"
                        autocomplete="off" />
                </mat-form-field>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div class="no-data empty-dta" *ngIf="dataSource?.data?.length === 0">{{i18n.LBL_NDF}}</div>
            <mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length > 0" matSort matSortDirection="asc" matSortActive="currentCycleBeginDate"
                matSortDisableClear>

                <!-- Project Name Column-->
                <ng-container matColumnDef="projectName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectAudit.TBL_HEADER.HDR_PN}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="project-name-cell"> {{element?.projectName}}
                    </mat-cell>
                </ng-container>

                <!-- Audit Status Column -->
                <ng-container matColumnDef="auditStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{i18n.projectAudit.TBL_HEADER.HDR_AS}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{(element?.projectDetails?.auditSettings?.auditStatus)?(element?.projectDetails?.auditSettings?.auditStatus):'-'}}
                    </mat-cell>
                </ng-container>

                <!-- Last Audit Date Column -->
                <ng-container matColumnDef="latestAuditDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectAudit.TBL_HEADER.HDR_LAD}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{(element?.projectDetails?.auditSettings?.latestAuditDate)?(element?.projectDetails?.auditSettings?.latestAuditDate|date
                        : 'y-MMM-dd'):'-'}} </mat-cell>
                </ng-container>

                <!-- Current Audit Cycle Begin Date Column -->
                <ng-container matColumnDef="currentAuditCycleBeginDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> <div class="current-audit-cycle">Current Audit Cycle Begin Date</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [class] = "{'justify-content-center' : (element?.projectDetails?.auditSettings?.auditStatus !== 'In Progress')}">
                        {{(element?.projectDetails?.auditSettings?.auditStatus === 'In Progress' || (toDay === element?.hiddenNextAuditDate))?(element?.projectDetails?.auditSettings?.currentCycleBeginDate|date
                        : 'y-MMM-dd'):'-'}} </mat-cell>
                </ng-container>

                <!-- Next Audit Date Column -->
                <ng-container matColumnDef="currentCycleBeginDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{i18n.projectAudit.TBL_HEADER.HDR_NAD}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [class] = "{'justify-content-center' : (element?.projectDetails?.auditSettings?.auditStatus === 'In Progress')}">
                        {{(element?.projectDetails?.auditSettings?.auditStatus !== 'In Progress')?(element?.projectDetails?.auditSettings?.currentCycleBeginDate|date:'y-MMM-dd'):'-'}} </mat-cell>
                </ng-container>

                <!-- Days Remaining For Next Audit Column -->
                <ng-container matColumnDef="daysRemainingForNextAudit">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="days-header">
                        {{i18n.projectAudit.TBL_HEADER.HDR_DR}} </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="days-cell">
                        {{(element?.projectDetails?.auditSettings?.daysRemainingForNextAudit)?(element?.projectDetails?.auditSettings?.daysRemainingForNextAudit):'-'}} </mat-cell>
                </ng-container>

                <!-- Cadence Column -->
                <ng-container matColumnDef="cadence">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectAudit.TBL_HEADER.HDR_CD}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{(element?.projectDetails?.auditSettings?.cadence)?(element?.projectDetails?.auditSettings?.cadence):'-'}} </mat-cell>
                </ng-container>

                <!-- Action Button Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="action-header"> {{i18n.projectAudit.TBL_HEADER.HDR_ATN}}
                    </mat-header-cell>
                    <mat-cell class="action-cell" *matCellDef="let element">
                        <button mat-stroked-button
                             (click)="onSelectProject(element)">{{i18n.BTN_SELECT}}</button>
                    </mat-cell>
                </ng-container>

                <!-- Table header -->
                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>
        </mat-card-content>

        <mat-card-footer>
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                [hidden]="dataSource?.data?.length === 0">
            </mat-paginator>
        </mat-card-footer>


    </mat-card>
</div>