import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { ProjectManagementService } from '../project-management.service';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-dataingress-overview',
  templateUrl: './dataingress-overview.component.html',
  styleUrls: ['./dataingress-overview.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]  
})
export class DataingressOverviewComponent implements OnInit {
  i18n = i18n;
  displayedColumns: string[] = ['datasetName', 'dataFilesCount', 'datasetSize', 'datasetStatus', 'createdDate', 'actions'];
  hasNoUserData = false;
  dataSource: any;
  isLoading = false;
  profile: any;
  userEmail: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() projectName: string;
  @Input() projectID: string;
  @Input() roleId: string;
  projectDetailList:any;

  constructor(private storageService: StorageService, public router: Router,private projectService: ProjectManagementService, private readonly activatedRoute:ActivatedRoute  ) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.projectDetailList = queryParams
      this.dataSource = [];
    });
  }

  ngOnInit(): void {
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.userEmail = this.profile.username;   
    this.getDataset();
  }

  getDataset() {    
    this.isLoading = true;
    this.projectService.getDataSetList(this.projectID, this.userEmail).subscribe({
      next: (data: any) => {     
      this.dataSource = new MatTableDataSource(data.result);     
      setTimeout(() => this.dataSource.paginator = this.paginator);
      setTimeout(() => this.dataSource.sort = this.sort);
      },
      error:(err:any) => {
        this.isLoading = false;
      },  
      complete:()=>{
        this.isLoading = false;
      }  
    });
    
  }

  onCreateDataset(){
    this.router.navigate(['projectmanagement/datafileslist'], {
      queryParams: this.projectDetailList
   });
}

  onSelectDataset(rowData: any) {
    this.router.navigate(['projectmanagement/datasetDetails'], {
      queryParams: {
        projectName: this.projectName,
        projectKey: this.projectID,
        datasetId: rowData.datasetId,
        roleId: this.roleId
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);
  }
}
function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;

  return customPaginatorIntl;
}