import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectManagementService } from '../project-management.service';
import { ProjectIdeaComponent } from '../add-project/project-idea/project-idea.component';
import { ProjectConceptComponent } from '../add-project/project-concept/project-concept.component';
import { ComputeConfigurationComponent } from '../add-project/compute-configuration/compute-configuration.component';
import { AdditionalFilesComponent } from '../add-project/additional-files/additional-files.component';
import { CITCDetailsComponent } from '../add-project/citcdetails/citcdetails.component';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DownloadReportComponent } from '../../project-audit/download-report/download-report.component';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditProjectComponent implements OnInit {
  selectedTabIndex: number = 0;
  projectID: string;
  postObj;
  displayEditDetails: boolean = false;
  formDataObj;
  overviewList;
  role = environment.config.roles;
  datacontroller;
  showEditForDC:boolean = false;
  startAuditStatus: boolean = false;
  fileListDeleteOnCancel: { fileName: string, fileId: string, projectFileType: string }[] = []
  fileListDeleteOnSave: { fileName: string, fileId: string, projectFileType: string }[] = []
  @Input() isFromAuditProjectInfo: boolean;
  @Input() projectDetails: any;
  isLoading: boolean;
  transferDate: string;

  @ViewChild(ProjectIdeaComponent) step1Component: ProjectIdeaComponent;
  @ViewChild(ProjectConceptComponent) step2Component: ProjectConceptComponent;
  @ViewChild(ComputeConfigurationComponent) step3Component: ComputeConfigurationComponent;
  @ViewChild(AdditionalFilesComponent) step4Component: AdditionalFilesComponent;
  @ViewChild(CITCDetailsComponent) step5Component: CITCDetailsComponent;
  constructor(private readonly activatedRoute: ActivatedRoute,
    public readonly service: ProjectManagementService,
    public readonly storageService: StorageService,
    private readonly toastrService: ToastrService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
  }
  ngAfterViewInit() {
    this.getProjectDetails();
    this.getCollaboratorTableData();
    this._changeDetectorRef.detectChanges();
  }
  onTabChanged(event) {
    this.selectedTabIndex = event.index;

  }
  getProjectDetails() {
    this.service.getProjectDetails(this.projectID).subscribe((data: any) => {
      this.projectDetails = data.result;
      this.formDataObj = data.result.projectDetails;
      this.transferDate = data.result.projectDetails.transfer.transferOn;
      if( this.formDataObj.auditSettings.auditStatus === 'In Progress'){
        this.startAuditStatus = true;
      }
    });
  }
  editProjectDetails() {
    this.displayEditDetails = true;
  }

  onUpdate(callingFrom: string = "") {
    const FORMDATA = {
      "projectIdea": this.step1Component?.projectIdea.valid ? this.step1Component?.projectIdea.getRawValue() : this.formDataObj?.projectIdea,
      "projectConcept": this.step2Component?.projectConcept.valid ? this.step2Component.projectConcept.getRawValue() : this.formDataObj?.projectConcept,
      "computeConfig": this.step3Component?.computeConfig.valid ? this.step3Component.computeConfig.getRawValue() : this.formDataObj?.computeConfig,
      "additionalFiles": this.step4Component?.additionalFiles.valid ? this.step4Component.additionalFiles.getRawValue() : this.formDataObj?.additionalFiles,
      "citcDetails": this.step5Component?.citcDetails.valid ? this.step5Component.citcDetails.getRawValue() : this.formDataObj?.citcDetails,
    }
    this.postObj = {
      projectKey: this.projectID,
      roleName: this.storageService.secureStorage.getItem('currentUserRoles')[0],
      orgKey: environment.config.orgKey,
      userId: this.storageService.secureStorage.getItem('userUuid'),
      roleId: this.storageService.secureStorage.getItem('orgRoleId'),
      status: null,
      projectDetails: { ...FORMDATA }
    }
    this.service.sendEditProjectDetailsData(this.postObj).subscribe({
      next: () => {
        if (callingFrom.length === 0) {
          this.deleteFileList(this.fileListDeleteOnSave)
          this.fileListDeleteOnSave = [];
          const message = "Project details updated successfully";
          this.toastrService.success(message);
          this.displayEditDetails = false;
          this.getProjectDetails();
        }
        else {
          this.displayEditDetails = true;
        }
      },
      error: () => {
        const message = "Unable to save the project information";
        this.toastrService.error(message);
      }
    });
  }
  oncancel() {
    this.deleteFileList(this.fileListDeleteOnCancel)
    this.fileListDeleteOnCancel = [];
    this.getProjectDetails();
    this.displayEditDetails = false;
  }
  emitFileOnUplaod(fileList: { fileName: string, fileId: string, projectFileType: string }) {
    this.fileListDeleteOnCancel.push(fileList)
  }

  isFormValid(): boolean {
    return (this.step1Component?.projectIdea.valid &&
      this.step2Component?.projectConcept.valid &&
      this.step3Component?.computeConfig.valid &&
      this.step4Component?.additionalFiles.valid &&
      this.step5Component?.citcDetails.valid)
  }

  getCollaboratorTableData() {
    this.service.getCollaborator(this.projectID, environment.config.orgKey).subscribe({
      next: (res: any) => {
        this.overviewList = res.result;
        this.datacontroller = this.overviewList.filter((result: any) => {
          return result.roleName.toLowerCase() === this.role.data_controller
        });
        this.showEditForDC = this.datacontroller.some((result: any) => {
          return result.userUuid === this.storageService.secureStorage.getItem('userUuid')
        })
      }
    });
  }

  downloadReport() {
    const dialogRef = this.matDialog.open(DownloadReportComponent, {
      width: '650px',
      data: {
        transferDate: this.transferDate
      }
    });
  }

  deleteFileList(array) {
    const payload = {
      "projectKey": this.projectID,
      "region": "eu-central-1",
      "fileDetail": array
    }
    if (array.length > 0) {
      this.service.deletedSelectedFile(payload).subscribe({
        next: (res: any) => {
        }
      });
    }
  }
}
