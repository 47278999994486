import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationManagementRoutingModule } from './organization-management-routing.module';
import { OrganizationOverviewComponent } from './organization-overview/organization-overview.component';
import { core } from '@angular/compiler';
import { CoreMaModule } from 'src/app/shared/core-ma/core-ma.module';



@NgModule({
  declarations: [
    OrganizationOverviewComponent

  ],
  imports: [
    CommonModule,
    OrganizationManagementRoutingModule,
    CoreMaModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrganizationManagementModule { }
