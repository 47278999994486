import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectManagementService } from '../../project-management.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ProjectConcept, projectCreation } from '../model/model';
import { i18n } from 'src/app/i18n.en';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-project-concept',
  templateUrl: './project-concept.component.html',
  styleUrls: ['./project-concept.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectConceptComponent implements OnInit,OnChanges{
  projectCreation = projectCreation;
  i18n = i18n;
  userUuid:string;
  projectConcept: FormGroup;
  isDisabled: boolean = false;
  fileToUpload: File | null = null;
  isDropDisabled: boolean = false;
  public files: NgxFileDropEntry[] = [];
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() setExitingData:ProjectConcept;
  @Input() fileListDeleteOnSave;
  @Output() emitFileOnUplaod: EventEmitter<{ fileName: string, fileId: string, projectFileType: string }> = new EventEmitter<{ fileName: string, fileId: string, projectFileType: string }>();
  pcfileUploadStatusData = {};
  pcdataForFileUpload: { projectKey: string; region: string; };
  pcdataForEachFile: { projectFileType: string; };
  @ViewChild('pcFileUpload') pcfileUploadComponent;
  constructor(public readonly service: ProjectManagementService,
    private storageService: StorageService) {
    this.projectConcept = new FormGroup({
      isIsctIdeaEndorsed: new FormControl(null, [Validators.required]),
      pcEndorsementDate: new FormControl(null, [Validators.required]),
      pcComments: new FormControl(null, [Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength500), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)]),
      pcFiles: new FormControl([]),
      pcOverrideReason: new FormControl({ value: null, disabled: true },
        [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
          , Validators.maxLength(projectCreation.formValidation.maxlength500), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)]),
    });
    this.userUuid = this.storageService.secureStorage.getItem('userUuid');
  }

  ngOnInit(): void {
    this.showRemainingFieldasDisable();
    this.pcdataForFileUpload = {
      projectKey: this.projectID,
      region: 'eu-central-1'
    }
    this.pcdataForEachFile = {
      projectFileType: 'pcFiles'
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.projectID && changes['setExitingData']){
      this.updateTheValuesExist();
    }
    if (changes['projectID']) {
      console.log(this.projectID, changes['projectID'])
      this.pcdataForFileUpload = {
        projectKey: this.projectID,
        region: 'eu-central-1'
      }
      this.pcdataForEachFile = {
        projectFileType: 'pcFiles'
      }
    }

  }
  ngAfterViewInit() {
    this.pcfileUploadStatusData = this.pcfileUploadComponent.fileUploadService.filesListObject;
  }

  pcfileUploadCompletionEvent(fileDetails) {
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "fileSize": String(fileDetails.fileDetails.size),
      "uploadId": fileDetails.uploadId,
      "fileType": fileDetails.fileName.split('.').pop()
    }
    const deleteObj = {
      "fileName": fileDetails.fileName,
      "fileId": fileDetails.fileId,
      "projectFileType": 'pcFiles'
    }
    if (this.projectConcept.controls["pcFiles"].value === null) {
      this.projectConcept.controls["pcFiles"].setValue([])
    }
    this.projectConcept.controls["pcFiles"].value.push(obj);
    this.emitFileOnUplaod.emit(deleteObj);
    this.projectConcept.controls["pcFiles"].setValue(this.projectConcept.controls["pcFiles"].value);
  }

  toList(data): any[] {
    return Object.values(data)
  }

  get f() {
    return this.projectConcept.controls;
  }
  showRemainingFieldasDisable() {
    this.projectConcept.controls['isIsctIdeaEndorsed'].valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue === 'No') {
          this.isDisabled = true;
          this.projectConcept.controls['pcEndorsementDate'].disable();
          this.projectConcept.controls['pcComments'].disable();
          this.projectConcept.controls['pcFiles'].disable();
          this.isDropDisabled = true;
          this.projectConcept.controls['pcOverrideReason'].enable();
        }
        else {
          this.isDisabled = false;
          this.projectConcept.controls['pcEndorsementDate'].enable();
          this.projectConcept.controls['pcComments'].enable();
          this.projectConcept.controls['pcFiles'].enable();
          this.isDropDisabled = false;
          this.projectConcept.controls['pcOverrideReason'].setValue('');
          this.projectConcept.controls['pcOverrideReason'].disable();

        }

      })
  }
 
 

  deleteFile(file, fileType, controller) {
    const payload = {
        "fileName": file.fileName,
        "fileId": file.fileId,
        "projectFileType": fileType
      }
        if (file.fileId) {
          const newArray = controller.value.filter(item => item.fileId !== file.fileId);
    
          controller.setValue(newArray);
          this.fileListDeleteOnSave.push(payload);
        }
  }

  updateTheValuesExist() {
    if (this.setExitingData && this.setExitingData !== null) {
      this.projectConcept.patchValue(this.setExitingData);
      this.projectConcept.controls['isIsctIdeaEndorsed'].disable();
      this.projectConcept.markAsPristine();
    }  
}


}
