import { Component, OnInit } from '@angular/core';
import { NavifyauthService } from '../../services/navifyauth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading = false;
  constructor(private navifyServ: NavifyauthService) { 
    
  }

   async ngOnInit() {
    await this.doLogin();
  }
  async doLogin() {
    this.isLoading = true;
    await this.navifyServ.loginToNavify().then(()=>{
      this.isLoading = false;
    });
    await this.navifyServ.setAuthSessionRenewal().then(()=>{
      this.isLoading = false;
    });
    await this.navifyServ.initiateAuth().then(()=>{
      this.isLoading = false;
    });
    
  }

}
