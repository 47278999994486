import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { MatDialog } from '@angular/material/dialog';
import { RemoveProjectComponent } from './remove-project/remove-project.component';
import { environment as env, environment} from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { EMPTY } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ] 
})
export class ProjectOverviewComponent implements OnInit {
  i18n = i18n;
  constructor(public storageService: StorageService ,public http: HttpClient,
     public matDialog: MatDialog, public router: Router, public service: ProjectManagementService,
      public commonSer: CommonService,private toastrService: ToastrService, private route: ActivatedRoute) { }
  currentUserPermissions:any;
  overviewList: any = [];
  projectviewColumns = ['projectName', 'projectKey', 'status', 'region', 'createdDate', 'actions'];
  hasNoUserData = false;

  isLoading = false;
  showSearchCancelBtn: boolean;
  inputSearch: FormControl = new FormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showRemove = false;
  selectedRowElement: any; 
  addpermission = false;
  orgRole: any;
  orgRoleId: any = this.storageService.secureStorage.getItem('orgRoleId');
  overviewTblLength: [];
  auditSettingsStatus: string;
  ngOnInit(): void {
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.showRemove = true;     
    this.getProjectViewTable();
  }

  
  getProjectViewTable() {
    this.isLoading = true;
    const obj = {
      userUuid: this.storageService.secureStorage.getItem('userUuid'),
      orgKey: environment.config.orgKey
    }
    this.service.getProjLists(obj).subscribe((data: any) => {
      this.isLoading = false;
      this.overviewTblLength = data.result;
      
      //this.auditSettingsStatus = data.result;
      for(const i in data.result){
        if(data.result[i].createdDate != null){
          const datepipe: DatePipe = new DatePipe('en-US');
          const testDate = datepipe.transform( data.result[i].createdDate, "y-MMM-dd HH:mm:ss");
          data.result[i].hiddencreatedDate = testDate;     
          this.overviewList = new MatTableDataSource(data.result);
          setTimeout(() => this.overviewList.paginator = this.paginator);
          setTimeout(() => this.overviewList.sort = this.sort);
        }
       }
      this.overviewList.filterPredicate = function (item: any, filter: string): boolean {
        return item.projectKey.toLowerCase().includes(filter) || item.projectName.toLowerCase().includes(filter)
          || item.status.toLowerCase().includes(filter) || item.region?.regionName.toLowerCase().includes(filter) 
          || item.hiddencreatedDate.toLowerCase().includes(filter) || !filter;
      };
    });
  }

  applyFilter(event: Event) {
    this.showSearchCancelBtn = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.overviewList.filter = filterValue.trim().toLowerCase();     
    if(!filterValue){
      this.showSearchCancelBtn = false;
    }
  }

  
  redirectToProjectdetails(data: any) {
    data.roleId = data.roleId === null ? this.storageService.secureStorage.getItem('orgRoleId') : data.roleId;
    if(data.projectDetails.auditSettings !== null){
     this.router.navigate(['/projectmanagement/projectdetails'], { relativeTo: this.route, queryParams: {...data, from: 'noPopup'}});
    } else {
      this.router.navigate(['projectmanagement/projectdetails'], {
        queryParams:data
     });
    }
    this.service.newEvent(EMPTY)
  }
  redirectToTransferPage(data: any) {
    this.router.navigate(['projectmanagement/projecttransfer'],
      { queryParams: { projectKey: data.projectKey,
        projectName : data.projectName, }})
  }
  redirectToContinuePage(data: any){
    this.router.navigate(['projectmanagement/createproject'],
    { queryParams: { projectKey: data.projectKey,
      projectName : data.projectName, }})
  }

  onSearchCancelBtn() {
    this.showSearchCancelBtn = false;
    this.inputSearch.reset();
    this.getProjectViewTable()

  }

  createProject() {
    this.service.checkProjectCount().subscribe((response: any) => {
      if (response.result >= 250) {
        this.toastrService.error("You've reached the maximum number of projects allowed. If you would like to create more projects, one or more projects need to be deleted.");
      }
      else {
        this.router.navigate(['projectmanagement/createproject']);
      }
    })
  }
  


  onSelectProject(element: any) {
    this.showRemove = false;
    this.selectedRowElement = element;

  }
  onRemoveProject() {
    const dialogRef = this.matDialog.open(RemoveProjectComponent, {
      width: '400px',
      height: '200px',
      data: {
        isEdit: true,
        selectedRowElement: this.selectedRowElement
      }
    });

  } 
  onCopyProjectKey(){
    this.toastrService.success(this.i18n.projectMnt.PO.TOASTR_S);
  }

  ngAfterViewInit() {
    this.overviewList.sort = this.sort;
    this.overviewList.paginator = this.paginator;
    this.overviewList.filterPredicate = function(data:any, filter: string): boolean {     
      return data.projectKey.toLowerCase().includes(filter)||data.projectName.toLowerCase().includes(filter)
      ||data.status.toLowerCase().includes(filter)||data.region?.regionName.toLowerCase().includes(filter)||!filter;
  };  
  }
}
function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;

  return customPaginatorIntl;
}


