<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>
<div class="invite-popup-card">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="createForm">                
                <mat-grid-list cols="2" rowHeight="70px">
                    <!-- For invite collaborator -->
                    <mat-grid-tile *ngIf="fromComponent === 'collaborator' || fromComponent === 'project-audit'">
                        <div class="top-field">
                            <mat-label>
                                <h5>{{i18n.userManagement.inviteUser.LBL_PN}}</h5>
                                <h3>{{projectName}}</h3>
                            </mat-label>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile *ngIf="fromComponent === 'collaborator' || fromComponent === 'project-audit'">
                        <div class="top-field">
                            <mat-label>
                                <h5>{{i18n.userManagement.inviteUser.LBL_ON}}</h5>
                                <h3>{{orgName}}</h3>
                            </mat-label>
                        </div>
                    </mat-grid-tile>
                    <!-- end-->
                    <mat-grid-tile>
                        <div class="input-error-div">
                            <mat-form-field appearance="fill" disabled="isEdit">
                                <mat-label>{{i18n.userManagement.inviteUser.LBL_Username}}</mat-label>
                                <input matInput formControlName="username" autocomplete="off" />
                            </mat-form-field>
                            <mat-error *ngIf="userNameErrorMsg">
                                {{userNameErrorMsg}}
                            </mat-error>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile *ngIf="fromComponent !== 'collaborator' && fromComponent !== 'project-audit'">
                        <mat-checkbox formControlName="starCAProles" [(ngModel)]="isAdmin"
                            (change)="onPlatformAdminSelection($event)"> {{i18n.userManagement.inviteUser.LBL_SPA}}</mat-checkbox>
                    </mat-grid-tile>

                    <!-- Project Role -->
                    <mat-grid-tile *ngIf="fromComponent === 'project-audit'">
                        <div class="input-error-div">
                            <mat-form-field appearance="fill">
                                <mat-label>Project Role</mat-label>
                                <mat-select [(value)]="auditProjectRoleType" [disabled]="true">
                                    <mat-option [value]="auditProjectRole">{{auditProjectRole}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>

                    <mat-grid-tile *ngIf="fromComponent === 'collaborator'">
                        <div class="input-error-div">
                            <mat-form-field appearance="fill">
                                <mat-label> {{i18n.userManagement.inviteUser.LBL_PR}} </mat-label>
                                <mat-select formControlName="projectRole">
                                    <mat-option *ngFor="let item of roles" value="{{item.roleId}}#{{item.roleName}}"
                                        [disabled]="(isDataControllerExist == true && item.roleName.toLowerCase() === role.data_controller) || item.roleName.toLowerCase() === role.imcore_member_data_ingress_only">
                                        {{item.roleName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <div class="input-error-div">
                            <mat-form-field appearance="fill">
                                <mat-label> {{i18n.userManagement.inviteUser.LBL_FN}} </mat-label>
                                <input matInput formControlName="firstname" autocomplete="off" />
                            </mat-form-field>
                            <mat-error *ngIf="(createForm.get('firstname')?.errors?.['required']||createForm.get('firstname')?.errors?.['maxlength'])&&
                         (createForm.get('firstname')?.dirty || createForm.get('firstname')?.touched)">
                         {{i18n.userManagement.inviteUser.ERR_MM}}
                            </mat-error>
                            <mat-error *ngIf="createForm.get('firstname')?.errors?.['specialchar']">
                                {{i18n.userManagement.inviteUser.ERR_SPL}}
                            </mat-error>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="input-error-div">
                            <mat-form-field appearance="fill">
                                <mat-label> {{i18n.userManagement.inviteUser.LBL_LN}} </mat-label>
                                <input matInput formControlName="lastname" autocomplete="off" />
                            </mat-form-field>
                            <mat-error *ngIf="(createForm.get('lastname')?.errors?.['required']||createForm.get('lastname')?.errors?.['maxlength']) &&
                         (createForm.get('lastname')?.dirty || createForm.get('lastname')?.touched)">
                         {{i18n.userManagement.inviteUser.ERR_MM}}
                            </mat-error>
                            <mat-error *ngIf="createForm.get('lastname')?.errors?.['specialchar']">
                                {{i18n.userManagement.inviteUser.ERR_SPL}}
                            </mat-error>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile *ngIf="!isAdmin && (fromComponent !== 'collaborator' && fromComponent !== 'project-audit')">
                        <div class="input-error-div">
                            <mat-form-field appearance="fill">
                                <mat-label>{{i18n.userManagement.inviteUser.LBL_ORG}}</mat-label>
                                <mat-select formControlName="organization">
                                    <mat-option *ngFor="let org of orgList" [value]="org">
                                        {{org}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile *ngIf="!isAdmin && (fromComponent !== 'collaborator' && fromComponent !== 'project-audit')">
                        <div class="input-error-div">
                            <mat-form-field appearance="fill">
                                <mat-label>{{i18n.userManagement.inviteUser.LBL_OR}}</mat-label>
                                <mat-select formControlName="organizationRole" [disabled]="fromComponent === 'project-audit'">
                                    <mat-option *ngFor="let orgrole of orgRoleList" [value]="orgrole.roleId">
                                        {{orgrole.roleName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </mat-card-content>

        <mat-card-footer>
            <div mat-dialog-actions>
                <button mat-stroked-button (click)="onCancel()">{{i18n.userManagement.inviteUser.BTN_CANCEL}}</button>
                <button mat-raised-button *ngIf="!isEdit" (click)="onInvite(createForm.value)"
                    [disabled]="!createForm.valid">{{inviteButtonName}}</button>
                <button mat-raised-button *ngIf="isEdit" (click)="onUpdate(createForm.value)"
                    [disabled]="!(formHasChange) ||(formHasChange && !createForm.valid)">{{i18n.userManagement.inviteUser.BTN_UPDATE}}</button>
            </div>
        </mat-card-footer>
    </mat-card>
</div>