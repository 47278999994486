import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/services/storage.service';
import { ProjectManagementService } from '../project-management.service';
import { i18n } from 'src/app/i18n.en';
import { environment as env } from 'src/environments/environment';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CommonService } from 'src/app/services/common.service';


export interface FileListObj {
  [key: number]: {
    file: File
    name?: string
    size?: number
    path?: string
    lastModified?: number
    fileDetails?: {
      name: string;
      size: number;
      lastModified: number;
    }
    chunkPercents: {
      [key: number]: number
    }
    fileProgress: number
  }
}


export interface PeriodicElement {
  keyname: string
  filename: string,
  file_id: string,
  file_extension: string,
  file_size: number,
  date_modified: string,
  approval_status:any
}

export class ComparableSelectionModel<T> extends SelectionModel<T> {
  constructor(
    _multiple?: boolean,
    initial?: T[],
  ) {
    super(_multiple, initial)
  }
  override isSelected(value: T): boolean {
    return (this.selected.filter((x) => x['keyname'] === value['keyname']).length === 1)
  }
}

@Component({
  selector: 'app-data-files-list',
  templateUrl: './data-files-list.component.html',
  styleUrls: ['./data-files-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ] 
})
export class DataFilesListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('fileUpload') fileUploadComponent;
  i18n = i18n;
  isLoading = false;
  searchTerm: any;
  projectdetailsValues: any;
  showSearchClearBtn: boolean;
  projectName: string;
  projectKey: any;
  selectedReference: any[];
  displayNoRecordsFound: boolean;
  dataFileListData: any[] = [];
  isGotRefreshed = false;
  dataSource: MatTableDataSource<PeriodicElement> = new MatTableDataSource<PeriodicElement>([]);
  displayedColumnsProgress: string[] = ['filename', 'date_modified'];
  displayedColumns: string[] = ['select', 'filename', 'date_modified'];
  selection = new ComparableSelectionModel<PeriodicElement>(true, []);
  sftpUrl: string;
  profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
  authenticationKey:string;
  isRocheUser:boolean;
  deselectAllDisable = true;
  selectAllDisable = false;
  tableToggle = false;
  dataLength: number;
  dataForFileUpload: { projectKey: string; region: string; };
  dataForEachFile: { projectFileType: string; };
  fileUploadApis: { fileupload: string; completeMultiPart: string; failedNotificationApi: string; };
  fileUploadStatusData:any ={}
  userUuid = this.storageService.secureStorage.getItem('userUuid');
  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly clipboard: Clipboard, private readonly toastrService: ToastrService,
    private storageService: StorageService, private service: ProjectManagementService,
    private readonly commonService: CommonService
    ) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.projectdetailsValues = queryParams
      this.dataForFileUpload = {
        projectKey: this.projectdetailsValues.projectKey,
        region: 'eu-central-1'
      }
      this.dataForEachFile = {
        projectFileType: 'Datafile'
      }
      this.projectKey = queryParams['projectKey'];
      this.projectName = queryParams['projectName'];
    });
    this.fileUploadApis = {
      fileupload: env.config.domain + env.dataIngressFileUpload,
      completeMultiPart: env.config.domain + env.dataIngressCompleteMultiPart,
      failedNotificationApi: env.config.domain + env.dataIngressFailedNotificationApi
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.projectKey = queryParams['projectKey'];
      this.projectName = queryParams['projectName'];
    });
    this.getDataFileListTableData(true);
    this.getSFTPTableData();
    this.getTempPassword();
    this.selection.changed.subscribe((s) => {
      if (this.selection.selected.length > 200) {
        this.toastrService.error(this.i18n.projectMnt.DFL.TOASTR_DFC_E);
      }
    });
  }
  ngAfterViewInit() {
    this.fileUploadStatusData = this.fileUploadComponent.fileUploadService.filesListObject
    this.commonService.dataIngressFileUploadData = this.fileUploadComponent.fileUploadService.filesListObject
  }

  fileUploadCompletionEvent(filadata) {
    this.getDataFileListTableData()
  }

  toList(data){
    return Object.values(data)
  }

  checkActiveUpload(data) {
    const fileUploadData = Object.values(data)
    const filteredData = fileUploadData.filter((x:any) => x['status'] !== 'FAILED')
    if (filteredData.length !== 0) {
      return true
    }
    return false
  }
  
  getDataFileListTableData(enableLoader = false) {
    if (enableLoader) {
      this.isLoading = true;
    }
    const USERID = this.storageService.secureStorage.getItem('userUuid');

    this.service.getDataFilelist(this.projectKey, USERID).subscribe((data: any) => {
     // this.service.getDataFilelist().subscribe((data: any) => {
      this.isLoading = false;
      if (data.result.length > 0) {
        this.dataFileListData = data.result;
        //Automatically sort the data by uploaded date/time
        this.dataFileListData.sort((a, b) => b.date_modified.localeCompare(a.date_modified));
        // this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataFileListData);
        this.dataSource.data = this.dataFileListData
        this.dataSource.paginator = this.paginator;
        this.dataLength = this.dataSource?.data?.length;
        const selectedData = this.storageService.secureStorage.getItem('selectedFiles' + USERID);
        if (selectedData && !this.isGotRefreshed) {
          this.service.events$.forEach(event => {
            if (event === 'backToFiles') {
              this.selection.select(...selectedData);
              const itemsToAdd = this.dataSource.data.
                filter(item => {
                  const foundItem = this.selection.selected.find(selectedItem => selectedItem.filename === item.filename);
                  if (!foundItem) return;
                  // removes item from selection
                  this.selection.deselect(foundItem);
                  return item;
                });
              this.selection.select(...itemsToAdd);
            }

          }
          )
        }
        this.displayNoRecordsFound = false;
      }
      else {
        this.displayNoRecordsFound = true;
      }
    })
  }


  getSFTPTableData() {
    this.service.getsFTPUrl(this.projectKey, 'PROJECTINFRA.SFTP_HOST_URL').subscribe((data: any) => {
      this.sftpUrl = data.result;
    })
  }

  getTempPassword(){
    //Check For Roche User
    if(this.profile.username.search('roche.com') !== -1){
      this.isRocheUser = true;
    }
    else{
      this.isRocheUser=false;
    }
    this.service.getPwd(this.profile.username).subscribe((data: any) => {
      this.authenticationKey = data.result;
    })
  }

  backToProjectDetails() {
    this.router.navigate(['projectmanagement/projectdetails'], {
      queryParams: {...this.projectdetailsValues, selectProjectTabIndex:'2'}
    });
    this.storageService.secureStorage.removeItem('selectedFiles');
  }

  copysFTPUrl(url: string,toastMsg:string) {
    this.clipboard.copy(url);
    this.toastrService.success(toastMsg);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {    
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectAllDisable = false;
      this.deselectAllDisable = true;
      
      this.selection.clear();
      return;
    } else {
      this.selectAllDisable = true;
      this.deselectAllDisable = false;
    }
    this.selection.select(...this.dataSource.data);
  }

  applyFilter(event: Event) {
    this.showSearchClearBtn = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onSearchCancelBtn() {
    this.showSearchClearBtn = false;
    this.dataSource.filter = '';
    this.searchTerm = '';
  }
  onProceed() {
    this.router.navigate(['projectmanagement/createdataset'], {
      queryParams: this.projectdetailsValues
    });
    const USERID = this.storageService.secureStorage.getItem('userUuid');
    this.storageService.secureStorage.setItem('selectedFiles' + USERID, this.selection.selected);
  }

  onRefresh() {
    this.isGotRefreshed = true;
    this.getDataFileListTableData(true);
    this.selection.clear();
    this.searchTerm = '';
    this.onSearchCancelBtn()
  }

  public selectAllBox(): void {
      this.selectAll();
      this.selectAllDisable = true;
      this.deselectAllDisable = false;
  }

  public deSelectAllBox(): void {
      this.deselectAll();
      this.selectAllDisable = false;
      this.deselectAllDisable = true;
  }

  private selectAll(): void {
    this.selection.select(...this.dataSource.data.map((row) => row));
    if (this.selection.selected.length > 200) {
      this.toastrService.error(this.i18n.projectMnt.DFL.TOASTR_DFC_E);
    }
  }

  private deselectAll(): void {
    this.selection.clear();
  }

  protected displayMessage(event){
    console.log(event)
    if (event['checked']) {
      let test = this.toastrService.info( "Displaying Uploaded files(Completed)","",{
        timeOut: 1000,
        tapToDismiss:true
      })
      console.log(test)
    }
    else {
      this.toastrService.info("Displaying files upload Inprogress","",{
        timeOut: 1000,
        tapToDismiss:true
      })
    }
  }
}

function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;

  return customPaginatorIntl;
}