import { Injectable } from '@angular/core';

// @Injectable()
export class Service1 {

  constructor() { }

  doSomethingFromService1() {
    console.log('service 1 just did something');
  }

}


// @Injectable()
export class Queue {
  public items: any;
  public frontIndex;
  public backIndex;
  constructor() {
    this.items = {}
    this.frontIndex = 0
    this.backIndex = 0
  }
  enqueue(item: string) {
    this.items[this.backIndex] = item
    this.backIndex++
    return item + ' inserted'
  }
  dequeue() {
    const item = this.items[this.frontIndex]
    delete this.items[this.frontIndex]
    this.frontIndex++
    return item
  }
  dequeuePromise() {
    let dequeue = new Promise((resolve) => {
      const item = this.items[this.frontIndex]
      delete this.items[this.frontIndex]
      this.frontIndex++
      return item
    })
    dequeue.then((item) => { return item })
  }
  peek() {
    return this.items[this.frontIndex]
  }
  get printQueue() {
    return this.items;
  }
  get qLength() {
    // return true if the queue is empty.
    // console.log(this.items)
    return Object.keys(this.items).length;
  }
}

@Injectable()
export class AsyncLock {
  disable: () => void;
  promise: Promise<void>;
  constructor () {
    this.disable = () => {}
    this.promise = Promise.resolve()
  }

  enable () {
    this.promise = new Promise(resolve => this.disable = resolve)
  }
}
