
export const SP = 'starCAP Platform';
export const NDF = 'No data found';
export const PUM = 'Platform User Management';
export const OM = 'Organization Management';
export const PM = 'Project Management';
export const PD = 'Project Details';
export const DC = 'Data Catalog';
export const MA = 'My Approvals';
export const AS = 'Audit Settings';
export const DSD = 'Dataset Details';
export const CD = 'Create Dataset';
export const PN = 'Project Name';
export const ON = 'Organization Name';
export const DCLAS = 'Data Classification';
export const DSN = 'Dataset Name';
export const DSDESC = 'Dataset Description';
export const AP = 'Add Project';
export const RPP = 'Rows per page:';
export const DID = 'Dataset ID';
export const CB = 'Created By';
export const DCD = 'Date Created';
export const DING = 'Date Ingressed';
export const DMTY = 'Data Maturity';
export const NODF = 'No. of Data Files';
export const DSIZE = 'Dataset Size';
export const PA = 'Project Audit';


export const i18n = {
  WELCOME:'Welcome',
  SESSION_IA: 'Session Inactive',
  SESSION_ST: 'Session Timeout',
  SESSION_IDLE_DESC1:'Your session has been inactive. You will be logged out in',
  SESSION_DESC1:'Your session has been Expired. You will be logged out in',
  SESSION_DESC2:'seconds. Would you like to continue?',
  LBL_NDF: NDF,
  BTN_CANCEL: 'Cancel',
  BTN_REMOVE: 'Remove',
  BTN_INVITE: 'Invite',
  BTN_UPDATE:'Update',
  BTN_REFRESH: 'Refresh',
  BTN_PROCEED: 'Proceed',
  BTN_FREQ: 'Frequency',
  BTN_OD: 'On-Demand',
  BTN_YES: 'Yes',
  BTN_NO: 'No',
  BTN_ADD: 'Add',
  BTN_SELECT: 'Select',
  BTN_APPROVE: 'Approve',  
  BTN_SEARCH: 'Search',
  BTN_APPLY: 'Apply',
  BTN_RESET: 'Reset',
  BTN_CLEAR: 'Clear',
  BTN_LOGOFF: 'Log off',
  BTN_DOWNLOAD: 'Download',
  NAV:{
    TC: '/termsconditions',
    LOBBY: '/applobby'
  },
    DATE:{
      SHORT:'YYYY-MM-dd'
    },
    HEADER: {
      SP: SP,
    },
    MENU: {
      HOME: 'Home',
      PUM,
      OM,
      PS: 'Platform Settings',
      DC,
      DC_2LINES: `Data <br /> Catalog`,
      PA_2LINES: `Project <br /> Audit`,
      PM,
      PA
    },
    BREADCRUMB:{
      HOME: 'Home',
      PUM,
      OM,
      PS: 'Platform Settings',
      DC,
      PM,
      PM_H:'Project Management - ',
      PD,
      COLL: 'Collaborators',
      DI: 'Data Ingress',
      MA,
      DATA_CATALOG: DC,
      DD: DSD,
      DFO:'Data Files Overview',      
      CD,
      AP,
      EPD: 'Edit Project Details',
      PA,
      AUDIT_HISTORY: 'Audit History',
      AS
    },
    LINKS:{
      PR: 'Password Reset'
    },
    ICONS:{
      APPS: 'apps',
      PROFILE: 'account_circle',
      NN: 'navigate_next',
      SEARCH: 'search',
      CANCEL:'cancel',
      BACK: 'arrow_back',
      CLOSE: 'close',
      DONE: 'done',
      DELETE: 'delete_outline',
      HELP: 'help_outline',
      HL_OFF:'highlight_off',
      TICK_CIRCLE:'check_circle_outline',
      CC: 'content_copy',
      DESC: 'description'
    },
    applobby:{
      LBL_PH: 'Placeholder',
      DUMMY_CNT: `"Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
      when an unknown printer took a galley of type and scrambled it to make a type
      specimen book. It has survived not only five centuries, but also the leap into
      electronic typesetting, remaining essentially unchanged. It was popularised in
      the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
      and more recently with desktop publishing software like Aldus PageMaker including
      versions of Lorem Ipsum."`,
      LBL_IL: 'Important Links',
      LBL_HEADING:'HEADING',
      ILS: 'Important Link',
      LBL_SD: 'starCAP Documentation',
      BTN_GS:'Get Started'
    },
    TNC:{
      TITLE: 'Terms and Conditions',
      LBL_SP: SP,
      DECLINE: 'You have chosen to decline our terms and conditions, your session will be logged out.',
      BTN_DECLINE:'Decline',
      BTN_ACCEPT: 'Accept',
      DES_TRAINING: 'Please complete your required training(s) to access starCAP',
      DES_ROLE: `You don't have access to any organization in starCAP Platform. You can't proceed further. Please exit the application.`
    },
    userManagement:{
      PUM,
      NDF,
      PAGINATION: RPP,
      BTN:{
        IU: 'Invite User',
        EDIT: 'Edit',
        EU: 'Edit User',
        INVITE:'Invite'
      },
      inviteUser:{
        LBL_PN: PN,
        LBL_ON: ON,
        LBL_Username:'Username (E-mail ID)',
        LBL_SPA: 'starCAP Platform Admin',
        LBL_PR: 'Project Role',
        LBL_FN: 'First Name',
        LBL_LN: 'Last Name',
        LBL_ORG: 'Organization',
        LBL_OR: 'Organization Role',
        BTN_CANCEL:'Cancel',
        BTN_UPDATE:'Update',
        ERR_MM:'Please enter min 1 char, max 16 characters',
        ERR_SPL: 'Only these special characters allowed &lt;space&gt; - . _',
      },
      ApproveUser:{
        LBL_AU:'Approve User',
        DES_Q: 'The selected user will get access to imCORE. Are you sure, you want to continue?',
        LBL_REMARKS: 'Remarks',
        BTN_CANCEL:'Cancel',
        BTN_APPROVE:'Approve'
      }
    },
    orgMnt:{ //Organization Management
      TITLE: OM,
      HEADER: SP,
      PAGINATION: RPP,
      LBL_OV: 'Organization Overview',
      BTN_CREATE_ORG: 'Create Organization',
      LBL_NDF: NDF
    },
    projectMnt:{ //Project Management 
      TITLE: PM,
      TITLE_UO: 'Users Overview',
      LBL_NDF: NDF,
      PAGINATION: RPP,
      TABS:{
        PO: 'Project Overview',
        UO: 'Users Overview'
      },
      PO:{ //Project Overview
        TITLE: 'Project Overview',
        BTN_ADD: AP,
        TOASTR_S: 'Project ID Copied Successfully',
        RP:{ //Remove Project
          TITLE:'Remove Project',
          DESC: `The selected project will be removed and the users will lose access to the project. Are you sure, you want to continue?`,
          TOASTR_S: 'Project removed from the organization successfully',
          BTN_CANCEL: 'Cancel',
          BTN_REMOVE: 'Remove'
        }
      },
      PD:{ // Project Details
        TITLE: PD,
        BTN_ED: 'Edit Details',
        SUB_TITLE_PI: 'Project Information',
        SUB_TITLE_OI: 'Other Information',
        PN,
        ON,
        R:'Region',
        ISD: 'imCORE Scientific Director',
        SN: 'Site Name',
        PD: 'Project Description',
        DC: DCLAS,
        AB: 'Allow Publishing to Data Catalog',
        SAR:'Set Audit Rule',
        SFAD:'Set First Audit Date'
      },
      UO:{ // User Overview
        BTN_REMOVE: 'Remove',
        TOASTR_S:'User removed from the organization successfully!',
        TOASTR_E:'Something went wrong!',
        UD:{ // User details
          BTN_CLOSE:'Close'
        },
        RU:{ // Remove user
          TITLE: 'Remove User from imCORE',
          DESC: `The selected user will be removed from imCORE and all associated projects. Are you sure, you want to continue?`,
          BTN_CANCEL: 'Cancel',
          BTN_REMOVE: 'Remove'
        }
      },
      PF:{ // Project file
        TABS:{
          PD,
          COLL: 'Collaborators',
          DI: 'Data Ingress',
          MA
        },
      },
      MA:{ // My approvals
        TITLE: MA,
        RF:{  //Reject File
          TITLE: 'Reject File',
          LBL_RR:'Rejection Reason',
          BTN_CANCEL: 'Cancel',
          BTN_SUBMIT: 'Submit'
        },
        DD:{ // Dataset Details
          TITLE: DSD,
          LBL_DN:DSN,
          LBL_DD:DID,
          LBL_CB: CB,
          LBL_DC: DCD,
          LBL_DI: DING,
          LBL_PN: PN,
          LBL_ON: ON,
          LBL_DM: DMTY,
          LBL_SN: 'Site Name',
          LBL_DSD: DSDESC,
          LBL_DCL: DCLAS,
          LBL_NODF: NODF,
          LBL_DS: DSIZE,
          BTN_R: 'Reject',
          BTN_A: 'Approve',
          BTN_S: 'Submit'
        }
      },
      collaborators:{
        TITLE: 'Collaborators Overview',
        BTN_IC: 'Invite Collaborators',
        BTN_R: 'Remove',
        BTN_EDIT:'Edit',
        RC:{
          TITLE: 'Remove Collaborator',
          DESC1: `The selected user will be removed from the project`,
          DESC2: `. Are you sure, you want to continue?`,
        },
        IC:{
          TITLE: 'Invite Collaborators',
          LBL_SU: 'Search Username (email ID)',
          LBL_UNF: 'User not found',
          LBL_DESC:'Click below to invite a new user',
          LBL_DESC2: 'No users selected. Select Users from the list.',
          BTN_IU: 'Invite User',
          LBL_NMU: 'No mapping user',
          LBL_CBNU: 'Click below to invite a new user',
          LBL_ON: ON,
          LBL_PN: PN,
          LBL_UE: 'Username (email ID)',
          LBL_FN: 'User Name',
          LBL_SR: 'Select Role',
          TOASTR_S:'User invited to the project successfully'
        },
        EC:{
          TOASTR_S: 'Collaborator role changed successfully',
          TOASTR_E: 'Unable to update user role. Please try again.',
          TITLE: 'Edit Collaborator',
          LBL_U: 'Username',
          LBL_ON: ON,
          LBL_PN:PN,
          LBL_CR:'Current Role',
          LBL_SNR: 'Select New Role'
        },
        DelCollabUsers:{
          TOASTR_S: 'Collaborator removed from project successfully',
          TOASTR_E: 'User removal is not successful. Please try again.',
        }
      },
      DI: { // DATA INGRESS
        TITLE: 'Dataset Overview',
        BTN_CDS: CD,
        DSD: {
          TITLE: DSD,
          LBL_DD: DSD,
          LBL_DN:DSN,
          LBL_DID: DID,
          LBL_CB:CB,
          LBL_DC:DCD,
          LBL_DI:DING,
          LBL_DDESC:DSDESC,
          LBL_SN:'Site Name',
          LBL_PN:PN,
          LBL_ON:ON,
          LBL_DM:DMTY,
          LBL_DCLA:DCLAS,
          LBL_NODF:NODF,
          LBL_DS:DSIZE,
          LBL_F:'Files',
          LBL_FN:'File Name',
          LBL_FE:'File Extension',
          LBL_S:'Status'
        }
      },
      DFL:{ // Data Files List
        TOASTR_DFC_E:'Data files count is limited to 200 per dataset. Please review your selection',
        LBL_CD:CD,
        LBL_SFTP: 'Upload through sFTP',
        LBL_SPTP_TOOLTIP: 'Please use the provided details to configure third \
                           party sFTP tools like WinSCP or FileZilla for uploading \
                           files to your project. All successfully uploaded files \
                           shall appear on "Data Files Overview" pane',
        LBL_URL:'sFTP URL',
        LBL_AK: 'Authentication Key',
        LBL_DFO:'Data Files Overview',
        LBL_SH: 'Search here',
        DESC: `No data files found <br />After uploading the files, click on the refresh button below`,
        NDF
      },
      CD: { //Create Dataset
        TITLE: CD,
        DN: DSN,
        LBL_ERR: 'Field cannot be empty',
        LBL_ERR1: 'Dataset Name must,',
        LBL_ERR2: 'Min length = 1 and Max length = 75 characters.',
        LBL_ERR3: 'Characters are alphanumeric',
        LBL_ERR4: 'hyphen (-) and underscore (_) special characters are allowed',
        LBL_DSDES: DSDESC,
        BTN_BTF: 'Back To Files',
        BTN_CD: CD
      },
      CP: {
        TITLE_AP: AP,
        TITLE_EP: 'Edit Project Details',
        LBL_PI: 'Project Information',
        LBL_PN: PN,
        LBL_R: 'Region',
        LBL_SN: 'Site Name',
        LBL_PD: 'Project Description',
        LBL_OI: 'Other Information',
        LBLDC: DCLAS,
        LBL_AP: 'Allow Publishing to Org Data Catalog *',
        LBL_SAR: 'Set Audit Rule *',
        LBL_SFAD: 'Set First Audit Date *',
        LBL_DC: DCLAS,
        ERR_PNM:'Project Name must',
        ERR_2:'It must start with an alphabetic character',
        ERR_3:'Characters 2 to 5 should be alphanumeric',
        ERR_4:'Special characters are allowed beyond first *five* characters: + - = . _ : / @',
        ERR_5:'Min length = *5* and Max length = *20*'        
      },
    },
    catalogManagement:{ //Catalog Management 
      TITLE: 'Dataset Overview',
      
      SEARCH_BUTTON: 'Search',
      ADD_BUTTON: 'Add',
      RESET_BUTTON: 'Reset',
      CANCEL_BUTTON: 'Cancel',
      APPLY_BUTTON: 'Apply',
      CLEAR_BUTTON: 'Clear',
      LBL_CND: 'Condition',
      NRF: 'No results found',
      LBL_V: 'Value',
      LBL_SD: 'Select date',
      LBL_SDR: 'Select Date range',
      LBL_AND: 'AND',
      LBL_OR: 'OR',
      LBL_EQLTO: 'equals to',

      DATASET_DETAILS:{ // Dataset Details
        TITLE: 'Dataset Metadata Details',
        LBL_DN:'Dataset Name',
        LBL_DD:DID,
        LBL_CB: CB,
        LBL_DC: DCD,
        LBL_DI: DING,
        LBL_PN: 'Project Name',
        LBL_ON: 'Organization Name',
        LBL_DM: DMTY,
        LBL_SN: 'Site Name',
        LBL_DSD: 'Dataset Description',
        LBL_DCL: 'Data Classification',
        LBL_NODF: NODF,
        LBL_DS: DSIZE,
        LBL_DF: 'Data Files',
        LBL_FN: 'File Name',
        LBL_FE: 'File Extension',
      }
    },
    projectAudit:{//Project Audit
      TITLE: 'Project Audit',
      TBL_TITLE: 'Project Audit Overview',
      TBL_MANAGE_USERS_TITLE: 'Manage Users',
      TBL_HEADER:{
        HDR_PN: 'Project Name',
        HDR_AS: 'Audit Status',
        HDR_LAD: 'Last Audit Date',
        HDR_NAD: 'Next Audit Date',
        HDR_DR: 'Days remaining for the next Audit',
        HDR_CD: 'Cadence',
        HDR_ATN: 'Actions'
      },
      TABS:{
        TAB_SAR: 'Start Audit & Document Audit Result',
        TAB_PI: 'Project Information',
        TAB_MU: 'Manage Users',
        TAB_AH:'Audit History'
      },
      LBL_MDC: 'Manage Data Controller',
      BTN_ASSIGN: 'Assign',
      TAB_SAR_CONTENT:{
        TITLE: 'Start Audit & Document Audit Result',
        H_PN: 'Project Name',
        H_AID: 'Audit Initiation date by the Site GPO/DPO',
        H_ACBD: 'Current Audit Cycle Begin Date',
        H_LAD: 'Last Audit Date',
        H_CFA: 'Cadence for Audit',
        H_SND: 'Specify number of days',
        BTN_SA: 'Start Audit',
        BTN_AR: 'Document Audit Result'
      },
      DAR_POPUP:{
        TITLE: 'Document Audit Result',
        FF_TITLE_DAR: 'Document Audit Result',
        FF_TBTN_AP: 'Audit Passed',
        FF_TBTN_PWW: 'Passed with Warning',
        FF_TITLE_CMT: 'Comments',
        FF_TITLE_ARN: 'Additional recipients to notify.',
        FF_ARN_ERR_M_COUNT: 'Only 20 email addresses are allowed',
        FF_ARN_ERR_M_VALID: 'Enter valid email address',
        FF_TITLE_ATC: 'Attachments',
        TXT_DD: 'Drag and Drop files here',
        TXT_OR: 'or',
        BTN_BF: 'Browse for Files',
        TXT_GDL_UP: 'Guidelines to upload,',
        TXT_GDL_1: 'Maximum number of files allowed for upload: 20 Files.',
        TXT_GDL_2: 'Size limit per file: 0.5 GB per file.',
        TXT_GDL_3: 'Total attachments size - 1 GB.',
        TXT_GDL_4: 'File types allowed for upload: .doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls, .xlsx, .ics (apple calendar), .msg (Outlook email message).'
      },
      TAB_AH_CONTENT:{
        TITLE: 'Audit History -',
        TBL_HDR_LAD: 'Last Audit Date',
        TBL_HDR_ABY: 'Audited By',
        TBL_HDR_AR: 'Audit Result',
        TBL_HDR_ATN: 'Actions'        
      },
      AR_POPUP:{
        TITLE_DAR: 'Document Audit Result',
        TITLE_AD: 'Audited Date',
        TITLE_CMT: 'Comments',
        TITLE_AEN: 'Additional recipients to notify.',
        TITLE_ATCH: 'Attachments',
        TBL_HDR_FN: 'File Name'
      }
    }
  }

  // https://api.starcap-dev.platform.navify.com/user-management-services/api/v1/user/getadminroleaccess 