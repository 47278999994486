import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationOverviewComponent } from './organization-overview/organization-overview.component';


const routes: Routes = [
  { path: '', component: OrganizationOverviewComponent },
  //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthguardService], data: { breadcrumb: 'Dashboard', path:'dashboard'}},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationManagementRoutingModule { }
