<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto"> <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2" src="./assets/images/project-management-icon-small.svg" alt="pm ICON" /> </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-title">{{i18n.HEADER.SP}}</span><span class="me-2 fw-normal">|</span> <span class="fs-6">{{i18n.projectMnt.TITLE}}</span></li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
          </mat-grid-list>        
    </div>
    
  </div>
   

<div class="cont-fluid cmn_tbl">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
      <li>
        <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.PM}}</li>
    </ol>
  </nav>

  <mat-card>
    <div>
      <mat-tab-group>
        <mat-tab class="pl-3" [label]="i18n.projectMnt.TABS.PO">
          <ng-template matTabContent>
            <app-project-overview></app-project-overview>
          </ng-template>
        </mat-tab>

        <!-- <mat-tab [label]="i18n.projectMnt.TABS.UO" *ngIf="service.permission(orgRole, 'userAllList')"> -->
          <mat-tab [label]="i18n.projectMnt.TABS.UO">
          <ng-template matTabContent>
            <app-user-overview></app-user-overview>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>

</div>
<router-outlet></router-outlet>