<div class="tab-details container">
    <div class="row">
        <div class="col-12 mb-3">
            <mat-label>
                <p>Is the project endorsed by CITC? *</p>
                <h4>{{citcDetails?.isCitcIdeaEndorsed ? citcDetails?.isCitcIdeaEndorsed : '-' }}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Data of Endorsement *</p>
                <h4>{{citcDetails?.citcEndorsementDate  ? (citcDetails?.citcEndorsementDate | date: "yyyy-MMM-dd") : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Comments</p>
                <h4>{{citcDetails?.citcComments ? citcDetails?.citcComments : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>{{projectCreation.pc.attach}}</p>
                <div class="create-project-table-container">
                    <div class="table-container">
                        <table mat-table [dataSource]="citcFilesDataSource?.data.length > 0  ? citcFilesDataSource : emptyData" class="mat-elevation-z0" matSort
                            >
                            <!-- Filename Column -->
                            <ng-container matColumnDef="fileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                            </ng-container>

                            <!-- download Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element">
                                    <a  (click)="service.getDownloadPresignedURL(element,'citcFiles',projectID);" class="browse-button">Download</a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="empty-row">
                                <td mat-cell class="mt-3 d-flex justify-content-center" *matCellDef="let element">no record found</td>
                              </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: citcFilesDataSource?.data?.length > 0 ? displayedColumns: ['empty-row'];"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                        class="mat-paginator-sticky" #citcFilesPaginator></mat-paginator>
                </div>
            </mat-label>
        </div>
        <div class="col-12 mb-3" *ngIf="citcDetails?.citcOverrideReason">
            <mat-label>
                <p>Override with Reason *</p>
                <h4>{{citcDetails?.citcOverrideReason}}</h4>
            </mat-label>
        </div>
    </div>
</div>