<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>
<div class="downloadReport-card">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'Download Report'}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="downloadForm">  
                <h5 class="mb-0">Select Date Range</h5>
                <mat-grid-list cols="2" rowHeight="50px">
                    <!-- For invite collaborator -->
                    <mat-grid-tile>
                        <div class="top-field">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label class="select-lable">{{'From'}}</mat-label>            
                                <mat-datepicker-toggle matPrefix [for]="onFrompicker"></mat-datepicker-toggle>
                                <input matInput [matDatepicker]="onFrompicker" formControlName="startDate" [min]="transferDate" />
                                <mat-datepicker #onFrompicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="top-field">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label class="select-lable">{{'To'}}</mat-label>            
                                <mat-datepicker-toggle matPrefix [for]="onTopicker"></mat-datepicker-toggle>
                                <input matInput [matDatepicker]="onTopicker" formControlName="endDate" [max]="maxDate" />
                                <mat-datepicker #onTopicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </mat-grid-tile>                    
                </mat-grid-list>
            </form>
        </mat-card-content>

        <mat-card-footer>
            <div mat-dialog-actions>
                <button mat-stroked-button (click)="onCancel()">{{i18n.userManagement.inviteUser.BTN_CANCEL}}</button>                
                <button mat-raised-button (click)="downloadProjectInfo()" [disabled]="!downloadForm.valid">{{'Download'}}</button>
                <!-- <button mat-raised-button (click)="downloadProjectInfo();" [disabled]="!downloadForm.valid">{{'Download'}}</button> -->

                <!-- <button (click)="clickMainContent()">Change main content</button> -->
                <!-- <button (click)="clickSubContent()" #subcontent>Change sub content</button> -->

                <!-- <a id="dl" href="{{projectInfoDownloadUrl}}" download>
                    click
                  </a> -->
                
                <!-- <a href="{{downloadUrl}}" (click)="testDownload()" download>
                    click
                  </a> -->

                  <!-- <a download>
                    <button mat-raised-button data-dli (click)="downloadProjectInfo($event);" [disabled]="!downloadForm.valid">{{'Download'}}</button>
                  </a> -->

                  <!-- <button mat-raised-button (click)="fileDownload();" [disabled]="!downloadForm.valid">{{'Download'}}</button> -->

                  <!-- <a href="https://starcap-dev2.platform.navify.com/project-management-services/api/v1/project/downloadprojectinfo?projectName=testing&orgKey=imCORE&projectKey=july11689069512668" download>multiple DLinks</a> -->

                  <!-- <a href="{{datasetDownloadUrl}}" download>
                    <button mat-raised-button (click)="downloadProjectInfo();" [disabled]="!downloadForm.valid">{{'Download'}}</button>
                  </a> -->
            </div>
        </mat-card-footer>
    </mat-card>
</div>
