import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectManagementService } from '../../project-management.service';
import { ComputeConfig, projectCreation } from '../model/model';
@Component({
  selector: 'app-compute-configuration',
  templateUrl: './compute-configuration.component.html',
  styleUrls: ['./compute-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComputeConfigurationComponent implements OnChanges {
  computeConfig: FormGroup;
  projectCreation = projectCreation;
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() setExitingData: ComputeConfig;
  constructor(public readonly service: ProjectManagementService) {
    this.computeConfig = new FormGroup({
      computeDetails: new FormControl(null, [this.noWhitespaceValidator,Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxLength5000)]),
    });

  }

  get f() {
    return this.computeConfig.controls;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.projectID && changes['setExitingData']) {
      this.updateTheValuesExist();
    }
  }
  updateTheValuesExist() {
    if (this.setExitingData && this.setExitingData !== null) {
      this.computeConfig.patchValue(this.setExitingData);
      this.computeConfig.markAsPristine();
    }
  }
  public noWhitespaceValidator(control: AbstractControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
}
