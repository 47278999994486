<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto"> <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2"
                            src="./assets/images/home-icon.svg" alt="" /> </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-title">{{i18n.TNC.LBL_SP}} </span> </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

<div class="cont-fluid">
    <div class="loadingcontainer">
        <div *ngIf="isLoading" class="loading-user-spinner">
            <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
            </mat-progress-spinner>
        </div>
    </div>
    <mat-card class="container px-0" *ngIf="termsAndConditionStatus">
        <div class="px-3">
            <mat-card-header>
                <mat-card-title>{{i18n.TNC.TITLE}} </mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <p class="para"> {{ContentTC}} </p>
            </mat-card-content>
        </div>

        <mat-divider class="border-1 border-bottom"></mat-divider>

        <div class="footer px-3" align="right">
            <button mat-raised-button class="decline" (click)="onDecline()">{{i18n.TNC.BTN_DECLINE}}</button>
            <button mat-raised-button (click)="onAccepted()">{{i18n.TNC.BTN_ACCEPT}}</button>
        </div>
    </mat-card>

    <mat-card class="container px-0" *ngIf="trainingStatus">
        <div class="px-3">
            <mat-card-content>
                <p class="warning">
                    <img src="../../../assets/images/warning.svg" alt="" />
                    <br />
                    {{i18n.TNC.DES_TRAINING}}
                </p>
            </mat-card-content>
        </div>
    </mat-card>

    <mat-card class="container px-0" *ngIf="roleStatus">
        <div class="px-3">
            <mat-card-content>
                <p class="warning">
                    <img src="../../../assets/images/warning.svg" alt="" />
                    <br />
                    {{i18n.TNC.DES_ROLE}}
                </p>
            </mat-card-content>
        </div>
    </mat-card>
</div>