import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  userDetailsUrl = env.config.domain + env.userDetails;
  inviteUserUrl = env.config.domain + env.inviteUser;
  approveUrl = env.config.domain + env.approve;
  addCollaboratorUrl = env.config.domain + env.addCollaborator;
  inviteCollaboratorsUrl = env.config.domain + env.inviteCollaborators;
  editCollaboratorsUrl = env.config.domain + env.editCollaborators;
  userDetailsUpdateUrl = env.config.domain + env.updateUserDetails;
  manageDataControllerUrl = env.config.domain + env.manageDataController;

  constructor(private http: HttpClient) { }

  getUserDetails() {
    return this.http.get(this.userDetailsUrl);
  }

  getUpdateUserDetails(obj: any) {
    return this.http.put(this.userDetailsUpdateUrl, obj);
  }

  getInviteUser(obj: any) {
    return this.http.post(this.inviteUserUrl, obj);
  }

  postUserApproval(obj: any) {
    return this.http.put<any>(this.approveUrl, obj)
  }

  addCollaborator(obj: any) {
    return this.http.post(this.addCollaboratorUrl, obj);
  }

  inviteCollaborators(obj: any) {
    return this.http.post(this.inviteCollaboratorsUrl, obj);
  }

  editCollaborators(obj: any) {
    return this.http.put<any>(this.editCollaboratorsUrl, obj)
  }
  manageDataController(obj: any){
    return this.http.put<any>(this.manageDataControllerUrl, obj);
  }
}
