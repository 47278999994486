import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RejectFilesComponent } from '../reject-files/reject-files.component';
import { CommonService } from 'src/app/services/common.service';
import { ProjectManagementService } from '../../project-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { i18n } from 'src/app/i18n.en';

export interface Task {
  filename: string;
  completed: boolean;
  status: string;
  subtasks?: Task[];
}

@Component({
  selector: 'app-myapprovals-dataset-details',
  templateUrl: './myapprovals-dataset-details.component.html',
  styleUrls: ['./myapprovals-dataset-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyapprovalsDatasetDetailsComponent implements OnInit {
  i18n = i18n;
  [x: string]: any;
  orgRole: string;
  isLoading = false;
  datasetDetailsDataSource: any = [];
  datasetName: string;
  datasetId: string;
  createdBy: string;
  approved_by: string;
  dateCreated: string;
  dateIngressed: string;
  datasetDescription: string;
  dataClassification: any;
  noOfDataFiles: number;
  fileSize: number;
  files: any = [];
  rejectApproveStatus = false;
  submitStatus = true;
  datasetForm: FormGroup;
  rejectionReason = '';
  filterdOptions: any;
  queryParamsData: any;
  projectName: string;
  projectId: string;
  region: string;
  orgKey: string;
  roleId: string;
  remarks: string;
  dataclassification: string;
  payloadFileList:any = [];
  searchStatus = false;
  tempFileset: any;
  testClassification: any;
  userEmail: string;
  editStatus = false;
  editDisableMode = false;
  before_filter:any = [];

  constructor(public storageService: StorageService, private matDialog: MatDialog, private toastrService: ToastrService, private commonService: CommonService,
    private projectService: ProjectManagementService, private activatedRoute: ActivatedRoute, public router: Router) { }

  ngOnInit(): void {    
    this.orgRole = this.storageService.secureStorage.getItem("role");
    this.userEmail = this.storageService.secureStorage.getItem('userEmail');
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParamsData = queryParams;
      this.datasetId = queryParams['datasetId'];
    });

    if(this.queryParamsData.datasetStatus == "approval"){
      this.editDisableMode = true;
    }

    this.getApprovalsDatasetDetails();
    this.getDataClassification();

    this.datasetForm = new FormGroup({
      'dataClassification': new FormControl('', [Validators.required]),
    });
  }

  getDataClassification(){
    this.projectService.getDataClassification().subscribe((res: any) => {
      this.dataClassification = res.result;
    },
    err => {
      this.isLoading = false;
    });
  }

  getApprovalsDatasetDetails(){
    this.isLoading = true;
    this.projectService.getDatasetDetails(this.queryParamsData.datasetId).subscribe({
      next: (data)=>{
        this.datasetDetailsDataSource = data.result;        
        this.dataclassification = this.datasetDetailsDataSource.dataClassification;
        this.datasetForm.patchValue({
          dataClassification: (this.dataclassification==null) ? "RED (Confidential/Restricted)": this.dataclassification
        });
        
        if(this.dataclassification!=null) {
          this.editStatus = true;
        }
        
        this.files =  this.datasetDetailsDataSource.filesList;
        for (const i in this.files){
          if(this.files[i].approval_status!=null)      
            this.files[i].status = (this.files[i].approval_status).toLowerCase();
        }
        this.task['subtasks'] = this.files;     
        this.isLoading = false;
      },
      error: (err)=>{
        this.isLoading = false;
      },
      complete: ()=>{
        this.isLoading = false;
      }
    });
  }

  compareById(o1:any, o2:any) {
    return o1 === o2;
  }

  onChange(event:any){
    console.log("On Change: ");   
  }

  // for checkbox
  task: Task = { 
    filename: 'Select All',
    completed: false,
    status: '',
  };

  allComplete = false;

  applyFilter(event: Event) {
    this.submitStatus = true;
    const filterValue = (event.target as HTMLInputElement).value;

    this.filterdOptions = (this.files).filter(
    (item: any) => (item.filename.toLowerCase().includes(filterValue)));
    this.task['subtasks'] = this.filterdOptions;
  }

  updateAllComplete() {    
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    this.rejectApproveStatus = true;
    
    if(this.task.subtasks != null && this.task.subtasks.every(t => !t.completed)){
      this.rejectApproveStatus = false; 
    }   
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
    this.rejectApproveStatus = true;
    if(!completed){     
      this.rejectApproveStatus = false;
    }
  }
  // end
  
  rejectApprove(type:any){    
    for (const i in this.files){
      const objIndex = (this.files).filter(((obj:any) => obj.completed == true));
      for (const j in objIndex){
        if(this.files[i].filename == objIndex[j].filename){
          this.files[i].status = type;
          this.files[i].approval_status = type;
          this.files[i].completed = false;
        }
      }
    }
    if(this.searchStatus === true){
      this.tempFileset = this.filterdOptions;
    } else {
      this.tempFileset = this.files;
    }

    const totalStatus = (this.files).filter((item:any) => item.status == 'Rejected' || item.status == 'Approved');
    if(totalStatus.length == (this.tempFileset).length){
      this.submitStatus = false;
      this.allComplete = false;
    }
    this.rejectApproveStatus = false;
  }

  datasetSubmit(){
    
    for (const i in this.files){
      this.before_filter[i] = this.files[i];
    }
   
    const rejectFileCount = (this.files).filter(((obj:any) => obj.status === 'Rejected')).length; 
    
    if(rejectFileCount > 0){
      const dialogRef = this.matDialog.open(RejectFilesComponent, {
        width: '450px',
        height: '300px',
        data: {
          isEdit: true,
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.rejectionReason = result;  
          this.filterFiles();          
        } else{
          for (const i in this.files){
            this.files[i]=this.before_filter[i]; 
          }
        }     
      })
    } else {  
      this.filterFiles();
    }   
    
  }

  filterFiles(){
    for (const i in this.files){
      const {completed,status, ...filterFiles} = this.files[i];
      this.files[i].approval_status = this.files[i].status;
      this.files[i] = filterFiles;            
    }  
    this.myApprovalSubmit();
  }

  myApprovalSubmit(){
    this.isLoading = true;
    // obj
    const obj = {
      datasetName: this.datasetDetailsDataSource.datasetName,
      datasetId: this.datasetDetailsDataSource.datasetId,        
      datasetDescription: this.datasetDetailsDataSource.datasetDescription,
      datasetSize: this.datasetDetailsDataSource.datasetSize,
      createdDate: this.datasetDetailsDataSource.createdDate,
      createdBy: this.datasetDetailsDataSource.createdBy,
      requestedBy: this.datasetDetailsDataSource.requestedBy,
      userId: this.datasetDetailsDataSource.userId,
      approvedBy: this.userEmail,
      modifiedDate: this.datasetDetailsDataSource.modifiedDate,
      ingressedDate: this.datasetDetailsDataSource.ingressedDate,
      siteName: this.queryParamsData.siteName,
      projectName: this.datasetDetailsDataSource.projectName,
      projectId: this.datasetDetailsDataSource.projectId,
      region: this.datasetDetailsDataSource.region,        
      orgName: this.datasetDetailsDataSource.orgName,
      orgId: this.datasetDetailsDataSource.orgId,
      dataMaturity: this.datasetDetailsDataSource.dataMaturity,
      dataClassification: this.datasetForm.controls['dataClassification']?.value,
      dataFilesCount: this.datasetDetailsDataSource.dataFilesCount,     
      filesList: this.files,
      remarks: (this.rejectionReason) ? this.rejectionReason : this.datasetDetailsDataSource.remarks,
      status: this.datasetDetailsDataSource.status,
      }
    // end 

    this.projectService.approveDataset(obj).subscribe((res: any) => {     
      this.backToProjectDetails();
      
      this.isLoading = false;      
      this.toastrService.success(res.messages);
   },
     err => {
      this.isLoading = false;
      for (const i in this.files){
        this.files[i]=this.before_filter[i]; 
      }
      this.toastrService.error("Unable to submit the decision made on dataset. Please try again.");
    });
  }

  backToProjectDetails(){
    this.router.navigate(['projectmanagement/projectdetails'], {
      queryParams: {
        projectKey: this.queryParamsData.projectKey,
        orgKey: this.queryParamsData.orgKey,
        projectName: this.queryParamsData.projectName,
        region: this.queryParamsData.region,
        projectDescription: this.queryParamsData.projectDescription,
        dataClassification: this.queryParamsData.dataClassification,
        publishToOrgData: this.queryParamsData.publishToOrgData,
        createdDate: this.queryParamsData.createdDate,
        modifiedDate: this.queryParamsData.modifiedDate,
        createdBy: this.queryParamsData.createdBy,
        siteName: this.queryParamsData.siteName, 
        roleId: this.queryParamsData.roleId,        
        selectProjectTabIndex: 4,
        from: 'noPopup'
      }
    });
  }

}
