<div class="projectAuditresult-popup cmn_tbl container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{i18n.projectAudit.AR_POPUP.TITLE_DAR}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <!-- Document Audit Result -->
                <div class="col-6">
                    <p>{{i18n.projectAudit.AR_POPUP.TITLE_DAR}}</p>
                    <h4>{{projectAuditHistory.auditResult.auditResult}}</h4>
                </div>
                <!-- Audited Date -->
                <div class="col-6">
                    <p>{{i18n.projectAudit.AR_POPUP.TITLE_AD}}</p>
                    <h4>{{projectAuditHistory.latestAuditDate|date:'y-MMM-dd'}}</h4>
                </div>
            </div>

            <div class="row">
                <!-- Comments -->
                <div class="col mt-4">
                    <p>{{i18n.projectAudit.AR_POPUP.TITLE_CMT}}</p>           
                   <h4>{{projectAuditHistory.auditResult.comments}}</h4>
                </div>
            </div>

            <div class="row">
                <!-- Additional Eamil to notify -->
                <div class="col mt-4">
                    <p>{{i18n.projectAudit.AR_POPUP.TITLE_AEN}}</p>
                    <h4 *ngIf="projectAuditHistory.auditResult.additionalEmailAddress.length===0">{{'-'}}</h4>
                    <h4 *ngIf="projectAuditHistory.auditResult.additionalEmailAddress.length>0" class="text-break">{{projectAuditHistory.auditResult.additionalEmailAddress}}</h4>
                </div>
            </div>

            <div class="row">
                <!-- Attachment -->
                <div class="col mt-4">
                    <p>{{i18n.projectAudit.AR_POPUP.TITLE_ATCH}}</p>
                    <div *ngIf="!dataSource.data"  class="no-data empty-dta">{{i18n.LBL_NDF}}</div>
                    <mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length > 0" matSort
                        matSortActive="attachments" matSortDirection="asc" matSortDisableClear>

                        <!-- File Name Column-->
                        <ng-container matColumnDef="attachments">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{i18n.projectAudit.AR_POPUP.TBL_HDR_FN}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element}}
                            </mat-cell>
                        </ng-container>

                        <!-- Action Button Column -->
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="action-header">
                                {{i18n.projectAudit.TBL_HEADER.HDR_ATN}}
                            </mat-header-cell>
                            <mat-cell class="action-cell" *matCellDef="let element">
                                <button mat-stroked-button (click)="onDownload(element)">{{i18n.BTN_DOWNLOAD}}</button>
                            </mat-cell>
                        </ng-container>

                        <!-- Table header -->
                        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div mat-dialog-actions>
                <button mat-stroked-button (click)="onCancel()">Close</button>
            </div>
        </mat-card-footer>
    </mat-card>
</div>