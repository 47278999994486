<div class="confirmDialog" *ngIf="sessionOrIdle.idleTimeOut">
  <h1 mat-dialog-title>{{i18n.SESSION_IA}}</h1>
  <div mat-dialog-content *ngIf="sessionOrIdle.idleTimeOut">
    <p>{{i18n.SESSION_IDLE_DESC1}} {{ timeRemaining$ | async | date:'s' }} {{i18n.SESSION_DESC2}}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick('idle_no')" [mat-dialog-close]="'idle_no'" mat-stroked-button
      color="primary">{{i18n.BTN_NO}}</button>
    <button mat-button (click)="onYesClick('idle_yes')" [mat-dialog-close]="'idle_yes'" mat-raised-button color="primary"
      cdkFocusInitial>{{i18n.BTN_YES}}</button>
  </div>
</div>

<div class="confirmDialog" *ngIf="sessionOrIdle.sessionOut">
  <h1 mat-dialog-title>{{i18n.SESSION_IA}}</h1>
  <div mat-dialog-content>
    <p>{{i18n.SESSION_IDLE_DESC1}} {{ timeRemaining$ | async | date:'s' }} {{i18n.SESSION_DESC2}}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick('session_no')" [mat-dialog-close]="'session_no'" mat-stroked-button
      color="primary">{{i18n.BTN_NO}}</button>
    <button mat-button (click)="onYesClick('session_yes')" [mat-dialog-close]="'session_yes'" mat-raised-button color="primary"
      cdkFocusInitial>{{i18n.BTN_YES}}</button>
  </div>
</div>