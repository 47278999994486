<!-- Page Loader -->
<div class="loadingcontainer my-approval-loader">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<div class="cont project-file configure-audit-settings">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto backbutton">
                        <mat-icon class="backbutton" (click)="backToProjectDetails()">{{i18n.ICONS.CLOSE}}</mat-icon>
                    </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-sub-title">{{(auditConfigData !== null) ? 'Update Audit Settings' : 'Configure Audit Settings'}}</span>
                    </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <form [formGroup]="auditForm">
        <div class="container-fluid">
        <div class="row">
            <div class="col-8 shadow-1 border bg-white mx-auto mt-4 p-3">
                <div class="me-2 project-sub-title fs-6 mb-4">{{(auditConfigData !== null) ? 'Update Audit Settings' : 'Configure Audit Settings'}}</div>
                <div class="row">
                    <div class="col-4">
                        <mat-grid-list cols="1" rowHeight="50px">
                            <mat-form-field appearance="fill">
                                <mat-label>Next Audit Date</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="firstAuditDate" [min]="maxDate" placeholder="mm/dd/yyyy" (dateChange)="datePicker()">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                        </mat-grid-list>
                        
                    </div>
                    <div class="col-4" *ngIf="auditConfigData !== null">
                        <mat-grid-list cols="1" rowHeight="60px">
                            <mat-grid-tile>
                                <mat-label>
                                    <p class="mb-1">Last Audit Date</p>
                                    <h4>{{auditConfigData.latestAuditDate ? (auditConfigData.latestAuditDate | date : 'y-MMM-dd') : '-'}}</h4>                                      
                                </mat-label>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-grid-list cols="1" rowHeight="50px">
                            <mat-grid-tile class="cadence">
                                <mat-form-field appearance="fill">
                                    <mat-label>Cadence for Audit</mat-label>
                                    <mat-select formControlName="cadenceForAudit" (selectionChange)="cadenceValue()" [compareWith]="compareById">
                                    <mat-option *ngFor="let data of cadence" [value]="data">
                                        {{data}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-grid-tile>                            
                        </mat-grid-list>
                        <div class="small ms-2 text-muted">The duration between two consecutive audits</div>
                    </div>
                    <div class="col-4" [class]="{'display-none': numberOfDaysDisplay === false}">
                        <mat-grid-list cols="1" rowHeight="50px" >
                            <mat-grid-tile>
                                <div>
                                    <mat-form-field appearance="fill">
                                        <mat-label class="select-lable">Specify Number of days</mat-label>
                                        <input matInput formControlName="numberOfDays" (keyup)="onKeyup($event)" maxlength="3" />                                        
                                    </mat-form-field>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list> 
                        <div class="small ms-2 text-danger" *ngIf="auditForm.get('numberOfDays')?.errors?.['alphabet']">
                            Enter number of days between 1 and 730
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </form>

</div>

<div class="container-fluid">
<div class="row footer">
    <div class="col-8 mx-auto text-end py-2">
        <button mat-stroked-button class="me-3" (click)="backToProjectDetails()">{{i18n.BTN_CANCEL}}</button>
        <button mat-raised-button [disabled]="!auditForm.valid" *ngIf="!editStatus" (click)="submitAuditSettings()">{{(auditConfigData !== null ? 'Update' : 'Submit')}}</button>
        <button mat-raised-button [disabled]="updateButtonDisabled" (click)="submitAuditSettings()" *ngIf="editStatus">Update</button>
    </div>
</div>
</div>


