<!-- Page Loader -->
<div class="loadingcontainer my-approval-loader">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<div class="cont project-file">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto backbutton">
                        <mat-icon class="backbutton" (click)="backToProjectDetails()">{{i18n.ICONS.BACK}}</mat-icon>
                    </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-sub-title">{{i18n.projectMnt.MA.TITLE}}</span>
                        <span class="me-2 fw-normal pipe-separator">|</span> <span
                            class="fs-6 project-sub-title">{{queryParamsData.projectName}}</span>
                    </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>



    <div class="cont-fluid cmn_tbl dataset-details">

        <!-- breadcrumb -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}} {{queryParamsData.projectName}}</a>
                </li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a (click)="backToProjectDetails()">{{i18n.BREADCRUMB.MA}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li>
                    {{i18n.BREADCRUMB.DD}}
                </li>
            </ol>
        </nav>
        
        <div class="row">
            <div class="col">                
                    <mat-card class="bsn">
                        <mat-card-header>
                            <mat-card-title>
                                {{i18n.projectMnt.MA.DD.TITLE}}       
                            </mat-card-title>                           
                        </mat-card-header>  
                        <mat-card-content>
                        <mat-grid-list cols="2" rowHeight="100px" align="left">
                            <mat-grid-tile>
                                <div>
                                    <h5>{{i18n.projectMnt.MA.DD.LBL_DN}} </h5>
                                    <h3>{{datasetDetailsDataSource.datasetName}}</h3>
                                </div>
                            </mat-grid-tile>
                                <mat-grid-tile>
                                    <div>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_DD}} </h5>
                                        <h3>{{datasetDetailsDataSource.datasetId}}</h3>
                                    </div>
                                </mat-grid-tile>
                        </mat-grid-list>

                        <mat-grid-list cols="2" rowHeight="80px" >
                            <mat-grid-tile>
                                <div>
                                    <h5>{{i18n.projectMnt.MA.DD.LBL_CB}} </h5>
                                    <h3>{{datasetDetailsDataSource.createdBy}}</h3>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>

                        <mat-grid-list cols="2" rowHeight="80px" >
                            <mat-grid-tile>
                                <div>
                                    <h5>{{i18n.projectMnt.MA.DD.LBL_DC}} </h5>
                                    <h3>{{datasetDetailsDataSource.createdDate | date : 'y-MMM-dd HH:mm:ss'}}</h3>
                                </div>
                            </mat-grid-tile>
                                <mat-grid-tile>
                                    <div>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_DI}} </h5>
                                        <h3>{{datasetDetailsDataSource.ingressedDate ? (datasetDetailsDataSource.ingressedDate | date : 'y-MMM-dd HH:mm:ss') : "-"}}</h3>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_PN}}</h5>
                                        <h3> {{datasetDetailsDataSource.projectName}}</h3>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_ON}}</h5>
                                        <h3> {{datasetDetailsDataSource.orgName}}</h3>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_DM}}</h5>
                                        <h3> {{datasetDetailsDataSource.dataMaturity}}</h3>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_SN}}</h5>
                                        <h3 *ngIf="datasetDetailsDataSource.siteName"> {{datasetDetailsDataSource.siteName}}</h3>
                                        <h3 *ngIf="!datasetDetailsDataSource.siteName"> {{'N/A'}}</h3>
                                    </mat-label>
                                </mat-grid-tile>
                        </mat-grid-list>
                        <mat-grid-list cols="1" rowHeight="100px" >
                            <mat-grid-tile>
                                <mat-label>
                                    <h5>{{i18n.projectMnt.MA.DD.LBL_DSD}} </h5>
                                    <div class="desc-inner-div">
                                        {{datasetDetailsDataSource.datasetDescription}}
                                    </div> 
                                </mat-label>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-grid-list cols="2" rowHeight="70px">
                            <mat-grid-tile>
                                <div>
                                    <form [formGroup]="datasetForm">
                                    

                                        <mat-form-field appearance="fill">
                                            <mat-label>{{i18n.projectMnt.MA.DD.LBL_DCL}}</mat-label>
                                            <mat-select [disabled]="editDisableMode" name="dataClassification" formControlName="dataClassification" [compareWith]="compareById" (selectionChange)="onChange($event)">
                                                <mat-option *ngFor="let classfic of dataClassification" [value]="classfic">
                                                    {{classfic}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </form>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>

                        <mat-grid-list cols="2" rowHeight="110px" >
                            <mat-grid-tile>
                                <div>
                                    <h5>{{i18n.projectMnt.MA.DD.LBL_NODF}} </h5>
                                    <h3>{{datasetDetailsDataSource.dataFilesCount}}</h3>
                                </div>
                            </mat-grid-tile>
                                <mat-grid-tile>
                                    <div>
                                        <h5>{{i18n.projectMnt.MA.DD.LBL_DS}}</h5>
                                        <h3>{{datasetDetailsDataSource.datasetSize | bytesToMB}}</h3>
                                    </div>
                                </mat-grid-tile>
                        </mat-grid-list>
                    </mat-card-content>
                    </mat-card>
                </div>            
            <div class="col">
                <div class="cmn_tbl">
                    <mat-card class="bsn">
                        <mat-grid-list class="border-bottom" cols="2" rowHeight="60px" >
                            <mat-grid-tile>
                                <mat-checkbox
                                [checked]="allComplete"
                                color="primary"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)" [disabled]="editDisableMode">
                                {{task.filename}}
                                </mat-checkbox>
                            </mat-grid-tile>
                            <mat-grid-tile class="search-field">                                
                                <input type="search" matInput placeholder="Search" class="border" (keyup)="applyFilter($event)" autocomplete="off" />                                
                            </mat-grid-tile>
                        </mat-grid-list>

                <div class="scrollbar" id="style-1">
                        <div *ngFor="let subtask of task.subtasks; index as i" class="border-bottom">
                            <mat-grid-list cols="2" rowHeight="55px" class="file-list" >
                                <mat-grid-tile>
                                    <ul>
                                        <li>
                                            <mat-checkbox [(ngModel)]="subtask.completed"
                                            (ngModelChange)="updateAllComplete()" [disabled]="editDisableMode">
                                            {{subtask.filename}}
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-icon class="close" *ngIf="( subtask.status === 'Rejected' )">{{i18n.ICONS.CLOSE}} {{subtask.completed}}</mat-icon>
                                        <mat-icon class="done" *ngIf="( subtask.status === 'Approved' )">{{i18n.ICONS.DONE}} {{subtask.completed}}</mat-icon>
                                    </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                        </div>
                        <mat-grid-list class="border-bottom" cols="2" rowHeight="60px" >
                            <mat-grid-tile>
                                <button mat-stroked-button class="userBtn me-3 px-4" (click)="rejectApprove('Rejected')" *ngIf="rejectApproveStatus == true">{{i18n.projectMnt.MA.DD.BTN_R}}</button>
                                <button mat-raised-button class="userBtn" (click)="rejectApprove('Approved')" *ngIf="rejectApproveStatus == true">{{i18n.projectMnt.MA.DD.BTN_A}}</button>
                            </mat-grid-tile><!-- && !datasetForm.valid -->
                            <mat-grid-tile class="search-field">
                                <button mat-raised-button [disabled]="submitStatus" (click)="datasetSubmit()">{{i18n.projectMnt.MA.DD.BTN_S}}</button>
                            </mat-grid-tile>
                        </mat-grid-list>

                    </mat-card>
                </div>
            </div>
        </div>
    </div>

</div>

