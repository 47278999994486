import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InviteuserComponent } from './inviteuser/inviteuser.component';
import { CoreMaModule } from 'src/app/shared/core-ma/core-ma.module';
import { ApproveuserComponent } from './approveuser/approveuser.component';

@NgModule({
  declarations: [
    UsermanagementComponent,
    InviteuserComponent,
    ApproveuserComponent   
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    CoreMaModule,
    FormsModule,
    ReactiveFormsModule,
  ]  
})
export class UserManagementModule { }
