<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<div class="create-dataset">
    <mat-grid-list cols="1" rowHeight="50px">
        <mat-grid-tile class="header-close">
            <a>
                <mat-icon (click)="backToProjectDetails()">{{i18n.ICONS.CLOSE}}</mat-icon>
            </a>
            <h3>{{i18n.projectMnt.DFL.LBL_CD}}</h3>
        </mat-grid-tile>
    </mat-grid-list>
    <div class="cont-fluid">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}}
                        {{projectName}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a (click)="backToProjectDetails()">{{i18n.BREADCRUMB.DI}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.DFO}}</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col">
                <div class="row">
                <mat-card class="container sFTP-Detail-container">
                    <h6 mat-dialog-title class="sFTP-Detail-title">{{i18n.projectMnt.DFL.LBL_SFTP}}
                        <mat-icon class="material-icons-outlined info-icon" 
                            matTooltip=" {{i18n.projectMnt.DFL.LBL_SPTP_TOOLTIP}}"
                            matTooltipPosition="right">
                            info
                        </mat-icon>
                    </h6>
                    <div class="sFTP-container">
                        <div class="sFTP-item">
                            <label class="labelrow">
                                <span class="sFTP-detail-label">{{i18n.projectMnt.DFL.LBL_URL}}</span>
                                <div class="sFTP-url-group">
                                    <mat-icon matTooltipPosition="above" matTooltip="Copy"
                                            (click)="copysFTPUrl(sftpUrl,'sFTP URL Copied Successfully')"
                                            class="material-icons-round icon-color">{{i18n.ICONS.CC}}</mat-icon>
                                        <span class="sFTP-detail-data">{{sftpUrl}}</span>
                                    </div>
                                </label>
                            </div>
                            <div class="sFTP-item" *ngIf="isRocheUser">
                                <label class="labelrow">
                                    <span class="sFTP-detail-label">{{i18n.projectMnt.DFL.LBL_AK}}</span>
                                    <div class="sFTP-url-group">
                                        <mat-icon matTooltipPosition="above" matTooltip="Copy"
                                            (click)="copysFTPUrl(authenticationKey,'Authentication Key Copied Successfully')"
                                            class="material-icons-round icon-color">{{i18n.ICONS.CC}}</mat-icon>
                                        <span class="sFTP-detail-data">{{authenticationKey}}</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="row">
                    <mat-card class="container fileupload-Detail-container">
                        <h6 mat-dialog-title class="fileupload-Detail-title">Upload from local</h6>
                        <div class="note-message-dialog">
                            <mat-icon class="material-icons-outlined info-icon-warn">info</mat-icon>
                            <mat-list role="list">
                                <mat-list-item role="listitem">Guidelines to upload,</mat-list-item>
                                <mat-list-item role="listitem">1.File types that are not allowed for upload: .exe
                                    Files</mat-list-item>
                                <mat-list-item role="listitem">2.Number of files allowed per upload: 100
                                    files</mat-list-item>
                                <mat-list-item role="listitem">3.Total file size allowed per upload: 160
                                    GB</mat-list-item>
                                <mat-list-item role="listitem">4.File size allowed per file: 100 GB</mat-list-item>
                                <mat-list-item role="listitem">5.Please follow the below instructions carefully while
                                    uploading,</mat-list-item>
                                <div class="bullet-list">
                                    <ul>
                                        <li>Do not click on Refresh or back button on browser</li>
                                        <li>Do not log off or close the browser until all pending file uploads complete.
                                        </li>
                                        <li>You can navigate to other apps (e.g. the Data Catalog) within starCAP as
                                            usual.</li>
                                        <li>Also, make sure network connectivity is good and device has enough battery
                                            charge to complete file upload.</li>
                                        <!-- <li>Drag and drop here - section to drag the files and or folders from local
                                            system</li> -->
                                    </ul>
                                </div>
                            </mat-list>
                        </div>
                        <div [ngClass]="[checkActiveUpload(fileUploadStatusData) ? 'disable-upload' : '']">
                            <app-file-upload #fileUpload 
                                [templateVersion]="'v2'"
                                [resetOnLoad]="false"
                                [userUuid]="userUuid"
                                [projectKey]="projectKey"
                                [maxFilesAllowed]="100" 
                                [maxParallelFileUploads]="4"
                                [maxParallelChunksUpload]="10"
                                [maxChunkSizeInBytes]="209715200"
                                [maxFileSizeAllowedInGB]="100"
                                [maxCumulativeSizeAllowedInGB]="160" 
                                [restrictedExtensions]="['.exe']"
                                [allowedExtensions] = "[]"
                                [fileUploadApis]='fileUploadApis' 
                                [dataForFileUpload]="dataForFileUpload"
                                [dataForEachFileInUpload]="dataForEachFile" 
                                [Identifier]="'filesDetailList'"
                                (completedFileEventReceiver) = "fileUploadCompletionEvent($event)"
                                [uploadStartToastMessage]="'Upload Started. Click on Show Upload Status to view the progress.'"
                                ></app-file-upload>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="col">
                <mat-card class="container data-file-overview-container">
                <div class="header">
                    <div class="row">
                        <div class="col-12">
                            <div class="datadomain-overview-label">
                                <h2 class="ps-2">{{i18n.projectMnt.DFL.LBL_DFO}}</h2>
                            </div>
                            <div class="search-btn-div create-button" *ngIf="!displayNoRecordsFound && !tableToggle">
                        
                                <!-- <button mat-raised-button (click)="toggleSelectAll()"
                                    class="selection-toggle-button float-start me-3 mt-2">
                                    {{ isAllSelected() ? 'Deselect All' : 'Select All' }} <span>({{selection.selected.length}})</span>
                                </button> -->
        
                                <button mat-raised-button (click)="selectAllBox()"
                                    class="selection-toggle-button float-start me-2 mt-2" [disabled]="selectAllDisable && dataLength === selection.selected.length">Select All
                                    <span>{{!selectAllDisable || dataLength !== selection.selected.length ? '('+dataLength+')': ''}}</span>
                                </button>
                                <button mat-raised-button (click)="deSelectAllBox()"
                                    class="selection-toggle-button float-start me-2 mt-2" [disabled]="selection.selected.length === 0">
                                    <span >Deselect All {{(selection.selected.length !== 0) ? '('+dataLength+')': ''}}</span>
                                </button>
                                <mat-icon class='me-2 mt-3'(click)="onRefresh()">refresh</mat-icon>
                       
                               
                               
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-slide-toggle class="mat-slider-css"  [color]="'primary'" [(ngModel)]="tableToggle" value="primary">
                                 Show Upload Status
                            </mat-slide-toggle>
                           
                            <!-- <div class="search-btn-div create-button" *ngIf="!displayNoRecordsFound && tableToggle">
                            <mat-form-field appearance="fill" class="search-form">
                                <mat-label>{{i18n.projectMnt.DFL.LBL_SH}}</mat-label>
                                <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchTerm" />
                                <mat-icon matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon>
                                <mat-icon matSuffix *ngIf="showSearchClearBtn" (click)="onSearchCancelBtn()">{{i18n.ICONS.CANCEL}}</mat-icon>
                            </mat-form-field>
                            </div> -->
                            <div class="search-group-new" *ngIf="!displayNoRecordsFound && !tableToggle">
                                <input class="my-input" matInput placeholder="Search" autocomplete="off"
                                    (keyup)="applyFilter($event)"  [(ngModel)]="searchTerm"/>
                                <mat-icon class="search-icon" matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <mat-card class="container data-file-overview-container"> -->

                    <mat-card-content>
                        <div class="no-data empty-dta" *ngIf="tableToggle && toList(fileUploadStatusData).length === 0">
                            <p [innerHTML]="i18n.projectMnt.DFL.NDF"></p>
                        </div>

                        <div class="no-data empty-dta" *ngIf="displayNoRecordsFound && !tableToggle">
                            <mat-icon class="material-icons-outlined white-icon">{{i18n.ICONS.DESC}}</mat-icon>
                            <p [innerHTML]="i18n.projectMnt.DFL.DESC"></p>
                            <button mat-button mat-stroked-button class="refresh-button" (click)="onRefresh()">{{i18n.BTN_REFRESH}}</button>
                        </div>

                        <div [hidden]="!tableToggle" class="create-dataset-table-container">
                            <div class="table-container" >
                        
                        
                                <table mat-table [dataSource]="toList(fileUploadStatusData)"
                                *ngIf="toList(fileUploadStatusData).length !== 0" 
                                    class="mat-elevation-z0">
                        
                                    <!--Checkbox column-->
                                    <!-- <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox 
                                    
                                                [disabled]="true">
                                            
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox 
                                  
                                                [disabled]="true">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container> -->
                        
                                    <!--File Name column-->
                                    <ng-container matColumnDef="filename">
                                        <th mat-header-cell *matHeaderCellDef class="p-right">
                                            File Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="p-right">
                                            <div class="file-name-ellipsis">
                                                <span 
                                                matTooltip="{{element?.path}}"
                                                [matTooltipShowDelay]="500"
                                                (disableTooltipInteractivity)="true">
                                                    {{element?.path}}
                                                </span>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date_modified">
                                        <th mat-header-cell *matHeaderCellDef class="p-right">
                                            Uploaded Date/Time
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="p-right">
                                            <div  class="failed_status" *ngIf="element.status === 'FAILED'">
                                                    Upload Failed!
                                            </div>
                                            <div class="mat-column-upload-data" *ngIf="element.status !== 'FAILED'">
                                                <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                                                value="{{element.fileProgress}}"></mat-progress-bar>
                                            </div>
                                            
                                        </td>
                                    </ng-container>
                                    <!-- <div *ngIf ="(toList(fileUploadStatusData)).length!=0" > -->
                                        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumnsProgress; sticky:true"></tr> -->
                                    <!-- </div> -->
                                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr> -->
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsProgress;">
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div [hidden]="tableToggle" class="create-dataset-table-container">
                            <div class="table-container" >
                                <table mat-table [dataSource]="dataSource" *ngIf="!displayNoRecordsFound"
                                    class="mat-elevation-z0">
                                    <!--Checkbox column-->
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="($event ? toggleAllRows() : null)"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="($event ? selection.toggle(row) : null)"
                                                [checked]="selection.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
        
                                    <!--File Name column-->
                                    <ng-container matColumnDef="filename">
                                        <th mat-header-cell *matHeaderCellDef class="p-right">
                                            File Name
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="p-right">
                                            <div class="mat-column-file-name file-name-ellipsis">
                                                <span [matTooltipShowDelay]="500" matTooltip="{{element?.keyname?.split('/').slice(2).join('/')}}">
                                                    {{element?.keyname?.split('/').slice(2).join('/')}} </span>
                                            </div>
                                        </td>
                                    </ng-container>
        
                                    <!--File Extension column-->
                                    <!-- <ng-container matColumnDef="fileextension">
                                        <th mat-header-cell *matHeaderCellDef class="p-right">
                                            File Extension
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="p-right">
                                            <div class="mat-column-file-extension">
                                                {{element?.file_extension}}
                                            </div>
                                        </td>
                                    </ng-container> -->
        
                                    <!--uploaded date/time column-->
                                    <ng-container matColumnDef="date_modified">
                                        <th mat-header-cell *matHeaderCellDef class="p-right">
                                            Uploaded Date/Time
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="p-right">
                                            <div class="mat-column-upload-data">
                                                {{element?.date_modified | date: "yyyy-MMM-dd HH:mm:ss"}}
                                            </div>
                                        </td>
                                    </ng-container>
        
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        >
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-footer class="pagination-footer">
                        <mat-paginator [hidden]="tableToggle && !displayNoRecordsFound"   [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                            [hidden]="displayNoRecordsFound">
                        </mat-paginator>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>


    </div>
</div>
<div class="footer px-3" align="right">
    <button mat-raised-button class="data-file-cancel-button"
        (click)="backToProjectDetails()">{{i18n.BTN_CANCEL}}</button>
    <button mat-raised-button [disabled]="selection.selected.length === 0 || selection.selected.length > 200"
        (click)="onProceed()">{{i18n.BTN_PROCEED}}</button>
</div>