<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class mx-auto">
        </mat-progress-spinner>
    </div>
</div>
<div class="cont-fluid cmn_tbl edit_collaborator">
    <mat-card>
        <!-- <mat-card-header> -->
        <mat-card-title>
            <h3>{{i18n.projectMnt.collaborators.EC.TITLE}}</h3>
        </mat-card-title>

        <mat-card-content>
            <mat-grid-list cols="3" rowHeight="100px">
                <mat-grid-tile>
                    <mat-label>
                        <p>{{i18n.projectMnt.collaborators.EC.LBL_U}}</p>
                        <h4> {{projectDetailsList.userEmail}}</h4>
                    </mat-label>
                </mat-grid-tile>

                <mat-grid-tile>
                    <mat-label>
                        <p>{{i18n.projectMnt.collaborators.EC.LBL_ON}}</p>
                        <h4> {{projectDetailsList.orgName}}</h4>
                    </mat-label>
                </mat-grid-tile>

                <mat-grid-tile>
                    <mat-label>
                        <p>{{i18n.projectMnt.collaborators.EC.LBL_PN}}</p>
                        <h4> {{projectDetailsList.projName}}</h4>
                    </mat-label>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="3" rowHeight="100px">
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    <mat-label>
                        <p>{{i18n.projectMnt.collaborators.EC.LBL_CR}}</p>
                        <h4> {{projectDetailsList.roleName}}</h4>
                    </mat-label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    <mat-label>
                        <mat-form-field appearance="fill">
                            <mat-label>{{i18n.projectMnt.collaborators.EC.LBL_SNR}}</mat-label>
                            <mat-select (selectionChange)="onChange($event.value)">
                                <mat-option *ngFor="let list of exceptCurrentRoles" [value]="list.roleName"
                                    [disabled]="(isDataControllerExist == true &&  list.roleName.toLowerCase() === role.data_controller) || list.roleName.toLowerCase() === role.imcore_member_data_ingress_only">
                                    {{list.roleName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-label>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
        
    </mat-card>
    <mat-card-footer>        
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onCancel()">{{i18n.BTN_CANCEL}}</button>
            <button mat-raised-button [disabled]="showUpdate" (click)="onUpdate()">{{i18n.BTN_UPDATE}}</button>
        </div>       
</mat-card-footer>
</div>