import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { projectCreation ,StepperObj5,Review } from '../model/model';
import { ProjectManagementService } from '../../project-management.service';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-review-project-proposal',
  templateUrl: './review-project-proposal.component.html',
  styleUrls: ['./review-project-proposal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewProjectProposalComponent {
  projectCreation = projectCreation;
  review:FormGroup;
  @Input() formDataObj:StepperObj5;
  @Input() currentStepIndex: number;
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() validateInprogress: boolean;
  @Input() setExitingData:Review;
  @Output() nextStep: EventEmitter<string> = new EventEmitter<string>();
  @Output() previousStep: EventEmitter<null> = new EventEmitter<null>();
  @Output() formDataChanged: EventEmitter<string> = new EventEmitter<string>();
  constructor(public readonly service: ProjectManagementService,
    private readonly storageService: StorageService) { 
    this.review = new FormGroup({
      additionalEmails: new FormControl(null, [this.commaSepEmail]),
      reviewStatus:new FormControl(null),
      approvedBy:new FormControl(null),
      approvedOn:new FormControl(null)
    });
   
  }

   //custom validation for Multiple mail check
   commaSepEmail(control: AbstractControl) {
  const emails = control.value?.split(',').map((e:string)=>e.trim());
     const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     const forbidden1 = emails?.some((email: string) => (emailPattern.test(email) === false));
  const forbidden2 = emails?.length >= projectCreation.review.maxlimit
  if (forbidden1) {
    return { emailValidation: true };
  }
  else if(forbidden2){
    return {emailCountValidation: true  };
  }
  return null;
    }
 
  get f() {
    return this.review.controls;
  }
 
  
  onClickSaveandExit() {
    const profile = JSON.parse(this.storageService.secureStorage.getItem('profile'))
    this.review.patchValue({ reviewStatus: "Save and Exit",approvedBy:profile.username,approvedOn:new Date() });
    this.formDataChanged.emit();
  }
  onApprove() {
   const profile = JSON.parse(this.storageService.secureStorage.getItem('profile'))
    this.review.patchValue({ reviewStatus: "Approve",approvedBy:profile.username,approvedOn:new Date() });
    this.formDataChanged.emit('approved');
  }
  onclickPrevious() {
      this.previousStep.emit();
  }
  
}
