import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TcGuard implements CanActivate {
  trianingStatus = this.storageService.secureStorage.getItem('trianingStatus');
  tcAccepted = JSON.parse(this.storageService.secureStorage.getItem('tcAccepted'));
  makeIt = false;
  constructor(private router: Router, private storageService: StorageService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.trianingStatus === 'completed' && this.tcAccepted === true){
        this.makeIt = true;  
        //  console.log('both TC:', this.trianingStatus, this.tcAccepted)      
      } else{
        this.router.navigate(['/404']); 
      }       
      return this.makeIt;     
  }
  
}
