import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  constructor(private router: Router, private storageService: StorageService) { }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const currentUser = this.storageService.secureStorage.getItem('isLoggedIn');
    //console.log(currentUser)

    /* istanbul ignore else */
    if (currentUser === 'true') {      
      return true;
    }
    else {
      this.router.navigate(['/login'], {replaceUrl: true});
      return false;
      /* //alert(`AuthGuard: ${_state.url}`)
      return this.router.navigate(['/'],
        {
          queryParams: {
            returnUrl: _state.url
          }
        }); */
    }
  }
}