import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ErrorIntercept } from './services/error.interceptor';
import { AuthguardService } from './services/authguard.service';
import { LoginComponent } from './pages/login/login.component';
import { CoreMaModule } from './shared/core-ma/core-ma.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './core/header/header.component';
import { UserManagementModule } from './pages/user-management/user-management.module';
import { OrganizationManagementModule } from './pages/organization-management/organization-management.module';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { ApplobbyComponent } from './pages/applobby/applobby.component';
import { ProjectModule } from './pages/project-management/project.module';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { NavifyauthService } from './services/navifyauth.service';
import { RoleGuard } from './services/role.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { CommonService } from './services/common.service';
import { TcGuard } from './services/tc.guard';
import { ChildroleGuard } from './services/childrole.guard';
import { ProjectAuditModule } from './pages/project-audit/project-audit.module';
import { CwrErrorHandler } from './cwr-error-handler';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './services/custom-route-reuse-strategy';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    TermsConditionsComponent,
    ApplobbyComponent,
    LogoutComponent,
    NotfoundComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreMaModule,
    FormsModule,
    ReactiveFormsModule,
    UserManagementModule,
    OrganizationManagementModule,
    ProjectModule,
    HttpClientModule,
    ProjectAuditModule,
    ToastrModule.forRoot({
      closeButton: true,
      disableTimeOut: false,
      preventDuplicates: true,
      timeOut:5000,
      extendedTimeOut:1000,
      progressBar:true,
      tapToDismiss:true,
      newestOnTop:true
    }),
    // ProjectModule
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    },
    AuthguardService,
    NavifyauthService,
    RoleGuard,
    ChildroleGuard,
    CommonService,
    TcGuard,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: CwrErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
