<div class="loadingcontainer create-project-loader">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>
<div class="create-project">

    <mat-grid-list cols="1" rowHeight="50px">
        <mat-grid-tile class="header-close">
            <a>
                <mat-icon (click)="onDecline()" *ngIf="!isEditProject">{{i18n.ICONS.CLOSE}}</mat-icon>
            </a>
            <a>
                <mat-icon (click)="onCancelOrBack()" *ngIf="isEditProject">{{i18n.ICONS.CLOSE}}</mat-icon>
            </a>
            <h3 *ngIf="!isEditProject">{{i18n.projectMnt.CP.TITLE_AP}}</h3>
            <h3 *ngIf="isEditProject">{{i18n.projectMnt.CP.TITLE_EP}}</h3>
        </mat-grid-tile>
    </mat-grid-list>

    <div class="cont-fluid cmn_tbl">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb" *ngIf="!isEditProject">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}} {{orgKey}}</a>
                </li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.AP}}</li>
            </ol>
            <ol class="breadcrumb" *ngIf="isEditProject">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}}
                        {{editData.projectName}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.EPD}}</li>
            </ol>
        </nav>

        <mat-card class="container inner-section">
            <h2 *ngIf="!isEditProject">{{i18n.projectMnt.CP.TITLE_AP}} </h2>
            <h2 *ngIf="isEditProject">{{i18n.projectMnt.CP.TITLE_EP}}</h2>
            <form [formGroup]="createProject">
                <mat-grid-list cols="3" rowHeight="70px">
                    <mat-grid-tile class="sub-title">{{i18n.projectMnt.CP.LBL_PI}}</mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>{{i18n.projectMnt.CP.LBL_PN}}</mat-label>
                            <input matInput formControlName="projectName" autocomplete="off" minlength="5"
                                maxLength="20" required (keyup)="errorPopup($event, 'project-name')"
                                (focusout)="onFocusOutEvent()" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <div class="error-popup" *ngIf="projectNameErrorPopupDisplay ? true : false">
                        <p>{{i18n.projectMnt.CP.ERR_PNM}}</p>
                        <div class="float-start small mb-1"
                            [ngClass]="createProject.get('projectName')?.errors?.['alphabet'] ? 'text-danger':'text-success'">
                            <mat-icon class="close"
                                *ngIf="createProject.get('projectName')?.errors?.['alphabet']">{{i18n.ICONS.CLOSE}}</mat-icon>
                            <mat-icon class="done"
                                *ngIf="!createProject.get('projectName')?.errors?.['alphabet']">{{i18n.ICONS.DONE}}</mat-icon>
                            {{i18n.projectMnt.CP.ERR_2}}
                        </div>
                        <div class="float-start small mb-1"
                            [ngClass]="createProject.get('projectName')?.errors?.['alphanum'] ? 'text-danger':'text-success'">
                            <mat-icon class="close"
                                *ngIf="createProject.get('projectName')?.errors?.['alphanum']">{{i18n.ICONS.CLOSE}}</mat-icon>
                            <mat-icon class="done"
                                *ngIf="!createProject.get('projectName')?.errors?.['alphanum']">{{i18n.ICONS.DONE}}</mat-icon>
                            {{i18n.projectMnt.CP.ERR_3}}
                        </div>
                        <div class="float-start small mb-1"
                            [ngClass]="(createProject.get('projectName')?.errors?.['specialchar']||createProject.get('projectName')?.errors?.['alphanum']) ? 'text-danger':'text-success'">
                            <mat-icon class="close"
                                *ngIf="createProject.get('projectName')?.errors?.['specialchar']||createProject.get('projectName')?.errors?.['alphanum']">{{i18n.ICONS.CLOSE}}</mat-icon>
                            <mat-icon class="done"
                                *ngIf="!createProject.get('projectName')?.errors?.['specialchar']&&!createProject.get('projectName')?.errors?.['alphanum']">{{i18n.ICONS.DONE}}</mat-icon>
                            <div class="beyond-5-err-div">{{i18n.projectMnt.CP.ERR_4}}</div>
                        </div>
                        <div class="float-start small mt-1"
                            [ngClass]="(maxProjectName) || createProject.get('projectName')?.errors?.['minlength'] ? 'text-danger':'text-success'">
                            <mat-icon class="close"
                                *ngIf="(maxProjectName) || createProject.get('projectName')?.errors?.['minlength']">{{i18n.ICONS.CLOSE}}</mat-icon>
                            <mat-icon class="done"
                                *ngIf="(!maxProjectName) && (!createProject.get('projectName')?.errors?.['minlength'])">{{i18n.ICONS.DONE}}</mat-icon>
                            {{i18n.projectMnt.CP.ERR_5}}
                        </div>
                    </div>

                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>{{i18n.projectMnt.CP.LBL_R}}</mat-label>
                            <mat-select name="region" formControlName="region" [compareWith]="compareByRegionValue"
                                [disabled]="isEditProject">
                                <mat-option *ngFor="let item of dataRegion" [value]="item">
                                    {{item.regionName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>

                <mat-grid-list cols="3" rowHeight="150px">
                    <mat-grid-tile> </mat-grid-tile>
                    <mat-grid-tile class="siteNames">
                        <mat-form-field appearance="fill">
                            <mat-label>{{i18n.projectMnt.CP.LBL_SN}}</mat-label>
                            <mat-select name="siteName" formControlName="siteName">
                                <mat-option *ngFor="let name of siteNameList" [value]="name">
                                    {{name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="sub-title">
                        <mat-form-field class="Project-Description" appearance="fill" *ngIf="!isEditProject">
                            <mat-label>{{i18n.projectMnt.CP.LBL_PD}}</mat-label>
                            <textarea matInput #postalCode formControlName="projectDescription"
                                maxLength="256"></textarea>
                            <mat-hint align="end">{{postalCode.value.length}} / 256</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="Project-Description" appearance="fill" *ngIf="isEditProject">
                            <mat-label>{{i18n.projectMnt.CP.LBL_PD}}</mat-label>
                            <textarea matInput #postalCodeEdit formControlName="projectDescription"
                                maxLength="2000"></textarea>
                            <mat-hint align="end">{{postalCodeEdit.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </mat-grid-tile>

                </mat-grid-list>

                <mat-divider class="divider border-1 border-bottom my-4"></mat-divider>

                <mat-grid-list cols="3" rowHeight="110px">
                    <mat-grid-tile class="sub-title">{{i18n.projectMnt.CP.LBL_OI}}</mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>{{i18n.projectMnt.CP.LBL_DC}}</mat-label>
                            <mat-select name="dataClassification" formControlName="dataClassification"
                                [compareWith]="compareById" required>
                                <mat-option *ngFor="let classfic of dataClassification" [value]="classfic">
                                    {{classfic.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <section class="data-catalog">
                            <p>{{i18n.projectMnt.CP.LBL_AP}} </p>
                            <mat-button-toggle-group aria-label="Font Style" [disabled]="!isEditProject"
                                formControlName="publishToOrgData">
                                <mat-button-toggle value="Yes">{{i18n.BTN_YES}}</mat-button-toggle>
                                <mat-button-toggle value="No">{{i18n.BTN_NO}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </section>
                    </mat-grid-tile>
                </mat-grid-list>

                <mat-grid-list cols="3" rowHeight="100px">
                    <mat-grid-tile> </mat-grid-tile>
                    <mat-grid-tile>
                        <section>
                            <p>{{i18n.projectMnt.CP.LBL_SAR}} </p>
                            <mat-button-toggle-group aria-label="Font Style" [disabled]="true">
                                <mat-button-toggle value="frequency">{{i18n.BTN_FREQ}}</mat-button-toggle>
                                <mat-button-toggle value="on-demand">{{i18n.BTN_OD}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </section>
                    </mat-grid-tile>
                    <mat-grid-tile> </mat-grid-tile>
                </mat-grid-list>

                <mat-grid-list cols="3" rowHeight="70px">
                    <mat-grid-tile> </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label class="auditDate">{{i18n.projectMnt.CP.LBL_SFAD}}</mat-label>
                            <input matInput [matDatepicker]="picker" [disabled]="true" />
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile> </mat-grid-tile>
                </mat-grid-list>
            </form>
        </mat-card>
    </div>
</div>
<div class="footer px-3" align="right">
    <button mat-raised-button class="decline" (click)="onCancel()" *ngIf="!isEditProject">{{i18n.BTN_CANCEL}}</button>
    <button mat-raised-button class="decline" (click)="onCancelOrBack()"
        *ngIf="isEditProject">{{i18n.BTN_CANCEL}}</button>
    <button mat-raised-button (click)="onAdd(createProject.value)" [disabled]="!createProject.valid"
        *ngIf="!isEditProject">{{i18n.BTN_ADD}}</button>
    <button mat-raised-button (click)="onEdit(createProject.value); submitButton.disabled = true" #submitButton
        [disabled]="!createProject.valid" *ngIf="isEditProject">{{i18n.BTN_UPDATE}}</button>
</div>