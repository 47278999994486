<div class="reject-files-card">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{i18n.projectMnt.MA.RF.TITLE}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="rejectFilesForm">
            <mat-form-field appearance="fill">
                    <mat-label>{{i18n.projectMnt.MA.RF.LBL_RR}}</mat-label>
                    <textarea matInput #message maxlength="256" formControlName="reason"></textarea>                    
                    <mat-hint>{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
        </form>
        </mat-card-content>
        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button (click)="onCancel()">{{i18n.projectMnt.MA.RF.BTN_CANCEL}}</button>
            <button mat-raised-button (click)="onSubmit()" [disabled]="!rejectFilesForm.valid">{{i18n.projectMnt.MA.RF.BTN_SUBMIT}}</button>
        </div>
        </mat-card-footer>
    </mat-card>
</div>