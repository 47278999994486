import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-remove-user',
  templateUrl: './remove-user.component.html',
  styleUrls: ['./remove-user.component.scss']
})
export class RemoveUserComponent implements OnInit {
  i18n = i18n;
  from: string;
  projectName: string;
  dataControllerCount: number = 0;
  hasDataControllerRole:boolean = false;
  constructor(public dialogRef: MatDialogRef<RemoveUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
              ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.from = this.data.from;
    this.projectName = this.data.projectName;
    this.dataControllerCount = this.data.dataControllerCount;
    this.hasDataControllerRole = this.data.hasDataControllerRole;
  }
  onCancel() {
    this.dialogRef.close('cancel');
  }

  onRemove(){
    this.dialogRef.close('yes');
  }
}