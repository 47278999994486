import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AdditionalFiles,projectCreation,FileObj} from '../../model/model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { ProjectManagementService } from '../../../project-management.service';
import { ActivatedRoute } from '@angular/router';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-additional-files-details',
  templateUrl: './additional-files-details.component.html',
  styleUrls: ['./additional-files-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class AdditionalFilesDetailsComponent implements OnInit,OnChanges {
  projectCreation = projectCreation;
  i18n = i18n;
  @Input() projectID: string;
  @Input() additionalFiles:AdditionalFiles | undefined;
  budgetdataSource;
  othersDataSource;
  emptyData;
  @ViewChild('budgetSort') budgetSort = new MatSort();
  @ViewChild('othersSort') othersSort = new MatSort();
  @ViewChild('budgetPaginator', { static: true }) budgetPaginator!: MatPaginator;
  @ViewChild('othersPaginator', { static: true }) othersPaginator!: MatPaginator;
  displayedColumns: string[] = ['fileName', 'action'];
  constructor(public readonly service: ProjectManagementService,
    public readonly activatedRoute: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
        this.budgetdataSource = new MatTableDataSource(this.additionalFiles?.budgetFiles);
        this.budgetdataSource.paginator = this.budgetPaginator;
        this.budgetdataSource.sort = this.budgetSort;
        this.othersDataSource = new MatTableDataSource(this.additionalFiles?.otherFiles);
        this.othersDataSource.paginator = this.othersPaginator;
        this.othersDataSource.sort = this.othersSort;
        this.emptyData = new MatTableDataSource([{ empty: "row" }])     
  }

  ngOnInit(): void {
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
  }
  downloadUrl(data){
    console.log(data)
  }
}

function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;
  return customPaginatorIntl;
}
