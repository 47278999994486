import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { StorageService } from './storage.service';
import { Env } from '@navify-platform/env';
import { AuthConf, Auth, AuthSession, AuthLoginReturn, AuthLogoutReturn, AuthEventType, AuthLoginReason, AuthLogoutReason } from '@navify-platform/auth';
import { Router } from '@angular/router';
import { Event } from '@navify-platform/event';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { IdleOptions, Idle, IdleEvent, IdleEventType } from '@navify-platform/idle';
import { ConfirmComponent } from '../shared/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { i18n } from '../i18n.en';

const env = new Env({
  props: {
    platformApiUrl: environment.platformApiUrl,  // Navify Platform URL
    authUiUrl: environment.authUiUrl,       // Authentication application URL
    appAlias: environment.appAlias,        // Client application alias
    tenantAlias: environment.tenantAlias,     // Client tenant alias
  },
});

const authConf: AuthConf = {
  env: env,                                 // Env library instance
  sessionExpirationBeforeTime: 60,  // How much time before expiration should the library emit the BEFORE_SESSION_EXPIRATION event (milliseconds)
  sessionPollingIntervalTime: 600,  // How often should the library send a polling request to check the existing session (milliseconds)
  sessionAutoRefresh: true,           // Flag deciding whether to refresh session automatically before session expiration
  redirectUrlUseHash: false,           // Flag deciding whether to use URL hash instead of query parameters
  identificationFormSkip: false,       // Flag deciding whether to force skip the identification step
  identificationFormSubmit: false,     // Flag deciding whether to submit the identification step if username is provided
  customPageTitle: 'starCAP',              // Page title customization HTML
  customBackgroundStyle: "url('${landingPage}') center no-repeat / cover",        // Background style customization CSS
  customHeader: '',                 // Header customization HTML
  customHeadline: '<strong>NAVIFY<sup>®</sup> starCAP</strong>',               // Headline customization HTML
  customCaption: '',                // Caption customization HTML
  customFooter: "<div class=''><a href='http://roche.com'>Roche</a></div>",                 // Footer customization HTML
  i18n: false,                         // Flag deciding whethet to internationalize the UI

};


const idleOptions: IdleOptions = {
  env: env,                             // environment, NavifyPlatformEnv instance
  syncTimeInterval: 1000 * 60 * 10,     // clock sync interval time (optional)
  tickTimeInterval: 1000 * 60 * 1,          // clock tick interval time (optional)
  idleTimeout: 1000 * 60 * 59,           // Idle timeout value override (optional)
  beforeIdleTimeSpan: 1000 * 60 * 1,        // how much time before idle the event is triggered (optional)
  aliveCallOpt: ({                      // alive call condition check function (optional)
    timeSinceAttempt,                   // * time passed since most recent alive attempt (sent request)
    timeSinceCall,                      // * time passed since most recent alive call (received response)
  }) => (
    timeSinceAttempt >= 1000 * 10 &&
    timeSinceCall >= 1000 * 30
  ),
  lastActivityCallOpt: ({               // last activity call condition check function (optional)
    timeSinceAttempt,                   // * time passed since most recent last activity attempt (sent request)
    timeSinceCall,                      // * time passed since most recent last activity call (received response)
    isBeforeIdle,                       // * is user in before idle state
  }) => (
    isBeforeIdle ? (
      timeSinceAttempt >= 1000 * 10 &&
      timeSinceCall >= 1000 * 10
    ) : (
      timeSinceAttempt >= 1000 * 60 &&
      timeSinceCall >= 1000 * 60
    )
  ),
  toggleSyncActivity: true,             // flag deciding whether to synchronize activity information via API
  toggleTracking: true,                 // flag deciding whether to turn on DOM events tracking
};

export const auth: Auth = new Auth(authConf);
export const idle = new Idle(idleOptions);

@Injectable({
  providedIn: 'root'
})
export class NavifyauthService {
  i18n  = i18n;
  unsubs: any;
  showConfirmWindow = false;
  userRole: string;
  orgRole: string;
  trianingStatus: string;
  tcAccepted: boolean;
  isNavifyRoleExist: string;
  valueSet: any;
  roleSet: any;
  isPlatformAdmin: boolean;
  constructor(private router: Router, private storageService: StorageService, private http: HttpClient,
    private commonService: CommonService, public dialog: MatDialog) {
      
  }  

  async setAuthSessionRenewal() {

    auth.subscribe((event: Event) => {
      // console.log(event.type);
      if (event.type === AuthEventType.beforeSessionExpiration) {
        setTimeout(async () => {
          await this.openConfirm(false, true);
        }, 2000);
      }
    });
  }


  async initiateAuth() {
    await auth.init();
    await idle.init();
    // await idle.markActivity();

    idle.subscribe((event: IdleEvent) => {
      console.log('idle: ',event);
      if (IdleEventType.BeforeIdle) {
        setTimeout(async () => {
          await this.openConfirm(true, false);
        }, 5000);
      }
      if (IdleEventType.Idle) {
        // console.log('Idle', event.data.isIdle);
        // this.logOut();
      }
      if (IdleEventType.NotIdle) {
        console.log('Not Idle');
      }
      if (IdleEventType.NotActive) {
        console.log('Not Active');
      }
      if (IdleEventType.Active) {
        console.log('Active');
      }
    });
  }

  async openConfirm(idleOut: boolean, session: boolean) {

    const dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      data: {
        sessionOut: session,
        idleTimeOut: idleOut
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === 'idle_yes') {
        await idle.markActivity();
        // await idle.toggleSyncActivity(true);
      } 
      if (result === 'session_yes') { 
        auth.refreshSession();
      } else if (result === 'session_no' || result === 'idle_no') {
        this.router.navigate(['/logout']);
      }
    });

  }

  async loginToNavify() {
    this.storageService.secureStorage.setItem('isNavifyRoleExist', 'false');

    const loginOutput: AuthLoginReturn = await auth.getLoginReturn();
    const session: AuthSession = await auth.getSession();
    if (loginOutput !== null && session !== null) {
      this.storageService.secureStorage.setItem('isLoggedIn', 'true');

        // Get UserId from whoami api
        this.getUserProfile();
      
    } else {
      this.storageService.secureStorage.setItem('alreadyLoggedIn', JSON.stringify('false'));
      await auth.loginRedirect({
        returnTo: window.location.href, // Login return URL
        username: '',
        state: {}
      });
      // this.router.navigate(['/login']);
    }
  }

  // For Login
 getUserProfile(){
  this.getProfile().subscribe({
    next: (data: any) => {
      
      // Get current user permissions
      

      for (const element of data.tenants) {
        // let elementValue = element['alias'];
        // console.log('alias: ',element.alias)
        if (element.alias === environment.config.navifyRoles.alias || element.alias.includes(environment.config.navifyRoles.starcap)) {
          this.storageService.secureStorage.setItem('isNavifyRoleExist', 'true');

          this.storageService.secureStorage.setItem('userUuid', data.userUuid);
          // Login API to get Current Role details
          this.getCurrentUserRole(data, element);
          this.getCurrentUserPermissions(data.userUuid);
        }
      }
      // Checking if user exist from StarCap
      this.isNavifyRoleExist = this.storageService.secureStorage.getItem('isNavifyRoleExist');
      if (this.isNavifyRoleExist === 'false') {
        this.router.navigate([i18n.NAV.TC]);
      }
    },
    error: (err) => {
      this.router.navigate(['/login']);
     },
    complete: () => { },
  });
 }

 
  // For Current User Role
 getCurrentUserRole(data:any, element:any){
  this.commonService.getCurrentUserRole({userUuid:data.userUuid}).subscribe({
    next: (res: any) => {
      this.storageService.secureStorage.setItem('loginUserName', [res.result.firstName, res.result.lastName]);
      res.result.roleDetails[0].roleId === environment.config.roles.roleIds.platformAdmin? this.isPlatformAdmin = true : this.isPlatformAdmin = false;
      // this.getCurrentUserPermissions(data.userUuid);
      this.orgRole = (res['result']['roleDetails'][0]['roleName'].toLowerCase());
      this.trianingStatus = (res['result']['roleDetails'][0]['trianingStatus'].toLowerCase());
      this.tcAccepted = (res['result']['tcAccepted']);
      const orgRoleData: any = [];
      const currentUserRoles: any = [];
      const currentUserRoleIds: any = [];
      for (const i of res.result.roleDetails) {
        currentUserRoles.push(i.roleName);
        currentUserRoleIds.push(i.roleId);
        if (i.roleType === environment.config.roles.roleTypes.org) {
          orgRoleData.push(i);
        }
        
      }
      // check training status
      const trainingStatus = (res.result.roleDetails).filter((val: any) => val.trianingStatus === 'COMPLETED');
      if (trainingStatus.length > 0) {
        this.storageService.secureStorage.setItem('trianingStatus', 'completed');
      } else {
        this.storageService.secureStorage.setItem('trianingStatus', 'pending');
      }

      // console.log(currentUserRoles);
      sessionStorage.setItem('currentUserRoles', JSON.stringify(currentUserRoles));
      this.storageService.secureStorage.setItem('currentUserRoles', currentUserRoles);      
      this.storageService.secureStorage.setItem('orgRoleId', orgRoleData[0] ? orgRoleData[0].roleId : ''); // currentUserRoleIds
      this.storageService.secureStorage.setItem('tcAccepted', this.tcAccepted);
      this.checkIfNavifyRoleExist(data, element);
      // end

      if (this.orgRole === environment.config.roles.starcap_platform_admin) {
        this.router.navigate([i18n.NAV.LOBBY]);
      }
      else {
        this.router.navigate([i18n.NAV.TC]);
      }
    },
    error: (err) => {
      // console.log('Error: ', err.message);
      this.router.navigate([i18n.NAV.TC]);
    }
  });
 }

  // For Login
  getCurrentUserPermissions(userUuid:string){
    if(this.isPlatformAdmin){
     this.getAdminRoleAccess();
    } else {
     this.getOtherUsersRoleAccess(userUuid);
   }
  }

 checkIfNavifyRoleExist(data:any, element: any){
  // navify role
  for (const [key, valueSet] of Object.entries(data)) {
    this.valueSet = valueSet;
    if (key == 'tenants') {
      // console.log('valueSet: ', valueSet);
      for (const item of this.valueSet) {
        // let elementValue = item['alias'];
        if (item.alias === environment.config.navifyRoles.alias) {
          this.storageService.secureStorage.setItem('isNavifyRoleExist', 'true');

          // this.checkIsAdmin(item, element);
        }
        else {
          this.storageService.secureStorage.setItem('isNavifyRoleExist', 'false');
          this.router.navigate([i18n.NAV.TC]);
        }
      }
    }
  }
 }

/*  checkIsAdmin(item:any, element: any){
  // let elementValue = item['apps'];
  if (item.apps) {
    for (const item_alias of element.alias) {
      const roles = item_alias['roles'];
      // this.userRole = element.roles;
      if (item_alias.roles) {
        for (const roledata of roles) {
          if (roledata.includes(environment.config.roles.starCap_Platform_Admin) === true) {
            this.storageService.secureStorage.setItem('adminName', environment.config.roles.starCap_Platform_Admin);
            this.router.navigate([i18n.NAV.LOBBY]);
            break;
          }
          else {
            this.router.navigate([i18n.NAV.TC]);
            break;
          }
        }
      }
    }
  }
 } */

 getAdminRoleAccess(){
  this.commonService.getAdminRoleAccess().subscribe({
    next: (res:any) =>{
      sessionStorage.setItem('currentUserPermissions', JSON.stringify(res?.result?.DashboadPermissions));
      sessionStorage.setItem('projectPermissions', JSON.stringify(res?.result?.RoleDetails));
    },
    error:(err)=>{
      if(err.status === 401){
        auth.loginRedirect({
          returnTo: window.location.href,
          state: {},
          reason: AuthLoginReason.notAuthorized
        });
      }
    },
    complete:()=>{}
  });
 }

 getOtherUsersRoleAccess(userUuid:string){
  this.currentUserPermissions(userUuid).subscribe({
    next: (res: any) => {
      // console.log('Get current user permissions: ', res.result)           
      sessionStorage.setItem('currentUserPermissions', JSON.stringify(res?.result?.DashboadPermissions));
      sessionStorage.setItem('projectPermissions', JSON.stringify(res?.result?.RoleDetails));
    },
    error: (err) => {
      // console.log('CR:', err)
      if(err.status === 401){
        auth.loginRedirect({
          returnTo: window.location.href,
          state: {},
          reason: AuthLoginReason.notAuthorized
        });
      }
    },
    complete: () => { },
  });
 }

  async getLoginResults() {
    await auth.init();
    const session: AuthSession = await auth.getSession();
    this.storageService.secureStorage.setItem('profile', JSON.stringify(session.profile));
  }

  async logOut() {
    const domainUrl = window.location.hostname;
    await auth.logoutRedirect({
      returnTo: 'https://'+ domainUrl,   // Logout return URL
      state: {},       // Preserved client state
      reason: AuthLogoutReason.userAction,      // Logout reason
      logMessage: 'Logout by User',  // Logout log message (audit)
    });
    const output: AuthLogoutReturn = await auth.getLogoutReturn();
    // console.log('output:', output);
    if(output.success){
      await auth.destroy();
      await idle.destroy();
      this.storageService.secureStorage.setItem('isLoggedIn', 'false');
      this.storageService.secureStorage.clear();
      this.storageService.secureStorage.removeItem('alreadyLoggedIn');
      this.storageService.secureStorage.setItem('alreadyLoggedIn', JSON.stringify('false'));
      sessionStorage.clear();
      localStorage.clear();
      this.commonService.getLogout().subscribe(() => {});
    }

  }

  getProfile() {
    const url = environment.platformApiUrl + environment.apiProfile;
    return this.http.get(url);
  }

  currentUserPermissions(userId: string) {
    const url = environment.config.domain + environment.currentRole + userId + '&orgKey=' + environment.config.orgKey;
    return this.http.get(url);
  }

  async resetPwd(un: string) {
    await auth.loginRedirect({
      returnTo: 'https://roche.authapp.appdevus.platform.navify.com/recover?authunm=' + un
    });
  }
 
}
