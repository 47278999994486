<div [formGroup]="projectIdea" class="container">
    <div class="row">
        <div class="col-5 mb-5">
            <mat-form-field appearance="fill" class="custom_width">
                <mat-label>Project Name (Scientific Study Title)</mat-label>
                <input matInput formControlName="projectIdeaName" autocomplete="off" minlength="1" maxLength="100"
                    required />
                <div class="pwd-error-message" *ngIf="f['projectIdeaName'].touched && 
                        f['projectIdeaName'].invalid && 
                        f['projectIdeaName'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message" *ngIf="f['projectIdeaName'].touched &&
                         f['projectIdeaName'].invalid && 
                         f['projectIdeaName'].errors?.['pattern']">
                    only alphanumeric are allowed
                </div>
                <div class="pwd-error-message" *ngIf="f['projectIdeaName'].touched &&
                     f['projectIdeaName'].invalid && 
                     f['projectIdeaName'].errors?.['duplicateValidation']">
                    Project Name already exists
                </div>
            </mat-form-field>
        </div>
        <div class="col-5 mb-5">
            <mat-form-field appearance="fill" class="custom_width">
                <mat-label>Region</mat-label>
                <mat-select name="region" formControlName="piRegion" [compareWith]="compareByRegionValue">
                    <mat-option *ngFor="let item of dataRegion" [value]="item">
                        {{item.regionName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mb-4">
            <div class="label-title">Project Description *
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #projectIdeaDesc formControlName="projectIdeaDesc"
                    maxlength="250">
                </textarea>
                <mat-hint align="end">{{projectIdeaDesc.value.length}} / 250</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['projectIdeaDesc'].touched && 
                    f['projectIdeaDesc'].invalid && 
                    f['projectIdeaDesc'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['projectIdeaDesc'].touched && 
                    f['projectIdeaDesc'].invalid && 
                    f['projectIdeaDesc'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>

        </div>
        <div class="row">
            <div class="col-5 mb-5 mt-5">
                <mat-form-field appearance="fill" class="custom_width">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email" autocomplete="off" required />
                    <div class="pwd-error-message"
                        *ngIf="f['email'].touched && f['email'].invalid && f['email'].errors?.['required']">
                        Field cannot be empty
                    </div>
                    <div class="pwd-error-message"
                        *ngIf="f['email'].touched && f['email'].invalid && f['email'].errors?.['email']">
                        Provide valid email address
                    </div>
                    <div class="pwd-error-message"
                        *ngIf="f['email'].touched && f['email'].invalid && f['email'].errors?.['pattern']">
                        Provide valid email address
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div class="col-5 mb-5">
            <mat-form-field appearance="fill" class="custom_width">
                <mat-label>First name of Principal Investigator</mat-label>
                <input matInput formControlName="principalFirstName" autocomplete="off" minlength="1" maxLength="50"
                    required />
                <div class="pwd-error-message" *ngIf="f['principalFirstName'].touched && 
                        f['principalFirstName'].invalid && 
                        f['principalFirstName'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message" *ngIf="f['principalFirstName'].touched && 
                        f['principalFirstName'].invalid && 
                        f['principalFirstName'].errors?.['pattern']">
                    Only alphabets and special character "-" are allowed
                </div>
            </mat-form-field>
        </div>
        <div class="col-5 mb-5">
            <mat-form-field appearance="fill" class="custom_width">
                <mat-label>Last Name of Principal Investigator</mat-label>
                <input matInput formControlName="principalLastName" autocomplete="off" minlength="1" maxLength="50"
                    required />
                <div class="pwd-error-message" *ngIf="f['principalLastName'].touched 
                        && f['principalLastName'].invalid && 
                        f['principalLastName'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message" *ngIf="f['principalLastName'].touched && 
                        f['principalLastName'].invalid && 
                        f['principalLastName'].errors?.['pattern']">
                    Only alphabets and special character "-" are allowed
                </div>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-5 mb-5">
                <mat-form-field appearance="fill" class="custom_width">
                    <mat-label>Lead Site/ Institution</mat-label>
                    <mat-select formControlName="leadSite" panelClass="multiPanelClass">
                        <mat-option *ngFor="let institu of siteDetails"
                            [value]="getSiteDetails(institu.siteName,institu.siteCode)">
                            {{getSiteDetails(institu.siteName,institu.siteCode)}}
                        </mat-option>
                    </mat-select>
                    <div class="pwd-error-message"
                        *ngIf="f['leadSite'].touched && f['leadSite'].invalid && f['leadSite'].errors?.['required']">
                        Field cannot be empty
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div class="col-5 mb-5">
            <mat-form-field appearance="fill" class="custom_width">
                <mat-label>First Name of other idea co author(s)</mat-label>
                <input matInput formControlName="coauthorFirstName" autocomplete="off" minlength="1" maxLength="50"
                    required />
                <div class="pwd-error-message" *ngIf="f['coauthorFirstName'].touched && 
                        f['coauthorFirstName'].invalid && 
                        f['coauthorFirstName'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message" *ngIf="f['coauthorFirstName'].touched && 
                        f['coauthorFirstName'].invalid && 
                        f['coauthorFirstName'].errors?.['pattern']">
                    Only alphabets and special character "-" are allowed
                </div>
            </mat-form-field>
        </div>
        <div class="col-5 mb-5">
            <mat-form-field appearance="fill" class="custom_width">
                <mat-label>Last Name of other idea co author(s)</mat-label>
                <input matInput formControlName="coauthorLastName" autocomplete="off" minlength="1" maxLength="50"
                    required />
                <div class="pwd-error-message" *ngIf="f['coauthorLastName'].touched && 
                        f['coauthorLastName'].invalid && 
                        f['coauthorLastName'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message" *ngIf="f['coauthorLastName'].touched && 
                        f['coauthorLastName'].invalid && 
                        f['coauthorLastName'].errors?.['pattern']">
                    Only alphabets and special character "-" are allowed
                </div>
            </mat-form-field>
        </div>
        <div class="col-12 mb-4">
            <div class="label-title">Name(s) of Collaborating Site(s)/Institution(s) *
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #collaboratingSite formControlName="collaboratingSite"
                    maxlength="200">
                </textarea>
                <mat-hint align="end">{{collaboratingSite.value.length}} / 200</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['collaboratingSite'].touched && 
                    f['collaboratingSite'].invalid && 
                    f['collaboratingSite'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['collaboratingSite'].touched && 
                    f['collaboratingSite'].invalid && 
                    f['collaboratingSite'].errors?.['pattern']">
                Only alphanumeric and special characters -._/\; are allowed
            </div>
        </div>
        <div class="row">
            <div class="col-5 mb-5 mt-5">
                <mat-form-field appearance="fill" class="custom_width">
                    <mat-label>Disease Area</mat-label>
                    <mat-select formControlName="diseaseArea">
                        <mat-option *ngFor="let disease of diseaseArea" [value]="disease">
                            {{disease}}
                        </mat-option>
                    </mat-select>
                    <div class="pwd-error-message" *ngIf="f['diseaseArea'].touched && 
                    f['diseaseArea'].invalid && 
                    f['diseaseArea'].errors?.['required']">
                        Field cannot be empty
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-5 mb-5">
                <mat-form-field appearance="fill" class="custom_width">
                    <mat-label>Study Type</mat-label>
                    <mat-select formControlName="studyType" multiple>
                        <mat-option *ngFor="let type of studyType" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                    <div class="pwd-error-message" *ngIf="f['studyType'].touched && 
                    f['studyType'].invalid && 
                    f['studyType'].errors?.['required']">
                        Field cannot be empty
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 mb-4">
            <div class="label-title">Please describe the scientific question and rationale that will be answered
                with the study incl. essential information about study design *
                <span class="mt-2">
                    <mat-icon class="material-icons-outlined info-icon" matTooltip="Please apply (if possible) the PICO Framework: 
                            PICO: Population, Intervention, Control, Outcome(s)"
                        matTooltipPosition="right">info</mat-icon>
                </span>
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #studyDesignDesc formControlName="studyDesignDesc"
                    maxlength="250">
                </textarea>
                <mat-hint align="end">{{studyDesignDesc.value.length}} / 250</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['studyDesignDesc'].touched && 
                    f['studyDesignDesc'].invalid && 
                    f['studyDesignDesc'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['studyDesignDesc'].touched && 
                    f['studyDesignDesc'].invalid && 
                    f['studyDesignDesc'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>

        </div>
        <div class="col-12 mb-4 mt-5">
            <div class="label-title">Drug Products that are being researched in the study, if applicable *
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #drugDetails formControlName="drugDetails" maxlength="250">
                </textarea>
                <mat-hint align="end">{{drugDetails.value.length}} / 250</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['drugDetails'].touched && 
                    f['drugDetails'].invalid && 
                    f['drugDetails'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['drugDetails'].touched && 
                    f['drugDetails'].invalid && 
                    f['drugDetails'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>
        </div>
        <div class="col-12 mb-4 mt-5">
            <div class="label-title">Who have you discussed the idea with from Roche already? Names of (project)
                affiliated Roche Scientists
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #scientistDetails formControlName="scientistDetails"
                    maxlength="250">
                </textarea>
                <mat-hint align="end">{{scientistDetails.value.length}} / 250</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['scientistDetails'].touched && 
                    f['scientistDetails'].invalid && 
                    f['scientistDetails'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>
        </div>
        <div class="col-12 mt-5">
            <div class="label-title">Is your IDEA submitted in response to the "Immune Fitness" RFP?</div>
            <mat-button-toggle-group formControlName="immuneFitness" value="immuneFitness" class="ideaEndorsed-button">
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-12 mb-4 mt-5">
            <div class="label-title">Which RFP generated this project, if any?
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #rfpDetails formControlName="rfpDetails" maxlength="250">
                </textarea>
                <mat-hint align="end">{{rfpDetails.value.length}} / 250</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea"
                *ngIf="f['rfpDetails'].touched && f['rfpDetails'].invalid && f['rfpDetails'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>
        </div>
        <div class="col-12 mb-4 mt-5">
            <div class="label-title">Please fill in your specific expertise.
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #specialExpertise formControlName="specialExpertise"
                    maxlength="500">
                </textarea>
                <mat-hint align="end">{{specialExpertise.value.length}} / 500</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea"
                *ngIf="f['specialExpertise'].touched && f['specialExpertise'].invalid && f['specialExpertise'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>
        </div>
        <div class="col-12 mb-4 mt-5 fileupload-container" [ngClass]="[!disableFileDrop ? 'disable-lable' : '']">
            <div class="label-title">Additional Information supporting the IDEA (i.e. a document with graphics)</div>
            <div class="warning-message-dialog">
                <mat-icon class="material-icons-outlined info-icon-warn">info</mat-icon>
                <mat-list role="list">
                    <mat-list-item role="listitem">Guidelines to upload,</mat-list-item>
                    <mat-list-item role="listitem">1. Maximum number of files allowed for upload 20
                        files</mat-list-item>
                    <mat-list-item role="listitem">2. Size limit per file - 0.5 GB per file</mat-list-item>
                    <mat-list-item role="listitem">3. Total attachments size - 1 GB</mat-list-item>
                    <mat-list-item role="listitem">4. File types allowed for upload - </mat-list-item>
                    <mat-list-item role="listitem" class="extention">.doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls,
                        .xlsx, .ics (apple calendar), .msg (Outlook email message) </mat-list-item>
                </mat-list>
            </div>
            <app-file-upload #supportFileUpload [resetOnLoad]="true" [userUuid]="userUuid" [projectKey]="projectID"
                [errForValidFileSize]="'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection'"
                [errForCumulativeFileSize]="'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.'"
                [maxFilesAllowed]="20" [maxParallelFileUploads]="4" [maxParallelChunksUpload]="10"
                [maxChunkSizeInBytes]="209715200" [maxFileSizeAllowedInGB]="0.5" [maxCumulativeSizeAllowedInGB]="1"
                [allowedExtensions]="['.doc', '.docx', '.txt', '.pdf', '.csv', '.png', '.jpeg', '.xls', '.xlsx', '.ics', '.msg']"
                [restrictedExtensions]="[]" [fileUploadApis]='service.pcFileUploadApis'
                [dataForFileUpload]="dataForFileUpload" [dataForEachFileInUpload]="supportdataForEachFile"
                [Identifier]="'supportingFileList'"
                (completedFileEventReceiver)="supportFileUploadCompletionEvent($event)"></app-file-upload>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let item of toList(supportfileUploadStatusData)"
                class="files-list-container">
                <mat-grid-tile [colspan]=1>
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{item.name}}</span>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                        value="{{item.fileProgress}}"></mat-progress-bar>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let items of f['supportingIdeaFiles'].value"
                class="files-list-container">
                <mat-grid-tile class="item1">
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{
                            items.fileName}}</span></div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-icon (click)="deleteFile(items,'supportingFiles',f['supportingIdeaFiles'])"
                        class="delete-icon">{{projectCreation.icon.close}}</mat-icon>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div class="col-12 mb-5">
            <div class="label-title">imCORE Study Identifier</div>
            <div class="static-value">{{f['imCoreIdentifier'].value ? f['imCoreIdentifier'].value : '-'}} </div>
        </div>
        <div class="row">
            <div class="col-5 mb-4">
                <mat-form-field appearance="fill" class="custom_width">
                    <mat-label>MDMS Study Identifier</mat-label>
                    <input matInput formControlName="studyIdentifier" autocomplete="off" minlength="1" maxLength="50" />
                    <div class="pwd-error-message" *ngIf="f['studyIdentifier'].touched && 
                            f['studyIdentifier'].invalid && 
                            f['studyIdentifier'].errors?.['required']">
                        Field cannot be empty
                    </div>
                    <div class="pwd-error-message" *ngIf="f['studyIdentifier'].touched && 
                            f['studyIdentifier'].invalid && 
                            f['studyIdentifier'].errors?.['pattern']">
                        Only alphanumeric and special characters "-" , "_" and "." are allowed
                    </div>
                </mat-form-field>
            </div>
        </div>

        <div class="col-12 mb-4 mt-4">
            <div class="label-title">Status *</div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #projectStatus formControlName="projectStatus"
                    maxlength="100">
                </textarea>
                <mat-hint align="end">{{projectStatus.value.length}} / 100</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['projectStatus'].touched && 
                    f['projectStatus'].invalid && 
                    f['projectStatus'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['projectStatus'].touched && 
                    f['projectStatus'].invalid && 
                    f['projectStatus'].errors?.['pattern']">
                    Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>
        </div>
        <div class="col-12 mb-4 mt-5">
            <div class="label-title">Remarks</div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #remarks formControlName="remarks" maxlength="500">
                </textarea>
                <mat-hint align="end">{{remarks.value.length}} / 500</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['remarks'].touched && 
                    f['remarks'].invalid && 
                    f['remarks'].errors?.['pattern']">
                Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
            </div>
        </div>
        <div class="col-12 mb-4 mt-5 current-status">
            <div class="label-title">Current Status details
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #statusDetails formControlName="statusDetails">
                </textarea>
            </mat-form-field>
        </div>
        <div class="col-12 mb-4 mt-5">
            <div class="label-title">sARM*
            </div>
            <mat-form-field class="comment-form-field" appearance="fill">
                <textarea class="comment-text-area" matInput #sarm formControlName="sarm" maxlength="100" required>
                </textarea>
                <mat-hint align="end">{{sarm.value.length}} / 100</mat-hint>
            </mat-form-field>
            <div class="pwd-error-message-textarea" *ngIf="f['sarm'].touched && 
                    f['sarm'].invalid && 
                    f['sarm'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['sarm'].touched && 
                    f['sarm'].invalid && 
                    f['sarm'].errors?.['pattern']">
                Only alphanumeric and special characters "-" and "_" are allowed
            </div>
        </div>
        <div class="col-12 mb-4 mt-5 fileupload-container" [ngClass]="[!disableFileDrop ? 'disable-lable' : '']">
            <div class="label-title">Recent communication to Project Investigator</div>
            <div class="warning-message-dialog">
                <mat-icon class="material-icons-outlined info-icon-warn">info</mat-icon>
                <mat-list role="list">
                    <mat-list-item role="listitem">Guidelines to upload,</mat-list-item>
                    <mat-list-item role="listitem">1. Maximum number of files allowed for upload 20
                        Files</mat-list-item>
                    <mat-list-item role="listitem">2. Size limit per file - 0.5 GB per file</mat-list-item>
                    <mat-list-item role="listitem">3. Total attachments size - 1 GB</mat-list-item>
                    <mat-list-item role="listitem">4. File types allowed for upload - </mat-list-item>
                    <mat-list-item role="listitem" class="extention">.doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls,
                        .xlsx, .ics (apple calendar), .msg (Outlook email message) </mat-list-item>
                </mat-list>
            </div>
            <app-file-upload #comFileUpload [resetOnLoad]="true" [userUuid]="userUuid" [projectKey]="projectID"
                [errForValidFileSize]="'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection'"
                [errForCumulativeFileSize]="'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.'"
                [maxFilesAllowed]="20" [maxParallelFileUploads]="4" [maxParallelChunksUpload]="10"
                [maxChunkSizeInBytes]="209715200" [maxFileSizeAllowedInGB]="0.5" [maxCumulativeSizeAllowedInGB]="1"
                [allowedExtensions]="['.doc', '.docx', '.txt', '.pdf', '.csv', '.png', '.jpeg', '.xls', '.xlsx', '.ics', '.msg']"
                [restrictedExtensions]="[]" [fileUploadApis]='service.pcFileUploadApis'
                [dataForFileUpload]="dataForFileUpload" [dataForEachFileInUpload]="dataForEachFile"
                [Identifier]="'communicationFileList'"
                (completedFileEventReceiver)="fileUploadCompletionEvent($event)"></app-file-upload>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let item of toList(fileUploadStatusData)"
                class="files-list-container">
                <mat-grid-tile [colspan]=1>
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{item.name}}</span>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                        value="{{item.fileProgress}}"></mat-progress-bar>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let items of f['communication'].value"
                class="files-list-container">
                <mat-grid-tile class="item1">
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{
                            items.fileName}}</span></div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-icon (click)="deleteFile(items,'communicationFiles',f['communication'])"
                        class="delete-icon">{{projectCreation.icon.close}}</mat-icon>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div class="row">
            <div class="col-5 mt-3">
                <mat-form-field appearance="fill" class="input-manifst custom_width">
                    <mat-label>Year of Submission</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="submissionYear" [max]="maxDate"
                        [min]="minDate" readonly/>
                    <mat-datepicker-toggle matIconSuffix [for]="picker" class="picker-icon"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div class="pwd-error-message" *ngIf="f['submissionYear'].touched && 
                        f['submissionYear'].invalid && 
                        f['submissionYear'].errors?.['required']">
                    Field cannot be empty
                </div>
            </div>
        </div>

    </div>
</div>
