import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectManagementService } from '../project-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { i18n } from 'src/app/i18n.en';
import { AuditService } from '../../project-audit/audit.service';
import { ToastrService } from 'ngx-toastr';

interface cadence {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-configure-audit-settings',
  templateUrl: './configure-audit-settings.component.html',
  styleUrls: ['./configure-audit-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class ConfigureAuditSettingsComponent implements OnInit {
  i18n = i18n;
  maxDate = new Date();
  isLoading: boolean = false;
  auditForm: FormGroup;
  numberOfDaysDisplay: boolean = false;
  projectKey: string;
  orgKey: string;

  currentCycleBeginDate: string;
  daysRemainingForNextAudit: string;
  auditInitatedDateAndTime: string;
  auditStatus: string;
  latestAuditDate: string;
  startAudit: boolean;
  auditResult: boolean;
  projectdetailsValues: any;
  projectName: any;
  queryParamData: any;
  auditSettingsTitle :string;
  auditConfigData:any = {};
  startAuditStatus: boolean = false;
  lastAdutiStatus: string;
  numberOfDaysValidation: boolean;
  editStatus: boolean = false;
  updateButtonDisabled: boolean = true;

  constructor(private readonly router: Router, private readonly projectManagementService: ProjectManagementService, private readonly activatedRoute:ActivatedRoute,
    readonly datePipe: DatePipe, private auditService: AuditService, public toastr: ToastrService) { }
    get max() { return this.auditForm.get('numberOfDays')?.errors?.['maxlength']; }

  cadence: cadence[] = [];

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParamData = queryParams;
      this.editStatus = this.queryParamData.editStatus;
      });

    this.projectKey = this.queryParamData.projectKey;
    this.orgKey = this.queryParamData.orgKey;
    this.auditSettingsTitle = (this.queryParamData.status === true) ? 'Update Audit Settings' : 'Configure Audit Settings';
    this.auditForm = new FormGroup({
      'firstAuditDate': new FormControl('', [Validators.required]),
      'cadenceForAudit': new FormControl('', [Validators.required]),
      'numberOfDays': new FormControl({ value: '', disabled: true }, [Validators.required, this.numberofDaysValidation])
    });
    this.getCadenceData();
    this.getAuditDetails();
    this.getProjectAuditDetails();
  }

  numberofDaysValidation(control: FormControl) {
    const prjname: any = control.value;
    if(!(prjname > 0 && prjname < 731) && prjname !== ''){
      return {
        alphabet: "Enter number of days between 1 and 730"
      }
    }
    return null;
  }

  getCadenceData(){
    this.isLoading = true;
    this.projectManagementService.getCadence().subscribe({
      next: (data:any)=>{
        this.cadence = data.result;
        this.isLoading = false;
      },
      error: (err)=>{
        this.isLoading = false;
        console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      },
      complete: ()=>{
        this.isLoading = false;
      }
    });
  }

  getAuditDetails(){
    this.isLoading = true;
    this.projectManagementService.getProjectDetails(this.queryParamData.projectKey).subscribe((data:any)=>{
      this.auditConfigData  = data.result;
      this.auditConfigData  = this.auditConfigData.projectDetails.auditSettings;
      console.log("Data: ", this.auditConfigData);

      const firstAuditDate = this.datePipe.transform(this.auditConfigData.firstAuditDate, i18n.DATE.SHORT);
      this.auditForm.get('firstAuditDate')?.setValue(firstAuditDate);
      this.auditForm.get('cadenceForAudit')?.setValue(this.auditConfigData.cadence);      
      if(this.auditConfigData.cadence === 'Custom'){
        this.auditForm.get('numberOfDays')?.setValue(this.auditConfigData.numberOfDays);
        this.numberOfDaysDisplay = true;
        this.auditForm.get('numberOfDays')?.enable();
      } else {
        this.updateButtonDisabled = true;
      }
      this.isLoading = false;
    })
  }

  getProjectAuditDetails() {
    this.isLoading = true;
    this.auditService.getAuditDetails(this.projectKey).subscribe(res => {
      this.startAuditStatus = res['result'].projectDetails.auditSettings.startAudit;
      this.lastAdutiStatus = res['result'].projectDetails.auditSettings.latestAuditDate;
    })
  }

  compareById(o1?: any, o2?: any) {
    return o1 === o2;
  }

  backToProjectDetails() {
    this.router.navigate(['/projectmanagement/projectdetails'], { relativeTo: this.activatedRoute, queryParams: {...this.queryParamData, selectProjectTabIndex:3, from: 'noPopup'}});
  }
  cadenceValue(){
    const cadenceValue = this.auditForm.get('cadenceForAudit')?.value;
    if(cadenceValue === 'Custom'){
      this.numberOfDaysDisplay = true;
      this.auditForm.get('numberOfDays')?.enable();
      this.updateButtonDisabled = true;
      if(this.queryParamData.editStatus === "true"){
        //this.auditForm.get('numberOfDays')?.setValue(this.auditConfigData.numberOfDays);
        //this.updateButtonDisabled = false;
      } else {
        this.auditForm.get('numberOfDays')?.setValue('');
      }
    } else {
      this.auditForm.markAsTouched();
      this.numberOfDaysDisplay = false;
      this.auditForm.get('numberOfDays')?.disable();
      this.auditForm.get('numberOfDays')?.setValue('');
      if(this.queryParamData.editStatus === "true"){
        this.updateButtonDisabled = false;
      }
    }
  }

  // text box validation
  onKeyup(e: any) {
    this.numberOfDaysValidation = ((e.target.value > 0 && e.target.value < 731 || e.target.value == '')) ? false : true;
    if(this.queryParamData.editStatus === "true"){      
      if(this.numberOfDaysValidation === false && e.target.value !== ''){
        this.updateButtonDisabled = false;
      } else {
        this.updateButtonDisabled = true;
      }
    }
    const value = e.target.value;
    if(value.includes('.')){
      e.target.value = value.replace('.', '');
    }
    if(value.indexOf("0") == 0){
      e.target.value = value.replace('0', '');
    }
  }
  

  submitAuditSettings(){
    const firstAuditDate = this.datePipe.transform(this.auditForm.get('firstAuditDate')?.value, 'YYYY-MM-ddT00:00:00.000000');
    const obj = {}
    
    if(this.auditConfigData !== null){
      const obj = {
        //firstAuditDate: this.auditForm.get('firstAuditDate')?.value,
        firstAuditDate: firstAuditDate,
        cadence: this.auditForm.get('cadenceForAudit')?.value,
        numberOfDays: this.auditForm.get('numberOfDays')?.value,

        currentCycleBeginDate: this.auditConfigData.currentCycleBeginDate,
				daysRemainingForNextAudit: this.auditConfigData.daysRemainingForNextAudit,
        auditInitatedDateAndTime: this.auditConfigData.auditInitatedDateAndTime,
        auditStatus: this.auditConfigData.auditStatus,
        latestAuditDate: this.auditConfigData.latestAuditDate,
        startAudit: this.auditConfigData.startAudit,
        auditResult: this.auditConfigData.auditResult
      }
      this.submitAuditSettingsCall(obj, 'edit');
    } else {
      const obj = {
        //firstAuditDate: this.auditForm.get('firstAuditDate')?.value,
        //firstAuditDate: "2023-08-20T18:30:00.000",
        firstAuditDate: firstAuditDate,
        cadence: this.auditForm.get('cadenceForAudit')?.value,
        numberOfDays: this.auditForm.get('numberOfDays')?.value
      }
      this.submitAuditSettingsCall(obj, 'add');
    }
  }
  submitAuditSettingsCall(object:any, type: string){
    this.isLoading = true;
    this.projectManagementService.getAuditData(object, this.orgKey, this.projectKey).subscribe({
      next: (res:any)=>{
        if(res.responseCode==='200'||res.responseCode==='201'){
          if(type === 'add'){
            this.toastr.success("Audit settings configured successfully");
          }
          else {
            this.toastr.success("Audit settings updated successfully");
          }
          this.isLoading = false;
          }else{
            this.toastr.error(res.messages);
          }
        this.backToProjectDetails();
        
      },
      error: (err)=>{
        this.isLoading = false;
        console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      },
      complete: ()=>{
        this.isLoading = false;
      }
    });
  }

  // Validation
  datePicker(){
    if(this.queryParamData.editStatus === "true"){
      this.updateButtonDisabled = false;
    }
  }

}
