import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from '../user-management.service';
import { CustomValidators } from '../../project-management/custom-validators';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { i18n } from '../../../i18n.en';
import { environment as env, environment} from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

interface Role {
  name: string;
  id: string;
}

@Component({
  selector: 'app-inviteuser',
  templateUrl: './inviteuser.component.html',
  styleUrls: ['./inviteuser.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class InviteuserComponent implements OnInit {
  i18n = i18n;
  createForm: FormGroup;
  orgList: string[] = [env.config.orgKey];
  orgRoleList = [{ 'roleName': '', 'roleId': '' }];
  role:any = env.config.roles;
  isEdit: boolean;
  title: string;
  formHasChange = false;
  selectedRowElement: any;
  isAdmin = false;
  userNameErrorMsg: string;
  isLoading: boolean;
  fromComponent: string;
  projectName: string;
  orgName: string;
  orgKey: string;
  projectKey: string;
  roles: any = [];
  isDataControllerExist = false;
  inviteButtonName: string;
  auditProjectRole: string;
  auditProjectRoleType: string;
  queryParamsData: any;
  existingUserId: string;
  fromApi:string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly userService: UserManagementService, public dialogRef: MatDialogRef<InviteuserComponent>,
    private toastrService: ToastrService, private commonService: CommonService, private readonly router: Router,
    private route: ActivatedRoute,) {
    this.isEdit = data.isEdit
    this.selectedRowElement = data.selectedRowElement;
    this.fromComponent = data.fromComponent;
    this.projectName = data.projectName;
    this.orgName = data.orgName;
    this.projectKey = data.projectKey;
    this.isDataControllerExist = data.isDataControllerExist;
    this.existingUserId = data.existingUserId;
    this.fromApi = data.fromApi;
  }

  ngOnInit(): void {
    console.log("this.projectKey: ", this.projectKey);
    this.auditProjectRole = 'Data Controller';
    this.auditProjectRoleType = this.auditProjectRole;
    this.orgKey = environment.config.orgKey;
    this.getRoles();
    if (this.fromComponent === "collaborator") {
      this.createForm = new FormGroup({
        username: new FormControl({ value: '', disabled: this.isEdit }, [Validators.required]),
        firstname: new FormControl('', [Validators.required, Validators.maxLength(16), this.ProjectNameSpecialCharValidator]),
        lastname: new FormControl('', [Validators.required, Validators.maxLength(16), this.ProjectNameSpecialCharValidator]),
        projectRole: new FormControl('', [Validators.required])
      });
      this.inviteButtonName = i18n.userManagement.BTN.IU;
    } else if (this.fromComponent === "project-audit") {
      this.createForm = new FormGroup({
        username: new FormControl({ value: '', disabled: this.isEdit }, [Validators.required]),
        firstname: new FormControl('', [Validators.required, Validators.maxLength(16), this.ProjectNameSpecialCharValidator]),
        lastname: new FormControl('', [Validators.required, Validators.maxLength(16), this.ProjectNameSpecialCharValidator]),
      });
      this.inviteButtonName = i18n.userManagement.BTN.IU;
    } else {
      this.createForm = new FormGroup({
        username: new FormControl({ value: '', disabled: this.isEdit }, [Validators.required]),
        starCAProles: new FormControl({ value: '', disabled: this.isEdit }),
        firstname: new FormControl('', [Validators.required, Validators.maxLength(16)]),
        lastname: new FormControl('', [Validators.required, Validators.maxLength(16)]),
        organization: new FormControl({ value: '', disabled: this.isEdit }, [Validators.required]),
        organizationRole: new FormControl({ value: '', disabled: this.isEdit }, [Validators.required])
      });
      this.inviteButtonName = i18n.userManagement.BTN.INVITE;
    }

    this.title = this.isEdit ? i18n.userManagement.BTN.EU : i18n.userManagement.BTN.IU;

    //Organization field selection defaulted to Org for invite
    if (!this.isEdit) {
      const defaultOrg = this.orgList.find(c => c === env.config.orgKey);
      this.createForm.get('organization')?.setValue(defaultOrg);
    }
    if (this.isEdit) {
      this.createForm.patchValue({
        ['username']: this.data.selectedRowElement.userEmail,
        ['starCAProles']: (this.data.selectedRowElement.roleDetails.roleId === env.config.roles.roleIds.platformAdmin) ? (this.isAdmin = true) : (this.isAdmin = false),
        ['firstname']: this.data.selectedRowElement.firstName,
        ['lastname']: this.data.selectedRowElement.lastName,
        ['organizationRole']: this.data.selectedRowElement.roleDetails.roleId,
        ['organization']: env.config.orgKey //this.data.selectedRowElement.orgName
      });

      this.onCreateGroupFormValueChange();
    }
    //Removes error message from user name field when value gets changed
    this.createForm.get('username')?.valueChanges.subscribe(val => {
      if (this.userNameErrorMsg) {
        this.userNameErrorMsg = '';
      }
    });
  }
  getRoles() {
    if (this.fromComponent === 'userManagement') {
      this.orgName = this.orgKey;
    }
    this.isLoading = true;
    this.commonService.getPermissionRoles(env.config.orgKey).subscribe((data: any) => {
      this.isLoading = false;
      this.roles = data.result.orgProjectRoles;
      this.orgRoleList = data.result.orgRoles;
    })
  }

  ProjectNameSpecialCharValidator(control: FormControl) {
    const prjname: string = control.value;
    if (!/^[a-z A-Z 0-9 . _ -]*$/.test(prjname.substr(0))) {
      return {
        specialchar: "not valid"
      }
    }
    return null;
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onInvite(obj: any) {
    let roleName = '';
    let roleType: string;
    let roleId: string;

    if (this.createForm.get('starCAProles')?.value) {
      roleName = env.config.roles.starCap_Platform_Admin;
      roleId = env.config.roles.roleIds.platformAdmin;
      roleType = env.config.roles.roleTypes.platform;
    } else {
      roleId = this.createForm.get('organizationRole')?.value;
      this.orgRoleList?.forEach((ele: any) => {
        if (ele.roleId === roleId) {
          roleName = ele.roleName
        }
      })
      roleType = env.config.roles.roleTypes.org;
    }
    // new invite for collaborator
    if (this.fromComponent === "collaborator") {
      const roleValue = (this.createForm.get('projectRole')?.value).split("#");
      const objCollaborator = {
        'firstName': this.createForm.get('firstname')?.value,
        'lastName': this.createForm.get('lastname')?.value,
        'userEmail': this.createForm.get('username')?.value,
        'projectName': this.projectName,
        'projectKey': this.projectKey,
        'orgName': this.orgName,
        'orgKey': this.orgName,
        'roleName': roleValue[1],
        'roleId': roleValue[0],
        'roleType': 'PROJECT',
        'description': 'Project role',
        'userType': 'External'
      }
      this.isLoading = true;
      this.addCollaborator(objCollaborator);
    } else if (this.fromComponent === "project-audit" && this.fromApi !== "project-transfer") {
      const objDataController = {
        'firstName': this.createForm.get('firstname')?.value,
        'lastName': this.createForm.get('lastname')?.value,
        "userType":"Internal",
        'userEmail': this.createForm.get('username')?.value,
        "roleName": this.auditProjectRole,
        "roleId":"imcore_proj_role03",
        'roleType': 'PROJECT',
        'description': 'Project role',
        'orgName': this.orgName,
        'orgKey': this.orgName,
        'projectName': this.projectName,
        'projectKey': this.projectKey,
        'existingUserId': this.existingUserId
      }
      this.addCollaborator(objDataController);
      this.isLoading = true;
      /* const username = this.createForm.get('username')?.value;
      this.dialogRef.close(username); */
       
    } else if (this.fromComponent === "project-audit" && this.fromApi === "project-transfer") {
      const objDataController = {
        'firstName': this.createForm.get('firstname')?.value,
        'lastName': this.createForm.get('lastname')?.value,
        "userType":"Internal",
        'userEmail': this.createForm.get('username')?.value,
        "roleName": this.auditProjectRole,
        "roleId":"imcore_proj_role03",
        'roleType': 'PROJECT',
        'description': 'Project role',
        'orgName': this.orgName,
        'orgKey': this.orgName,
        'projectName': this.projectName,
        'projectKey': this.projectKey,
        'existingUserId': this.existingUserId
      }
      console.log("inTransfer",objDataController);
      this.addCollaborator(objDataController);
      this.isLoading = true;

       
    }
    else {
      if (this.validateRocheUser()) {
        const objUser = {
          firstName: obj.firstname,
          lastName: obj.lastname,
          userType: 'External',
          userEmail: obj.username,
          roleDetails: {
            roleName: roleName,
            orgKey: obj.organization,
            roleType: roleType,
            description: 'Inviting user',
            orgName: obj.organization,
            roleId: roleId
          }
        }
        this.userNameErrorMsg = '';
        this.isLoading = true;
        this.getInviteUser(objUser); 
      } else {
        this.toastrService.error('Only Roche users can be added as Organization PO');
      }
    }
  }

  addCollaborator(objCollaborator:any){    
    this.userService.addCollaborator(objCollaborator).subscribe({
      next: (data: any) => {        
        //console.log("Collaborator Data: ", data);
        this.isLoading = false;                
        this.toastrService.success('User invited to the project successfully');
        if(this.fromComponent === "project-audit" && this.fromApi === "project-transfer"){
          const username = this.createForm.get('username')?.value;
          this.dialogRef.close(username); 
        }else{
          this.dialogRef.close('invited');
        }
      },
      error: (err) => {
        this.isLoading = false;
        if (err.error.error[0].errorDetails.search('Email') !== -1 || err.error.error[0].errorDetails.search('email') !== -1) {
          this.userNameErrorMsg = err.error.error[0].errorDetails;
        } else {
          this.toastrService.error(err.error.error[0].errorDetails);
        }
      }
    });
  }

  getInviteUser(objUser:any){
    this.userService.getInviteUser(objUser).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        this.dialogRef.close('invited');
        if (this.fromComponent === "project-audit"){          
          this.toastrService.success('User invited to the project successfully');          
        } else {
          this.toastrService.success('User invited successfully');
        }
        
      },
      error: (err) => {
        this.isLoading = false;
        if (err.error.error[0].errorDetails.search('Email') !== -1 || err.error.error[0].errorDetails.search('email') !== -1) {
          this.userNameErrorMsg = err.error.error[0].errorDetails;
        } else {
          this.toastrService.error(err.error.error[0].errorDetails);
        }
      }
    });
  }

  //Validates users for Roche to assign PO or admin 
  validateRocheUser() {
    const userEmail = this.createForm.controls['username']?.value;
    const organizationRole = this.createForm.controls['organizationRole']?.value;
    const rocheUserCheck = /roche.com/;
    if (this.fromComponent === "collaborator") {
      if (userEmail?.search(rocheUserCheck) === -1) {
        return false;
      } else {
        return true;
      }

    } else {
      if (organizationRole === 'Organization PO') {
        if (userEmail?.search(rocheUserCheck) === -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  onUpdate(form: any) {
    const obj = {
      userUuid: this.selectedRowElement.userUuid,
      firstName: form.firstname,
      lastName: form.lastname
    }
    this.isLoading = true;
    this.userService.getUpdateUserDetails(obj).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        this.dialogRef.close('updated');
        this.toastrService.success('User details updated successfully');
      },
      error: (err) => {
        this.isLoading = false;
        this.toastrService.error(err.error.error[0].errorDetails);
      }
    });
  }

  onCreateGroupFormValueChange() {
    const initialValue = this.createForm.value;
    this.createForm.valueChanges.subscribe(value => {
      this.formHasChange = Object.keys(initialValue).some(key => this.createForm.value[key] !=
        initialValue[key])
    });
  }

  onPlatformAdminSelection(event: any) {
    if (event.checked) {
      this.createForm.get('organizationRole')?.reset();
      this.createForm.get('organizationRole')?.clearValidators();
      this.createForm.get('organizationRole')?.updateValueAndValidity();
      this.createForm.get('organization')?.clearValidators();
      this.createForm.get('organization')?.updateValueAndValidity();

    } else {
      this.createForm.get('organizationRole')?.setValidators(Validators.required);
      this.createForm.get('organizationRole')?.updateValueAndValidity();
      this.createForm.get('organization')?.setValidators(Validators.required);
      this.createForm.get('organization')?.updateValueAndValidity();

    }
  }
}
