import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesToMB'
})
export class BytesToMBPipe implements PipeTransform {

  transform(value:any): any {
    const mb = (value/1024/1024);
    if(mb<1){
      const kb = (mb*1024);
     return kb.toFixed(2)+ ' KB';
    }else{
      return mb.toFixed(2)+ ' MB';
    }
    
  }

}
