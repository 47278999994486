<div [formGroup]="projectConcept" class="container">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="label-title">Is the Idea endorsed by iScT? *</div>
                <mat-button-toggle-group formControlName="isIsctIdeaEndorsed" value="days" class="ideaEndorsed-button">
                    <mat-button-toggle value="Yes">{{projectCreation.pc.s}}</mat-button-toggle>
                    <mat-button-toggle value="No">{{projectCreation.pc.n}}</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="pwd-error-message"
                    *ngIf="f['isIsctIdeaEndorsed'].touched && 
                    f['isIsctIdeaEndorsed'].invalid && 
                    f['isIsctIdeaEndorsed'].errors?.['required']">
                    Field cannot be empty
                </div>
            </div>
            <div class="row">
                <div class="col-5 mb-5">
                    <mat-form-field appearance="fill" class="input-manifst custom_width">
                        <mat-label>Date of Endorsement</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="pcEndorsementDate" readonly />
                        <mat-datepicker-toggle matIconSuffix [for]="picker" class="picker-icon"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div class="pwd-error-message"
                        *ngIf="f['pcEndorsementDate'].touched && 
                        f['pcEndorsementDate'].invalid && 
                        f['pcEndorsementDate'].errors?.['required']">
                        Field cannot be empty
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4" [ngClass]="[isDisabled ? 'disable-lable' : '']">
                <div class="label-title">{{projectCreation.pc.cm}}</div>
                <mat-form-field class="comment-form-field" appearance="fill">
                    <textarea class="comment-text-area" matInput #pcComments formControlName="pcComments"
                        maxlength="500">
                </textarea>
                    <mat-hint align="end">{{pcComments.value.length}} / 500</mat-hint>
                </mat-form-field>
                <div class="pwd-error-message-textarea"
                    *ngIf="f['pcComments'].touched && 
                    f['pcComments'].invalid && 
                    f['pcComments'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message-textarea"
                    *ngIf="f['pcComments'].touched && f['pcComments'].invalid && f['pcComments'].errors?.['pattern']">
                    Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
                </div>
            </div>
            <div class="col-12 mb-5 mt-5" [ngClass]="[isDisabled ? 'disable-lable' : '']">
                <div class="label-title">{{projectCreation.pc.attach}}</div>
                <div class="warning-message-dialog">
                    <mat-icon class="material-icons-outlined info-icon-warn">{{projectCreation.icon.info}}</mat-icon>
                    <mat-list role="list">
                        <mat-list-item role="listitem">Guidelines to upload,</mat-list-item>
                        <mat-list-item role="listitem">1. Maximum number of files allowed for upload 20 files</mat-list-item>
                        <mat-list-item role="listitem">2. Size limit per file - 0.5 GB per file</mat-list-item>
                        <mat-list-item role="listitem">3. Total attachments size - 1 GB</mat-list-item>
                        <mat-list-item role="listitem">4. File types allowed for upload - </mat-list-item>
                        <mat-list-item role="listitem" class="extention">.doc, .docx, .txt, .pdf, .csv, .png, .jpeg, .xls, .xlsx, .ics (apple calendar), .msg (Outlook email message) </mat-list-item>
                    </mat-list>
                </div>
                <app-file-upload #pcFileUpload 
                [resetOnLoad]="true"
                [userUuid]="userUuid"
                [projectKey]="projectID"
                [errForValidFileSize]="'One or more files selected exceed the 0.5 GB file size limit. Please revisit your selection'"
                [errForCumulativeFileSize]="'Total file size of the upload exceeds the total file size limitation of 1 GB. Please revisit your selection.'"
                [maxFilesAllowed]="20" 
                [maxParallelFileUploads]="4"
                [maxParallelChunksUpload]="10"
                [maxChunkSizeInBytes]="209715200"
                 [maxFileSizeAllowedInGB]="0.5"
                [maxCumulativeSizeAllowedInGB]="1" 
                [allowedExtensions] = "['.doc', '.docx', '.txt', '.pdf', '.csv', '.png', '.jpeg', '.xls', '.xlsx', '.ics', '.msg']"
                [restrictedExtensions]="[]" 
                [fileUploadApis]='service.pcFileUploadApis'
                [dataForFileUpload]="pcdataForFileUpload" 
                [dataForEachFileInUpload]="pcdataForEachFile"
                [Identifier]="'pcFileList'"
                (completedFileEventReceiver)="pcfileUploadCompletionEvent($event)"></app-file-upload>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let item of toList(pcfileUploadStatusData)"
                class="files-list-container">
                <mat-grid-tile [colspan]=1>
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{item.name}}</span>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-progress-bar class="fileupload-progress-bar" mode="determinate"
                        value="{{item.fileProgress}}"></mat-progress-bar>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="50px" *ngFor="let items of f['pcFiles'].value"
                class="files-list-container">
                <mat-grid-tile class="item1">
                    <div mat-line><mat-icon class="file-icon">{{i18n.ICONS.DESC}}</mat-icon><span>{{
                            items.fileName}}</span></div>
                </mat-grid-tile>
                <mat-grid-tile class="item2">
                    <mat-icon (click)="deleteFile(items,'pcFiles',f['pcFiles'])" class="delete-icon">{{projectCreation.icon.close}}</mat-icon>
                </mat-grid-tile>
            </mat-grid-list>
            </div>
            <div class="col-12 mb-4" [ngClass]="[!isDisabled ? 'disable-lable' : '']">
                <div class="label-title">Override with Reason *</div>
                <mat-form-field class="comment-form-field" appearance="fill">
                    <textarea class="comment-text-area" matInput #pcOverrideReason formControlName="pcOverrideReason"
                        maxlength="500">
                </textarea>
                    <mat-hint align="end">{{pcOverrideReason.value.length}} / 500</mat-hint>
                </mat-form-field>
                <div class="pwd-error-message-textarea"
                    *ngIf="f['pcOverrideReason'].touched && 
                    f['pcOverrideReason'].invalid && 
                    f['pcOverrideReason'].errors?.['required']">
                    Field cannot be empty
                </div>
                <div class="pwd-error-message-textarea"
                    *ngIf="f['pcOverrideReason'].touched && 
                    f['pcOverrideReason'].invalid && 
                    f['pcOverrideReason'].errors?.['pattern']">
                    Only special characters dash(-), underscore(_), period(.), comma(,) and enter(line break) are allowed
                </div>
            </div>
        </div>
    </div>