import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { CoreMaModule } from 'src/app/shared/core-ma/core-ma.module';
import { FileUploadModule } from '../project-management/file-upload/file-upload.module';
import { ProjectModule } from '../project-management/project.module';
import { AuditHistoryComponent } from './project-audit-files/audit-history/audit-history.component';
import { AuditResultComponent } from './project-audit-files/audit-result/audit-result.component';
import { AuditResultsComponent } from './project-audit-files/audit-results/audit-results.component';
import { AuditStartResultsComponent } from './project-audit-files/audit-start-results/audit-start-results.component';
import { ManageDataControllerComponent } from './project-audit-files/manage-data-controller/manage-data-controller.component';
import { ManageUsersComponent } from './project-audit-files/manage-users/manage-users.component';
import { ProjectAuditFilesComponent } from './project-audit-files/project-audit-files.component';
import { ProjectAuditOverviewComponent } from './project-audit-overview/project-audit-overview.component';
import { ProjectAuditRoutingModule } from './project-audit-routing.module';
import { ProjectInformationComponent } from './project-audit-files/project-information/project-information.component';
import { DownloadReportComponent } from './download-report/download-report.component';

@NgModule({
  declarations: [
    ProjectAuditOverviewComponent,
    ProjectAuditFilesComponent,
    AuditStartResultsComponent,
    ManageUsersComponent,
    AuditHistoryComponent,
    ManageDataControllerComponent,
    AuditResultComponent,
    AuditResultsComponent,
    ProjectInformationComponent,
    DownloadReportComponent,
  ],
  imports: [
    CommonModule,
    ProjectAuditRoutingModule,
    ProjectModule,
    CoreMaModule,
    FormsModule,
    ReactiveFormsModule,     
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    MatMenuModule,   
    FileUploadModule
  ]
})
export class ProjectAuditModule { }
