import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';

import { DialogData } from 'src/app/interfaces/confirm.interface';
import { map, timer, takeWhile } from 'rxjs';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, CommonModule],
})
export class ConfirmComponent implements OnInit {
  i18n = i18n;
  timeRemaining$:any;
  sessionOrIdle: any;
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    // console.log(this.data)
    this.sessionOrIdle = this.data;
    this.timeRemaining$ = timer(0, 1000).pipe(
      map((n) => (60 - n) * 1000),
      takeWhile((n) => n >= 0)
    );
    setInterval(()=>{
      this.onNoClick('session_no');
    },59000);
  }

  onNoClick(no?:string): void {
    this.dialogRef.close(no);
  }
  onYesClick(yes?:string): void {
    this.dialogRef.close(yes);
  }

}
