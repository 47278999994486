import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProjectIdea } from '../../model/model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { projectCreation } from '../../model/model';
import { ProjectManagementService } from '../../../project-management.service';
import { ActivatedRoute } from '@angular/router';
import { i18n } from 'src/app/i18n.en';
@Component({
  selector: 'app-project-idea-details',
  templateUrl: './project-idea-details.component.html',
  styleUrls: ['./project-idea-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ] 
})
export class ProjectIdeaDetailsComponent implements OnInit, OnChanges {
  projectCreation = projectCreation;
  i18n = i18n;
  @Input() isFromAuditProjectInfo: boolean;
  @Input() projectIdea: ProjectIdea | undefined;
  @Input() projDetails: any;
  @Input() projectID: string;
  supportingdataSource;
  communicationDataSource;
  @ViewChild('table1Sort') table1Sort = new MatSort();
  @ViewChild('table2Sort') table2Sort = new MatSort();
  @ViewChild('table1Paginator', { static: true }) table1Paginator!: MatPaginator;
  @ViewChild('table2Paginator', { static: true }) table2Paginator!: MatPaginator;
  displayedColumns: string[] = ['fileName', 'action'];
  emptyData;
  constructor(public readonly service: ProjectManagementService,
    public readonly activatedRoute: ActivatedRoute) {
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.supportingdataSource = new MatTableDataSource(this.projectIdea?.supportingIdeaFiles);
      this.supportingdataSource.paginator = this.table1Paginator;
      this.supportingdataSource.sort = this.table1Sort;
      this.communicationDataSource = new MatTableDataSource(this.projectIdea?.communication);
      this.communicationDataSource.paginator = this.table2Paginator;
      this.communicationDataSource.sort = this.table2Sort;
      this.emptyData = new MatTableDataSource([{ empty: "row" }])
  }

  ngOnInit(): void {
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
  }
}
function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;
  return customPaginatorIntl;
}
