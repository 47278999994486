import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { ProjectIdeaComponent } from './project-idea/project-idea.component';
import { ProjectConceptComponent } from './project-concept/project-concept.component';
import { ComputeConfigurationComponent } from './compute-configuration/compute-configuration.component';
import { AdditionalFilesComponent } from './additional-files/additional-files.component';
import { FormGroup } from '@angular/forms';
import { CITCDetailsComponent } from './citcdetails/citcdetails.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ProjectManagementService } from '../project-management.service';
import { projectCreation, StepperObj5} from './model/model';
import { StorageService } from 'src/app/services/storage.service';
import { ReviewProjectProposalComponent } from './review-project-proposal/review-project-proposal.component';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  encapsulation: ViewEncapsulation.None

})



export class AddProjectComponent implements OnInit {
  projectCreation = projectCreation;
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;
  postObj: {};
  projectDetails: {};
  currentStepIndex: number;
  SELECTEDINDEX: number;
  isLinear: boolean = true;
  formDataObj: StepperObj5;
  stopMovingToNextStep: boolean = false;
  stopMovingToconecpt: boolean = false;
  stopMovingReview: boolean = false;
  projectID: string;
  projectName: string;
  currentStepIndexOnSave: number;
  isContinueFromFlowTriggered: boolean = false;
  disableConfirmationPopup:boolean = true;
  fileListDeleteOnCancel: { fileName: string, fileId: string, projectFileType: string }[] = []
  fileListDeleteOnSave: { fileName: string, fileId: string, projectFileType: string }[] = []
  rejectedProjectFileList:{ fileName: string, fileId: string, projectFileType: string }[]  = []
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(ProjectIdeaComponent, { static: true }) step1Component: ProjectIdeaComponent;
  @ViewChild(ProjectConceptComponent, { static: true }) step2Component: ProjectConceptComponent;
  @ViewChild(ComputeConfigurationComponent, { static: true }) step3Component: ComputeConfigurationComponent;
  @ViewChild(AdditionalFilesComponent, { static: true }) step4Component: AdditionalFilesComponent;
  @ViewChild(CITCDetailsComponent, { static: true }) step5Component: CITCDetailsComponent;
  @ViewChild(ReviewProjectProposalComponent, { static: true }) step6Component: ReviewProjectProposalComponent;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef,
    public readonly activatedRoute: ActivatedRoute,
    public readonly service: ProjectManagementService,
    public readonly storageService: StorageService,
    public readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly matDialog: MatDialog) {
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
    this.projectName = this.activatedRoute.snapshot.queryParams["projectName"];
  }

  ngOnInit(): void {
    this.isLinear = true;
    //this.getProjectKey();
    this.formObjCreate();
    if (this.projectID) {
      this.setCurrentTab();
    }

    window.addEventListener('beforeunload', (e) => {
      if (this.router.url.includes('/projectmanagement/createproject')) {
        e.returnValue = 'Value';
        e.preventDefault();
      }
    })
   
  }

  async ngAfterViewInit() {
    this.form1 = this.step1Component?.projectIdea;
    this.form2 = this.step2Component?.projectConcept;
    this.form3 = this.step3Component?.computeConfig;
    this.form4 = this.step4Component?.additionalFiles;
    this.form5 = this.step5Component?.citcDetails;
    this.form6 = this.step6Component?.review;
    this.conditionToDisableStepperHeader();
    this._changeDetectorRef.detectChanges();
  }

conditionToDisableStepperHeader(){
  this.form2.controls['isIsctIdeaEndorsed'].valueChanges.subscribe((sv) => {
    if (sv === 'No') {
      this.stopMovingToNextStep = true;
     this.fileListTobeRemoveOnReject(this.form4.controls['budgetFiles'].value,'budgetFiles')
     this.fileListTobeRemoveOnReject(this.form4.controls['otherFiles'].value,'othersFiles')
     this.fileListTobeRemoveOnReject(this.form5.controls['citcFiles'].value,'citcFiles')
     console.log(this.rejectedProjectFileList)
    }
    else {
      this.stopMovingToNextStep = false;
      this.rejectedProjectFileList = []
    }
  });
  this.form5.controls['isCitcIdeaEndorsed'].valueChanges.subscribe((sv) => {
    if (sv === 'No') {
      this.stopMovingReview = true;
    }
    else {
      this.stopMovingReview = false;
    }
  });
  for (let i = 0; i < this.currentStepIndex; i++) {
    this.stepper.next();
  }
}

fileListTobeRemoveOnReject(arrayOfObject,projectFileType){
  arrayOfObject.forEach(element => {
    //console.log(element)
    const deleteObj = {
      "fileName": element.fileName,
      "fileId": element.fileId,
      "projectFileType": projectFileType
    }
    this.rejectedProjectFileList.push(deleteObj)
  }); 
}

async canDeactivate() {
  if(this.disableConfirmationPopup){
    const dialogRef = this.matDialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: {
        title: 'Warning',
        confiramtion_text: 'You will lose all entered values and attachments. Are you sure you want to exit project creation?',
        accept_btn: 'Yes',
        decline_btn: 'No'
      }
    });
    return await firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        let response = false
        if (result === 'accept') {
          response = true
           this.deleteFileList(this.fileListDeleteOnCancel)
        }
        return Promise.resolve(response)
      });
  }
  else{
    return null;
  }
}

protected onCancelEvent(){
  this.router.navigate(['projectmanagement']);
}

 deleteFileList(array) {
  const payload = {
    "projectKey": this.projectID,
    "region": "eu-central-1",
    "fileDetail": array
  }
if(array.length > 0){
  this.service.deletedSelectedFile(payload).subscribe({
    next: (res: any) => {
       if (res.result.fileDetail[0].fileId) {
        this.router.navigate(['projectmanagement']);
       }
    }
  });
}
}

  public onStepChange(event: StepperSelectionEvent): void {
    this.isLinear = true;
    this.formObjCreate();
    this.SELECTEDINDEX = event.selectedIndex;
  }

  // getProjectKey() {
  //   if (this.step1Component?.projectIdea.controls['projectIdeaName']?.value) {
  //     this.service.getDuplicateProjectName(this.step1Component?.projectIdea.controls['projectIdeaName']?.value).subscribe((response) => {
  //       if (!response.result.isProjectDuplicate) {
  //         this.projectID = response.result.projectKey;
  //       }
  //     });
  //   }
  // }


  onNext() {
    if (this.stepper.selectedIndex < this.stepper.steps.length - 1) {
      this.isLinear = true;
      this.currentStepIndex++;
      this.stepper.next();
    }
  }

  onPrevious() {
    if (this.stepper.selectedIndex > 0) {
      this.isLinear = true;
      this.currentStepIndex--;
      this.stepper.previous();
    }
  }


  onFormDataChanged(callingFrom: string = "") {
    this.disableConfirmationPopup = false;
    this.SELECTEDINDEX = this.stepper.selectedIndex + 1
    this.formObjCreate();
    this.service.addProject(this.postObj).subscribe({
      next: () => {
        if (callingFrom.length === 0) {
          this.router.navigate(['projectmanagement'])
          this.deleteFileList (this.fileListDeleteOnSave)
          this.deleteFileList(this.rejectedProjectFileList)
          if(this.stopMovingToNextStep){
            
          }
        }
        else if(callingFrom === 'approved'){
        const message = 'Project creation initiated successfully'
        this.toastrService.success(message);
        this.router.navigate(['projectmanagement'])
        }
      },
      error: () => {
        const message = 'Unable to save the project information'
        this.toastrService.error(message);
      }
    });
  }

  emitProjectKey(projectKey: string) {
    this.projectID = projectKey;
  }

  emitFileOnUplaod(fileList:{fileName:string,fileId:string,projectFileType:string}){
   this.fileListDeleteOnCancel.push(fileList)
   if(this.fileListDeleteOnCancel.length > 0){
    this.form1.controls['projectIdeaName'].disable();
   }
  }
  stepperFormDataObj() {
    this.formDataObj = {
      "projectIdea": this.step1Component?.projectIdea.valid ? this.step1Component.projectIdea.getRawValue() : null,
      "projectConcept": this.step2Component.projectConcept.valid ? this.step2Component.projectConcept.value : null,
      "computeConfig": this.step3Component.computeConfig.valid && !this.stopMovingToNextStep ? this.step3Component.computeConfig.getRawValue() : null,
      "additionalFiles": this.step4Component.additionalFiles.valid && !this.stopMovingToNextStep ? this.step4Component.additionalFiles.getRawValue() : null,
      "citcDetails": this.step5Component.citcDetails.valid && !this.stopMovingToNextStep ? this.step5Component.citcDetails.value : null,
      "review": this.step1Component?.projectIdea.valid && 
      this.step2Component?.projectConcept.valid && 
      this.step3Component?.computeConfig.valid && 
      this.step4Component?.additionalFiles.valid && 
      this.step5Component?.citcDetails.valid && !(this.stopMovingToNextStep || this.stopMovingReview) ? this.step6Component.review.getRawValue() : null,
    }
  }

  formObjCreate() {
    this.stepperFormDataObj();
    let storeTab;
    if (this.SELECTEDINDEX === 0 || this.SELECTEDINDEX === undefined) {
      storeTab = "1";
    }
    else {
      storeTab = this.SELECTEDINDEX + "";
    }
    this.postObj = {
      projectKey: this.projectID,
      projectName: this.step1Component?.projectIdea.controls['projectIdeaName']?.value,
      projectDescription: null,
      region: this.step1Component?.projectIdea.controls['region']?.value,
      publishToOrgData: null,
      roleName: this.storageService.secureStorage.getItem('currentUserRoles')[0],
      orgKey: environment.config.orgKey,
      userId: this.storageService.secureStorage.getItem('userUuid'),
      roleId: this.storageService.secureStorage.getItem('orgRoleId'),
      status: null,
      tabSaved: storeTab,
      projectDetails: { ...this.formDataObj }
    }
  }
  setDefaultTabSelection() {
    let allFormStatus = [this.form1.valid,
      this.form2.valid,
      this.form3.valid,
      this.form4.valid,
      this.form5.valid].every(e => e === true)
    console.log(this.currentStepIndexOnSave)
    if(allFormStatus){
        this.currentStepIndex = 5;
      }
    else if (this.currentStepIndexOnSave) {
      this.currentStepIndex = this.currentStepIndexOnSave - 2;
    }
    // else if(this.currentStepIndexOnSave === 6){
    //   this.currentStepIndex = 5;
    // }
    else {
      this.currentStepIndex = 0;
    }
    this.isContinueFromFlowTriggered = true;
    for (let i = 0; i < this.currentStepIndex; i++) {
      if (this.form1.valid === false) {
        break;
      }
      this.stepper.next();
    }
    this.isLinear = true;
    this.isContinueFromFlowTriggered = false;
  }

  setCurrentTab() {
    this.service.getProjectDetails(this.projectID).subscribe((data: any) => {
      this.currentStepIndexOnSave = data?.result?.projectDetails?.currentTab;
      this.form1.controls['projectIdeaName'].disable();
      this.form1.patchValue(data?.result?.projectDetails?.projectIdea);
      this.form2.patchValue(data?.result?.projectDetails?.projectConcept);
      this.form3.patchValue(data?.result?.projectDetails?.computeConfig);
      this.form4.patchValue(data?.result?.projectDetails?.additionalFiles);
      this.form5.patchValue(data?.result?.projectDetails?.citcDetails);
      this.form6.patchValue(data?.result?.projectDetails?.review);
      this.stepperFormDataObj();
      this.setDefaultTabSelection();
    });
  }

  validateInprogress() {
    const data: boolean[] =
      [
        Object.values(this.step1Component.supportfileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
        Object.values(this.step1Component.fileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
        Object.values(this.step2Component.pcfileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
        Object.values(this.step1Component.fileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
        Object.values(this.step4Component.fileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
        Object.values(this.step4Component.othersFileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
        Object.values(this.step5Component.fileUploadStatusData).some((x: any) => x ? x['status'] === "INP" : false),
      ]
    return data.some(x => x === true)
  }
}