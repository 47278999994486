import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavifyauthService } from '../../../services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { OrganizationManagementService } from '../organization-management.service';
import { environment } from 'src/environments/environment';
import { i18n } from '../../../i18n.en';

@Component({
  selector: 'app-organization-overview',
  templateUrl: './organization-overview.component.html',
  styleUrls: ['./organization-overview.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class OrganizationOverviewComponent implements OnInit {
  i18n = i18n;
  displayedColumns = ['OrganizationName', 'ExternalCollaboration', 'POName', 'StatusPO', 'StatusOrganization'];
  dataSource: any;
  isLoading = true;
  orgRole: string | null;
  orgKey: string;
  profile: any;
  currentUserPermissions: any;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private navifyServ: NavifyauthService, private storageService: StorageService, private organizationService: OrganizationManagementService) { }

  ngOnInit(): void {
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.getOrganizationDetails();
    this.orgRole = this.storageService.secureStorage.getItem("role");
    this.orgKey = environment.config.orgKey;
  }

  async logout() {
    await this.navifyServ.logOut();
  }

  getOrganizationDetails() {
    this.organizationService.getOrganizationDetails().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.result);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      this.isLoading = false;
    },
      err => {
        this.isLoading = false;
      });
  }
}
function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.orgMnt.PAGINATION;

  return customPaginatorIntl;
}