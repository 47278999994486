<!-- Page Loader -->
<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>
<mat-card>
    <mat-card-header>
        <mat-card-title class="title-matcard">
            {{i18n.projectMnt.TITLE_UO}}
        </mat-card-title>
        <!-- {{userviewDataSource.data | json}} -->
        <div class="search-btn-div">
            <mat-form-field #input *ngIf="userviewDataSource?.data?.length > 0" appearance="fill" floatLabel="always">
                <mat-icon class="mat-icon-search" matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon>
                <mat-icon matSuffix [hidden]="!showSearchCancelBtn" (click)="onSearchCancelBtn()">{{i18n.ICONS.CANCEL}}</mat-icon>
                <input matInput placeholder="Search here" (keyup)="applyFilter($event)" [formControl]="inputSearch"
                    autocomplete="off" />
            </mat-form-field>
        </div>

    </mat-card-header>
    <mat-card-content>
        <div class="no-data empty-dta" *ngIf="userviewDataSource?.data?.length === 0">{{i18n.projectMnt.LBL_NDF}}</div>

        <mat-table [dataSource]="userviewDataSource" *ngIf="userviewDataSource?.data?.length > 0" matSort
            matSortDirection="asc" matSortDisableClear>

            <!-- Radio button column -->
            <ng-container matColumnDef="radio" *ngIf="currentUserPermissions.projectMgntAccess.inviteRemoveUser">
                <th mat-header-cell *matHeaderCellDef class="radio-btn-headercell">
                </th>
                <td mat-cell *matCellDef="let element" class="radio-btn-cell">

                    <mat-radio-group [(ngModel)]="selectedRowElement" (change)="onSelectUser(element)">
                        <mat-radio-button [value]="element"></mat-radio-button>
                    </mat-radio-group>
                </td>
            </ng-container>

            <!-- User Name Column-->
            <ng-container matColumnDef="userEmail">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Username (Email ID)
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.userEmail}}
                </mat-cell>
            </ng-container>

            <!-- First Name Column -->
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Full Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.firstName+' '+element?.lastName}} </mat-cell>
            </ng-container>


            <!-- Action  Name Column -->
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="action-header"> Actions </mat-header-cell>
                <mat-cell class="action-cell" *matCellDef="let element">
                    <button class="table-rounded-action-btn" *ngIf="element.projectAvailable" mat-stroked-button
                        (click)="onViewProjects(element)" color="primary">View Projects</button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="userviewColumns;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: userviewColumns;"></mat-row>

        </mat-table>

    </mat-card-content>
    <mat-card-footer class="p-10">
        <button mat-stroked-button [disabled]="showRemove" (click)="onRemoveUSer()" *ngIf="currentUserPermissions.projectMgntAccess.inviteRemoveUser">{{i18n.projectMnt.UO.BTN_REMOVE}}</button>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
            [hidden]="userviewDataSource?.data?.length === 0">
        </mat-paginator>
    </mat-card-footer>

</mat-card>