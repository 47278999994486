<div class="tab-details container">
    <!-- <pre>{{projDetails | json}}</pre> -->
    <h3 *ngIf="isFromAuditProjectInfo">Project Details</h3>
    <div class="row" *ngIf="isFromAuditProjectInfo">
        <div class="col-6 mb-3">
            <mat-label>
                <p>Project Created By</p>
                <h4>{{projDetails?.createdBy ? projDetails?.createdBy : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Project Created On</p>
                <h4>{{projDetails?.createdDate ? (projDetails?.createdDate | date : 'MM/dd/yyyy') : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Approved By</p>
                <h4>{{projDetails?.review?.approvedBy ? projDetails?.review?.approvedBy : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Approved On</p>
                <h4>{{projDetails?.review?.approvedOn ? (projDetails?.review?.approvedOn | date : 'MM/dd/yyyy') : '-'}}</h4>
            </mat-label>
        </div>       
        <div class="col-6 mb-3 text-break">
            <mat-label>
                <p>Transferred To</p>
                <h4>{{projDetails?.projectDetails?.transfer?.transferTo ? (projDetails?.projectDetails?.transfer?.transferTo) : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3 text-break">
            <mat-label>
                <p>Transferred On</p>
                <h4>{{projDetails?.projectDetails?.transfer?.transferOn ? (projDetails?.projectDetails?.transfer?.transferOn | date : 'MM/dd/yyyy') : '-'}}</h4>
            </mat-label>
        </div>
        </div>
        <h3 *ngIf="isFromAuditProjectInfo">Project Idea</h3>
    <div class="row">
        <div class="col-6 mb-3">
            <mat-label>
                <p>Project Name (Scientific Study Title) *</p>
                <h4>{{projectIdea?.projectIdeaName}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Region *</p>
                <h4>{{projectIdea?.piRegion?.regionName}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Project Decription *</p>
                <h4>{{projectIdea?.projectIdeaDesc}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Email Address *</p>
                <h4>{{projectIdea?.email}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>First name of Principal Investigator *</p>
                <h4>{{projectIdea?.principalFirstName}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Last Name of Principal Investigator *</p>
                <h4>{{projectIdea?.principalLastName}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Lead Site/ Institution *</p>
                <h4>{{projectIdea?.leadSite}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>First Name of other idea co author(s) *</p>
                <h4>{{projectIdea?.coauthorFirstName}}</h4>
            </mat-label>
        </div>
        <div class="col-6 mb-3">
            <mat-label>
                <p>Last Name of other idea co author(s) *</p>
                <h4>{{projectIdea?.coauthorLastName}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Name(s) of Collaborating Site(s)/Institution(s) *</p>
                <h4>{{projectIdea?.collaboratingSite}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Disease Area *</p>
                <h4>{{projectIdea?.diseaseArea}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Study Type *</p>
                <h4>{{projectIdea?.studyType}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Please describe the scientific question and rationale that will be answered
                    with the study incl. essential information about study design *</p>
                <h4>{{projectIdea?.studyDesignDesc}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Drug Products that are being researched in the study, if applicable *</p>
                <h4>{{projectIdea?.drugDetails}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Who have you discussed the idea with from Roche already? Names of (project)
                    affiliated Roche Scientists </p>
                <h4>{{projectIdea?.scientistDetails ? projectIdea?.scientistDetails : '-' }}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Is your IDEA submitted in response to the "Immune Fitness" RFP?</p>
                <h4>{{projectIdea?.immuneFitness ? projectIdea?.immuneFitness : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Which RFP generated this project, if any?</p>
                <h4>{{projectIdea?.rfpDetails ? projectIdea?.rfpDetails : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Please fill in your specific expertise</p>
                <h4>{{projectIdea?.specialExpertise ? projectIdea?.specialExpertise: '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Additional Information supporting the IDEA (i.e. a document with graphics)</p>
                <div class="create-project-table-container">
                    <div class="table-container">
                        <table mat-table [dataSource]="supportingdataSource?.data?.length > 0 ? supportingdataSource : emptyData" class="mat-elevation-z0" matSort  #table1Sort="matSort">
                            <!-- Filename Column -->
                            <ng-container matColumnDef="fileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                            </ng-container>

                            <!-- download Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element">
                                    <a (click)="service.getDownloadPresignedURL(element,'supportingFiles',projectID);" class="browse-button">Download</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="empty-row">
                                <td mat-cell class="mt-3 d-flex justify-content-center"
                                    *matCellDef="let element">no record found</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: supportingdataSource?.data?.length > 0 ? displayedColumns: ['empty-row'];"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                        class="mat-paginator-sticky" #table1Paginator>
                    </mat-paginator>
                </div>

            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>imCORE Study Identifier</p>
                <h4>{{projectIdea?.imCoreIdentifier ? projectIdea?.imCoreIdentifier : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>MDMS Study Identifier</p>
                <h4>{{projectIdea?.studyIdentifier ? projectIdea?.studyIdentifier : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Status *</p>
                <h4>{{projectIdea?.projectStatus}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Remark</p>
                <h4>{{projectIdea?.remarks ? projectIdea?.remarks : '-'}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Current Status details *</p>
                <h4 id="currentStatus">{{projectIdea?.statusDetails}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>sARM *</p>
                <h4>{{projectIdea?.sarm}}</h4>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Recent communication to Project Investigator</p>
                <div class="create-project-table-container">
                    <div class="table-container">
                        <table mat-table [dataSource]="communicationDataSource?.data.length > 0  ? communicationDataSource : emptyData" class="mat-elevation-z0" matSort #table2Sort="matSort">
                            <!-- Filename Column -->
                            <ng-container matColumnDef="fileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                            </ng-container>

                            <!-- Download Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element">
                                    <a  (click)="service.getDownloadPresignedURL(element,'communicationFiles',projectID);" class="browse-button">Download</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="empty-row">
                                <td mat-cell class="mt-3 d-flex justify-content-center"
                                    *matCellDef="let element">no record found</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: communicationDataSource?.data?.length > 0 ? displayedColumns: ['empty-row'];"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                        class="mat-paginator-sticky" #table2Paginator>
                    </mat-paginator>
                </div>
            </mat-label>
        </div>
        <div class="col-12 mb-3">
            <mat-label>
                <p>Year of Submission</p>
                <h4>{{projectIdea?.submissionYear ? (projectIdea?.submissionYear | date: "MM/dd/yyyy") : '-'}}</h4>
            </mat-label>
        </div>
    </div>
</div>