import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthguardService } from './services/authguard.service';
import { UsermanagementComponent } from './pages/user-management/usermanagement/usermanagement.component';
import { ApplobbyComponent } from './pages/applobby/applobby.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { RoleGuard } from './services/role.guard';
import { environment as env } from 'src/environments/environment';
import { LogoutComponent } from './pages/logout/logout.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { DatacatalogOverviewComponent } from './pages/data-catalog/datacatalog-overview/datacatalog-overview.component';
import { TcGuard } from './services/tc.guard';
import { FileProgressGuard } from './services/fileProgress.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'applobby',
    component: ApplobbyComponent,
    canActivate: [AuthguardService, RoleGuard, TcGuard],
    data: {
      allowedRoles: [
        env.config.roles.XCAP_PLATFORM_ADMIN,
        env.config.roles.starcap_platform_admin,
        env.config.roles.imcore_scientific_director,
        env.config.roles.inspector_gadget,
        env.config.roles.imcore_member,
        env.config.roles.imcore_member_data_ingress_only,
        env.config.roles.data_controller,
        env.config.roles.imcore_guest,
        env.config.roles.site_dpo_gpo
      ]
    }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [ FileProgressGuard ],
  },
  {
    path: 'termsconditions',
    component: TermsConditionsComponent,
    canActivate: [AuthguardService],
    data: { breadcrumb: 'TermsConditions', path: 'termsconditions' }
  },
  {
    path: 'organization',
    loadChildren: () => import('./pages/organization-management/organization-management.module').then(m => m.OrganizationManagementModule),
    canActivate: [AuthguardService, RoleGuard, TcGuard],
    data: {
      breadcrumb: 'organization', path: 'organization',
      allowedRoles: [
        env.config.roles.XCAP_PLATFORM_ADMIN,
        env.config.roles.starcap_platform_admin
      ]
    }
  },
  {
    path: 'usermanagement',
    loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [AuthguardService, RoleGuard, TcGuard],
    data: {
      breadcrumb: 'User management', path: 'usermanagement',
      allowedRoles: [
        env.config.roles.XCAP_PLATFORM_ADMIN,
        env.config.roles.starcap_platform_admin
      ]
    }
  },
  {
    path: 'projectmanagement',
    loadChildren: () => import('./pages/project-management/project.module').then(m => m.ProjectModule),
    canActivate: [AuthguardService, RoleGuard, TcGuard],
    data: {
      breadcrumb: 'Project Management', path: 'projects', title: 'Project Management',
      allowedRoles: [
        env.config.roles.imcore_scientific_director,
        env.config.roles.inspector_gadget,
        env.config.roles.imcore_member,
        env.config.roles.imcore_member_data_ingress_only,
        env.config.roles.data_controller,
        env.config.roles.site_dpo_gpo
      ]
    },
  },
  {
    path: 'datacatalog',
    loadChildren: () => import('./pages/data-catalog/catalog.module').then(m => m.CatalogModule),
    canActivate: [AuthguardService, RoleGuard, TcGuard],
    data: {
      breadcrumb: 'datacatalog', path: 'datacatalog',
      allowedRoles: [
        env.config.roles.imcore_scientific_director,
        env.config.roles.inspector_gadget,
        env.config.roles.imcore_member,
        env.config.roles.imcore_member_data_ingress_only,
        env.config.roles.data_controller,
        env.config.roles.site_dpo_gpo
      ]
    }
  },
  {
    path: 'projectaudit',
    loadChildren: () => import('./pages/project-audit/project-audit.module').then(m => m.ProjectAuditModule),
    canActivate: [AuthguardService, RoleGuard, TcGuard],
    data: {
      breadcrumb: 'projectaudit', path: 'projectaudit',
      allowedRoles: [       
        env.config.roles.site_dpo_gpo
      ]
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
