import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { i18n } from 'src/app/i18n.en';
import { CommonService } from 'src/app/services/common.service';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-project-audit-files',
  templateUrl: './project-audit-files.component.html',
  styleUrls: ['./project-audit-files.component.scss']
})
export class ProjectAuditFilesComponent implements OnInit {
  i18n = i18n;
  projectName: string;
  selectedTabIndex: number = 0;
  profile;
  orgRole: string | null;
  projectID: string;
  roleId:string;
  currentUserPermissions;
  queryParamData;
  breadcrumbTitle: string;
  constructor(private route: ActivatedRoute,public commonSer: CommonService, private readonly router: Router,
    public storageService: StorageService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.breadcrumbTitle = "";
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams['selectProjectTabIndex'])
        this.selectedTabIndex = Number(queryParams['selectProjectTabIndex']);
    });
    if (this.selectedTabIndex === 0) {
      this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_SAR;
    } else if (this.selectedTabIndex === 1) {
      this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_PI;
    } else if (this.selectedTabIndex === 2) {
      this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_MU;
    } else if (this.selectedTabIndex === 3) {
      this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_AH;
    }

    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.profile = { displayName: localStorage.getItem('dn'), email: localStorage.getItem('email') };

    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParamData = queryParams;
    });
    this.commonSer.rumConfig();
  }
      onTabClick(tab) {
        this.selectedTabIndex = tab.index;
        if (this.selectedTabIndex === 0) {
          this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_SAR;
        } else if (this.selectedTabIndex === 1) {
          this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_PI;
        } else if (this.selectedTabIndex === 2) {
          this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_MU;
        } else if (this.selectedTabIndex === 3) {
          this.breadcrumbTitle = i18n.projectAudit.TABS.TAB_AH;
        }
        this.router.navigate([], { relativeTo: this.route, queryParams: {...this.queryParamData, selectProjectTabIndex:this.selectedTabIndex}});
    
      }
}
