import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NavifyauthService } from '../../services/navifyauth.service';
import { StorageService } from '../../services/storage.service';
import { CommonService } from 'src/app/services/common.service';
import { environment as env } from 'src/environments/environment';
import { i18n } from '../../i18n.en';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  i18n = i18n;
  showTerms: boolean;
  completeTraining = false;
  accessUser: boolean;
  trainingStatus: boolean;
  termsAndConditionStatus: boolean;
  roleStatus: boolean;
  orgRole = '';
  trianingStatus = '';
  userUuid: string;
  isLoading: boolean;
  result: any;
  ContentTC: any;
  trianingStatusValue: string;
  tcAccepted: boolean;
 

  constructor(private router: Router, private navifyServ: NavifyauthService, private storageService: StorageService,
    private toastrService: ToastrService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.isLoading = true;
    const isNavifyRoleExist = this.storageService.secureStorage.getItem('isNavifyRoleExist');

    if (isNavifyRoleExist == 'true') {
      this.trianingStatus = this.storageService.secureStorage.getItem('trianingStatus');
      this.tcAccepted = JSON.parse(this.storageService.secureStorage.getItem('tcAccepted'));

      this.getRoleDetails();
      this.getTermsConditionStatus();
    }
    else {
      this.roleStatus = true;
      this.isLoading = false;
    }
  }

  async logout() {
    await this.navifyServ.logOut();
    window.location.reload();
  }

  getTermsConditionStatus() {
    this.commonService.getTermsConditionStatus().subscribe((res: any) => {
      this.ContentTC = res.result.contents;
    },
      err => {
        this.isLoading = false;
        // console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      });
  }

  getRoleDetails() {
      if (this.trianingStatus === 'completed') {
        if (this.tcAccepted === true) {
          this.router.navigate(['applobby']);
        }
        else {
          this.termsAndConditionStatus = true;
        }
      } else if (this.trianingStatus === 'pending') {
        this.trainingStatus = true;
      }
    // }
    else {
      this.roleStatus = true;
    }
    this.isLoading = false;
  }

  onDecline() {
    this.toastrService.warning(i18n.TNC.DECLINE);
    setTimeout(() => {
      this.toastrService.clear();
      this.logout();
    }, 4000);
  }

  onAccepted() {
    const userUUID = this.storageService.secureStorage.getItem('userUuid');
    this.termsAndConditionStatus = false;
    this.isLoading = true;
    /* this.commonService.updateTermsConditionStatus(userUUID).subscribe((result: any) => {
      this.router.navigate(['applobby']); */
      this.commonService.updateTermsConditionStatus(userUUID).subscribe((result: any) => {

        this.commonService.getCurrentUserRole({userUuid: userUUID}).subscribe((res:any)=>{  
          this.storageService.secureStorage.getItem('userUuid')  
           const ts = res['result']['roleDetails'][0]['trianingStatus'].toLowerCase();  
           const accept = this.tcAccepted = res['result']['tcAccepted'];  
           this.storageService.secureStorage.setItem('trianingStatus', ts);
           this.storageService.secureStorage.setItem('tcAccepted', accept);
           this.isLoading = false;
          this.router.navigate(['applobby']);  
        })
    },
      err => {
        // console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      });
  }
}