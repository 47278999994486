import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';
import { UserManagementService } from 'src/app/pages/user-management/user-management.service';
import { CommonService } from 'src/app/services/common.service';
import { environment as env} from 'src/environments/environment';

@Component({
  selector: 'app-edit-collaborator',
  templateUrl: './edit-collaborator.component.html',
  styleUrls: ['./edit-collaborator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditCollaboratorComponent implements OnInit {
  i18n = i18n;
  role:any = env.config.roles;
  selectedValue: string;
  selectedCar: string;
  showUpdate = false;
  selectedRowElement: any;
  roles: any = [];
  exceptCurrentRoles: any = [];
  projectDetailsList: any;
  roleId: string;
  roleName: string;
  isDataControllerExist: boolean;
  isLoading: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditCollaboratorComponent>, public toastrService: ToastrService,
  readonly commonService: CommonService, private readonly userService: UserManagementService) {
    this.projectDetailsList = data.selectedRowElement;
    this.isDataControllerExist = data.isDataControllerExist;
  }

  ngOnInit(): void {
    this.showUpdate = true;
    this.getRoles();
  }

  getRoles() {
    this.commonService.getPermissionRoles(this.projectDetailsList.orgName).subscribe((res: any) => {
      this.roles = res.result.orgProjectRoles;
      this.exceptCurrentRoles = this.roles?.filter((role: any) => role.roleName !== this.projectDetailsList.roleName);
    });
  }

  onChange(roleName: any) {
    this.roleName = roleName;
    this.roles.forEach((role: any) => {
      if (role.roleName === roleName) {
        this.roleId = role.roleId;
      }
    });
    this.showUpdate = false;
  }

  onCancel() {
    this.dialogRef.close('no');
  }

  onUpdate() {
    const obj = {
      "firstName": this.projectDetailsList.firstName,
      "lastName": this.projectDetailsList.lastName,
      "userType": this.projectDetailsList.userType,
      "userEmail": this.projectDetailsList.userEmail,
      "roleName": this.roleName,
      "roleId": this.roleId,
      "roleType": this.projectDetailsList.roleType,
      "description": this.projectDetailsList.description,
      "orgName": this.projectDetailsList.orgName,
      "orgKey": this.projectDetailsList.orgKey,
      "projectName": this.projectDetailsList.projName,
      "projectKey": this.projectDetailsList.projectKey,
      "userUuid": this.projectDetailsList.userUuid,
      "roleHashKey": this.projectDetailsList.roleHashKey
    }
    this.isLoading = true;
    this.userService.editCollaborators(obj).subscribe((data: any) => {
      if(data.responseCode==='200'||data.responseCode==='201'){         
        this.dialogRef.close('yes');         
        this.toastrService.success(this.i18n.projectMnt.collaborators.EC.TOASTR_S);
      }else{
          this.dialogRef.close();
          this.toastrService.error(data.message);
        }  
        this.isLoading = false;   
    },
      err => {
        this.isLoading = false; 
        this.toastrService.error(this.i18n.projectMnt.collaborators.EC.TOASTR_E);
        this.dialogRef.close();
      });
  }
}