import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CitcDetails, projectCreation } from '../../model/model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectManagementService } from '../../../project-management.service';
import { ActivatedRoute } from '@angular/router';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-citc-details',
  templateUrl: './citc-details.component.html',
  styleUrls: ['./citc-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class CitcDetailsComponent implements OnInit, OnChanges {
  @Input() citcDetails: CitcDetails | undefined;
  i18n = i18n;
  @Input() projectID: string;
  @ViewChild(MatSort) citcFilessort: MatSort;
  @ViewChild('citcFilesPaginator', { static: true }) citcFilesPaginator!: MatPaginator;
  citcFilesDataSource;
  emptyData;
  projectCreation = projectCreation;
  displayedColumns: string[] = ['fileName', 'action'];
  constructor(public readonly service: ProjectManagementService,
    public readonly activatedRoute: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
  
      this.citcFilesDataSource = new MatTableDataSource(this.citcDetails?.citcFiles)
      this.citcFilesDataSource.paginator = this.citcFilesPaginator;
      this.citcFilesDataSource.sort = this.citcFilessort;
      this.emptyData = new MatTableDataSource([{ empty: "row" }])

  }
  ngOnInit(): void {
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
  }

}
function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;
  return customPaginatorIntl;
}