<div class="approve-popup-card">
    <div class="loadingcontainer" *ngIf="isLoading">
        <div  class="loading-modal-spinner">
            <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
            </mat-progress-spinner>
        </div>
    </div>
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{i18n.userManagement.ApproveUser.LBL_AU}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p class="confirmation-text">{{i18n.userManagement.ApproveUser.DES_Q}}</p>
                <form [formGroup]="approveForm">
            <mat-form-field appearance="fill">
                    <mat-label>{{i18n.userManagement.ApproveUser.LBL_REMARKS}}</mat-label>
                    <textarea matInput #message maxlength="100" formControlName="remarks"></textarea>                 
                    <mat-hint>{{message.value.length}} / 100</mat-hint>
            </mat-form-field>
        </form>
        </mat-card-content>
        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button (click)="onCancel()">{{i18n.userManagement.ApproveUser.BTN_CANCEL}}</button>
            <button mat-raised-button (click)="onApprove()" [disabled]="!approveForm.valid">{{i18n.userManagement.ApproveUser.BTN_APPROVE}}</button>
        </div>
        </mat-card-footer>
    </mat-card>
</div>