import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extension'
})
export class ExtensionPipe implements PipeTransform {

  transform(item:any):string {
    return item.substring(item.indexOf('.')+1);
  }

}
