import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { i18n } from 'src/app/i18n.en';
import { DatePipe } from '@angular/common';
import { BytesToMBPipe } from 'src/app/pipes/bytes-to-mb.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-approvals',
  templateUrl: './my-approvals.component.html',
  styleUrls: ['./my-approvals.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]  
})
export class MyApprovalsComponent implements OnInit {
  i18n = i18n;
  overviewList: any = [];
  orgKey: any;
  isLoading = false;
  showSearchCancelBtn: boolean;
  hasNoUserData = false;
  myApprovalsDataSource: any = [];
  queryParamsData: any;
  projectKey:string;
  datasetStatus: string;
  myApprovalsviewColumns = ['datasetName', 'datasetId', 'dataFilesCount', 'datasetSize', 'datasetStatus', 'requestedBy', 'createdDate', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private storageService: StorageService, private projectService: ProjectManagementService, private activatedRoute: ActivatedRoute,
    public router: Router, private bytesToMb: BytesToMBPipe) { }

  public inputSearch: FormControl = new FormControl('');

  ngOnInit(): void {
    this.orgKey = environment.config.orgKey;
    this.activatedRoute.queryParams.subscribe(queryParams => {      
      this.queryParamsData = queryParams;
      this.projectKey =  queryParams['projectKey'];
    });
    this.getMyApprovalsData();        
  }

  getMyApprovalsData() {
    this.isLoading = true;
    this.projectService.getmyApprovalsList(this.queryParamsData.projectKey, this.orgKey).subscribe((data: any) => {
     
      for(const i in data.result){
        if(data.result[i].createdDate != null){
      const datepipe: DatePipe = new DatePipe('en-US');
          const testDate = datepipe.transform( data.result[i].createdDate, "y-MMM-dd HH:mm:ss");
          data.result[i].hiddencreatedDate = testDate;
        }
        if(data.result[i].datasetSize != null){
              const testSize = this.bytesToMb.transform( data.result[i].datasetSize);
              data.result[i].hiddenDatasetSize = testSize;
            }
      }
       this.overviewList = data.result;
      this.myApprovalsDataSource = new MatTableDataSource(this.overviewList);
      this.myApprovalsDataSource.paginator = this.paginator;
      setTimeout(() => this.myApprovalsDataSource.sort = this.sort);
      setTimeout(() => this.myApprovalsDataSource.paginator = this.paginator);
      this.hasNoUserData = (this.myApprovalsDataSource.data.length === 0) ? true : false;
      this.isLoading = false; 
    },
    err => {
      this.isLoading = false;
    });

  }
      
  ngAfterViewInit() {
    setTimeout(() => this.myApprovalsDataSource.paginator = this.paginator);
    setTimeout(() => this.myApprovalsDataSource.sort = this.sort);
  }

  applyFilter(event: Event) {
    this.showSearchCancelBtn = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.myApprovalsDataSource.filter = filterValue.trim().toLowerCase();
    if(!filterValue){
      this.showSearchCancelBtn = false;
    }
  }

  onSearchCancelBtn() {
    this.showSearchCancelBtn = false;
    this.inputSearch.reset();
    this.getMyApprovalsData();
  }

  datasetDetails(datasetId:any, datasetStatus:any){
    if(datasetStatus == "Pending for Approval"){
        this.datasetStatus = "associate";
      } else {
        this.datasetStatus = "approval";
      }
    this.router.navigate(['projectmanagement/myapprovalsdatasetdetails'], {
      queryParams: {
        projectKey: this.queryParamsData.projectKey,
        orgKey: this.queryParamsData.orgKey,
        projectName: this.queryParamsData.projectName,
        region: this.queryParamsData.region,
        projectDescription: this.queryParamsData.projectDescription,
        dataClassification: this.queryParamsData.dataClassification,
        publishToOrgData: this.queryParamsData.publishToOrgData,
        createdDate: this.queryParamsData.createdDate,
        modifiedDate: this.queryParamsData.modifiedDate,
        createdBy: this.queryParamsData.createdBy,
        siteName: this.queryParamsData.siteName,        
        datasetId: datasetId,
        datasetStatus: this.datasetStatus,
        roleId: this.queryParamsData.roleId,
      }
    });

  }

}
function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;

  return customPaginatorIntl;
}