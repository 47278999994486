import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  profile:any;
  projectRoles: any;
  orgRole = '';
  orgKey: string;
  platformAdminRole: string;
  dataControllerRole: string;
  imcoreScientificDirectorRole: string;
  imcoreMemberRole: string;
  imcoreGuestRole: string;
  siteDpoGpoRole: string;
  inspectorGadgetRole: string;
  count = 0;

  constructor(private storageService: StorageService, private commonService: CommonService) { }

  ngOnInit(): void {
    const obj = {
      userUuid: this.storageService.secureStorage.getItem('userUuid')
    };

    this.commonService.getCurrentUserRole(obj).subscribe((res: any) => {
      this.orgRole = (res['result']['roleDetails'][0]['roleName']);
      this.orgKey = (res['result']['roleDetails'][0]['orgKey']);

      this.commonService.getPermissionRoles(this.orgKey).subscribe((data: any) => {
        this.projectRoles = data['result']['roleDetails'];
        console.log("User role: ",this.orgRole);

        //role
        for (const item of this.projectRoles ){
          for (const role in item ){
            console.log("item=>role: ", role, this.count);
            if(this.count == 0)
            this.platformAdminRole = role;
            else if(this.count == 1)
            this.dataControllerRole = role;
            else if(this.count == 2)
            this.imcoreScientificDirectorRole = role;
            else if(this.count == 3)
            this.imcoreMemberRole = role;
            else if(this.count == 4)
            this.imcoreGuestRole = role;
            else if(this.count == 5)
            this.siteDpoGpoRole = role;
            else if(this.count == 6)
            this.inspectorGadgetRole = role;             
            this.count++;
          }                
        }
        // end
      })
    },
      err => {
        console.log(`Err status: ${err.status} <br /> Error message: ${err.message}`);
      });

    
  }

}




