import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FileUploadService } from './file-upload.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { TOAST_CONFIG, ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/services/storage.service';
import { DataForFileUploadType, DataForEachFileInUploadType, FileWithPath, FileUploadApisType, UploadFileDetailObj } from './model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    FileUploadService
    // { provide: 'dbName', useValue: this.Identifier}
  ]
})
export class FileUploadComponent implements OnInit, OnChanges {
  @ViewChild('folderInput') folderInput: ElementRef;
  @Input() uploadStartToastMessage: string;
  @Input() maxFilesAllowed: number;
  @Input() maxFileSizeAllowedInGB: number;
  @Input() maxCumulativeSizeAllowedInGB: number;
  @Input() maxParallelFileUploads: number;
  @Input() maxParallelChunksUpload: number;
  @Input() maxChunkSizeInBytes: number;
  @Input() restrictedExtensions: string[];
  @Input() allowedExtensions: string[];
  @Input() dataForFileUpload: DataForFileUploadType;
  @Input() dataForEachFileInUpload: DataForEachFileInUploadType;
  @Input() Identifier: string
  @Input() fileUploadApis: FileUploadApisType
  @Output() completedFileEventReceiver = new EventEmitter<UploadFileDetailObj>();
  @Input() resetOnLoad: boolean;
  @Input() userUuid: string;
  @Input() projectKey: string;
  @Input() errForValidFileSize?: string;
  @Input() errForCumulativeFileSize?: string
  @Input() templateVersion?: string

  public files: NgxFileDropEntry[] = [];
  public filesList: any[] = [];
  public gbCalcDivider: number = Math.pow(1024, 3)

  constructor(public fileUploadService: FileUploadService,
    private storageService: StorageService,
    private readonly toastrService: ToastrService) {
    //  console.log("iidinigndg", this.Identifier)
    //   this.fileUploadService.createStore(this.Identifier)
    this.fileUploadService.completedFilesEventEmitter.subscribe((filename) => {
      this.completedFileEventReceiver.emit(filename)
    });
    this.templateVersion = 'v1'

  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes)
    this.fileUploadService.setServiceData(
      this.maxParallelFileUploads,
      this.maxParallelChunksUpload,
      this.maxChunkSizeInBytes,
       this.dataForFileUpload, 
       this.dataForEachFileInUpload,
       this.fileUploadApis,
       this.userUuid,
       this.projectKey
       )
  }

  ngOnInit(): void {
    // const USERID = this.storageService.secureStorage.getItem('userUuid');
    // console.log(USERID)
    // console.log("iidinigndg", this.Identifier)
    // this.fileUploadService.createStore(this.Identifier)
    // console.log(this.dataForFileUploadApi)
    // console.log(this.dataForFileUpload, this.dataForEachFileInUpload)
    this.fileUploadService.createStore(this.Identifier, this.resetOnLoad, this.userUuid)
    this.fileUploadService.setServiceData(
     this.maxParallelFileUploads,
     this.maxParallelChunksUpload,
     this.maxChunkSizeInBytes,
      this.dataForFileUpload, 
      this.dataForEachFileInUpload,
      this.fileUploadApis,
      this.userUuid,
      this.projectKey
      )

  }
  ngOnDestroy() {
    // console.log('Component Destroyed', this.Identifier);
  }

  openFolderSelector(){
    this.folderInput.nativeElement.click()
  }

  uploadFiles(files) {
    const arrFiles: File[] = Array.from(files);
    // console.log(arrFiles)
    const folderHolder = {};
    arrFiles.forEach((file: FileWithPath, index) => {
      console.log(file)
      file['path'] = file.webkitRelativePath
      if(index+1 == arrFiles.length){
        this.startUpload(arrFiles)
      }
    })
    this.folderInput.nativeElement.value = null;
  }

  async toFileList(files: NgxFileDropEntry[]) {
    let checkForUploadViaDrop = 'fullPath'  in files[0].fileEntry
    let filesList: FileWithPath[] = []
    files.forEach(async (droppedFile: NgxFileDropEntry, idx: number, array: NgxFileDropEntry[]) => {
      await new Promise(resolve => {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const fullPath = droppedFile.relativePath
          fileEntry.file(async (file: FileWithPath) => {
            file['path'] = fullPath
            filesList.push(file)
            resolve(filesList)
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
        if( idx + 1 === array.length && !checkForUploadViaDrop ){
          this.startUpload(filesList)
        } 
        
      }).then(async (res: any) => {
        if (res.length == files.length && checkForUploadViaDrop) {
          this.startUpload(res)

        }
      })
    });
  }

  async startUpload(filesList) {
    const validation_status = await this.validateFileList(filesList)
    if (validation_status) {
      const FilterFiles = filesList.filter((file: File) => !(this.restrictedExtensions.includes('.' + file.name.split('.').pop())))
      this.fileUploadService.addToDB(FilterFiles)
      if(this.uploadStartToastMessage){
        this.toastrService.success(this.uploadStartToastMessage)
      }
    }

  }

  validateFileList(files: File[]) {
    // let is_valid =  false
    return new Promise((resolve) => {
      if (!this.isMaxFilesValid(files)) {
        const message = 'File count selected for upload exceeds the file count limitation of ' + this.maxFilesAllowed + ' files per upload. Please revisit your selection.'
        this.toastrService.error(message)
        resolve(false)
      }
      if (!this.isExtensionsValid(files)) {
        const message = 'File with extension ' + this.restrictedExtensions.join(', ') + ' is restricted to upload'
        this.toastrService.error(message)
        resolve(true)
      }
      if (!this.isAllowedExtensionsValid(files)) {
        const extensionListfromInput = files.map((file) => "." + file.name.split('.').pop())
        const extNotInAllowed = [...new Set(extensionListfromInput.filter((ext)=> !(this.allowedExtensions.includes(ext))))] 
        const message = 'File with extension ' + extNotInAllowed.join(', ') + ' is restricted to upload'
        this.toastrService.error(message)
        resolve(false)
      }
      if (!this.isValidFileSize(files)) {
        let message = 'One or more files selected exceed the ' + this.maxFileSizeAllowedInGB + 'GB file size limit. Please use a sFTP tool to upload large files. For help, Click here.'
        if(this.errForValidFileSize){
          message = this.errForValidFileSize
        }
        this.toastrService.error(message)
        resolve(false)
      }
      if (!this.isValidCumulativeSize(files)) {
        let message = 'Total file size of the upload exceeds the total file size limitation of ' + this.maxCumulativeSizeAllowedInGB + 'GB. Please revisit your selection. Try sFTP tool to upload files that are larger than ' + this.maxFileSizeAllowedInGB + 'GB'
        if(this.errForCumulativeFileSize){
          message = this.errForCumulativeFileSize
        }
        this.toastrService.error(message)
        resolve(false)
      }
      resolve(true)
    })
  }

  isMaxFilesValid(files: File[]) {

    if (files.length > this.maxFilesAllowed) {
      return false
    }
    return true
  }

  isExtensionsValid(files: File[]) {
    const extensionList = files.map((file) => "." + file.name.split('.').pop())
    // console.log(this.restrictedExtensions, extensionList)
    const valid = extensionList.some((ext) => this.restrictedExtensions.includes(ext))
    return !valid
  };
  
  isAllowedExtensionsValid(files: File[]) {
    let valid;
    if(this.allowedExtensions.length===0){
      return true
    }
    const extensionList = files.map((file) => "." + file.name.split('.').pop())
    // console.log(this.restrictedExtensions, extensionList)
    valid = extensionList.every((ext) => this.allowedExtensions.includes(ext))
    return valid
  };
  isValidFileSize(files: File[]) {
    const sizeValidCheck = files.some((file) => (file.size / this.gbCalcDivider) > this.maxFileSizeAllowedInGB)
    return !sizeValidCheck
  }

  isValidCumulativeSize(filesList: File[]) {
    let isFileSizeAllowed = false;
    let totalSize = 0;
    for (const file of filesList) {
      totalSize = totalSize + file.size;
    }
    // console.log(totalSize, this.maxCumulativeSizeAllowedInGB)
    if (totalSize / this.gbCalcDivider < this.maxCumulativeSizeAllowedInGB) {
      isFileSizeAllowed = true;
    }
    return isFileSizeAllowed;
  }


  public async dropped(files: NgxFileDropEntry[]) {
    // console.log(new Date(), 'dropped', files)
    const filesList = await this.toFileList(files)
    // console.log(filesList)

  }



  public fileOver(event: any) {
    console.log(event);
  }
  onChange(event: any) {
    console.log(event)
  }

  public fileLeave(event: any) {
    console.log(event);
  }

}
