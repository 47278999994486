import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectManagementService } from '../../project-management.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { projectCreation, RegionModel,ProjectIdea, FileObj} from '../model/model';
import { i18n } from 'src/app/i18n.en';
import { StorageService } from 'src/app/services/storage.service';
interface SiteData {
  id?:string;
  ideaCount?:string;
  siteCode?:string;
  siteName?:string;
}
@Component({
  selector: 'app-project-idea',
  templateUrl: './project-idea.component.html',
  styleUrls: ['./project-idea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectIdeaComponent implements OnInit,OnChanges {
  projectCreation = projectCreation;
  i18n = i18n;
  projectIdea: FormGroup;
  siteDetails: SiteData[];
  diseaseArea: string[];
  studyType: string[];
  dataRegion: RegionModel[];
  fileList: File[] = [];
  communicationFileList: FileObj[] = [];
  allowedFileSize: number;
  allowedFilesCount: number;
  disableFileDrop: boolean = false;
  hasChange: boolean = false;
  dataForFileUpload: { projectKey: string; region: string; };
  dataForEachFile: { projectFileType: string; };
  supportdataForEachFile :{ projectFileType: string; };
  fileUploadApis: { fileupload: string; completeMultiPart: string; };
  fileUploadStatusData = {};
  supportfileUploadStatusData = {};
  maxDate:Date;
  minDate:Date;
  userUuid:string;
  alphaNumericNoSpaceValidator:Validators;
  alphabetWithHypen:Validators;
  alphaNumericWithRequiredValidator:Validators;
  alphaNumericWithNoRequiredValidator:Validators;
  public files: NgxFileDropEntry[] = [];
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() setExitingData:ProjectIdea;
  @Input() fileListDeleteOnSave;
  @Output() emitProjectKey: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitFileOnUplaod: EventEmitter<{ fileName: string, fileId: string, projectFileType: string }> = new EventEmitter<{ fileName: string, fileId: string, projectFileType: string }>();
  @ViewChild('comFileUpload') fileUploadComponent;
  @ViewChild('supportFileUpload') supportfileUploadComponent;
  constructor(
    public readonly service: ProjectManagementService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private storageService: StorageService) {
    this.alphaNumericNoSpaceValidator = [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
      , Validators.maxLength(projectCreation.formValidation.maxlength100), Validators.pattern(projectCreation.formValidation.projectNamePattern)];
    this.alphabetWithHypen = [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
      , Validators.maxLength(projectCreation.formValidation.maxlength50), Validators.pattern(projectCreation.formValidation.alphabetPattern)];
    this.alphaNumericWithRequiredValidator = [Validators.required,Validators.minLength(projectCreation.formValidation.minLength1)
      , Validators.maxLength(projectCreation.formValidation.maxLength250), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)];
      this.alphaNumericWithNoRequiredValidator = [Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxLength250), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)];
    this.projectIdea = new FormGroup({
    projectIdeaName: new FormControl(null, {validators: [Validators.required,
      Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength100),
      Validators.pattern(projectCreation.formValidation.projectNamePattern),
      ],updateOn: 'blur'}),
      piRegion: new FormControl({
        "regionName": "Europe (Frankfurt)",
        "regionValue": "eu-central-1"
      }, [Validators.required],),
      projectIdeaDesc:new FormControl(null, this.alphaNumericWithRequiredValidator),
      email: new FormControl(null, [Validators.required,Validators.email,Validators.pattern(projectCreation.formValidation.emailValidator)]),
      principalFirstName: new FormControl(null, this.alphabetWithHypen),
      principalLastName: new FormControl(null, this.alphabetWithHypen),
      leadSite: new FormControl(null, [Validators.required]),
      coauthorFirstName: new FormControl(null, this.alphabetWithHypen),
      coauthorLastName: new FormControl(null, this.alphabetWithHypen),
      collaboratingSite: new FormControl(null, [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength200), Validators.pattern(projectCreation.formValidation.collaboratingSitePattern)]),
      diseaseArea: new FormControl(null, [Validators.required]),
      studyType: new FormControl([], [Validators.required]),
      studyDesignDesc: new FormControl(null, this.alphaNumericWithRequiredValidator),
      drugDetails: new FormControl(null, this.alphaNumericWithRequiredValidator),
      scientistDetails: new FormControl(null, this.alphaNumericWithNoRequiredValidator),
      immuneFitness: new FormControl(null),
      rfpDetails: new FormControl(null, this.alphaNumericWithNoRequiredValidator),
      specialExpertise: new FormControl(null, this.alphaNumericWithNoRequiredValidator),
      supportingIdeaFiles: new FormControl([]),
      imCoreIdentifier: new FormControl(null),
      studyIdentifier: new FormControl(null, [Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength50), Validators.pattern(projectCreation.formValidation.studyIdentifierPattern)]),
      projectStatus: new FormControl(null, [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength100), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)]),
      remarks: new FormControl(null, [Validators.minLength(1)
        , Validators.maxLength(projectCreation.formValidation.maxlength500), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscore)]),
      statusDetails: new FormControl({value:null,disabled:true}),
      sarm: new FormControl(null, [Validators.required, Validators.minLength(projectCreation.formValidation.minLength1)
        , Validators.maxLength(projectCreation.formValidation.maxlength100), Validators.pattern(projectCreation.formValidation.patternWithHypenAndUnderscoreonly)]),
      communication: new FormControl([]),
      submissionYear: new FormControl(null)
    });
    const allowedFiles = 20;
    const allowedSize = 10737418240;
    this.allowedFileSize = allowedSize;
    this.allowedFilesCount = allowedFiles;
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 10);
    this.maxDate = new Date();
    this.userUuid = this.storageService.secureStorage.getItem('userUuid');
  }

  ngOnInit(): void {
    this.getSiteandInsitutionList();
    this.getDiseaseAreaList();
    this.getStudyTypeList();
    this.getRegionList();
    this.isProjectIdeaNameValid();
      this.dataForFileUpload = {
        projectKey: this.projectID,
        region: 'eu-central-1'
      }
      this.dataForEachFile = {
        projectFileType: 'communicationFiles'
      }
    this.supportdataForEachFile = {
      projectFileType: 'supportingFiles'
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectID']) {
    this.emitProjectKey.emit(this.projectID);
    this.dataForFileUpload = {
       projectKey: this.projectID,
       region: 'eu-central-1'
     }
    }
    if(this.projectID && changes['setExitingData']){
      this.updateTheValuesExist();
    }

  }
  ngAfterViewInit() {
    this.fileUploadStatusData = this.fileUploadComponent.fileUploadService.filesListObject;
    this.supportfileUploadStatusData = this.supportfileUploadComponent.fileUploadService.filesListObject;
    this._changeDetectorRef.detectChanges();
   
  }
  toList(data):any[]{
    return Object.values(data)
  }
  fileUploadCompletionEvent(fileDetails) {
    console.log(fileDetails)
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId" : fileDetails.fileId,
      "fileSize":String(fileDetails.fileDetails.size),
      "uploadId" : fileDetails.uploadId,
      "fileType" : fileDetails.fileName.split('.').pop()
 }
 const deleteObj = {
  "fileName": fileDetails.fileName,
  "fileId": fileDetails.fileId,
  "projectFileType": 'communicationFiles'
}

 if(this.projectIdea.controls["communication"].value === null){
  this.projectIdea.controls["communication"].setValue([])
 } 
  this.projectIdea.controls["communication"].value.push(obj);
    this.emitFileOnUplaod.emit(deleteObj);
  this.projectIdea.controls["communication"].setValue(this.projectIdea.controls["communication"].value);
  }

  supportFileUploadCompletionEvent(fileDetails){
    console.log(fileDetails)
    const obj = {
      "fileName": fileDetails.fileName,
      "fileId" : fileDetails.fileId,
      "fileSize":String(fileDetails.fileDetails.size),
      "uploadId" : fileDetails.uploadId,
      "fileType" : fileDetails.fileName.split('.').pop()
 }
 const deleteObj = {
  "fileName": fileDetails.fileName,
  "fileId": fileDetails.fileId,
  "projectFileType": 'supportingFiles'
}
 if(this.projectIdea.controls["supportingIdeaFiles"].value === null){
  this.projectIdea.controls["supportingIdeaFiles"].setValue([])
 }
  this.projectIdea.controls["supportingIdeaFiles"].value.push(obj);
    this.emitFileOnUplaod.emit(deleteObj)
  this.projectIdea.controls["supportingIdeaFiles"].setValue(this.projectIdea.controls["supportingIdeaFiles"].value);
  }
  get f() {
    return this.projectIdea.controls;
  }
  getSiteDetails(item: string | undefined, code: string | undefined) {
    return `${item} (${code})`;
  }
  //for disable fileupload
  isProjectIdeaNameValid() {
    const projectIdeaName = this.projectIdea.controls['projectIdeaName']
    projectIdeaName.valueChanges.subscribe(
      () => {
        if(projectIdeaName.disabled === false){
          this.service.getDuplicateProjectName( this.projectIdea.controls['projectIdeaName'].value).subscribe((response)=> {
            if(response.result.isProjectDuplicate){
              projectIdeaName.setErrors({'duplicateValidation': true});
              projectIdeaName.invalid;
              this.disableFileDrop = false;
            }
            else{
              this.projectID = response.result.projectKey;
              this.emitProjectKey.emit(this.projectID);
              this.disableFileDrop = true;
            }
           });
        }
        else{
          this.disableFileDrop = true;
        }
      })
  }



  getSiteandInsitutionList() {
    this.service.getSites().subscribe({
      next: (res) => {
        this.siteDetails = res.result;
      }
    });
  }
  getDiseaseAreaList() {
    this.service.getDieasesArea().subscribe({
      next: (res) => {
        this.diseaseArea = JSON.parse(res.result.contents);
      }
    });
  }
  getStudyTypeList() {
    this.service.getStudyType().subscribe({
      next: (res) => {
        this.studyType = JSON.parse(res.result.contents);
      }
    });
  }
  getRegionList() {
    this.service.getRegion().subscribe((res: any) => {
      this.dataRegion = res.result;
    });

  }

  compareByRegionValue(o1: RegionModel, o2: RegionModel) {
    return o1.regionValue === o2.regionValue;
  }

  deleteFile(file, fileType, controller) {
    const payload = {
        "fileName": file.fileName,
        "fileId": file.fileId,
        "projectFileType": fileType
      }
        if (file.fileId) {
          const newArray = controller.value.filter(item => item.fileId !== file.fileId);
          console.log(newArray);
          controller.setValue(newArray);
          this.fileListDeleteOnSave.push(payload);
        }
  }
  updateTheValuesExist() {
    if (this.setExitingData && this.setExitingData !== null) {
      this.projectIdea.patchValue(this.setExitingData);
        this.projectIdea.controls['projectIdeaName'].disable();
        this.projectIdea.controls['piRegion'].disable();
        this.projectIdea.controls['leadSite'].disable();
        this.projectIdea.controls['statusDetails'].disable();
       this.disableFileDrop = true;
      this.projectIdea.markAsPristine();
    }  

}

}



