<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner> 
    </div>
</div>

<div class="cont-fluid projDetails" *ngIf="auditSettingsData !== {}">  
    <h3 class="title-prjDetails">
        Audit Settings
        <button mat-raised-button (click)="configureAuditSettings()" [disabled]="startAuditStatus">{{ auditSettingsData!==null ? 'Edit Details' : 'Configure'}}</button>
    </h3>

    <div class="row">
        <div class="col text-middle" *ngIf="auditSettingsData === null">Audit settings not configured. <br>Click on Configure to proceed</div>
    </div>

    <mat-grid-list cols="4" rowHeight="100px" *ngIf="auditSettingsData!==null">
        <!-- <mat-grid-tile>
            <mat-label>
                <p>First Audit Date</p>
                <h4>{{auditSettingsData.firstAuditDate ? (auditSettingsData.firstAuditDate | date : 'y-MMM-dd') : '-'}}</h4>    
                         
            </mat-label>
        </mat-grid-tile> -->
        <mat-grid-tile>
            <mat-label>
                <p>Next Audit Date</p>
                <h4>{{auditSettingsData.currentCycleBeginDate ? (auditSettingsData.currentCycleBeginDate | date : 'y-MMM-dd') : '-'}}</h4>                
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>Days remaining for the next Audit</p>
                <h4>{{auditSettingsData.daysRemainingForNextAudit}}</h4>
                
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-label>
                <p>Last Audit Date</p>
                <h4>{{auditSettingsData.latestAuditDate ? (auditSettingsData.latestAuditDate | date : 'y-MMM-dd') : '-'}}</h4>
            </mat-label>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="4" rowHeight="100px" *ngIf="auditSettingsData!==null">
        <mat-grid-tile>
            <mat-label>
                <p>Cadence for Audit</p>
                <h4>{{auditSettingsData.cadence ? (auditSettingsData.cadence) : '-'}}</h4>                
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" *ngIf="cadenceValue">
            <mat-label>
                <p>Specify Number of days</p>
                <h4>{{auditSettingsData.numberOfDays ? (auditSettingsData.numberOfDays) : '-'}}</h4>                          
            </mat-label>
        </mat-grid-tile>       
    </mat-grid-list>

</div>