<div class="confirmation-dialog">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>{{confirmation_text}}</mat-card-content>
        <mat-card-footer>
            <div mat-dialog-actions>
                <button mat-stroked-button (click)="onDecline()">{{decline_btn}}</button>
                <button mat-raised-button (click)="onAccept()">{{accept_btn}}</button>
            </div>
        </mat-card-footer>
    </mat-card>
</div>