import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProjectConcept, projectCreation } from '../../model/model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectManagementService } from '../../../project-management.service';
import { ActivatedRoute } from '@angular/router';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-project-concept-details',
  templateUrl: './project-concept-details.component.html',
  styleUrls: ['./project-concept-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class ProjectConceptDetailsComponent implements OnInit, OnChanges {
  projectCreation = projectCreation;
  i18n = i18n;
  pcFilesdataSource;
  emptyData;
  @Input() projectConcept: ProjectConcept | undefined;
  @Input() projectID: string;
  @ViewChild(MatSort) tableSort!: MatSort;
  @ViewChild('tablePaginator', { static: true }) tablePaginator!: MatPaginator;
  displayedColumns: string[] = ['fileName', 'action'];
  constructor(public readonly service: ProjectManagementService,
    public readonly activatedRoute: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
      this.pcFilesdataSource = new MatTableDataSource(this.projectConcept?.pcFiles);
      this.pcFilesdataSource.paginator = this.tablePaginator;
      this.pcFilesdataSource.sort = this.tableSort;
      this.emptyData = new MatTableDataSource([{ empty: "row" }])
  }


  ngOnInit(): void {
    this.projectID = this.activatedRoute.snapshot.queryParams["projectKey"];
  }
  getDownloadPresignedURL(element,fileType){
    const payload = {
      "projectKey": this.projectID,
      "region": "eu-central-1",
      "fileDetail": [
        {
          "fileName": element.fileName,
          "fileId": element.fileId,
          "projectFileType": fileType
        }
      ]

    }
    this.service.getFilePresignedURL(payload).subscribe((response:any) => {
      console.log(response.result.fileDetail[0].presignedURL);
      this.fileDownload(response.result.fileDetail[0].presignedURL,response.result.fileDetail[0].fileName);
    })
  }

  fileDownload(fileDownloadURL,fileName) {
    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', fileDownloadURL);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

}
function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;
  return customPaginatorIntl;
}