import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { AuthguardService } from 'src/app/services/authguard.service';

const routes: Routes = [  { path: '', component: UsermanagementComponent, pathMatch: 'full' }];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
