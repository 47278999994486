import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApplobbyService } from './applobby.service';
import { i18n } from '../../i18n.en';
import { Permissions, Profile } from 'src/app/interfaces/common.interface';


@Component({
  selector: 'app-applobby',
  templateUrl: './applobby.component.html',
  styleUrls: ['./applobby.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplobbyComponent implements OnInit {
  i18n = i18n;
  role: string | null;
  profile: Profile;
  firstname: string;
  isLoading = true;

   currentUserPermissions: Permissions;

  
  constructor(private service: CommonService, private navifyServ: NavifyauthService, readonly storageService: StorageService,
    readonly applobbyService: ApplobbyService) { }

  ngOnInit() {
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.currentUserPermissions === null ? window.location.reload() : this.currentUserPermissions;
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile')!);
    this.isLoading = false;
    const name = (this.profile['displayName']).split(" ")[0];
    this.firstname = name.charAt(0).toUpperCase() + name.slice(1);
    this.service.rumConfig();
    console.log("User role: ", this.storageService.secureStorage.getItem('currentUserRoles'));
  }
  async logout() {
    await this.navifyServ.logOut();
  }

}


