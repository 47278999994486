import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-audit-settings',
  templateUrl: './audit-settings.component.html',
  styleUrls: ['./audit-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditSettingsComponent {

  i18n = i18n;
  currentUserPermissions:any;
  isLoading: boolean;
  projectDetailsList: any = {};
  auditSettingsData: any = {};
  roles:any;
  orgRole: any;
  showEditPrj: boolean = false;
  auditSettingsEditStatus: boolean;
  startAuditStatus: boolean = false;
  cadenceValue: boolean = false;
  editStatus: boolean = false;

  constructor(public service: ProjectManagementService,public commonSer:CommonService, public storageService: StorageService, 
    public activatedRoute: ActivatedRoute, public router: Router, public toastr: ToastrService) {
   
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => { 
    this.projectDetailsList = queryParams      
    });
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.getProjectDetails();
  }

  getProjectDetails(){
    this.isLoading = true;
    
    this.service.getProjectDetails(this.projectDetailsList.projectKey).subscribe((data:any)=>{    
        
      this.auditSettingsData  = data.result.projectDetails.auditSettings;
      //this.auditSettingsData = null;
      
      if(this.auditSettingsData !== null){
        this.editStatus = true;
        if(data.result.projectDetails.auditSettings.cadence === 'Custom'){
          this.cadenceValue = true;
        }

        if( data.result.projectDetails.auditSettings.auditStatus === 'In Progress'){
          this.startAuditStatus = true;
        }
      }
      //this.startAuditStatus = false; // for testing
      this.showEditPrj = true;
      this.isLoading = false;
    })
  }

  configureAuditSettings(){
    if(this.editStatus){
      this.router.navigate(['/projectaudit/configureprojectaudit'], { relativeTo: this.activatedRoute, queryParams: {...this.projectDetailsList, editStatus: this.editStatus}});
    }else {
      this.router.navigate(['/projectaudit/configureprojectaudit'], { relativeTo: this.activatedRoute, queryParams: {...this.projectDetailsList}});
    }
    
  }

}
