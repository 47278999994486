import {
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouteReuseStrategy,
  } from '@angular/router';
  
  export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    reuseRoutes = ['datafileslist']

    createKey(route){
      console.log(route.queryParams['projectKey'])
      let path = route.routeConfig.path
      if (this.reuseRoutes.includes(path) && route.queryParams['projectKey'] !== undefined){
        const identifier = route.queryParams['projectKey']
        path = route.routeConfig.path + '_' + identifier
        return path
      }
      else{
        return path
      }
    }

    private routeStore = new Map<string, DetachedRouteHandle>();
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = route.routeConfig?.path as string
        return this.reuseRoutes.includes(path)
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const path = route.routeConfig?.path;
        this.createKey(route)
        if (path) {
            this.routeStore.set(this.createKey(route), handle);
        }
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = route.routeConfig?.path as string
        return this.reuseRoutes.includes(path) && !!this.routeStore.get(this.createKey(route))
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const path =  route.routeConfig?.path as string
        return this.routeStore.get(this.createKey(route)) as DetachedRouteHandle;
      }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
      return future.routeConfig === curr.routeConfig;
    }
  }
  