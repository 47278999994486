import { Component, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { i18n } from 'src/app/i18n.en';
import { ProjectManagementService } from './project-management.service';
import { Env } from '@navify-platform/env';

import { environment } from 'src/environments/environment';
import { IdleOptions, Idle} from '@navify-platform/idle';

/* const env = new Env({
  props: {
    platformApiUrl: environment.platformApiUrl,  // Navify Platform URL
    authUiUrl: environment.authUiUrl,       // Authentication application URL
    appAlias: environment.appAlias,        // Client application alias
    tenantAlias: environment.tenantAlias,     // Client tenant alias
  },
});

const idleOptions: IdleOptions = {
  env: env,                             // environment, NavifyPlatformEnv instance
  syncTimeInterval: 1000 * 60 * 10,     // clock sync interval time (optional)
  tickTimeInterval: 1000 * 10,          // clock tick interval time (optional)
  idleTimeout: 1000 * 10 * 3,           // Idle timeout value override (optional)
  beforeIdleTimeSpan: 1000 * 60,        // how much time before idle the event is triggered (optional)
  aliveCallOpt: ({                      // alive call condition check function (optional)
    timeSinceAttempt,                   // * time passed since most recent alive attempt (sent request)
    timeSinceCall,                      // * time passed since most recent alive call (received response)
  }) => (
    timeSinceAttempt >= 1000 * 10 &&
    timeSinceCall >= 1000 * 30
  ),
  lastActivityCallOpt: ({               // last activity call condition check function (optional)
    timeSinceAttempt,                   // * time passed since most recent last activity attempt (sent request)
    timeSinceCall,                      // * time passed since most recent last activity call (received response)
    isActive,                           // * is user active
    isBeforeIdle,                       // * is user in before idle state
    isIdle,                             // * is user idle
    timeSinceActivity,                  // * time passed since most recent activity
    timeToIdle,                         // * time left to go into idle state
  }) => (
    isBeforeIdle ? (
      timeSinceAttempt >= 1000 * 10 &&
      timeSinceCall >= 1000 * 10
    ) : (
      timeSinceAttempt >= 1000 * 30 &&
      timeSinceCall >= 1000 * 60
    )
  ),
  toggleSyncActivity: true,             // flag deciding whether to synchronize activity information via API
  toggleTracking: false,                 // flag deciding whether to turn on DOM events tracking
};

export const idle = new Idle(idleOptions); */

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectManagementComponent {
  profile: any;
  i18n = i18n
  orgRole: string | null;
  userRole:any;
  currentUserRoles: any
  currentUserPermissions: any = [];
  constructor( private navifyServ: NavifyauthService, public storageService: StorageService, public commonSer: CommonService, public service: ProjectManagementService) {


  }
  ngOnInit(): void {

    this.currentUserRoles = sessionStorage.getItem('currentUserRoles');
    
    this.profile = { displayName: localStorage.getItem('dn'), email: localStorage.getItem('email') };
    this.orgRole = this.storageService.secureStorage.getItem("role");
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('projectPermissions')!);
  }
  async logout() {
    await this.navifyServ.logOut(); 
  }


  /* async init(){
    await idle.init();
    await idle.markActivity();
  }
 */
}
