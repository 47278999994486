import { Injectable } from '@angular/core';
import { FilesDetailList, AppDB } from './db';
// import { liveQuery } from 'dexie';
import { HttpBackend, HttpClient, HttpEventType, HttpRequest } from '@angular/common/http'
// import { v4 as uuidv4 } from 'uuid';
import { Queue, AsyncLock } from './queue';
import { v4 as uuidv4 } from 'uuid';

import {
  DataForEachFileInUploadType,
  DataForFileUploadType,
  FileInprogressObj,
  FileListObj,
  FileWithPath,
  PayloadPreSignedObj,
  UploadFileDetailObj,
  FilesDetailListType,
  UploadFileRequestType,
  FileUploadApisType
} from './model';
import { Subject, tap, throwError } from 'rxjs';


const maxParellelFileUploads = 3
const maxChunkCount = 10
const number_100 = 100
const maxThrottlelimit = 10
const chunkSize = 6291456
const delay_ms = 1000

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  maxParellelFileUploads = maxParellelFileUploads
  maxChunkCount = maxChunkCount
  number_100 = number_100
  maxThrottlelimit = maxThrottlelimit
  chunkSize = chunkSize
  delay_ms = delay_ms
  completedFilesEventEmitter = new Subject<UploadFileDetailObj>;
  filesListObject: FileListObj = {}
  filesUploadInProgressObject: FileInprogressObj = {}
  updateTest: string = ''
  // maxParellelFileUploads: number = maxParellelFileUploads
  OverAllActiveUpload = 0
  // chunkSize: number = 209715200 // 200 MB in binary terms
  // chunkSize: number = 1000000 // 1 mb
  // chunkSize: number = chunkSize // 6mb
  freePoolingQueue = new Queue()
  fileListQueue = new Queue()
  asyncLocking = new AsyncLock()
  project_key: string
  region: string
  dataForFileUpload: DataForFileUploadType;
  dataForEachFileInUpload: DataForEachFileInUploadType;
  storeName: string
  db: AppDB;
  fileUploadApis; FileUploadApisType
  httpS3Client: HttpClient;
  userUuid: string
  projectKey: string
  constructor(private readonly httpClient: HttpClient,
    s3_handler: HttpBackend
  ) {
    this.httpS3Client = new HttpClient(s3_handler)
  }

  /**
   * 
   * @param storeName: database name
   */
  async createStore(storeName: string, resetOnLoad: boolean, userUuid: string) {
    this.storeName = storeName
    this.userUuid = userUuid
    this.db = new AppDB(storeName)
    await this.db.createStore(storeName)
    await this.clearFailedWithAge(2)
    if (resetOnLoad === true) {
      await this.resetTable(userUuid)
    }
    else {
      await this.checkExistingFilesData(storeName)
    }
  }

  async resetTable(userUuid: string){
    const filterData = {
      status: "INP",
      userUuid: this.userUuid,
      projectKey: this.projectKey
    }
    await this.db.table(this.storeName).where(filterData).delete()
    // await this.db.table(this.storeName).where(["userUuid", "projectKey"]).equals([userUuid, this.projectKey]).delete()
  }

  async setFileFailed(dbData) {
    const modified_for_update = dbData.map((data) => ({ ...data, ...{ status: 'FAILED', name: data.fileDetails.name } }))
    await this.db.table(this.storeName).bulkPut(modified_for_update, { allKeys: true })
      .then(async (response) => {
        const allData = await this.getAllListFromDB()
        allData.forEach(element => {
          this.filesListObject[element.id] = { ...this.filesListObject[element.id], ...element }
        });
      })
      .catch((error) => {
      })
  }

  async setFileFailesWithList(idList) {
    const dbData = await this.db.table(this.storeName).bulkGet(idList)
    await this.setFileFailed(dbData)
  }

  async checkExistingFilesData(storeName) {
    const allData = await this.getAllListFromDB()
    const modified_data = allData.filter((data) => !(data.id in this.filesListObject))
    await this.setFileFailed(modified_data)
  }

  async getAllListFromDB(){
    const filterData = {
      // status: "INP",
      userUuid: this.userUuid,
      projectKey: this.projectKey
    }
    const allData = await this.db.table(this.storeName).where(filterData).toArray()
    return allData
  }

  async clearFailedWithAge(ageInDays) {
    const age = new Date(Date.now() - 60 * 60 * 1000 * 24 * ageInDays);
    try {
      const allData = await this.getAllListFromDB()
      const allDataFilteredIds = allData.filter((x) => x['timeStamp'] < age && x['status'] === 'FAILED').map(x => x.id)
      await this.db.table(this.storeName).bulkDelete(allDataFilteredIds)
    }
    catch (error) {
    }
  }




  addToDB = async (filesList: FileWithPath[]) => {

    for (const file of filesList) {
      const detailsObj = {
        "fileDetails": {
          name: file.name,
          size: file.size,
          lastModified: file.lastModified
        },
        userUuid: this.userUuid,
        projectKey: this.projectKey,
        timeStamp:  new Date(),
        path: file.path,
        status: "INIT",
      }
      const id = Number(await this.db.table(this.storeName).add(detailsObj))
      const tmpObj = {
        file: file,
        path: file['path'],
        name: file.name,
        size: file.size,
        userUuid: this.userUuid,
        projectKey: this.projectKey,
        lastModified: file.lastModified,
        fileDetails: detailsObj['fileDetails'],
        chunkPercents: {},
        fileProgress: 0
      }
      this.filesListObject[id] = tmpObj
      this.fileListQueue.enqueue(String(id))
    }

    this.checkUploadPoolQueue()
  }

  setServiceData(
    maxParallelFileUpload: number,
    maxParallelChunksUpload: number,
    maxChunkSizeInBytes: number,
    dataForFileUpload: DataForFileUploadType,
    dataForEachFile: DataForEachFileInUploadType,
    fileUploadApis: FileUploadApisType,
    userUuid: string,
    projectKey: string
  ) {
    // this.createStore(dbName)
    this.maxParellelFileUploads = maxParallelFileUpload
    this.maxChunkCount = maxParallelChunksUpload
    this.chunkSize = maxChunkSizeInBytes
    this.dataForFileUpload = dataForFileUpload
    this.dataForEachFileInUpload = dataForEachFile
    this.fileUploadApis = fileUploadApis
    this.userUuid = userUuid
    this.projectKey = projectKey
  }


  async checkUploadPoolQueue(triggerCount: number = 0) {
    const filterData = {
      status: "INP",
      userUuid: this.userUuid,
      projectKey: this.projectKey
    }
    const currentProgress = await this.db.table(this.storeName).where(filterData).toArray()
    let remainingCount = this.maxParellelFileUploads - currentProgress.length
    if (triggerCount !== 0) remainingCount = 1
    if (currentProgress.length < this.maxParellelFileUploads && this.OverAllActiveUpload < this.maxParellelFileUploads && this.fileListQueue.peek() !== undefined) {
      const idFromQ: number[] = []

      for (let i = 0; i < remainingCount; i++) {

        if (this.fileListQueue.peek() === undefined) {
          break
        }
        const tmpId = this.fileListQueue.dequeue()
        if (tmpId !== undefined) { idFromQ.push(Number(tmpId)) }
      }

      const idList = await this.db.table(this.storeName).bulkGet(idFromQ)
      const fileListToStart: FilesDetailListType[] = []
      for (const id of idList) {
        const testObj = { ...id }
        testObj['fileId'] = this.create_UUID()
        testObj['chunkCount'] = this.getChunkCount(testObj.fileDetails.size)
        testObj['status'] = 'INP'
        this.filesListObject[id['id']]['status'] = 'INP'
        fileListToStart.push(testObj)
      }
      await this.db.table(this.storeName ).bulkPut(fileListToStart, { allKeys: true })
        .then((response) => {
          this.getPreSignedUrls(fileListToStart)
        })
        .catch((error) => {
        })

    }

  }

  getChunkCount(fileSize: number) {
    const chunkCount = Math.floor(fileSize / this.chunkSize)
    if (chunkCount === 0) {
      return 1
    }
    else {
      return chunkCount
    }
  }

  updateChunkAndUuid() {

  }



  getPreSignedUrls(filesToStart: FilesDetailListType[]) {

    let payload: PayloadPreSignedObj = {
      // "projectKey": this.project_key,
      // "region": this.region,
      "fileDetail": []
    }
    payload = { ...payload, ...this.dataForFileUpload }

    for (const fd of filesToStart) {

      let fileDetail: UploadFileRequestType = {
        // fileName: fd.fileDetails.name,
        fileName: fd.path,
        fileId: fd.fileId,
        uploadId: "",
      }
      fileDetail = { ...fileDetail, ...this.dataForEachFileInUpload }

      if (fd.chunkCount > this.maxChunkCount) {
        fileDetail['parts'] = Array.from({ length: this.maxChunkCount }, (x, i) => i + 1);
      }
      else {
        fileDetail['parts'] = Array.from({ length: fd.chunkCount }, (x, i) => i + 1);
      }
      payload.fileDetail.push(fileDetail)
    }
    if (payload.fileDetail.length !== 0) {


      this.httpClient
        .post(this.fileUploadApis.fileupload, payload)
        .pipe(
          tap((res) => {
            if (res['status'] !== 'OK') {
              throw throwError(() => res);
            }
          })
        )
        .subscribe
        ({
          next: async (response: any) => {
            for (const data of response.result.fileDetails) {
              const fileObj: FilesDetailListType = (filesToStart.filter((x) => x.fileId === data.fileId))[0]
              if (data['statusCode'] === 200) {
                this.OverAllActiveUpload++

                // this.filesUploadInProgressObject[data.fileId] = {}

                const tmpObj = { ...fileObj, ...data }
                tmpObj['TRIGGERED_NEXT_SET'] = false
                this.filesUploadInProgressObject[data.fileId] = { ...tmpObj }
                const partlist = Object.keys(data.presignedURL).map(x => Number(x))
                this.filesUploadInProgressObject[data.fileId].parts = []

                this.filesUploadInProgressObject[data.fileId].parts = this.filesUploadInProgressObject[data.fileId].parts.concat(partlist)
                // this.filesUploadInProgressObject[data.fileId]
                // this.filesUploadInProgressObject[data.fileId].parts = filedetail['parts']
                // this.filesUploadInProgressObject[data.fileId].id = fileObj.id
                // this.filesUploadInProgressObject[data.fileId].chunkCount = fileObj.chunkCount
                // this.filesUploadInProgressObject[data.fileId]['TRIGGERED_NEXT_SET'] = false
                // this.filesUploadInProgressObject[data.fileId] = { ...this.filesUploadInProgressObject[data.fileId], ...data }
                if (fileObj.id) {
                  this.upload(fileObj.id, data.fileId)
                }
              }
              else {
                await this.setFileFailesWithList([fileObj.id])
                this.checkUploadPoolQueue(1)
              }

            }

          },
          error: async (error) => {
            const idList = filesToStart.map((data) => data.id)
            await this.setFileFailesWithList(idList)
            this.checkUploadPoolQueue()
          }
        })
    }

  }

  create_UUID() {
    // const uuid = crypto.randomUUID()
    // let dt = new Date().getTime();
    // const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //   const r = (dt + Math.random() * 16) % 16 | 0;
    //   dt = Math.floor(dt / 16);
    //   return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    // });
    return uuidv4();
  }


  getPreSignedUrlsSpecificID(fd: string, count: number) {
    try {



      let payload: PayloadPreSignedObj = {
        // "projectKey": this.project_key,
        // "region": this.region,
        "fileDetail": []
      }
      payload = { ...payload, ...this.dataForFileUpload }

      let fileDetail: UploadFileRequestType = {
        fileName: this.filesUploadInProgressObject[fd].fileName,
        fileId: this.filesUploadInProgressObject[fd].fileId,
        uploadId: this.filesUploadInProgressObject[fd].uploadId,
      }

      fileDetail = { ...fileDetail, ...this.dataForEachFileInUpload }
      const existing_length = Object.keys(this.filesUploadInProgressObject[fd].presignedURL).length
      fileDetail['parts'] = Array.from({ length: count }, (x, i) => i + 1 + existing_length);
      payload.fileDetail.push(fileDetail)

      this.httpClient
        .post(this.fileUploadApis.fileupload, payload)
        .pipe(
          tap((res) => {
            if (res['status'] !== 'OK') {
              throw throwError(() => res);
            }
          })
        )
        .subscribe({
          next: async (response) => {
            for (const data of response['result']['fileDetails']) {
              if (data['statusCode'] === 200) {
                const partlist = Object.keys(data.presignedURL).map(x => Number(x))
                this.filesUploadInProgressObject[data.fileId].parts = this.filesUploadInProgressObject[data.fileId].parts.concat(partlist)
                this.filesUploadInProgressObject[data.fileId].presignedURL = { ...this.filesUploadInProgressObject[data.fileId].presignedURL, ...data.presignedURL }
              }
              else {
                this.setFileFailesWithList([this.filesUploadInProgressObject[data.fileId].id])
                this.OverAllActiveUpload--
                this.checkUploadPoolQueue(1)
              }
            }
            this.filesUploadInProgressObject[fd].TRIGGERED_NEXT_SET = false
          },
          error: (error) => {
            this.filesUploadInProgressObject[fd].TRIGGERED_NEXT_SET = false
            this.setFileFailesWithList([this.filesUploadInProgressObject[fd].id])
            this.OverAllActiveUpload--
            this.checkUploadPoolQueue(1)
          }
        })
    } catch (error) {
    }



  }


  roundOff(Number: number) {
    return Math.round(Number)
  }



  sumValues = (obj: Object) => Object.values(obj).reduce((a: number, b: number) => a + b);


  setThrottleLimit(num_chunks: number, throttlelimit: number) {
    if (num_chunks < throttlelimit) {
      throttlelimit = num_chunks;
    }
    return throttlelimit
  }


  geneateQueue(fileId: string, chunkCount: number) {
    return new Promise((resolve) => {
      for (const chunkNumber of Array.from({ length: chunkCount }, (x, index) => index + 1)) {
        this.filesUploadInProgressObject[fileId].queue.enqueue(String(chunkNumber))
        if (chunkNumber === chunkCount) {
          resolve(1)
        }
      }
    })
  }

  getCountForNextSet(tmpPartNumber: number, chunkCount: number) {
    tmpPartNumber--
    let count = chunkCount - tmpPartNumber >= this.maxChunkCount ? this.maxChunkCount : Math.abs(chunkCount - tmpPartNumber)
    count = count === 0 ? 1 : count
    return count
  }

  async getPresignedUrlForNextSet(throttlelimit: number, chunkCount: number, fileId: string, tmpPartNumber: number) {
    if (throttlelimit < chunkCount &&
      this.filesUploadInProgressObject[fileId].presignedURL[tmpPartNumber] === undefined &&
      this.filesUploadInProgressObject[fileId].TRIGGERED_NEXT_SET === false) {

      this.filesUploadInProgressObject[fileId].TRIGGERED_NEXT_SET = true
      const count = this.getCountForNextSet(tmpPartNumber, chunkCount)
      const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
      this.getPreSignedUrlsSpecificID(fileId, count)

    }
  }

  checkProceedingInWhile(activeUploads: number, throttlelimit: number, TriggredsChunkCounts: number, chunkCount: number) {
    return activeUploads < throttlelimit && TriggredsChunkCounts < chunkCount
  }

  async upload(id: number, fileId: string) {
    try {

      this.filesUploadInProgressObject[fileId].queue = new Queue()
      const chunkCount = this.filesUploadInProgressObject[fileId].chunkCount
      let activeUploads = 0
      let completedUploads = 0
      const throttlelimit = this.setThrottleLimit(chunkCount, this.maxThrottlelimit)

      await this.geneateQueue(fileId, chunkCount)
      const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
      let TriggredsChunkCounts = 0
      this.filesListObject[id]['chunkPercents'] = {}

      while (completedUploads < chunkCount && this.filesListObject[id].status !== 'FAILED') {
        if (this.checkProceedingInWhile(activeUploads, throttlelimit, TriggredsChunkCounts, chunkCount)) {
          const tmpCurrentPartNumber = await this.filesUploadInProgressObject[fileId].queue.peek()
          const tmpPartNumber = tmpCurrentPartNumber
          this.getPresignedUrlForNextSet(throttlelimit, chunkCount, fileId, tmpPartNumber)

          if (this.filesUploadInProgressObject[fileId].presignedURL[tmpCurrentPartNumber] === undefined) {
            await delay(this.delay_ms);
            continue
          }
          activeUploads++
          const chunkPartNumber = await this.filesUploadInProgressObject[fileId].queue.dequeue()
          const PreSignedURL = this.filesUploadInProgressObject[fileId].presignedURL[chunkPartNumber]
          const start = (chunkPartNumber - 1) * this.chunkSize
          const end = (chunkPartNumber) * this.chunkSize
          const file = this.filesListObject[id].file
          const blob = chunkPartNumber < chunkCount ? file.slice(start, end) : file.slice(start);

          const percentageOfChunkInFile = (blob.size / file.size) * this.number_100
          const req = new HttpRequest('PUT', PreSignedURL, blob, {
            reportProgress: true
          });



          this.httpS3Client.request(req)
            .subscribe({
              next: async (event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    if (event.total && event.loaded) {
                      const chunkProgress = Math.round((event.loaded / event.total) * this.number_100)
                      const chunkPercentCompleted = percentageOfChunkInFile * (chunkProgress / this.number_100)
                      const chunkPercentageObj = {
                        [chunkPartNumber]: chunkPercentCompleted
                      }
                      this.filesListObject[id]['chunkPercents'] = { ...this.filesListObject[id]['chunkPercents'], ...chunkPercentageObj }
                      this.filesListObject[id]['fileProgress'] = this.roundOff(this.sumValues(this.filesListObject[id]['chunkPercents']))
                    }


                    break;
                  case HttpEventType.Response:
                    const chunkCompletedPercentageObj = {
                      [chunkPartNumber]: percentageOfChunkInFile
                    }
                    this.filesListObject[id]['chunkPercents'] = { ...this.filesListObject[id]['chunkPercents'], ...chunkCompletedPercentageObj }
                    this.filesListObject[id]['fileProgress'] = this.roundOff(this.sumValues(this.filesListObject[id]['chunkPercents']))
                    completedUploads++;
                    activeUploads--
                    await this.completeMultipartUpload(fileId, chunkCount, completedUploads)



                }
              },
              error: (error) => {
                this.UpdateFailedStatus(fileId)
                this.setFileFailesWithList([id])
                this.OverAllActiveUpload--
                this.checkUploadPoolQueue(1)
              }
            });


          TriggredsChunkCounts++;

        }
        else {
          await delay(this.delay_ms);
        }

      }

    } catch (error) {

    }
  }

  async UpdateFailedStatus(fileId) {
    let payload = [
      {
        "fileName": this.filesUploadInProgressObject[fileId].fileName,
        "fileId": fileId,
        "status": "Upload Failed",
        "projectKey": this.projectKey,
        "projectFileType": ""
      }
    ]
    this.httpClient.post(this.fileUploadApis.failedNotificationApi, payload).subscribe({
      next: async (response) => {
      },
      error: async (error) => {
      }
    })
  }

  async completeMultipartUpload(fileId: string, chunkCount: number, completedUploads: number) {
    if (chunkCount === completedUploads) {
      const payload = {
        "projectKey": this.dataForFileUpload.projectKey,
        "region": this.dataForFileUpload.region,
        "fileDetail": [
          {
            "fileName": this.filesUploadInProgressObject[fileId].fileName,
            fileId,
            "totalParts": this.filesUploadInProgressObject[fileId].chunkCount,
            "uploadId": this.filesUploadInProgressObject[fileId].uploadId,
            // "uploadID": this.filesUploadInProgressObject[fileId].uploadId
          }
        ]
      }
      payload.fileDetail[0] = { ...payload.fileDetail[0], ...this.dataForEachFileInUpload }
      this.httpClient.post(this.fileUploadApis.completeMultiPart, payload).subscribe({
        next: async (response) => {
          if (response['status'] === 'OK') {
            const id = this.filesUploadInProgressObject[fileId].id
            const fileData: FilesDetailList = { 'status': 'COMPS' }
            this.completedFilesEventEmitter.next(this.filesUploadInProgressObject[fileId])
            await this.db.table(this.storeName).update(id, fileData).then(async (updated) => {
              delete this.filesUploadInProgressObject[fileId]
              await this.db.table(this.storeName).delete(id)
              delete this.filesListObject[id]
            })
            this.OverAllActiveUpload--
            this.checkUploadPoolQueue(1)
          }
          else {
            this.UpdateFailedStatus(fileId)
            this.setFileFailesWithList([this.filesUploadInProgressObject[fileId].id])
            this.OverAllActiveUpload--
            this.checkUploadPoolQueue(1)
          }
        },
        error: async (error) => {
          this.UpdateFailedStatus(fileId)
          this.setFileFailesWithList([this.filesUploadInProgressObject[fileId].id])
          this.OverAllActiveUpload--
          this.checkUploadPoolQueue(1)
        }
      })
    }

  }





}
