<div class="details-page">
    <div class="header-grid-list">
        <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile class="header-close">
                <a>
                    <mat-icon (click)="onClose()">{{i18n.ICONS.CLOSE}}</mat-icon>
                </a>
                <h3>{{i18n.projectMnt.DI.DSD.TITLE}}</h3>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <div class="cont-fluid dataset-details-view">
        <!-- breadcrumb -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}} {{projectName}}</a>
                </li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a (click)="onClose()">{{i18n.BREADCRUMB.DI}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.DD}}</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col">
                <div>
                    <mat-card class="bsn">
                        <div class="loadingcontainer">
                            <div *ngIf="isLoading" class="loading-modal-spinner">
                                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
                                </mat-progress-spinner>
                            </div>
                        </div>
                        <mat-card-title>{{i18n.projectMnt.DI.DSD.TITLE}}</mat-card-title>
                        <mat-card-content>
                            <mat-grid-list cols="2" rowHeight="85px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DN}}</p>
                                        <h4> {{detailsData.datasetName}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DID}} </p>
                                        <h4> {{detailsData.datasetId}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list class="desc-div" cols="1" rowHeight="85px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_CB}}</p>
                                        <h4> {{detailsData.createdBy}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list cols="2" rowHeight="85px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DC}}</p>
                                        <h4> {{detailsData.createdDate | date : 'y-MMM-dd HH:mm:ss'}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DI}}</p>
                                        <h4> {{detailsData.ingressedDate?(detailsData.ingressedDate| date : 'y-MMM-dd
                                            HH:mm:ss'):'-'}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list class="desc-div" cols="1" rowHeight="100px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DDESC}}</p>                                        
                                        <div class="desc-inner-div">
                                             {{detailsData.datasetDescription}}
                                        </div>                                        
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list class="desc-div" cols="1" rowHeight="85px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_SN}}</p>
                                        <h4> {{detailsData.siteName}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>

                            <mat-grid-list cols="2" rowHeight="85px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_PN}}</p>
                                        <h4> {{detailsData.projectName}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_ON}}</p>
                                        <h4> {{detailsData.orgName}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DM}}</p>
                                        <h4> {{detailsData.dataMaturity}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DCLA}}</p>
                                        <h4> {{detailsData.dataClassification}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list cols="2" rowHeight="85px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_NODF}}</p>
                                        <h4> {{detailsData.dataFilesCount}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-label>
                                        <p>{{i18n.projectMnt.DI.DSD.LBL_DS}}</p>
                                        <h4> {{detailsData.datasetSize | bytesToMB}}</h4>
                                    </mat-label>
                                </mat-grid-tile>
                            </mat-grid-list>

                        </mat-card-content>
                        <mat-card-footer></mat-card-footer>
                    </mat-card>
                </div>
            </div>
            <div class="col">
                <div class="cmn_tbl">
                    <mat-card class="bsn">
                        <div class="file-table-grid-tile">
                            <mat-grid-list cols="2" rowHeight="60px">
                                <mat-grid-tile>
                                    <mat-label>
                                        <p class="title-files">{{i18n.projectMnt.DI.DSD.LBL_F}}</p>
                                    </mat-label>
                                </mat-grid-tile>
                                <mat-grid-tile class="search-field-dataset-details">
                                    <div class="search-group">
                                    <input class="my-input" matInput placeholder="Search" autocomplete="off"
                                        (keyup)="applyFilter($event)" />
                                    <mat-icon class="search-icon" matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon>
                                </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <div class="scrollbar-dataset" id="style-1">
                                <mat-table [dataSource]="dataSource" class="files-table" matSort matSortDirection="asc"
                                    matSortDisableClear>
                                    <!-- File Name Column-->
                                    <ng-container matColumnDef="file_id">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header class="file-name-header">
                                            {{i18n.projectMnt.DI.DSD.LBL_FN}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="file-name-cell">
                                            {{element?.file_id}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- First Extension Column -->
                                    <ng-container matColumnDef="file_extension">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header class="file-ext-header"> 
                                            {{i18n.projectMnt.DI.DSD.LBL_FE}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="file-ext-cell">
                                            {{element?.file_extension}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- First Status Column -->
                                    <ng-container matColumnDef="approval_status">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{i18n.projectMnt.DI.DSD.LBL_S}} </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <mat-icon
                                                *ngIf="element?.approval_status.toLowerCase() === 'pending for approval'"
                                                class="material-icons-outlined pending-icon">{{i18n.ICONS.HELP}} </mat-icon>
                                            <mat-icon *ngIf="element?.approval_status.toLowerCase() === 'rejected'"
                                                class="material-icons-outlined rejected-icon">{{i18n.ICONS.HL_OFF}} </mat-icon>
                                            <mat-icon *ngIf="element?.approval_status.toLowerCase() === 'approved'"
                                                class="material-icons-outlined approved-icon">{{i18n.ICONS.TICK_CIRCLE}} </mat-icon>
                                            {{element?.approval_status}} </mat-cell>
                                    </ng-container>

                                    <!-- Table header -->
                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                </mat-table>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
