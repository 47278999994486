import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-remove-project',
  templateUrl: './remove-project.component.html',
  styleUrls: ['./remove-project.component.scss']
})
export class RemoveProjectComponent implements OnInit {
  i18n = i18n;
  constructor(public dialogRef: MatDialogRef<RemoveProjectComponent>, private toastrService: ToastrService) { }

  ngOnInit(): void {
  }
  onCancel() {
    this.dialogRef.close();
  }

  onRemove() {
    this.toastrService.success(this.i18n.projectMnt.PO.RP.TOASTR_S);
    this.dialogRef.close();

  }
}
