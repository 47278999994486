import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { NavigationEnd, Router } from '@angular/router';
declare function cwr(operation: string, payload: any): void;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  permissionRolesUrl: string = env.config.domain + env.permissionRoles;
  headertitleMessageData = new Subject<any>();
  headertitleMessgae$ = this.headertitleMessageData.asObservable();

  baseUrl: string = env.orgRole;
  roleUrl: string = env.config.domain + env.orgRole;
  termsConditionUrl: string = env.config.domain + env.getTermsCondition;
  updateTermsConditionUrl: string = env.config.domain + env.updateTermsCondition;

  getdatasetlistUrl: string = env.config.domain + env.getdatasetlist;

  dataIngressFileUploadData = {};

  constructor(private http: HttpClient, public storageService: StorageService, private router: Router) {
    
  }

  sendMessage(title: any) {
    this.headertitleMessageData.next(title)
  }

  getLogout() {
    const logoutUrl = env.platformApiUrl + env.apiLogout;
    return this.http.get(logoutUrl);
  }

  getCurrentUserRole(useruuid: any) {
    return this.http.post(this.roleUrl, useruuid);
  }

  getTermsConditionStatus() {
    return this.http.get(this.termsConditionUrl);
  }

  updateTermsConditionStatus(userUUID: any) {
    const url = this.updateTermsConditionUrl + '/' + userUUID;
    return this.http.put(url, {});
  }

  getPermissionRoles(orgKey: string) {
    const permissionRolesUrl = this.permissionRolesUrl + "/" + orgKey;
    return this.http.get(permissionRolesUrl);
  }

  getAdminRoleAccess() {
    const url = env.config.domain + env.adminroleaccess;
    return this.http.get(url);
  }

  getClassification(projectKey: any, userId: any) {
    const url = this.getdatasetlistUrl + "/" + projectKey + "/" + userId;
    return this.http.get(url);
  }
  navifyUsersList() {
    const url = 'https://api.appdevus.platform.navify.com/api/v1/tenants/7bd7154e-95ce-45f3-8885-4a9e0df7b05e/admin-users';
    return this.http.get(url);
  }

  ///// RUM config
  rumConfig(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        cwr('recordPageView', this.router.url);
      }
    });
  }
}
