<div [formGroup]="computeConfig" class="container">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="label-title">Compute Configuration *</div>
                <mat-form-field class="comment-form-field" appearance="fill">
                    <textarea class="comment-text-area" matInput #computeDetails formControlName="computeDetails"
                        maxlength="5000">
              </textarea>
                    <mat-hint align="end">{{computeDetails.value.length}} / 5000</mat-hint>
                </mat-form-field>
                <div class="pwd-error-message-textarea"
                *ngIf="f['computeDetails'].touched && 
                f['computeDetails'].invalid && 
                f['computeDetails'].errors?.['required']">
                Field cannot be empty
            </div>
            <div class="pwd-error-message-textarea" *ngIf="f['computeDetails'].touched && 
                        f['computeDetails'].invalid && 
                        f['computeDetails'].errors?.['whitespace']">
                Field cannot be empty
            </div>
            </div>
        </div>
</div>