import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { i18n } from 'src/app/i18n.en';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuditService } from '../audit.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-project-audit-overview',
    templateUrl: './project-audit-overview.component.html',
    styleUrls: ['./project-audit-overview.component.scss'],
    providers: [
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ]
})
export class ProjectAuditOverviewComponent implements OnInit {
    i18n = i18n;

    displayedColumns: string[] = ['projectName', 'auditStatus', 'latestAuditDate', 'currentAuditCycleBeginDate', 'currentCycleBeginDate', 'daysRemainingForNextAudit', 'cadence', 'actions'];
    hasNoUserData = false;
    dataSource: any;
    isLoading = false;
    showSearchCancelBtn: boolean;
    canEditUser: boolean;
    profile: any;
    selectedRowElement: any;
    orgRole: string | null;
    currentUserPermissions: any;
    toDay: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public inputSearch: FormControl = new FormControl('');

    constructor(public router: Router, private readonly auditService: AuditService,
         private readonly navifyServ: NavifyauthService, private readonly storageService: StorageService) {
        this.dataSource = [];
    }

    ngOnInit(): void {
        const datepipe: DatePipe = new DatePipe('en-US');
        this.toDay = datepipe.transform( new Date(), 'y-MMM-dd');
        this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
        this.getTableData();
        this.orgRole = this.storageService.secureStorage.getItem("role");
        this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    }
    getTableData() {

        const obj = {
            'userUuid': this.storageService.secureStorage.getItem('userUuid'),
            'orgKey': environment.config.orgKey,
            'auditClock':true
        }
        this.isLoading = true;
        this.auditService.getProjects(obj).subscribe({
            next: (res) => {
                for(const i in res['result']){
                    if(res['result'][i].projectDetails?.auditSettings?.latestAuditDate != null){
                      const datepipe: DatePipe = new DatePipe('en-US');
                      const lastAuditDate = datepipe.transform( res['result'][i].projectDetails?.auditSettings?.latestAuditDate, 'y-MMM-dd');
                      
                      res['result'][i].hiddenLastDate = lastAuditDate;  
                    }
                }  
                for(const i in res['result']){
                    if(res['result'][i].projectDetails?.auditSettings?.currentCycleBeginDate != null){
                      const datepipe: DatePipe = new DatePipe('en-US');
                      const hiddenNextAuditDate = datepipe.transform( res['result'][i].projectDetails?.auditSettings?.currentCycleBeginDate, 'y-MMM-dd');
                      res['result'][i].hiddenNextAuditDate = hiddenNextAuditDate; 
                    }
                } 
                this.dataSource = new MatTableDataSource(res['result']);
              
                this.dataSource.sortingDataAccessor = (item: any, property: any) => {
                    switch (property) {
                        case 'auditStatus': return item.projectDetails?.auditSettings?.auditStatus;
                        case 'latestAuditDate': return item.projectDetails?.auditSettings?.latestAuditDate;
                        case 'currentCycleBeginDate': return item.projectDetails?.auditSettings?.currentCycleBeginDate;
                        case 'daysRemainingForNextAudit': return item.projectDetails?.auditSettings?.daysRemainingForNextAudit;
                        case 'cadence': return item.projectDetails?.auditSettings?.cadence;
                        default: return item[property];
                    }
                };
                this.dataSource.filterPredicate = function (datas: any, filter: string): boolean {
                    return datas.projectDetails?.auditSettings?.auditStatus?.toLowerCase().includes(filter) || datas.projectName?.toLowerCase().includes(filter)
                        || datas.hiddenLastDate?.toLowerCase().includes(filter)
                        || datas.hiddenNextAuditDate?.toLowerCase().includes(filter) || datas.projectDetails?.auditSettings?.cadence?.toLowerCase().includes(filter)
                        || String(datas.projectDetails?.auditSettings?.daysRemainingForNextAudit).includes(filter) || !filter;
                };
                

                setTimeout(() => this.dataSource.paginator = this.paginator);
                      setTimeout(() => this.dataSource.sort = this.sort);
                this.isLoading = false;
            },
            error: (err) => {
                this.isLoading = false
            }
        }); 
    }
    applyFilter(event: Event) {
        this.showSearchCancelBtn = true;
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (!filterValue) {
            this.showSearchCancelBtn = false;
        }
    }

   
    onSelectProject(element: any) {
        this.router.navigate(['projectaudit/projectauditfiles'], {
            queryParams: {
                projectName: element.projectName,
                projectKey: element.projectKey,
                orgName: element.orgKey,
                region: element.region.regionValue
            }
        });
    }
    
onSearchCancelBtn() {
  this.showSearchCancelBtn = false;
  this.inputSearch.reset();
  this.getTableData();
}
async logout() {
  await this.navifyServ.logOut();
}
ngAfterViewInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);       
}
}
function CustomPaginator(): any {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = i18n.userManagement.PAGINATION;

    return customPaginatorIntl;
}
