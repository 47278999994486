<!-- Page Loader -->
<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<div class="cmn_tbl">
    <mat-card class="bsn">
        <mat-card-header>
            <mat-card-title>
                {{i18n.projectMnt.MA.TITLE}}
            </mat-card-title>
            <div class="search-btn-div">
                <mat-form-field #input *ngIf="myApprovalsDataSource?.data?.length > 0" appearance="fill" floatLabel="always">
                    <mat-icon class="mat-icon-search" matPrefix>{{i18n.ICONS.SEARCH}}</mat-icon><mat-icon matSuffix
                        [hidden]="!showSearchCancelBtn" (click)="onSearchCancelBtn()">{{i18n.ICONS.CANCEL}}</mat-icon>
                    <input matInput placeholder="Search here" (keyup)="applyFilter($event)" [formControl]="inputSearch"
                        autocomplete="off" />
                </mat-form-field>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div class="no-data empty-dta" *ngIf="myApprovalsDataSource?.length === 0">{{i18n.projectMnt.LBL_NDF}} </div>
            <mat-table [dataSource]="myApprovalsDataSource" *ngIf="myApprovalsDataSource?.data?.length > 0" matSort
                matSortDirection="desc"  matSortActive="createdDate" matSortDisableClear class="table-base">

                <!-- Dataset Name Column-->
                <ng-container matColumnDef="datasetName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="name-header"> Dataset Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="name-cell"> {{element.datasetName}}
                    </mat-cell>
                </ng-container>

                <!-- Dataset ID Column -->
                <ng-container matColumnDef="datasetId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Dataset ID </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.datasetId}} </mat-cell>
                </ng-container>

                <!-- No. of files Column -->
                <ng-container matColumnDef="dataFilesCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> No. of files </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.dataFilesCount}} </mat-cell>
                </ng-container>

                <!-- File Size Column -->
                <ng-container matColumnDef="datasetSize">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Dataset size </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.datasetSize | bytesToMB}}</mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="datasetStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.datasetStatus }} </mat-cell>
                </ng-container>

                <!-- Requested By Column -->
                <ng-container matColumnDef="requestedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Requested By </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.requestedBy }} </mat-cell>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.createdDate | date : 'y-MMM-dd HH:mm:ss'}} </mat-cell>
                    <!-- <mat-cell *matCellDef="let element"> {{element?.createdDate}} </mat-cell> -->
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="action-header"> Actions </mat-header-cell>
                    <mat-cell class="action-cell" *matCellDef="let element">
                        <button  mat-stroked-button color="primary" (click)="datasetDetails(element?.datasetId, element?.datasetStatus)">View </button>
                    </mat-cell>
                </ng-container>

                <!-- Table header -->
                <mat-header-row *matHeaderRowDef="myApprovalsviewColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: myApprovalsviewColumns;"></mat-row>

            </mat-table>
        </mat-card-content>

        <mat-card-footer class="flex-row-reverse">
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                [hidden]="myApprovalsDataSource?.data?.length === 0">
            </mat-paginator>
        </mat-card-footer>
    </mat-card>
</div>