import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RemoveUserComponent } from 'src/app/pages/project-management/user-overview/remove-user/remove-user.component';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { i18n } from 'src/app/i18n.en';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageDataControllerComponent } from '../manage-data-controller/manage-data-controller.component';
import { AuditService } from '../../audit.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class ManageUsersComponent implements OnInit {
  i18n = i18n;
  overviewList: any = [];
  userviewColumns: string[] = ['radio', 'userEmail', 'firstName', 'projectRole', 'status', 'dateCreatedModified'];
  userviewDataSource: any = [];
  profile: any;
  isLoading = false;
  hasNoUserData = false;
  inputSearch: FormControl = new FormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showRemove = false;
  selectedRowElement: any;
  roleId: boolean;
  currentUserPermissions: any;
  projectName: string;
  dataControllerCount: number;
  userEmail: string;
  currentDataController: string;
  projectKey: string;
  orgName: string;
  existingUserId: string;
  existingRoleHashKey: string;
  startAuditStatus: boolean = false;  

  constructor(private matDialog: MatDialog, private readonly router: Router,
    public toastr: ToastrService, private activatedRoute: ActivatedRoute,
    private navifyServ: NavifyauthService, private storageService: StorageService, public service: ProjectManagementService, public commonSer: CommonService,
    private auditService: AuditService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.showRemove = true;
    
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.projectName = queryParams['projectName'];
      //if(queryParams['fromInviteUser'] === true ){
        this.projectKey = queryParams['projectKey'];
        this.orgName = queryParams['orgName'];
      //}
    });
    this.getProjectAuditDetails();
    this.getUserTableData();   
    
  }

  getProjectAuditDetails() {
    this.isLoading = true;
    this.auditService.getAuditDetails(this.projectKey).subscribe(res => {
      if( res['result'].projectDetails.auditSettings.auditStatus === 'In Progress'){
        this.startAuditStatus = true;
      }
      if(!this.startAuditStatus){
        this.userviewColumns = ['userEmail', 'firstName', 'projectRole', 'status', 'dateCreatedModified'];
       }
    })
  }

  getUserTableData() {
    this.service.getProjectUserList(this.projectKey, this.orgName).subscribe((resp:any) => {
    this.overviewList = resp.result;
    (this.overviewList).filter((val: any) => { 
      if(val.roleName === 'Data Controller'){
        this.currentDataController = val.userEmail;
        this.existingUserId = val.userUuid;
        this.existingRoleHashKey = val.roleHashKey;
      }
    });
    this.showRemove = true;
    this.userviewDataSource = new MatTableDataSource(this.overviewList);
    this.userviewDataSource.paginator = this.paginator;
    setTimeout(() => this.userviewDataSource.sort = this.sort);
    setTimeout(() => this.userviewDataSource.paginator = this.paginator);
    this.hasNoUserData = (this.userviewDataSource.data.length === 0) ? true : false;
    this.isLoading = false;
   }) 
 }

async logout() {
  await this.navifyServ.logOut();
}

ngAfterViewInit() {
  this.userviewDataSource.paginator = this.paginator;
  this.userviewDataSource.sort = this.sort;

}
onSelectUser(element: any) {
  this.showRemove = false;
  this.selectedRowElement = element;
  this.userEmail = element.userEmail;
}

backToProjectAudit(){
  this.router.navigate(['projectaudit'], {

  });
}

onRemoveUSer() {
  this.dataControllerCount = (this.overviewList).filter((val: any) => (val.roleName === 'Data Controller' && val.userEmail === this.userEmail)).length;
  const dialogRef = this.matDialog.open(RemoveUserComponent, {
    width: '400px',
    data: {
      isEdit: true,
      selectedRowElement: this.selectedRowElement,
      from: 'project_audit',
      projectName: this.projectName,
      dataControllerCount: this.dataControllerCount
    }
  });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.service.deleteAuditUsers(this.projectKey, this.selectedRowElement.userUuid).subscribe({
          next: (res)=>{ 
            if(res.responseCode==='200'||res.responseCode==='201'){
            this.toastr.success(res.messages);
            window.location.reload();
            this.isLoading = false;
            }else{
              this.toastr.error(res.messages);
            }  
        },
          error: (err)=>{this.toastr.error(i18n.projectMnt.UO.TOASTR_E); this.isLoading = false;},
          complete: ()=>{
            this.isLoading = false;
          }
        });
      }
      else if(result === 'cancel'){
        this.isLoading = false;
      }
    })
  }

manageDataController() {
const dialogRef = this.matDialog.open(ManageDataControllerComponent, {
  width: '600px',
  data: {
    currentDataController: this.currentDataController,
    projectName: this.projectName,
    existingUserId: this.existingUserId,
    projectKey: this.projectKey,
    existingRoleHashKey: this.existingRoleHashKey
  }
});

dialogRef.afterClosed().subscribe((result) => {
  console.log("after close: ", result);
    if (result === 'yes') {
      console.log("inside IF: ");
      window.location.reload();
  }
});
}
}

function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;
  return customPaginatorIntl;
}
