<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto"> <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2"
                            src="./assets/images/organization-managemen-icon-small.svg" alt="" /> </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-title">{{i18n.orgMnt.HEADER}}</span> <span
                            class="me-2 fw-normal">|</span> <span class="fs-6">{{i18n.orgMnt.TITLE}}</span></li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

<div class="cont-fluid cmn_tbl">
    <!-- breadcrumb -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
            <li>
                <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.OM}}</li>
        </ol>
    </nav>

    <mat-card class="users-matcard">
        <mat-card-header>
            <mat-card-title> {{i18n.orgMnt.LBL_OV}}</mat-card-title>
            <div class="search-btn-div">
                <button mat-raised-button class="primary-btn" [disabled]="true"
                    *ngIf="dataSource?.data?.length > 0">{{i18n.orgMnt.BTN_CREATE_ORG}}</button>
            </div>
        </mat-card-header>

        <div class="loadingcontainer">
            <div *ngIf="isLoading" class="loading-user-spinner">
                <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
                </mat-progress-spinner>
            </div>
        </div>

        <mat-card-content class="mhfc">
            <div class="no-data empty-dta" *ngIf="dataSource?.data?.length === 0">{{i18n.orgMnt.LBL_NDF}}</div>

            <mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length > 0" matSort matSortDirection="asc"
                matSortDisableClear class="dm-table user-table">

                <!-- Organization Name Column-->
                <ng-container matColumnDef="OrganizationName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.orgName}}
                    </mat-cell>
                </ng-container>

                <!-- External Collaboration Column -->
                <ng-container matColumnDef="ExternalCollaboration">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> External Collaboration</mat-header-cell>
                    <ng-container *matCellDef="let element">
                        <mat-cell *ngIf="(element.externalCollab ===  true); else noReplies"> Yes</mat-cell>
                    </ng-container>
                    <ng-template #noReplies>
                        <mat-cell> No</mat-cell>
                    </ng-template>
                </ng-container>

                <!-- PO Name Column -->
                <ng-container matColumnDef="POName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{orgKey}} Scientific Director Name</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.poName}} </mat-cell>
                </ng-container>

                <!-- Status PO Column -->
                <ng-container matColumnDef="StatusPO">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{orgKey}} Scientific Director Status</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.poStatus}} </mat-cell>
                </ng-container>

                <!-- Status Organization Column -->
                <ng-container matColumnDef="StatusOrganization">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Organization Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.status}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
                *ngIf="dataSource?.data?.length > 0" class="org">
            </mat-paginator>

        </mat-card-content>
    </mat-card>
</div>