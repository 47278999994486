<div class="approve-popup-card">

    <mat-card>
        <mat-card-header>
            <mat-card-title>Audit Settings</mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
            <p>Do you want to configure the project’s Audit settings now?</p>
       
        </mat-card-content>
        <mat-card-footer>
            <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNo()">No</button>
            <button mat-raised-button (click)="onYes()" [disabled]="">Yes</button>
        </div>
        </mat-card-footer>
    </mat-card>
</div>