<!-- Page Loader -->
<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<mat-card>
    <mat-card-header>
        <mat-card-title class="title-matcard">
            {{i18n.projectAudit.TBL_MANAGE_USERS_TITLE}}
        </mat-card-title>
        
        <div class="search-btn-div">
            <button mat-stroked-button (click)="manageDataController()" [disabled]="!startAuditStatus" *ngIf="userviewDataSource?.data?.length !== 0">{{i18n.projectAudit.LBL_MDC}}</button>
        </div>

    </mat-card-header>
    
    <mat-card-content>
        <div class="no-data empty-dta" *ngIf="userviewDataSource?.data?.length === 0">{{i18n.projectMnt.LBL_NDF}}</div>

        <mat-table [dataSource]="userviewDataSource" *ngIf="userviewDataSource?.data?.length > 0" matSort
            matSortDirection="asc" matSortDisableClear>

            <!-- Radio button column -->
            <ng-container matColumnDef="radio" *ngIf="startAuditStatus">
                <th mat-header-cell *matHeaderCellDef class="radio-btn-headercell">
                </th>
                <td mat-cell *matCellDef="let element" class="radio-btn-cell">

                    <mat-radio-group [(ngModel)]="selectedRowElement" (change)="onSelectUser(element)">
                        <mat-radio-button [value]="element"></mat-radio-button>
                    </mat-radio-group>
                </td>
            </ng-container>

            <!-- User Name Column-->
            <ng-container matColumnDef="userEmail">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Username (Email ID)
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.userEmail}}
                </mat-cell>
            </ng-container>

            <!-- First Name Column -->
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Full Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.firstName+' '+element?.lastName}} </mat-cell>
            </ng-container>

            <!-- Project Role Column -->
            <ng-container matColumnDef="projectRole">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Project Role
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.roleName}} </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Status
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element?.trianingStatus.toLowerCase() === 'completed')?'Active':'Invited'}} 
                </mat-cell>
            </ng-container>

            <!-- Date Created / Modified -->
            <ng-container matColumnDef="dateCreatedModified">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap"> Date Created/Modified
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element?.dateModified) ? (element?.dateModified | date : 'y-MMM-dd HH:mm:ss') : (element?.dateCreated | date : 'y-MMM-dd HH:mm:ss') }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="userviewColumns;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: userviewColumns;"></mat-row>

        </mat-table>

    </mat-card-content>
    <mat-card-footer class="p-10">
        <button mat-stroked-button [disabled]="showRemove || !startAuditStatus" (click)="onRemoveUSer()" *ngIf="currentUserPermissions.projectMgntAccess.inviteRemoveUser && (userviewDataSource?.data?.length !== 0)">{{i18n.projectMnt.UO.BTN_REMOVE}}</button>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true"
            [hidden]="userviewDataSource?.data?.length === 0">
        </mat-paginator>
    </mat-card-footer>

</mat-card>