import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectAuditOverviewComponent } from './project-audit-overview/project-audit-overview.component';
import { ProjectAuditFilesComponent } from './project-audit-files/project-audit-files.component';
import { ConfigureAuditSettingsComponent } from '../project-management/configure-audit-settings/configure-audit-settings.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectAuditOverviewComponent,
  },
  { 
    path: 'projectauditfiles',
    component: ProjectAuditFilesComponent,
   // canActivate: [AuthguardService],
  },
  {
    path: 'configureprojectaudit',
    component: ConfigureAuditSettingsComponent,
    /* canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    data: {
      allowedRoles: [ 
        env.config.roles.imcore_scientific_director
      ]
    } */
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectAuditRoutingModule { }
