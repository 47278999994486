import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { i18n } from 'src/app/i18n.en';
import { Profile } from 'src/app/interfaces/common.interface';
import { CommonService } from 'src/app/services/common.service';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  i18n = i18n;
  role: string;
  profile: Profile;
  firstLetter: string;
  secondLetter:string;
  firstChars:string;
  showResetPwd = false;

  constructor(public auth: NavifyauthService, readonly navifyServ: NavifyauthService, readonly storageService: StorageService, 
    readonly router: Router, readonly service:CommonService) { }
  
  ngOnInit(): void {
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));

    this.role = "platform-admin";
    const email = this.profile.username.toLowerCase();
    this.storageService.secureStorage.setItem('userEmail', email);
    if (email.search('roche.com') === -1) {
      this.showResetPwd = true;
    } else {
      this.showResetPwd = false;
    }
    this.service.rumConfig();
  }

  getShortName() {     
    return this.profile.displayName.split(' ').map((n:string) => n[0]).join('');    
  }

  async logout(){
    this.router.navigate(['/logout'],{replaceUrl: true});
  }

  async reset(){
    await this.navifyServ.resetPwd(this.profile.username);
    // NOT @roche.com, @contractors.roche.com, @businesspartner.roche.com
    // let url = 'https://roche.authapp.appdevus.platform.navify.com/recover?authunm=' + this.profile.username;
  }

}
