<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>
<div class="create-dataset-form-container">
    <mat-grid-list cols="1" rowHeight="50px">
        <mat-grid-tile class="header-close">
            <a>
                <mat-icon (click)="backToDataFile()">{{i18n.ICONS.CLOSE}}</mat-icon>
            </a>
            <h3>{{i18n.projectMnt.CD.TITLE}}</h3>
        </mat-grid-tile>
    </mat-grid-list>
    <div class="cont-fluid">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/applobby">{{i18n.BREADCRUMB.HOME}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a routerLink="/projectmanagement">{{i18n.BREADCRUMB.PM_H}}
                        {{projectName}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a (click)="backToDataIngress()">{{i18n.BREADCRUMB.DI}}</a></li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item"><a (click)="backToDataFile()">{{i18n.BREADCRUMB.DFO}}</a>
                </li>
                <li>
                    <mat-icon>{{i18n.ICONS.NN}}</mat-icon>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{i18n.BREADCRUMB.CD}}</li>
            </ol>
        </nav>
        <mat-card class="container inner-section">
            <h6 mat-dialog-title>{{i18n.projectMnt.CD.TITLE}}</h6>
            <div class="create-dataset-form-wrapper">
                <form [formGroup]="createDataset">
                    <p class="width-60">
                        <mat-form-field appearance="fill" class="dataset-name-form-field">
                            <mat-label>{{i18n.projectMnt.CD.DN}}</mat-label>
                            <input matInput formControlName="datasetName" autocomplete="off" minlength="1"
                                maxlength="75" (keyup)="errorPopup($event, 'dataset-name')"
                                (focusout)="onFocusOutEvent()" />
                            <mat-error class="v-clr"
                                *ngIf="datasetName?.errors?.['required'] && (datasetName?.dirty || datasetName?.touched)">
                                {{i18n.projectMnt.CD.LBL_ERR}}
                            </mat-error>
                            <div class="error-popup" *ngIf="datasetNameErrorPopupDisplay ? true : false">
                                <p>{{i18n.projectMnt.CD.LBL_ERR1}}</p>
                                <div class="float-start small mt-1"
                                    [ngClass]="datasetName?.errors?.['minlength'] || datasetName?.errors?.['maxlength']? 'text-danger':'text-success'">
                                    <mat-icon class="close"
                                        *ngIf="(datasetName?.errors?.['maxlength'] || datasetName?.errors?.['minlength'])">{{i18n.ICONS.CLOSE}}</mat-icon>
                                    <mat-icon class="done"
                                        *ngIf="!datasetName?.errors?.['maxlength'] && (!datasetName?.errors?.['minlength'])">{{i18n.ICONS.DONE}}</mat-icon>
                                    {{i18n.projectMnt.CD.LBL_ERR2}}
                                </div>
                                <div class="float-start small mb-1"
                                    [ngClass]="datasetName?.errors?.['pattern'] ? 'text-danger':'text-success'">
                                    <mat-icon class="close"
                                        *ngIf="datasetName?.errors?.['pattern']">{{i18n.ICONS.CLOSE}}</mat-icon>
                                    <mat-icon class="done"
                                        *ngIf="!datasetName?.errors?.['pattern']">{{i18n.ICONS.DONE}}</mat-icon>
                                    {{i18n.projectMnt.CD.LBL_ERR3}}
                                </div>

                                <div class="float-start small mb-1"
                                    [ngClass]="datasetName?.errors?.['pattern'] ? 'text-danger':'text-success'">
                                    <mat-icon class="close"
                                        *ngIf="datasetName?.errors?.['pattern']">{{i18n.ICONS.CLOSE}}</mat-icon>
                                    <mat-icon class="done"
                                        *ngIf="!datasetName?.errors?.['pattern']">{{i18n.ICONS.DONE}}</mat-icon>
                                    {{i18n.projectMnt.CD.LBL_ERR4}}
                                </div>
                            </div>
                        </mat-form-field>
                    </p>
                    <p class="width-60">
                        <mat-form-field class="dataset-description-form-field" appearance="fill">
                            <mat-label>{{i18n.projectMnt.CD.LBL_DSDES}}</mat-label>
                            <textarea matInput #postalCode formControlName="datasetDescription"
                                maxlength="2000"></textarea>
                            <mat-hint align="end">{{postalCode.value.length}} / 2000</mat-hint>
                            <mat-error class="v-clr" *ngIf="datasetDescription?.errors?.['required']">
                                {{i18n.projectMnt.CD.LBL_ERR}}
                            </mat-error>
                        </mat-form-field>
                    </p>
                </form>
            </div>
        </mat-card>
    </div>

</div>
<div class="footer px-3" align="right">
    <button mat-raised-button class="backToFilesButon"
        (click)="backToDataFile()">{{i18n.projectMnt.CD.BTN_BTF}}</button>
    <button mat-raised-button type="submit" (click)="onCreateDataset(createDataset.value)"
        [disabled]="!createDataset.valid">{{i18n.projectMnt.CD.BTN_CD}}</button>
</div>