import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectManagementComponent } from './project-management.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectFileComponent } from './project-file/project-file.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { AuthguardService } from 'src/app/services/authguard.service';
import { DatasetDetailsComponent } from './dataingress-overview/dataset-details/dataset-details.component';
import { DataingressOverviewComponent } from './dataingress-overview/dataingress-overview.component';
import { MyapprovalsDatasetDetailsComponent } from './my-approvals/myapprovals-dataset-details/myapprovals-dataset-details.component';
//import { DatasetDetailsComponent } from './my-approvals/dataset-details/dataset-details.component';
import { DataFilesListComponent } from './data-files-list/data-files-list.component';
import { CreateDatasetComponent } from './create-dataset/create-dataset.component';
import { environment as env} from 'src/environments/environment';
import { RoleGuard } from 'src/app/services/role.guard';
import { TcGuard } from 'src/app/services/tc.guard';
import { ChildroleGuard } from 'src/app/services/childrole.guard';
import { AddProjectComponent } from './add-project/add-project.component';
import { ProjectFilesTransferDetailsComponent } from './project-files-transfer-details/project-files-transfer-details.component';
// import { ProjectManagementComponent } from './project-management.component';
import {  CanDeactivateGuard } from 'src/app/services/deactivate.guard';

const routes: Routes = [
  {
    path: '',
    component: ProjectManagementComponent,
  },
  { 
    path: 'projectdetails',
    component: ProjectFileComponent,
    canActivate: [AuthguardService],
  },
  {
    path: 'createproject',
    component: AddProjectComponent,
    canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      breadcrumb: 'Create Project', path: 'createproject',
      allowedRoles: [
        env.config.roles.imcore_scientific_director
      ]
    }
  },
  {
    path: 'projecttransfer',
    component: ProjectFilesTransferDetailsComponent,
    canActivate: [AuthguardService],
    data: {
      breadcrumb: 'Project Transfer', path: 'projecttransfer',
      allowedRoles: [
        env.config.roles.data_controller
      ]
    }
  },
  {
    path: 'dataIngress',
    component: DataingressOverviewComponent,
    canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    data: {
      breadcrumb: 'Data Ingress', path: 'dataIngress',
      allowedRoles: [ 
        env.config.roles.imcore_scientific_director,
        env.config.roles.imcore_member,
        env.config.roles.data_controller,
      ]
    }
  },
  {
    path: 'datasetDetails',
    component: DatasetDetailsComponent,
    canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    data: {
      breadcrumb: 'Dataset Details', path: 'datasetDetails',
      allowedRoles: [ 
        env.config.roles.imcore_scientific_director,
        env.config.roles.imcore_member,
        env.config.roles.data_controller,
      ]
    }
  },
  {
    path: 'myapprovalsdatasetdetails',
    component: MyapprovalsDatasetDetailsComponent,
    canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    data: {
      breadcrumb: 'My Approvals Dataset Details', path: 'myapprovalsdatasetdetails',
      allowedRoles: [ 
        env.config.roles.data_controller,
      ]
    }
  },
  {

    path: 'datafileslist',
    component: DataFilesListComponent,
    canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    data: {
      breadcrumb: 'Data Files List', path: 'datafileslist',
      allowedRoles: [ 
        env.config.roles.imcore_scientific_director,
        env.config.roles.imcore_member,
        env.config.roles.data_controller,
      ]
    }
  },
  {
    path: 'createdataset',
    component: CreateDatasetComponent,
    canActivate: [AuthguardService, ChildroleGuard, TcGuard],
    data: {
      breadcrumb: 'Create Dataset', path: 'createdataset',
      allowedRoles: [ 
        env.config.roles.imcore_scientific_director,
        env.config.roles.imcore_member,
        env.config.roles.data_controller,
      ]
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectRoutingModule { }
