<li [matMenuTriggerFor]="appMenu">
    <mat-icon>{{i18n.ICONS.APPS}}</mat-icon>
    <mat-menu #appMenu="matMenu">
        <button mat-menu-item [routerLink]="['/applobby']" routerLinkActive="router-link-active">
            <img src="./assets/images/home-icon.svg" [alt]="i18n.MENU.HOME" /> {{i18n.MENU.HOME}} 
        </button>
        <button 
        mat-menu-item 
        [routerLink]="['/usermanagement']" 
        routerLinkActive="router-link-active" 
        *ngIf="cUPs?.platformUserManagementApp">
            <img src="./assets/images/platform-user-management-icon-smal.svg" [alt]="i18n.MENU.PUM" />
            {{i18n.MENU.PUM}}
        </button>
        <button 
        mat-menu-item 
        [routerLink]="['/organization']" 
        routerLinkActive="router-link-active" 
        *ngIf="cUPs?.orgManagementApp">
            <img src="./assets/images/organization-managemen-icon-small.svg" [alt]="i18n.MENU.OM" />
            {{i18n.MENU.OM}}
        </button>
        <button 
        mat-menu-item 
        [routerLink]="['/projectmanagement']" 
        routerLinkActive="router-link-active" 
        *ngIf="cUPs?.projectManagementApp">
            <img src="./assets/images/project-management-icon-small.svg" [alt]="i18n.MENU.PM" />
            {{i18n.MENU.PM}}
        </button>
        <button 
        mat-menu-item 
        routerLinkActive="router-link-active" 
        *ngIf="cUPs?.pltformSettingsApp">
            <img src="./assets/images/platform-settings-icon-small.svg" [alt]="i18n.MENU.PS" />
            {{i18n.MENU.PS}}
        </button>
        <button (click)="redirectToDataCatalog()"
        mat-menu-item 
         
        routerLinkActive="router-link-active" 
        *ngIf="cUPs?.DataCatalog">
            <img src="./assets/images/data-catalog-icon-small.svg" [alt]="i18n.MENU.DC" />
            {{i18n.MENU.DC}} 
        </button>
        <button 
        mat-menu-item 
        [routerLink]="['/projectaudit']" 
        routerLinkActive="router-link-active" 
        *ngIf="cUPs['performAudit']">
            <img src="./assets/images/project-audit-icon.svg" [alt]="i18n.MENU.PA" />
            {{i18n.MENU.PA}}
        </button>
    </mat-menu>
</li>