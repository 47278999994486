import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { i18n } from 'src/app/i18n.en';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit {
  i18n = i18n;
  fullName: any;
  userName: any;
  overviewList: any = [];
  projectviewDataSource: any = [];
  projectviewColumns = ['projectName', 'roleName', 'trianingStatus'];
  isLoading = false;
  hasNoUserData = false;
  inputSearch: FormControl = new FormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<UserDetailsComponent>, private service: ProjectManagementService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.fullName = this.data.firstName+' '+this.data.lastName;
    this.userName = this.data.userEmail;
    this.getTableData();
 
  }
  
  getTableData(){
    this.isLoading = true;
    this.service.getUserDetails(this.data.userUuid).subscribe((data:any) => {  
    this.overviewList = data.result;
    this.projectviewDataSource = new MatTableDataSource(this.overviewList);
    this.projectviewDataSource.paginator = this.paginator;
    setTimeout(() => this.projectviewDataSource.sort = this.sort);
    setTimeout(() => this.projectviewDataSource.paginator = this.paginator);
    this.hasNoUserData = (this.projectviewDataSource.data.length === 0) ? true : false;
    this.isLoading = false;
  })
}
  closeDialog() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    setTimeout(()=>this.projectviewDataSource.paginator = this.paginator); 
    setTimeout(()=>this.projectviewDataSource.sort = this.sort);  
  }

}
