import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { i18n } from 'src/app/i18n.en';
import { CommonService } from 'src/app/services/common.service';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuditService } from '../../audit.service';
import { AuditResultComponent } from '../audit-result/audit-result.component';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {
  i18n = i18n;
  datasource: any = [];
  userviewColumns: string[] = ['latestAuditDate', 'auditedBy', 'auditResult', 'actions'];
  userviewDataSource: any = [];
  profile: any;
  isLoading = false;
  hasNoUserData = false;
  inputSearch: FormControl = new FormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showRemove = false;
  selectedRowElement: any;
  roleId: boolean;
  currentUserPermissions: any;
  dataControllerCount: number;
  userEmail: string;
  currentDataController: string;
  @Input('projectKey') projectKey;
  @Input('projectName') projectName;

  constructor(private matDialog: MatDialog, private readonly router: Router,
    public toastr: ToastrService, 
    private navifyServ: NavifyauthService, private storageService: StorageService,
     public service: AuditService, public commonSer: CommonService) { }

  ngOnInit(): void {
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.getAuditHistoryTblData();
  }

  getAuditHistoryTblData() {
    this.isLoading = true;
    this.service.getAuditHIstory(this.projectKey).subscribe((res: any) => {
      this.datasource = new MatTableDataSource(res.result); 
      this.datasource.sortingDataAccessor = (item:any, property:any) => {
        switch(property) {
          case 'auditResult': return item['auditResult']?.['auditResult'];          
          default: return item[property];
        }
      };
      setTimeout(() => this.datasource.sort = this.sort);
      setTimeout(() => this.datasource.paginator = this.paginator);
      this.isLoading = false;
    })
  }

  async logout() {
    await this.navifyServ.logOut();
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  backToProjectAudit() {
    this.router.navigate(['projectaudit'], {

    });
  }

  auditResultOnView(element) {
    this.matDialog.open(AuditResultComponent, {
      width: '550px',
      data: {
        isEdit: true,
        selectedRowElement: element,
        from: 'project_audit',
        dataControllerCount: this.dataControllerCount
      }
    });   
  }

}


