<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto" [routerLink]="['/applobby']" routerLinkActive="router-link-active" > <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2"
                            src="./assets/images/home-icon.svg" alt="" /> </li>
                    <li class="px-2 w-auto my-auto"><span class="me-2 project-title">starCAP Platform </span> </li>
                </ul>
            </mat-grid-tile>
            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>
                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

<div class="container">
    <mat-card class="pnf">
        <mat-card-content>
            <img src="./assets/images/404.jpg" alt="404" />
        </mat-card-content>
    </mat-card>
</div>