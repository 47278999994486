import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../custom-validators';
import { NavifyauthService } from '../../../services/navifyauth.service';
import { validateAuthSession } from '@navify-platform/auth';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { environment as env, environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmOnCancelComponent } from 'src/app/shared/confirm-on-cancel/confirm-on-cancel.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { i18n } from 'src/app/i18n.en';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateProjectComponent implements OnInit {
  i18n = i18n;
  dataRegion: any;
  dataClassification: any;
  errMsg: any;
  alphacheck: any;
  alphanumcheck: any;
  minlengthcheck: any;
  hasNoData = false;
  dataSource: any;
  projectName: any;
  createProject: FormGroup;
  projectNameErrorPopupDisplay = false;
  wbsCodeErrorPopupDisplay = false;
  isLoading = false;
  siteNameList: any;
  public editData: any = {};
  isEditProject = false;
  projDetailsData: any;
  specialCharCheck: boolean;
  constructor(private readonly matDialog: MatDialog, private storageService: StorageService, private router: Router, public activatedRoute: ActivatedRoute, private projSer: ProjectManagementService, private readonly toastrService: ToastrService) { }
  get required() { return this.createProject.get('wbsCode')?.errors?.['required']; }
  get hasSpecialChr() { return this.createProject.get('wbsCode')?.errors?.['hasSpecialCharacters']; }
  get max() { return this.createProject.get('wbsCode')?.errors?.['maxlength']; }
  get maxProjectName() { return this.createProject.get('projectName')?.errors?.['maxlength']; }
  get projectname() { return this.createProject.get('projectName'); }
  orgKey:any = environment.config.orgKey;
  
  ngOnInit(): void {
    this.getRegionList();
    this.getSiteNameList();
    this.getClassificationList();

    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.editData = queryParams;
    });
    history.pushState(null, '', window.location.href);
    
    let projectDes:FormControl;
    if(this.isEditProject){
     projectDes = new FormControl('', [Validators.required, Validators.maxLength(256)]);

    }else{
     projectDes = new FormControl('', [Validators.required, Validators.maxLength(2000)]);
    } 
    
    this.createProject = new FormGroup({
      'projectName': new FormControl('', [Validators.required, Validators.maxLength(20), this.ProjectNameValidator,
      this.ProjectNameAlphanumValidator, this.ProjectNameSpecialCharValidator, this.SpaceValidator]),
      'region': new FormControl({}, Validators.required),
      'siteName': new FormControl([], Validators.required),
      'projectDescription': projectDes,
      'dataClassification': new FormControl([], Validators.required),
      'publishToOrgData': new FormControl('No', Validators.required),
    });

    // Edit project   
    if (this.editData.projectKey !== undefined) {
      this.isEditProject = true;
      this.getProjectDetails(this.editData.projectKey);
      this.createProject.patchValue({
        projectName: this.editData.projectName,
        region: { regionValue: this.editData.regionValue, regionName: this.editData.regionName},
        siteName: this.editData.siteName,
        publishToOrgData: this.editData.publishToOrgData,
        projectDescription: this.editData.projectDescription,
        dataClassification: {
          name: this.editData.name,
          type: this.editData.type,
          classificationcode: this.editData.classificationcode
        }
      });

    } else {
      this.isEditProject = false;
    }    
  }

  compareById(o1: any, o2: any) {
    return o1.classificationcode === o2.classificationcode;
  }
  compareByRegionValue(o1: any, o2: any) {
    return o1.regionValue === o2.regionValue;
  }
  // form validation
  SpaceValidator(control: FormControl) {
    const prjname: string = control.value;
    if (prjname.indexOf(' ') >= 0) {
      return { cannotContainSpace: true }
    }
    return null;
  }

  ProjectNameValidator(control: FormControl) {
    const prjname: string = control.value;
    if (!(/[a-zA-Z]/).test(prjname.charAt(0))) {
      return {
        alphabet: "not valid"
      }
    }
    return null;
  }

  ProjectNameAlphanumValidator(control: FormControl) {
    const prjname: string = control.value;
    if ((!/^[a-zA-Z0-9]*$/.test(prjname.substring(1, 5))) || !prjname.substring(1, 5)) {
      return {
        alphanum: "not valid"
      }
    }
    return null;
  }

  ProjectNameSpecialCharValidator(control: FormControl) {
    const prjname: string = control.value;
    if ((!/^[a-zA-Z0-9+=._:/@-]*$/.test(prjname.substring(4))) || !prjname.substring(4)) {
      return {
        specialchar: "not valid"
      }
    }
    return null;
  }
  projectNameErrorPopup(event: any) {
    const textLength = event.target.value;
    if ((textLength.length) > 0)
      this.projectNameErrorPopupDisplay = true;
    else
      this.projectNameErrorPopupDisplay = false;
  }
  errorPopup(event: any, field: any) {
    const textLength = event.target.value;
    if ((textLength.length) > 0) {
      if (field === 'project-name')
        this.projectNameErrorPopupDisplay = true;
      else if (field === 'wbscode')
        this.wbsCodeErrorPopupDisplay = true;
    } else {
      this.projectNameErrorPopupDisplay = false;
      this.wbsCodeErrorPopupDisplay = false;
    }

  }

  onFocusOutEvent() {
    this.projectNameErrorPopupDisplay = false;
    this.wbsCodeErrorPopupDisplay = false;
  }
  // end

  onAdd(obj:any) {
    this.isLoading = true;
    obj.orgKey = environment.config.orgKey;
    obj.userId = this.storageService.secureStorage.getItem('userUuid');
    this.projSer.createProject(obj).subscribe({
      next: (data: any) => {
        this.toastrService.success(data.messages[0]);
        setTimeout(() => {
          this.router.navigate(['/projectmanagement']);
        }, 2000);
      },
      error: (err) => {
        this.isLoading = false;
        this.toastrService.error(err.error.error[0].errorDetails);
      }
    });
  }

  onEdit(payload: any) {
    this.isLoading = true;    
    this.projDetailsData.siteName = payload.siteName;
    this.projDetailsData.region = payload.region;
    this.projDetailsData.dataClassification = payload.dataClassification;
    this.projDetailsData.projectName = payload.projectName;
    this.projDetailsData.projectDescription = payload.projectDescription;
    this.projDetailsData.publishToOrgData = payload.publishToOrgData;
    this.projDetailsData.userId = this.storageService.secureStorage.getItem('userUuid');

    this.projSer.editProject(this.projDetailsData).subscribe({
      next: (data: any) => {
        this.toastrService.success(data.messages[0]);
      },
      error: (err) => {
        this.isLoading = false;
        this.toastrService.error(err.error.error[0].errorDetails);
      },
      complete: () => {
        this.router.navigate(['projectmanagement/projectdetails'], {
          queryParams: this.editData
        });
        this.isLoading = false;
      }
    });
  }

  getProjectDetails(projectKey:string){
    this.isLoading = true;
    this.projSer.getProjectDetails(projectKey).subscribe({
      next: (data:any)=>{
        this.projDetailsData  = data.result;
      },
      error: (err)=>{this.toastrService.error(err.error.error[0].errorDetails);},
      complete: ()=>{
        this.isLoading = false; 
      }

    })
  }

  onCancelOrBack() {
    this.router.navigate(['projectmanagement/projectdetails'], {
      queryParams: this.editData
    });
  }

  onDecline() {
    const dialogRef = this.matDialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: {
        title: 'All the details will be lost',
        confiramtion_text: 'Are you sure you want to exit project creation?',
        accept_btn: 'Yes',
        decline_btn: 'No'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {      
      if(result==='accept'){
        this.router.navigate(['projectmanagement']);
      }
    })
    
  }

  onCancel(){
    this.router.navigate(['projectmanagement']);
  }

  onAccepted() {
    this.router.navigate(['projectmanagement']);
  }

  getRegionList() {
    this.isLoading = true;
    this.projSer.getRegion().subscribe((res: any) => {
      this.dataRegion = res.result;
      //region defaulted to Europe(Frankfurt) in create
     if(!this.isEditProject){     
      this.createProject.get('region')?.setValue(this.dataRegion[0]);
    }
      this.isLoading = false;
    });
     
  }

  getClassificationList() {
    this.projSer.getClassification().subscribe((res: any) => {
      this.dataClassification = res.result;
    });
  }

  getSiteNameList() {
    this.projSer.getSiteNames().subscribe({
      next: (res: any) => {
        this.siteNameList = res.result;
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    });
  }

  @HostListener('window:popstate', ['$event'])  
  onBrowserBackBtn(event: Event) { 
    history.pushState(null, '', window.location.href);  
    this.onDecline();
  }  
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event:any) {  
    event.returnValue = 'Your data will be lost!';    
    return false;
  }
}
