<!-- Page Loader -->
<div class="loadingcontainer">
    <div *ngIf="isLoading" class="loading-modal-spinner">
        <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
        </mat-progress-spinner>
    </div>
</div>

<div class="cmn_tbl">
    <mat-card class="bsn">
        <mat-card-header>
            <mat-card-title class="title-matcard">
                {{i18n.projectMnt.collaborators.TITLE}}
            </mat-card-title>
            <div class="search-btn-div">
                <button mat-stroked-button (click)="manageDataController()"
                    *ngIf="service.permission(roleId, 'inviteRemoveUser')">Manage Data Controller</button>
                <button mat-raised-button (click)="onInviteUser()" *ngIf="service.permission(roleId, 'inviteRemoveUser')">{{i18n.projectMnt.collaborators.BTN_IC}}</button>
            </div>

        </mat-card-header>
        <mat-card-content>
            <div class="no-data empty-dta" *ngIf="collaboratorDataSource?.data?.length === 0">{{i18n.LBL_NDF}}</div>
            <mat-table [dataSource]="collaboratorDataSource" *ngIf="collaboratorDataSource?.data?.length > 0" matSort
                matSortDirection="asc" matSortDisableClear class="table-base">

                <!-- Radio button column -->
                <ng-container matColumnDef="radio" *ngIf="service.permission(roleId, 'inviteRemoveUser')">
                    <th mat-header-cell *matHeaderCellDef class="radio-btn-headercell"> </th>
                    <td mat-cell *matCellDef="let element" class="radio-btn-cell">                        
                        <mat-radio-group [(ngModel)]="selectedCollaboratorRow" (change)="onSelectCollaborator(element)">
                            <mat-radio-button [value]="element"></mat-radio-button>
                        </mat-radio-group>
                    </td>
                </ng-container>

                <!-- Project Name Column-->
                <ng-container matColumnDef="userEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="name-header"> Username(Email ID)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="name-cell"> {{element.userEmail}}
                    </mat-cell>
                </ng-container>

                <!-- Project ID Column -->
                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Full Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.firstName+' '+element?.lastName}} </mat-cell>
                </ng-container>

                <!-- Organization Role Column -->
                <ng-container matColumnDef="roleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Project Role </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.roleName}} </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="trianingStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{(element?.trianingStatus.toLowerCase()==='completed')?'Active':'Invited'}} </mat-cell>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="dateCreated">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Date Created/Modified </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element?.dateModified !=null ? (element?.dateModified | date : 'y-MMM-dd HH:mm:ss') : (element?.dateCreated | date : 'y-MMM-dd HH:mm:ss')}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="action-header"> Actions </mat-header-cell>
                    <mat-cell class="action-cell" *matCellDef="let element">
                        <button mat-stroked-button (click)="onEditCollaborator(element)" *ngIf="service.permission(roleId, 'editUserRole')">{{i18n.projectMnt.collaborators.BTN_EDIT}}</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="collaboratorviewColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: collaboratorviewColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
        <mat-card-footer>
            <button mat-stroked-button [disabled]="showRemove" (click)="onRemoveCollaborator()" *ngIf="service.permission(roleId, 'inviteRemoveUser')" [hidden]="collaboratorDataSource?.data?.length === 0">{{i18n.projectMnt.collaborators.BTN_R}}</button>
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [showFirstLastButtons]="true" [hidden]="collaboratorDataSource?.data?.length === 0">
            </mat-paginator>
        </mat-card-footer>
    </mat-card>
</div>