import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  auditOverviewUrl = environment.config.domain + environment.projList;
  auditdetailsUrl = environment.config.domain + environment.projectDetails;
  startAuditUrl = environment.config.domain + environment.startAuditUrl;
  auditResultSubmitUrl = environment.config.domain + environment.submitAuditResult;
  getAuditHistoryUrl = environment.config.domain + environment.auditHistory;

  constructor(private readonly http: HttpClient) { }

  getProjects(obj) {
    return this.http.post(this.auditOverviewUrl, obj);
  }
  getAuditDetails(projectKey) {
    return this.http.get(`${this.auditdetailsUrl}${projectKey}/${environment.config.orgKey}`);
  }
  getStartAudit(obj) {
    return this.http.post(this.startAuditUrl, obj);
  }
  getauditResult(obj,auditId) {
    return this.http.put(this.auditResultSubmitUrl+'/'+auditId, obj);
  }
  getAuditHIstory(projectKey){
    return this.http.get(this.getAuditHistoryUrl+'/'+projectKey);
  }
}
