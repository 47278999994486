import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../pages/project-management/confirmation-popup/confirmation-popup.component';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class FileProgressGuard implements CanActivate {
    constructor(private router: Router, private readonly commonService: CommonService, private readonly matDialog: MatDialog) { }
    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const fileProgressList = Object.values(this.commonService.dataIngressFileUploadData)
        const filteredData = fileProgressList.filter((fd: any) => fd['status'] === 'INP')
        if (filteredData.length !== 0) {
            const dialogRef = this.matDialog.open(ConfirmationPopupComponent, {
                width: '400px',
                data: {
                    title: 'Warning',
                    confiramtion_text: `Upload in progress. If you log off now,
                                  all progress will be lost. 
                                  Are you sure want to cancel the uploads`,
                    accept_btn: 'Yes',
                    decline_btn: 'No'
                }
            });
            return await firstValueFrom(dialogRef.afterClosed())
                .then(result => {
                    let response = false
                    if (result === 'accept') {
                        response = true
                    }
                    return Promise.resolve(response)
                });

        }
        else {
            return true
        }

    }

}
