<div class="cont">
    <div class="header">
        <mat-grid-list cols="2" rowHeight="50px">
            <mat-grid-tile class="left">
                <ul>
                    <li class="my-auto"> 
                        <img class="my-auto px-2 border border-2 rounded-circle px-1 py-2" src="./assets/images/home-icon.svg" alt="" /> </li>
                    <li class="pe-2 w-auto my-auto"><span class="me-2 project-title">{{i18n.HEADER.SP}} </span> </li>
                </ul>
            </mat-grid-tile>

            <mat-grid-tile class="right">
                <ul class="text-right">
                    <app-app-menu></app-app-menu>
                    <app-profile-menu></app-profile-menu>

                    <li><img src="./assets/images/Roche_logo_global_header.svg" alt="Logo" /></li>
                </ul>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
<div class="container py-4 applobby">
    <div class="loadingcontainer">
        <div *ngIf="isLoading" class="loading-user-spinner">
            <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
            </mat-progress-spinner>
        </div>
    </div>
    <div>
        <p class="fs-4 mb-4 mt-3">{{i18n.WELCOME}} <span class="fw-bold">{{firstname}}!</span></p>
        <div *ngIf="currentUserPermissions?.platformUserManagementApp" class="row">
            <div class="col-12 col-md-12 col-lg-4">
                <div class="row bg-white border border-2 me-md-1 rounded box-padding">
                    <div class="col-4 my-auto">
                        <img src="/assets/images/platform-user-management-icon.svg" alt="platform-user-management" /></div>
                    <div class="col-8">
                        <h3>
                            <a routerLink="/usermanagement">
                                {{i18n.MENU.PUM}}
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4" *ngIf="currentUserPermissions?.orgManagementApp">
                <div class="row bg-white border border-2 me-md-1 rounded box-padding">
                    <div class="col-4 my-auto"><img src="/assets/images/organization-managemen-icon.svg" alt="organization-managemen" /></div>
                    <div class="col-8">
                        <h3>
                            <a routerLink="/organization">
                                {{i18n.MENU.OM}}
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4" *ngIf="currentUserPermissions?.pltformSettingsApp">
                <div class="row bg-white border border-2 me-md-1 rounded box-padding">
                    <div class="col-4 my-auto"><img src="/assets/images/platform-settings-icon.svg" alt="latform-settings" /></div>
                    <div class="col-8">
                        <h3>
                            <a>
                                {{i18n.MENU.PS}}
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="currentUserPermissions?.projectManagementApp" class="row">
            <div class="col-12 col-md-12 col-lg-4">
                <div class="row bg-white border border-2 me-md-1 rounded box-padding">
                    <div class="col-4 my-auto"><img src="/assets/images/project-management-icon.svg" alt="Project Management" /></div>
                    <div class="col-8">
                        <h3>
                            <a routerLink="/projectmanagement">
                                {{i18n.MENU.PM}}
                            </a>
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-12 col-lg-4" *ngIf="currentUserPermissions?.DataCatalog">
                <div class="row bg-white border border-2 me-md-1 rounded box-padding">
                    <div class="col-4 my-auto"><img src="/assets/images/data-catalog-ccon.svg" alt="data-catalog" /></div>
                    <div class="col-8">
                        <h3>
                            <a [routerLink]="['/datacatalog']" [innerHTML]="i18n.MENU.DC_2LINES"></a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4" *ngIf="currentUserPermissions['performAudit']">                
                <div class="row bg-white border border-2 me-md-1 rounded box-padding">
                    <div class="col-4 my-auto"><img src="/assets/images/project-audit-icon.svg" alt="project-audit" /></div>
                    <div class="col-8">
                        <h3>
                            <a [routerLink]="['/projectaudit']" [innerHTML]="i18n.MENU.PA_2LINES"></a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3 second-row">
            <div class="col-sm-4 col-md-12 col-lg-6">
                <div class="row bg-white border border-2 me-md-1 height rounded box-padding">
                    <div class="mb-3 title">{{i18n.applobby.LBL_PH}}</div>
                    <div class="scrollbar" id="style-1">
                        <p>{{i18n.applobby.DUMMY_CNT}}</p>
                        <p>{{i18n.applobby.DUMMY_CNT}}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-md-12 col-lg-3">
                <div class="row bg-white border border-2 me-md-1 overflow-auto height rounded box-padding">
                    <div class="mb-3 title">{{i18n.applobby.LBL_IL}}</div>
                    <div class="row small pe-0 important-links">
                        <div class="col-6">
                            <div class="heading">{{i18n.applobby.LBL_HEADING}}</div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 1</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 2</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 3</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 4</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 5</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 6</a></div>
                        </div>
                        <div class="col-6">
                            <div class="heading">{{i18n.applobby.LBL_HEADING}}</div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 1</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 2</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 3</a></div>
                            <div class="heading">{{i18n.applobby.LBL_HEADING}}</div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 4</a></div>
                            <div class="small my-1"><a href="#" class="important-link text-decoration-none">{{i18n.applobby.ILS}} 5</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-md-12 col-lg-3 text-center">
                <div class="row bg-white border border-2 me-md-1 overflow-auto height rounded box-padding">
                    <div class="mb-3 title">{{i18n.applobby.LBL_SD}}</div>
                    <div class="mx-auto my-5 w-50"><img src="/assets/images/starcap-documentation-icon.svg" alt="starcap-documentation" />
                    </div>
                    <div>
                        <button class="get-started-button">{{i18n.applobby.BTN_GS}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>