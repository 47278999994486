import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NavifyauthService } from 'src/app/services/navifyauth.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserDetailsComponent } from './user-details/user-details.component';
import { RemoveUserComponent } from './remove-user/remove-user.component';
import { ProjectManagementService } from 'src/app/pages/project-management/project-management.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { i18n } from 'src/app/i18n.en';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]  
})
export class UserOverviewComponent implements OnInit {
  i18n = i18n;
  overviewList: any = [];
  userviewColumns: string[] = ['radio', 'userEmail', 'firstName', 'action'];
  userviewDataSource: any = [];
  profile: any;
  isLoading = false;
  hasNoUserData = false;
  showSearchCancelBtn: boolean;
  inputSearch: FormControl = new FormControl('');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showRemove = false;
  selectedRowElement: any;
  orgRoleId: any;
  roleId: boolean;
  showRemoveBtn: boolean;
  currentUserPermissions: any;
  userEmail:string;
  dataControllerCount:number = 0;
  isUserHasDcRole: boolean = false;
  constructor(private matDialog: MatDialog, 
    public toastr: ToastrService,
    private navifyServ: NavifyauthService, private storageService: StorageService, public service: ProjectManagementService, public commonSer: CommonService) { }

  ngOnInit(): void {
    this.currentUserPermissions = JSON.parse(sessionStorage.getItem('currentUserPermissions')!);
    this.profile = JSON.parse(this.storageService.secureStorage.getItem('profile'));
    this.showRemove = true;
    if(!this.currentUserPermissions.projectMgntAccess.inviteRemoveUser){
     this.userviewColumns = ['userEmail', 'firstName', 'action'];
    }
    this.getUserTableData();  
  }
  
  getUserTableData() {   
     this.isLoading = true;
     const orgKey = environment.config.orgKey;
    this.service.getUserOverview(orgKey).subscribe((resp:any) => {
      this.overviewList = resp.result;
      this.showRemove = true;
      this.userviewDataSource = new MatTableDataSource(this.overviewList);
      this.userviewDataSource.paginator = this.paginator;
      setTimeout(() => this.userviewDataSource.sort = this.sort);
      setTimeout(() => this.userviewDataSource.paginator = this.paginator);
      this.userviewDataSource.filterPredicate = function(data:any, filter: string): boolean {
        const fullName = data.firstName.toLowerCase()+' '+data.lastName.toLowerCase();  
        return data.userEmail.toLowerCase().includes(filter)||fullName.includes(filter)||!filter;
        
    }; 
      this.hasNoUserData = (this.userviewDataSource.data.length === 0) ? true : false;
      this.isLoading = false;
    }) 
  }

  applyFilter(event: Event) {
    this.showSearchCancelBtn = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.userviewDataSource.filter = filterValue.trim().toLowerCase();
    if(!filterValue){
      this.showSearchCancelBtn = false;
    }
  }

  onSearchCancelBtn() {
    this.showSearchCancelBtn = false;
    this.inputSearch.reset();
    this.getUserTableData();

  }
  async logout() {
    await this.navifyServ.logOut();
  }

  onViewProjects(element: any) {
    const dialogRef = this.matDialog.open(UserDetailsComponent, {
      width: '700px',
      data: element
    });
  }


  ngAfterViewInit() {
    this.userviewDataSource.paginator = this.paginator;
    this.userviewDataSource.sort = this.sort;

  }
  onSelectUser(element: any) {
    this.showRemove = false;
    this.selectedRowElement = element;
    this.userEmail = element.userEmail;
  }
  onRemoveUSer() {
    this.service.checkUserHasDCRole(this.selectedRowElement.userUuid).subscribe((resp: any) => {
      this.isUserHasDcRole = resp.result;
      const dialogRef = this.matDialog.open(RemoveUserComponent, {
      width: '400px',
      height: '200px',
      data: {
        isEdit: true,
        selectedRowElement: this.selectedRowElement,
        from: 'userManagement',
        hasDataControllerRole: this.isUserHasDcRole 
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isLoading = true;
      if (result === 'yes') {
        this.service.deleteProjUsers(environment.config.orgKey, this.selectedRowElement.userUuid).subscribe({
          next: (res)=>{ 
            if(res.responseCode==='200'||res.responseCode==='201'){
            this.toastr.success(i18n.projectMnt.UO.TOASTR_S);
            this.getUserTableData();
            this.isLoading = false;
            }else{
              this.toastr.error(res.message);
            }  
        },
          error: (err)=>{this.toastr.error(i18n.projectMnt.UO.TOASTR_E); this.isLoading = false;},
          complete: ()=>{
            this.isLoading = false;
          }
        });
      }
      else if(result === 'cancel'){
        this.isLoading = false;
      }
    })
  })
  }
}
function CustomPaginator(): any {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = i18n.projectMnt.PAGINATION;

  return customPaginatorIntl;
}





